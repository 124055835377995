import { LocalDate } from 'js-joda';
import { toLocalDate } from '@lifetools/shared-utils-time';

/**
 * Converts a route date, which can include special values like `today` or `tomorrow`, into a
 * regular date.
 *
 * @param routeDate - The route date to convert to a normal date
 *
 * @returns A date object representing the route date, or `undefined` no date was passed or the date
 *          was invalid
 */
export function fromRouteDate(routeDate?: string): LocalDate | undefined {
  switch (routeDate) {
    case 'today':
      return LocalDate.now();

    case 'tomorrow':
      return LocalDate.now().plusDays(1);

    default:
      return routeDate ? toLocalDate(routeDate) : undefined;
  }
}
