/**
 * Returns the display name of the component.
 *
 * @param {React.Component} component a React component
 *
 * @return {string} The display name of the component, or `Component` if none could be found.
 */
export default function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}
