import React, { Component, Fragment } from 'react';
import Form from 'components/library/Form';
import withStyles from 'helpers/withStyles';
import repeatOptions from 'utils/todos/repeatOptions';
import repeatTypes from 'utils/todos/repeatTypes';
import parseRepeatType from 'utils/todos/parseRepeatType';
import { parseTagText } from 'utils/todos/parseTagText';

const fieldsI18nPath = 'data.todos.fields';

const styles = theme => ({
  defaultDuration: {
    display: 'block',
  },
});

const repeatOnSpecificDays = ({ repeatType }) => repeatType === 'specificDays';

class ActivityEditForm extends Component {
  /**
   * The validation schema for this form.
   */
  static schema = {
    title: {
      type: 'string',
      validation: [
        'required',
      ],
    },
  };

  // HACK: Currently there is no way for the form to return only the field that changed. Until we
  //       add this, the `editFields` static prop is used by the `TodoTableEditor` to only save
  //       these fields as the "changes". [twl 13.Oct.18]
  //
  // TODO: Add changes functionality to the Formik form, then remove this hack. [twl 13.Oct.18]
  static editFields = [ 'title', 'tags', 'desc', 'repeat', 'defaultDuration' ];

  /**
   * The path to the translation keys for this form.
   */
  static i18nPath = 'components.app.todos.TodoEditDialog.components.ActivityEditForm';

  /**
   * Returns a new data object to use as the default `value` when using this form to add a new item.
   *
   * @returns {object} a new data object
   */
  static getDefaultValue = () => ({
    type: 'activity',
    repeat: {
      weekly: repeatTypes.daily,
    }
  });

  /**
   * Parses the `value` into a flat object containing the values for the form.
   *
   * @param {object} value a data object
   *
   * @returns {object} a form object
   */
  static toFormValues = ({ tags, repeat, ...values }) => ({
    ...values,
    tagText: tags ? tags.join(', ') : '',
    repeatType: parseRepeatType(repeat),
    repeatDays: repeat.type === 'specificDays' ? repeat.weekly : [],
  });
  /**
   * Parses the values returned from the form into a data object.
   *
   * @param {object} value a form object
   *
   * @returns {object} a data object
   */
  static fromFormValues = ({ id, tagText, repeatType, repeatDays, defaultDuration, ...values }) => ({
    ...values,
    tags: parseTagText(tagText),
    repeat: repeatType === 'specificDays' ? {
      type: parseRepeatType({ weekly: repeatDays }) || repeatType,
      weekly: repeatDays,
    } : {
      type: repeatType,
      weekly: repeatTypes[repeatType],
    },
    // NOTE: We can't pass undefined values *unless* we're updating (i.e. `id` is set) or an error
    //       occurs This allows `defaultDuration` to be properly set to `undefined` *only* during
    //       updates, but set to any other value always. [twl 5.Apr.21]
    ...id && { id },
    ...(id || defaultDuration) && { defaultDuration },
  });

  /**
   * Renders the fields for this form. The `value` will be provided by the form this is inserted
   * into.
   */
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Form.TextField i18nPath={fieldsI18nPath} name="title" fullWidth autoFocus />

        <div>
          <Form.SelectorField i18nPath={fieldsI18nPath} name="repeatType" options={repeatOptions} />
          <Form.DayOfWeekSelectorField
            showIf={repeatOnSpecificDays}
            i18nPath={fieldsI18nPath}
            name="repeatDays"
          />
        </div>

        <Form.DurationField
          className={classes.defaultDuration}
          i18nPath={fieldsI18nPath}
          name="defaultDuration"
        />
        <Form.TextField i18nPath={fieldsI18nPath} name="tagText" fullWidth />
        <Form.TextField i18nPath={fieldsI18nPath} name="desc" multiline fullWidth rowsMax="10" />
      </Fragment>
    );
  }
}

export default withStyles(styles)(ActivityEditForm);
