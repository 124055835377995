import React from 'react';
import { compose } from 'recompose';
import Icon from 'components/library/Icon';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'data.todos.fields';

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing.unit / 2,
    alignItems: 'center',

    '& i': {
      fontSize: '1.5rem',
      marginRight: theme.spacing.unit,
    },

    'label + &': {
      marginTop: theme.spacing.unit * 2.5,        // extra .5 is to mimic extra margin with Input
    },
  },
});

const TodoType = ({ value, classes, t }) => (
  <div className={classes.root}>
    <Icon type={t(i18nPath, `type.options.${value}.icon`)} />
    <Typography variant="body2">{t(i18nPath, `type.options.${value}.label`)}</Typography>
  </div>
);

export default compose(
  withStyles(styles),
  withTranslations,
)(TodoType);
