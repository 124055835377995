import React from 'react';
import classnames from 'classnames';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    flex: '0 0 auto !important',                // Prevent layout overriding with more specificity
    background: theme.palette.grey['300'],
    textAlign: 'center',
    padding: theme.spacing.unit * 4,
    position: 'relative',
    borderBottom: `solid 1px ${ theme.palette.grey['400']}`,
  },
  close: {
    position: 'absolute',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    color: theme.palette.grey['700'],

    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 0,
    },
  },
});

const NotificationBar = ({ className, onClose, children, classes }) => (
  <div className={classnames(classes.root, className)}>
    {onClose &&
      <IconButton className={classes.close} color="inherit" onClick={onClose}>
        <Icon type="fas:times" />
      </IconButton>
    }

    {children}
  </div>
);

export default withStyles(styles)(NotificationBar);
