import React from 'react';
import sentry from 'services/sentry';
import Button from 'components/library/ButtonMui';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'components.code.ErrorBoundary';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',

    '& button': {
      margin: theme.spacing.unit,
    },

    // Inject this as a global style using `.not(&)`. Without `&`, the style is not injected
    '.sentry-error-embed:not(&) button[type=submit]': {
      background: theme.palette.primary.main,
      color: theme.palette.grey['A400'],
      border: 'none',
      borderRadius: theme.spacing.unit,

      '&:hover': {
        background: theme.palette.primary.dark,
      },
    }
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    console.error(error, info);

    sentry.withScope(scope => {
      scope.setExtras(info);

      const eventId = sentry.captureException(error);

      this.setState({ eventId });
    });
  }

  render() {
    const { classes, t } = this.props;

    if (this.state.hasError) {
      return (
        <div className={classes.root}>
          <h1>{t(i18nPath, 'title')}</h1>

          <Button
            variant="contained"
            color="primary"
            onClick={() => sentry.showReportDialog({ eventId: this.state.eventId })}
          >
            {t(i18nPath, 'button-feedback')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
          >
            {t(i18nPath, 'button-reload')}
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(withTranslations(ErrorBoundary));
