import { LocalDate } from 'js-joda';
import { isBeforeToday } from '@lifetools/shared-utils-time';
import selectScheduleByDate from '../../../services/store/schedules/selectors/selectScheduleByDate';

export const isPlanEditable = (date: LocalDate, state: any): boolean => {
  const today = LocalDate.now();
  const isYesterday = today.minusDays(1).equals(date);

  // Editable only for today and future dates, unless the user hasn't created a plan yet today, in
  // which case yesterday is editable until they do. All dates are editable when accessed via the
  // Today route, keeping open that backdoor for now
  return (isYesterday && !selectScheduleByDate(state, today))
    || window.location.pathname.startsWith('/plan/')
    || !isBeforeToday(date);
}
