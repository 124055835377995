import get from 'lodash/get';
import React from 'react';
import Icon from 'components/library/Icon';
import AsyncButton from 'components/library/AsyncButton';
import ItemList from 'components/library/ItemList';
import ListItem from 'components/library/ListItem';
import Switch from 'components/library/Switch';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { testProps } from 'utils/test/testProps';

const i18nPath = 'scenes.users.Account.components.CalendarSettings.components.CalendarList.components.ProfileList';

const styles = theme => ({
  header: {
    display: 'flex',
  },
  headerWrapper: {                                                     // adjust when button visible
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`,
  },
  provider: {
    flex: '1 1 auto',
    alignSelf: 'center',
  },
  revokeButton: {
    flex: '0 0 auto',
  },
  disconnectedIcon: {
    flex: '0 0 auto',
    color: theme.palette.error.main,
    alignSelf: 'center',
    margin: theme.spacing.unit,
  },
  calendar: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
  },
  colorSwab: {
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    marginRight: '1em',
  },
  syncToggle: {
    marginLeft: 'auto',
  },
});

const CalendarListItem = ({ value, onUpdateCalendar, classes }) => {
  const isActive = get(value, 'sync.state') === 'active' &&
                   get(value, 'settings.sync.state') !== 'inactive';
  const color = get(value, 'settings.color', value.color);

  return (
    <ListItem className={classes.calendar}>
      {color &&
        <div className={classes.colorSwab} style={{ background: color }} />
      }

      {value.label}

      <Switch
        className={classes.syncToggle}
        color="primary"
        checked={isActive}
        value={true}
        onChange={(e, active) =>
          onUpdateCalendar(value.id, {
            'settings.sync.state': active ? 'active' : 'inactive',
          })
        }
      />
    </ListItem>
  );
}

const ProfileList = ({
  value,
  calendars,
  useProviders,
  onRevokeProfile,
  onUpdateCalendar,
  t,
  classes,
}) => {
  const provider = (useProviders ? value.provider : value.integration) || 'unknown';

  return (
    <ItemList
      classes={{ header0: classes.headerWrapper }}
      items={useProviders ? calendars.filter(calendar => calendar.profile === value.id) : calendars}
      header={
        <div className={classes.header} {...testProps('ProfileListHeader', provider)}>
          <Typography className={classes.provider}>
            {t(i18nPath, `calendars.providers.${provider}`)}
          </Typography>

          <AsyncButton
            className={classes.revokeButton}
            icon={t(i18nPath, 'actions.remove-profile.icon')}
            tooltip={t(i18nPath, 'actions.remove-profile.label')}
            onClick={async () => (
              window.confirm(t(i18nPath, 'actions.remove-profile.confirmation')) &&
              await onRevokeProfile(value)
            )}
            {...testProps('RevokeProfileButton', provider)}
          />

          {get(value, 'connection.state') === 'disconnected' &&
            <Icon
              className={classes.disconnectedIcon}
              type={t(i18nPath, 'actions.reconnect-profile.icon')}
              tooltip={t(i18nPath, 'actions.reconnect-profile.tooltip')}
              tooltipVariant="message"
            />
          }
        </div>
      }
      component={CalendarListItem}
      componentProps={{ onUpdateCalendar, classes }}
    />
  );
}

export default withStyles(styles)(withTranslations(ProfileList));
