import get from 'lodash/get';
import React from 'react';
import Box from 'components/library/Box';
import Form from 'components/library/Form';
import { buildScene } from 'scenes/utils';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import selectUserSettingsStatus from 'services/store/settings/selectors/selectUserSettingsStatus';
import NotificationOption from './components/NotificationOption';

const i18nPath = 'scenes.users.Account.components.NotificationSettings';

async function loadData() {
  this.props.doLoadSettings();
}

const NotificationSettings = buildScene(({
  notifications,
  settingsStatus,
  doUpdateSettings,
  t,
  classes,
}) => (
  <Box
    classes={{ content: classes.content }}
    title={t(i18nPath, 'title')}
  >
    <Form.Group>
      <NotificationOption
        notifications={notifications}
        option="remind-schedule"
        onUpdateSettings={(enabled, updates, notification) => doUpdateSettings(updates)}
      />
    </Form.Group>
  </Box>
), {
  styles: theme => ({
    content: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
    },
  }),
  state: (state, props) => ({
    // HACK: Adding the settings write status forces an update of this component whenever the
    //       settings are updated. Otherwise, the `messages` is not compared deep enough to
    //       realize that the setting has changed. [twl 27.Feb.19]
    notifications: get(selectUserSettings(state), 'messages.settings') || {},
    settingsStatus: selectUserSettingsStatus(state, 'write'),
  }),
  dispatch: {
    doLoadSettings: loadSettings,
    doUpdateSettings: updateSettings,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default NotificationSettings;
