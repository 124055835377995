import get from 'lodash/get';
import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import { buildScene } from 'scenes/utils';
import Page from 'components/app/navigation/Page';
import TodoMaster from 'components/app/todos/TodoMaster';
import ViewLayout from 'components/layouts/ViewLayout';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import createTodo from 'services/store/todos/actions/createTodo';
import deleteTodo from 'services/store/todos/actions/deleteTodo';
import updateTodo from 'services/store/todos/actions/updateTodo';

const Todos = buildScene(({
  i18nPath,
  defaultType,
  variant,
  query,
  settings,
  settingsPath,
  defaultSettings,
  actionMode,
  sortOptions,
  defaultSortBy,
  filterOptions,
  defaultFilter,
  doCreate,
  doUpdate,
  doDelete,
  doUpdateSettings,
  t,
}) => (
  <Page title={t(i18nPath, 'title-master')}>
    <ViewLayout>
      <TodoMaster
        i18nPath={i18nPath}
        defaultType={defaultType}
        variant={variant}
        query={query}
        settings={settings}
        actionMode={actionMode}
        sortOptions={sortOptions}
        defaultSortBy={defaultSortBy}
        filterOptions={filterOptions}
        defaultFilter={defaultFilter}
        onCreate={doCreate}
        onUpdateSettings={(property, value) => (
          doUpdateSettings({
            [`${settingsPath}.${property}`]: value,
          })
        )}
        onResetSettings={() => doUpdateSettings({
          [settingsPath]: {
            ...settings,
            ...pick(defaultSettings, 'filter', 'search'),
          },
        })}
        boxTitle={t(i18nPath, 'title-list')}
      />
    </ViewLayout>
  </Page>
), {
  state: (state, { settingsPath, defaultSettings }) => {
    const userSettings = selectUserSettings(state);

    return {
      settings: {
        ...defaultSettings,
        ...get(userSettings, settingsPath),
      },
    };
  },
  dispatch: {
    doCreate: createTodo,
    doUpdate: updateTodo,
    doDelete: deleteTodo,
    doUpdateSettings: updateSettings,
  },
});

Todos.propTypes = {
  /** The default type to use when creating new todos in this view */
  defaultType: PropTypes.string,

  /** A query used to select which todos should display in this view */
  query: PropTypes.object,

  /** The current filter, search and sort settings for this view */
  settings: PropTypes.object,

  /** The path to the user settings for this component */
  settingsPath: PropTypes.string.isRequired,

  /** The path to the translation keys for this component */
  i18nPath: PropTypes.string.isRequired,

  /** Bound dispatch used to create a new todo. Provided internally via `buildScene`. */
  doCreate: PropTypes.func,

  /** Bound dispatch used to update an existing todo. Provided internally via `buildScene`. */
  doUpdate: PropTypes.func,

  /** Bound dispatch used to delete the todo. Provided internally via `buildScene`. */
  doDelete: PropTypes.func,

  /** Bound dispatch used to update the user settings. Provided internally via `buildScene`. */
  doUpdateSettings: PropTypes.func,

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

export default Todos;
