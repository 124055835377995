function isVariant(value, ...variants) {
  if (!value || !variants) {
    return false;
  } else if (variants.includes(value)) {
    return true;
  }

  // remove sub-variant
  const [ variant ] = value.split(':');

  return variants.includes(variant);
}

export default isVariant;
