import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/library/ButtonMui';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import withTranslations from 'helpers/withTranslations';

const ModalDialogButton = ({ i18nPath, id, type = 'button', icon, onClick, t }) => {
  const label = t(i18nPath, `button-${id}`);

  return (
    type === 'icon' ?
      <IconButton color="inherit" aria-label={label} onClick={onClick}>
        <Icon type={icon} />
      </IconButton>
      :
      <Button type={type} size="small" color="inherit" aria-label={label} onClick={onClick}>
        {label}
      </Button>
  );
}

ModalDialogButton.propTypes = {
  /** The path from which to load localization strings for this component */
  i18nPath: PropTypes.string,

  /** The ID of this button. Used to resolve translations for this component */
  id: PropTypes.string,

  /** The role this button on a form. Defaults to `button`. */
  type: PropTypes.oneOf([ 'button', 'icon', 'submit', 'reset' ]),

  /** Called when the button is clicked */
  onClick: PropTypes.func,

  /** A function used to provide translations of text. Provided by `withTranslations`. */
  t: PropTypes.func.isRequired,
};

export default withTranslations(ModalDialogButton);
