import get from 'lodash/get';
import { LocalDate } from 'js-joda';
import { toLocalDate, toNativeDate } from '@lifetools/shared-utils-time';
import store from 'services/store';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectGlobalSettings from 'services/store/settings/selectors/selectGlobalSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import planDefaults from 'utils/app/plans/planDefaults';

// TODO: Remove this in the next release. This is just so that people whose accounts were created
//       before subscriptions were released don't immmeditely have expired trials. [twl 4.Jun.20]
const legacyAccountCutoff = new Date('2020-06-01');
const legacyGraceDays = 3;

/**
 * Sets the subscription plan info for a user, if the user doesn't have it already set.
 */
async function setSubscriptionPlan(authInfo) {
  await store.dispatch(loadSettings());

  const state = store.getState();
  const settings = selectUserSettings(state);
  const promotion = get(selectGlobalSettings(state), 'plan.promotion', {});
  const plan = settings.plan || {};
  const trialLength = promotion.offer && promotion.offer.type === 'extendTrial'
    ? promotion.offer.amount || planDefaults.trialLength
    : (plan.trial && plan.trial.length) || planDefaults.trialLength;
  const accountCreatedAt = toNativeDate(authInfo.user.createdAt) ?? new Date();
  const type = plan.type || 'trial';
  const isLegacyAccount = accountCreatedAt.getTime() < legacyAccountCutoff.getTime();
  const updates = {};

  if (!plan.type) {
    updates['plan.type'] = type;
  }

  if (!plan.createdAt) {
    updates['plan.createdAt'] = accountCreatedAt;
  }

  if (type === 'trial') {
    if (!plan.trial || !plan.trial.length) {
      updates['plan.trial.length'] = trialLength;
    }

    if (!plan.trial || !plan.trial.started) {
      updates['plan.trial.started'] = accountCreatedAt;
    }

    if (!plan.trial || !plan.trial.ends) {
      updates['plan.trial.ends'] = isLegacyAccount
        ? LocalDate.now().toString()
        : toLocalDate(accountCreatedAt).plusDays(trialLength).toString();
    }

    if (isLegacyAccount && (!plan.trial || !plan.trial.graceDays)) {
      updates['plan.trial.graceDays'] = legacyGraceDays;
    }
  }

  if (Object.keys(updates).length > 0) {
    await store.dispatch(updateSettings(updates));
  }
}

export default setSubscriptionPlan;
