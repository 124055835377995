import React, { cloneElement, useRef } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'recompose';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import StackedLayout from 'components/layouts/StackedLayout';
import extractViewInfos from 'components/utils/extractViewInfos';
import withTranslations from 'helpers/withTranslations';

const printAndMobileStyles = theme => ({
  root: {
    flexDirection: 'column',
    alignItems: 'stretch',

    '& > *': {
      flex: '0 0 auto',
      maxWidth: '100%',
    },

    '& > *:not(:first-child)': {
      marginLeft: 0,
      marginTop: theme.spacing.unit * 4,
    },
  },
});

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'start',
    padding: theme.spacing.unit * 4,

    '& > *': {
      flex: '1 1 auto',
      // NOTE: Using `maxWidth` causes the layout to shift as elemens are added to lists by a few
      //       pixels. Switching to `width` forces the columns to be the same width. I think I used
      //       `maxWidth` when we had split layouts that may not have had a second column, which
      //       `width` doesn't handle properly. Right now we don't have any layouts like this, but
      //       be aware in the future that we may need to switch (or move to CSS columns).
      //       [twl 30.Sep.30]
      // maxWidth: '50%',
      '@media only screen': {
        width: '50%',
      },
    },

    '& > *:not(:first-child)': {
      marginLeft: theme.spacing.unit * 4,
    },

    [theme.breakpoints.down('sm')]: printAndMobileStyles(theme).root,
    '@media print': printAndMobileStyles(theme).root,
  },
  column: {
    position: 'sticky',
    height: 'fit-content',
  },
  scroll: {
    overflowY: 'auto',
    maxHeight: '100%',
    paddingRight: theme.spacing.unit * 1.5,                              // Add room for scrollbar
    marginRight: -theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit,                                   // Add room for box shadow
    paddingLeft: theme.spacing.unit,
  },
  stickyPadding: {
    top: theme.spacing.unit * 2,
  },
  noStickyPadding: {
    top: 0,
  },
});

function adjustVerticalScroll(node, deltaY) {
  if (node) {
    node.scrollTop = Math.max(0, node.scrollTop + deltaY);
  }
}

const SplitLayout = ({ t, classes, className, noStickyPadding, scrollColumns, children }) => {
  const layoutRef = useRef();
  const column1Ref = useRef();
  const column2Ref = useRef();
  const viewInfos = extractViewInfos(children, t);
  const column1 = viewInfos.filter(info => info.column === 1).map(info => info.node);
  const column2 = viewInfos.filter(info => info.column !== 1).map(info => info.node);
  const column1Scroll = ['*', '1'].includes(scrollColumns) ? classes.scroll : undefined;
  const column2Scroll = ['*', '2'].includes(scrollColumns) ? classes.scroll : undefined;

  // NOTE: On layouts where the `className` sets `overflow-y` to `auto`, so the contents of this
  //       layout can scroll, non-scrollable content is pushed down by the `top` padding of the
  //       column (e.g., `Itemize` & `Schedule` scenes). This allows `noStickyPadding` to be passed
  //       in to remove the `top` value. `top` is still used on other pages, so we don't wan to
  //       remove it entirely. [twl 29.Apr.21]
  const stickyHack = noStickyPadding ? classes.noStickyPadding : classes.stickyPadding;

  const onScroll = e => {
    if (e.target === ReactDOM.findDOMNode(layoutRef.current)) {
      adjustVerticalScroll(ReactDOM.findDOMNode(column1Ref.current), e.deltaY);
      adjustVerticalScroll(ReactDOM.findDOMNode(column2Ref.current), e.deltaY);
    }
  }

  return (
    <div ref={layoutRef} className={classNames(classes.root, className)} onWheel={onScroll}>
      {column1.length > 0 &&
          (column1.length > 1 || column1Scroll
            ? <StackedLayout
                ref={column1Ref}
                className={classNames(classes.column, stickyHack, column1Scroll)}
              >
                {column1}
              </StackedLayout>
            : cloneElement(column1[0], {
              boxClassName: classNames(column1[0].props.boxClassName, classes.column, stickyHack),
            })
          )
      }

      {column2.length > 0 &&
          (column2.length > 1 || column2Scroll
            ? <StackedLayout
                ref={column2Ref}
                className={classNames(classes.column, stickyHack, column2Scroll)}
              >
                {column2}
              </StackedLayout>
            : cloneElement(column2[0], {
              boxClassName: classNames(column2[0].props.boxClassName, classes.column, stickyHack),
            })
          )
      }
    </div>
  );
}

export default compose(
  withTranslations,
  withStyles(styles),
)(SplitLayout);
