import { services } from '@lifetools/shared-services';
import analytics from 'services/analytics';
import sentry from 'services/sentry';
import { AuthenticationError } from 'types/AuthenticationError';
import { setSessionValue } from 'utils/browser/setSessionValue';
import { getActiveProvider } from './utils/getActiveProvider';

let currentUser;

export function getCurrentUser() {
  return currentUser;
}

export function setCurrentUser(user) {
  currentUser = user;
}

export async function createUser({ email, password, displayName }) {
  await services.auth.api.createUserWithEmailAndPassword(email, password);

  if (displayName) {
    await services.auth.api.updateUserProfile({ displayName });
  }
}

export async function sendPasswordResetEmail(email) {
  const providerId = await getActiveProvider(email);

  if (!providerId) {
    throw new AuthenticationError('auth/user-not-found');
  } else if (providerId !== 'password') {
    throw new AuthenticationError(
      'auth/account-exists-with-different-credential',
      'You have an account with a third-party sign in provider. Sign in using the provider below.',
      {
        email,
        providerId,
      }
    );
  }

  await services.auth.api.sendPasswordResetEmail(email);
}

export async function login({ email, password }) {
  await services.auth.api.signInWithEmailAndPassword(email, password);
}

export async function logout() {
  await services.auth.api.signOut();
}

/**
 * Log a user in using a federated identity provider. If the user is not registered, this will also
 * register the user.
 *
 * @param {string} name The name of the identity provider
 */
export async function loginWithProvider(action, provider, email) {
  try {
    analytics.track('auth_start', { action, provider });

    setSessionValue('authAttempt', { action, provider }, true);

    const FactoryClass = services.auth.providers[provider].factoryClass;
    const ProviderClass = services.auth.providers[provider].providerClass;
    const service = FactoryClass ? new FactoryClass(provider) : new ProviderClass();

    if (email) {
      // May not work with all providers
      service.setCustomParameters({
        'login_hint': email
      });
    }

    // May not work with all providers
    services.auth.api.useDeviceLanguage();

    await services.auth.api.signInWithRedirect(service);
  }
  catch (e) {
    // `signInWithRedirect` shouldn't throw any user errors, so log everything
    sentry.withScope(scope => {
      scope.setTag('type', 'authError');
      scope.setExtras({ action, provider });

      sentry.captureException(e);
    });

    return {
      action,
      code: e.code,
      message: e.message,
      email,
      provider,
    };
  }
}
