import moment, { Moment } from 'moment';
import { LocalDate, convert } from 'js-joda';
import { DateValue } from '../types/DateValue';
import { ITimestamp } from '../types/ITimestamp';

/**
 * Converts the `value` to a native JavaScript Date object.
 *
 * @param value - The date value to convert to a `Date`
 *
 * @returns A JavaScript Date object, `null` if the value could not be parsed, or `undefined` if the
 *          `value` was unset
 */
export function toNativeDate(value: DateValue): Date | undefined | null {
  if (!value) {
    return undefined;
  }

  if (value instanceof Date) {
    return value;
  }

  if (value instanceof LocalDate) {
    return convert(value).toDate();
  }

  if (value instanceof moment) {
    return (value as Moment).toDate();
  }

  if (typeof value === 'string') {
    const date = new Date(value);

    return !isNaN(date.getTime()) ? date : null;
  }

  const { seconds, nanoseconds } = value as ITimestamp;

  return new Date(seconds * 1000 + (nanoseconds / 1000000));
}
