import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import Page from 'components/app/navigation/Page';
import IFrame from 'components/library/IFrame';
import withTranslations from 'helpers/withTranslations';
import withStyles from 'helpers/withStyles';

const i18nPath = 'scenes.app.Help';
const source = "https://dayoptimizer.com/docs/app/en/";

const styles = theme => ({
  container: {
    flex: '1 1 auto',                                        // Take up all space of parent
    position: 'relative',                                    // Create a new layer for absolute
  },
  iframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

const Help = ({ match, t, classes }) => (
  <Page title={t(i18nPath, 'title')}>
    <div className={classes.container}>
      <IFrame
        title={t(i18nPath, 'title')}
        className={classes.iframe}
        source={`${source}${match.params.topic ? 'topic/' + match.params.topic : ''}`}
      />
    </div>
  </Page>
);

export default compose(
  withRouter,
  withTranslations,
  withStyles(styles)
)(Help);
