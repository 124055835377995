import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getDisplayName from 'helpers/utils/getDisplayName';

const enhance = t => (parentPaths, path, options) => {
  // check if options were passed in as second parameter
  if (!options && typeof path === 'object') {
    options = path;
    path = undefined;
  }

  let searchPaths = Array.isArray(parentPaths) ? [ ...parentPaths ] : [ parentPaths ];

  if (path) {
    searchPaths.push('*');
    searchPaths = searchPaths.map(parentPath => parentPath + '.' + path);
  }

  searchPaths = searchPaths.length === 1 ? searchPaths[0] : searchPaths;

  return t(searchPaths, options);
};

/**
 * Enhances a component by adding the `t` translation function to it. The version of `t` used by
 * this helper has been enhanced to take multiple parent paths that can be used to resolve a
 * translation key.
 *
 * This allows the following usages:
 *
 * ```
 * t(i18nPath, 'button-back')
 * t([i18nPath, i18nPathDefault], 'button-back')
 * ```
 *
 * Instead of:
 *
 * ```
 * t(`${i18nPath}.button-back`)
 * t([`${i18nPath}.button-back`, `${i18nPathDefault}.button-back`])
 * ```
 *
 * The function is backwards-compatible with the original `t` function.
 */
function withTranslations(WrappedComponent) {
  const WithEnhancedT = ({ t, ...props }) => {
    const enhancedT = useMemo(() => enhance(t), [ t ]);

    return (
      <WrappedComponent {...props} t={enhancedT} />
    );
  }

  const WithTranslations = withTranslation('translations')(WithEnhancedT);

  hoistNonReactStatics(WithTranslations, WrappedComponent);

  WithTranslations.displayName = `WithTranslations(${getDisplayName(WrappedComponent)})`;

  return WithTranslations;
}

export default withTranslations;
