import React from 'react';
import Todos from 'scenes/todos/Todos';

// TODO: Use a static object rather than generating it for each render. Investigate further why we
//       need to do this. The compiler should be optimizing inline static objects to be static.
//       [twl 30.Nov.18]
const query = { type: 'activity' };

const Activites = props => (
  <Todos
    defaultType="activity"
    query={query}
    i18nPath="scenes.todos.Activities"
    settingsPath="defaults.activities"
    defaultSettings={{
      filter: 'all',
      sort: 'title',
      search: '',
    }}
    backTo="/activities"
    sortOptions={[ 'title', 'tagsStatus', 'newestFirst', 'oldestFirst' ]}
    filterOptions={
      [ 'all', 'daily', 'weekDays', 'weekendDays', 'never', 'hasDuration', 'noDuration' ]
    }
  />
);

export default Activites;
