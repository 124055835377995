import React from 'react';
import classnames from 'classnames';
import DailyPlanInfo from 'components/app/schedules/DailyPlanInfo';
import withStyles from 'helpers/withStyles';
import ExistingPlanMessage from './components/ExistingPlanMessage';

const i18nPath = 'components.app.schedules.WorkflowPromptCard';

const styles = theme => ({
  root: {
    textAlign: 'center',
  },
});

const WorkflowPromptCard = ({
  className,
  date,
  workflow,
  existingSchedule,
  activePlanType,
  classes,
}) => {
  return (
    <div className={classnames(classes.root, className)}>
      {activePlanType && activePlanType !== workflow &&
        <ExistingPlanMessage
          workflow={workflow}
          activePlanType={activePlanType}
          i18nPath={i18nPath}
        />
      }

      <DailyPlanInfo
        variant="flat"
        date={date}
        workflow={workflow}
        existingSchedule={existingSchedule}
        activePlanType={activePlanType}
      />
    </div>
  )
};

export default withStyles(styles)(WorkflowPromptCard);
