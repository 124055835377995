import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Steps from 'components/library/Steps';
import withDeviceInfo from 'helpers/withDeviceInfo';
import { routes } from 'routes/routes';
import { resolveRoute } from 'utils/app/routes/resolveRoute';

const styles = theme => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 100,

    [theme.breakpoints.down('sm')]: {
      borderBottom: `solid 1px ${theme.palette.grey['A400']}`,
      background: theme.palette.common.white,
    },

    '@media print': {
      display: 'none',
    },
  },
});

const steps = {
  commit: [ 'commit' ],
  allocate: [ 'commit', 'allocate' ],
  schedule: [ 'commit', 'allocate', 'schedule' ],
};

const BasicSteps = ({
  t,
  classes,
  match,
  history,
  currentStep,
  workflow = 'schedule',
  date,
  onBack,
  onNext,
  nextStepEnabled = true,
  isMobileDevice,
}) => {
  // NOTE: Currently, all workflows that started under Plans return to the main calendar Plans
  //       page (or the detail page on mobile). This provides a slightly better experience than
  //       routing to the plan type dashboard under /plans because when under Plans the user is
  //       more likely editing multiple days, and it throws the mobile navigation off more if
  //       we don't return to the calendar detail view.
  //
  //       In the future, it would be nice to just return to where the workflow was started, but
  //       that requires a lot of refactoring that I don't have time for now.[twl 16.Dec.20]
  const doneRoute = match.params.planPath === 'plans'
    ? '/plans/' + date.toString() + (isMobileDevice ? '/detail' : '')
    : resolveRoute(routes.today[workflow], { ...match.params, planDate: date });

  return (
    <Steps
      id="BasicSteps"
      className={classes.root}
      i18nPath={'components.app.schedules.BasicSteps'}
      steps={steps[workflow]}
      activeStep={currentStep}
      cancelStep={doneRoute}
      doneStep={doneRoute}
      nextStepEnabled={nextStepEnabled}
      onBack={async step => {
        onBack && await onBack();
        history.push(step);
      }}
      onNext={async step => {
        onNext && await onNext();
        history.push(step);
      }}
      onNavigateTo={step => history.push(step)} />
  );
}

export default compose(
  withStyles(styles),
  withRouter,
  withDeviceInfo,
)(BasicSteps);
