import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';              // Dependency of `react-router-dom`
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FullStory from 'react-fullstory';
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { getRootWindow } from '@lifetools/shared-utils';
import './services/sentry';
import { initialize as initializeServices } from './services/initialize';
import store from './services/store';
import App from './app/App';
import Loader from './components/library/Loader';
import LoaderWidget from './widgets/library/Loader';
import { theme } from './theme';
import { isDevEnvironment } from './utils/app/isDevEnvironment';
import { isIntegrationTestEnvironment } from './utils/app/isIntegrationTestEnvironment';
import './index.css';
// import registerServiceWorker from './registerServiceWorker';
import { registerTestExports } from './registerTestExports';

const services = initializeServices();
const history = createBrowserHistory();

// If running tests within Cypress
if (isIntegrationTestEnvironment()) {
  registerTestExports();
}

const mountApp = () => {
  ReactDOM.render(
    <Router history={history}>
      <Provider store={store}>
        <GrowthBookProvider growthbook={services.features.growthbook}>
          <MuiThemeProvider theme={createMuiTheme(theme)}>
            <Suspense fallback={<Loader status={{ state: 'running' }} loader="splash" />}>
              <LoaderWidget statusPath="auth.status.init.*" loader="splash">
                {process.env.NODE_ENV === 'production' && <FullStory org="FJF25" />}
                <App />
              </LoaderWidget>
            </Suspense>
          </MuiThemeProvider>
        </GrowthBookProvider>
      </Provider>
    </Router>,
    document.getElementById('root')
  );
}

// Allow test frameworks to initialize the app before mounting
//
// TODO: Merge this with the test export functionality above, so we have a single `$App` property
//       that contains `exports` and `willRenderApp` (renamed `$AppInitializer`). [twl 12.Nov.19]
//
// TODO: Consider adding this to just `window` and then using `cy.window()` to get the right window
//       instance when testing. [twl 12.Nov.19]
const appInitializer = isDevEnvironment() && getRootWindow().$AppInitializer;

if (appInitializer) {
  appInitializer(services).then(mountApp);
} else {
  mountApp();
}

// Disabling service worker until we have time to figure out caching issues
// registerServiceWorker();
