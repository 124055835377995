import get from 'lodash/get';
import { visit } from 'unist-util-visit';
import { parseIconType } from 'utils/app/parseIconType';
import { theme } from 'theme.js';

const fontSizes = {
  small: theme.typography.body2.fontSize,
  medium: theme.typography.h4.fontSize,
  large: theme.typography.h2.fontSize,
};

function parseColor(color) {
  const themeColor = get(theme.palette, color);

  return typeof themeColor === 'object' ? themeColor.main : themeColor ?? color;
}

export const iconDirective = function remarkIconDirectivePlugin() {
  return (tree, file) => {
    visit(tree, 'textDirective', (node) => {
      if (node.name === 'icon') {
        let { type, color, size = 'medium', style = '' } = node.attributes;

        if (!type) {
          console.warn(`The 'icon' Markdown directive requires a 'type' attribute`);
          return;
        }

        const { namespace, icon } = parseIconType(type);

        if (color) {
          style = `color:${parseColor(color)};${style}`;
        }

        if (size) {
          style = `font-size:${fontSizes[size] ?? size};${style}`;
        }

        node.data = node.data ?? {};
        node.data.hName = 'i';
        node.data.hProperties = {
          class: `${namespace} fa-${icon} icon-size-${size}`,
          style,
        };
      }
    });
  };
}
