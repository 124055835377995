import React from 'react';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiInputLabel from '@material-ui/core/InputLabel';

// Based off the wrapper code for `TextField` at
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TextField/TextField.js
// [twl 3.Dec.18]

const FormField = ({
  id,
  className,
  label,
  error,
  fullWidth,
  required,
  helperText,
  FormHelperTextProps,
  InputLabelProps,
  InputElement,
 ...props
}) => {
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  return (
    <MuiFormControl
      aria-describedby={helperTextId}
      className={className}
      error={error}
      fullWidth={fullWidth}
      required={required}
      {...props}>

      {label && (
        <MuiInputLabel htmlFor={id} {...InputLabelProps}>
          {label}
        </MuiInputLabel>
      )}

      {InputElement}

      {helperText && (
        <MuiFormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </MuiFormHelperText>
      )}
    </MuiFormControl>
  );
}

export default FormField;
