import { ITriggerRule, TriggerContext } from '@lifetools/shared-database';
import { services } from '@lifetools/shared-services';

/**
 * Updates the history properties of the `status.hide` object for a todo.
 */
const todoStatusHideHistory: ITriggerRule = {
  stage: 'before',
  operation: 'write',
  collection: 'todos',
  func: async ({ before, changes, after }: TriggerContext) => {
    // NOTE: Using `get` here because we don't want to execute this trigger both when `status.hide`
    //       doesn't exist AND when `status.hide` exists but has been set to `null`. [twl 16.Mar.20]
    if (!changes.values || !changes.get('status.hide')) {
      return;
    }

    if (before.get('status.hide.date') !== after.get('status.hide.date')) {
      changes.set('status.hide.at', services.database.fieldValues.serverTimestamp());
    }
  },
};

export default todoStatusHideHistory;
