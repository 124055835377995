import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from 'components/library/FormDialog';
import withTranslations from 'helpers/withTranslations';
import IntentEditForm from './components/IntentEditForm';

function doSave(EditForm, onSave, values) {
  const value = EditForm.fromFormValues(values);
  const changes = pick(value, EditForm.editFields);

  onSave(value, changes);
}

const IntentEditDialog = ({ value, onSave, onCancel, onClose, t }) => (
  <FormDialog
    formId="IntentEditDialog"
    i18nPath={IntentEditForm.i18nPath}
    title={t(IntentEditForm.i18nPath, value ? 'title-edit' : 'title-create')}
    values={IntentEditForm.toFormValues(value || IntentEditForm.getDefaultValue())}
    onSave={values => doSave(IntentEditForm, onSave, values)}
    onCancel={onCancel}
    onClose={onClose}
  >
    <IntentEditForm />

  </FormDialog>
);

IntentEditDialog.propTypes = {
  /** The intent object to render */
  value: PropTypes.object,

  /** Called to save the intent */
  onSave: PropTypes.func.isRequired,

  /** Called to cancel editing the intent */
  onCancel: PropTypes.func,

  /** Called when the dialog is cancelled, either by saving or cancelling */
  onClose: PropTypes.func,

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

export default withTranslations(IntentEditDialog);
