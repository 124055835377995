import React from 'react';
import classNames from 'classnames';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      flex: '0 0 auto',
    },
  },
  desktop: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing.unit * 4,
    },
  },
  mobile: {
    width: '100vw',
  },
});

const StackedLayout = ({ isMobileDevice, className, children, classes }) => (
  <div
    className={classNames(classes.root, classes[isMobileDevice ? 'mobile' : 'desktop'], className)}
  >
    {children}
  </div>
);

export default withStyles(styles)(StackedLayout);
