import { versions } from '@lifetools/shared-app-data';
import { DocumentMetaProperties, ITriggerRule, TriggerContext } from '@lifetools/shared-database';

const addDataVersion: ITriggerRule = {
  stage: 'before',
  operation: 'create',
  func: async ({ changes, collection }: TriggerContext) => {
    if (changes.values) {
      changes.set(DocumentMetaProperties.Version,  versions[collection]);
    }
  },
};

export default addDataVersion;
