/**
 * Returns whether the `uri` is an embed URI. Embed URIs are in one of the following forms:
 *
 * - `embed:${url}`
 * - `embed(${options}):${url}`
 *
 * Where `options` is a comma-separated list of name/value pairs separated by equals and `url` is
 * a valid `http` or `https` URL.
 */
export const isEmbedUri = (uri: string): boolean => (
  !!uri && uri.startsWith('embed') && (uri.indexOf(':http:') > 0 || uri.indexOf(':https:') > 0)
);
