import { GrowthBook } from "@growthbook/growthbook-react";
import analytics from 'services/analytics';

export function initializeFeatures() {
  const growthbook = new GrowthBook({
    // Callback when a user is put into an A/B experiment
    trackingCallback: (experiment, result) => {
      analytics.track('experiment_viewed', {
        experiment_id: experiment.key,
        variation_id: result.variationId,
      });
    },
  });

  // NOTE: Need to wrap the object because the generic `IFeaturesService` uses a simplified
  //       TyepScript definition of the method calls which is incompatible with the GrowthBook
  //       TypeScript definitions (and not easily fixed). [twl 28.Apr.22]
  return {
    evalFeature: (name, defaultValue) => growthbook.evalFeature(name, defaultValue),
    getFeatureValue: (name, defaultValue) => growthbook.getFeatureValue(name, defaultValue),
    isOn: name => growthbook.isOn(name),
    isOff: name => growthbook.isOff(name),
    setAttributes: attributes => growthbook.setAttributes(attributes),
    setFeatures: features => growthbook.setFeatures(features),
    growthbook,
  };
}
