import get from 'lodash/get';
import React from 'react';
import ActionBar from 'components/library/ActionBar';
import Button from 'components/library/ButtonMui';
import Spinner from 'components/library/Spinner';
import { buildScene } from 'scenes/utils';
import loadAuthUrl from 'services/store/integrations/calendars/actions/loadAuthorizationUrl';
import loadSettings from 'services/store/settings/actions/loadSettings';
import setSettingsStatusDirty from 'services/store/settings/actions/setSettingsStatusDirty';
import selectGlobalSettings from 'services/store/settings/selectors/selectGlobalSettings';
import selectUserSettingsStatus from 'services/store/settings/selectors/selectUserSettingsStatus';
import { validateCalendarCallbackPath } from 'utils/integrations/validateCalendarCallbackPath';
import { testProps } from 'utils/test/testProps';

const i18nPath = 'components.app.integrations.calendars.ConnectButton';

async function loadData() {
  const { settingsStatus, integration, callbackPath, doLoadSettings, doLoadAuthUrl } = this.props;

  if (!settingsStatus || settingsStatus.state !== 'success') {
    doLoadSettings();
  } else {
    doLoadAuthUrl(integration, callbackPath);
  }
}

const ConnectButton = buildScene(({
  variant = 'primary',
  integration,
  authUrl,
  settingsStatus,
  oauthCodeStatus,
  doSetSettingsStatusDirty,
  classes,
  t,
}) => {
  const disabled = !authUrl || oauthCodeStatus.state === 'running' ||
                   !(['success', 'failure'].includes(settingsStatus.state));

  function doClick() {
    if (integration !== 'cronofy') {
      setSettingsStatusDirty();
    }

    window.location = authUrl;
  };

  return variant === 'actionBar' ? (
    <ActionBar.Button
      i18nPath={i18nPath}
      action="connect-calendar"
      disabled={disabled}
      onClick={doClick}
      {...testProps('ConnectButton', integration)}
    />
  ) : (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={doClick}
      {...testProps('ConnectButton', integration)}
      data-action="connect-calendar"
    >
      {disabled && <Spinner className={classes.spinner} size="1em" color="inherit" />}
      {t(i18nPath, integration === 'cronofy' ? 'button-connect' : 'button-connect-gcalendar')}
    </Button>
  );
}, {
  styles: {
    spinner: {
      marginRight: '0.5em',
    },
  },
  state: (state, { integration, callbackPath, redirectPath }) => {
    const globalSettings = selectGlobalSettings(state);

    if (!callbackPath) {
      callbackPath = redirectPath + '/callback';
    }

    validateCalendarCallbackPath(callbackPath);

    return {
      integration,
      // TODO: Encode any property path special characters in `callbackPath` [twl 5.Jul.19]
      callbackPath,
      authUrl: get(globalSettings, `integrations.${integration}.${callbackPath}.authorizationUrl`),
      settingsStatus: selectUserSettingsStatus(state, 'read'),
      oauthCodeStatus: selectUserSettingsStatus(state, 'write', callbackPath),
    };
  },
  dispatch: {
    doLoadSettings: loadSettings,
    doLoadAuthUrl: loadAuthUrl,
    doSetSettingsStatusDirty: setSettingsStatusDirty,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  }
});

export default ConnectButton;
