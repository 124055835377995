import { IIntent, ITodo } from '@lifetools/shared-app-types';
import { DateValue, isAfterToday, toLocalDate } from '@lifetools/shared-utils-time';

export default function nextScheduledIntent(todo: ITodo, today: DateValue): IIntent | null {
  if (!todo.scheduleIds || todo.scheduleIds.length === 0) {
    return null;
  }

  const todaysDate = toLocalDate(today ?? new Date());
  const intents = Object.values(todo.intents ?? {});
  const futureIntents = intents.filter(
    intent => intent.date?.isEqual(todaysDate!) || isAfterToday(intent.date, todaysDate)
  );

  return futureIntents.reduce((earliestIntent: IIntent | null, intent: IIntent) => {
    return !earliestIntent || intent.date?.isBefore(earliestIntent.date) ? intent : earliestIntent;
  }, null);
}
