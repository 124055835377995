import { IUserProfile } from '@lifetools/shared-app-data';
import { parseDisplayName } from './parseDisplayName';

/**
 * Ensures the `firstName`, `lastName` and `displayName` are all present in `profile`, assuming
 * either `displayName` or `firstName` and `lastName` are set.
 */
export function normalizeProfile(profile: IUserProfile): IUserProfile {
  const { displayName, firstName, lastName } = profile;
  const parsedName = displayName ? parseDisplayName(displayName) : undefined;

  return {
    displayName: displayName ?? `${firstName} ${lastName}`,
    firstName: firstName ?? parsedName?.firstName,
    lastName: lastName ?? parsedName?.lastName,
  };
}
