import { services } from '@lifetools/shared-services';
import { addQuery } from './utils/addQuery';
import { parseIntoSubqueries } from './utils/parseIntoSubqueries';
import { ICollectionQuery } from './types/ICollectionQuery';

/**
 * Returns the number of documents from the collection that match the query. Note that this method
 * still requires accessing all the documents, so can be an expensive operation.
 *
 * The `query` supports both exact match or query object which indicate what type of query to
 * perform. The query object should be in the form of `{ [operation]: [value] }` where `operation`
 * is the comparison operation to use and `value` is the value to compare.
 *
 * Support operations include `greaterThan`, `greaterThanOrEqual`, `lessThan`, `lessThanOrEqual`,
 * `before`, `beforeOrOn`, `after`, `afterOrOn` and `not`.
 *
 * @param collection - The name of the collection to count the documents from
 * @param query      - An object mapping fields to the values that must exist on a document for it
 *                     to be returned--this is an AND query of all keys in this object
 *
 * @returns The number of documents that match the `query`
 */
export async function countDocuments(
  collection: string,
  query: ICollectionQuery = {}
): Promise<number> {
  const authUserId = services.auth.userId();

  if (!authUserId && !services.auth.isAdmin()) {
    // TODO: Think about whether we want to throw an error here. [twl 15.Jun.18]
    services.logger.warn(`Aborting attempt to count documents from '${collection}'. No` +
                         ` authenticated user.`);

    return -1;
  }

  const subqueries = parseIntoSubqueries(query);

  let count = 0;

  for (const subquery of subqueries) {
    const baseRef = services.database.collection(collection);
    const authRef = authUserId ? baseRef.where('createdBy', '==', authUserId) : baseRef;
    const queryRef = addQuery(authRef, subquery);
    const querySnapshot = await queryRef.get();

    count += querySnapshot.docs.length;
  }

  return count;
}
