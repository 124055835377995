import { useState } from 'react';
import { useDebouncedCallback } from './useDebouncedCallback';

/**
 * Enables state to be used immediately and then persisted via a save function after a delay.
 *
 * @param initialValue The initial value for the state
 * @param saveFunc     The function used to persist the state
 * @param saveConext   The context to be passed into `saveFunc` before the new value
 * @param saveDelay    The debounce delay before `saveFunc` is called
 *
 * @returns An array of the current value and a function to set the new value
 */
export function useSavedState<F extends (context: C, newValue: V) => R, C, V, R>(
  initialValue: V,
  saveFunc: F,
  saveContext: C,
  saveDelay: number,
): [V, (newValue: V) => R] {
  const [value, setImmediateValue] = useState(initialValue);
  const saveDelayedValue = useDebouncedCallback<F, R>(saveFunc, saveDelay);

  return [
    value,
    (newValue: V): R => {
      setImmediateValue(newValue);

      return saveDelayedValue(saveContext, newValue);
    }
  ];
}
