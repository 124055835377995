import React from 'react';
import ActionBar from 'components/library/ActionBar';
import { buildScene } from 'scenes/utils';
import deleteSchedule from 'services/store/schedules/actions/deleteSchedule';

const DeletePlanButton = buildScene(({ i18nPath = '*', schedule, doDeleteSchedule, ...props }) => (
  <ActionBar.Button
    {...props}
    i18nPath={i18nPath}
    action="delete-plan"
    onClick={() => doDeleteSchedule(schedule)} />
), {
  dispatch: {
    doDeleteSchedule: deleteSchedule,
  },
});

export default DeletePlanButton;
