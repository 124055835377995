interface EnvironmentVariables {
  [key: string]: string | undefined;
}

const resolve = (env: EnvironmentVariables, key: string) => env[`REACT_APP_${key}`];

export const initializeConfig = (env: EnvironmentVariables) => ({
  firebase: {
    apiKey: resolve(env, 'FIREBASE_API_KEY'),
    authDomain: resolve(env, 'FIREBASE_AUTH_DOMAIN'),
    authEmulatorUrl: resolve(env, 'FIREBASE_AUTH_EMULATOR_URL'),
    databaseURL: resolve(env, 'FIREBASE_DATABASE_URL'),
    projectId: resolve(env, 'FIREBASE_PROJECT_ID'),
    storageBucket: resolve(env, 'FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: resolve(env, 'FIREBASE_MESSAGING_SENDER_ID'),
    functionsEmulationURL: resolve(env, 'FIREBASE_FUNCTIONS_EMULATION_URL'),
    firestoreEmulationHost: resolve(env, 'FIREBASE_FIRESTORE_EMULATION_HOST'),
    appId: resolve(env, 'FIREBASE_APP_ID'),
    measurementId: resolve(env, 'FIREBASE_MEASUREMENT_ID'),
  },
});
