import React from 'react';
import { withRouter } from 'react-router';
import ActionBar from 'components/library/ActionBar';
import { routes } from 'routes/routes';
import { resolveRoute } from 'utils/app/routes/resolveRoute';

const EditPlanButton = ({ workflow, step = 'commit', date, i18nPath = '*', match, ...props }) => (
  <ActionBar.Button
    {...props}
    i18nPath={i18nPath}
    action="edit"
    to={resolveRoute(routes.workflows[workflow], { ...match.params, planDate: date, step })}
  />
);

export default withRouter(EditPlanButton);
