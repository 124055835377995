import React from 'react';
import ActionBar from 'components/library/ActionBar';
import Button from 'components/library/ButtonMui';
import withTranslations from 'helpers/withTranslations';
import createScheduleFromNow from 'utils/schedules/createScheduleFromNow';

const i18nPathDefault = 'components.app.schedules.StartWorkflowButton';

const getScheduleInfo = (workflow, existingSchedule, activePlanType, date, startTime, endTime) => (
  activePlanType
    ? { type: workflow, date }
    : existingSchedule && existingSchedule.date && existingSchedule.date.equals(date)
      ? { ...existingSchedule, type: workflow }
      : createScheduleFromNow(workflow, date, startTime, endTime)
);

/**
 * Renders a button that starts a workflow.
 *
 * Variants include:
 *
 * `actionBar`
 * : Renders as an action bar button with the "create" translation text
 *
 * `option`
 * : Renders as a button that allows the user to select a plan type from one of several
 *
 * `default`
 * : Renders as a primary button to start the planning process
 */
const StartWorkflowButton = ({
  t,
  i18nPath = i18nPathDefault,
  className,
  variant,
  workflow,
  existingSchedule,
  activePlanType,
  date,
  startTime,
  endTime,
  disabled,
  onStart = () => {}
}) => {
  const onClick = () => onStart(
    getScheduleInfo(workflow, existingSchedule, activePlanType, date, startTime, endTime)
  );

  return variant === 'actionBar'
    ? <ActionBar.Button
        className={className}
        i18nPath={i18nPath}
        action="create"
        disabled={disabled}
        onClick={onClick}
      />
    : <Button
        className={className}
        data-action={`create-plan:${workflow}`}
        variant={variant !== 'option' ? 'contained' : 'outlined'}
        color={variant !== 'option' ? 'primary' : undefined}
        disabled={disabled}
        onClick={onClick}
      >
        {t(i18nPath,
          'title-' + (variant !== 'option' ? (activePlanType ? 'existing' : 'new') : workflow)
        )}
      </Button>;
};

export default withTranslations(StartWorkflowButton);
