import React from 'react';
import FormField from '../FormField';
import MaterialUiField from '../MaterialUiField';

const GenericValue = ({ value, children }) => (
  <div>
    {value}
    {children}
  </div>
);

const MuiGenericField = ({ name, value, InputComponent = GenericValue, children, ...props }) => (
  <FormField
    {...props}
    InputElement={<InputComponent name={name} value={value} children={children} />}
  />
);

const GenericField = props => (
  <MaterialUiField {...props} muiComponent={MuiGenericField} />
);

export default GenericField;
