import omit from 'lodash/omit';
import { toDatabaseJson } from '../../documents/utils/toDatabaseJson';
import { ICollectionQuery } from '../types/ICollectionQuery';

/**
 * Parses a query into one or more subqueries used to return all of the data for the query.
 *
 * For example, Firestore does not support `not` queries. To support these queries in the data
 * layer, these queries are split into two queries: one less-than query and one greater-than query.
 *
 * @param query - The original query
 *
 * @returns An array of subqueries
 */
export function parseIntoSubqueries(query: ICollectionQuery) {
  const notFields = Object.keys(query).filter(field => query[field] && query[field].not);

  if (notFields.length > 1) {
    throw new Error('Only one "not" operator per query is supported');
  }

  const baseQuery = toDatabaseJson(omit(query, notFields), false);
  const notField = notFields.length && notFields[0];
  const notValue = notField && query[notField].not;

  return !notField ? [ baseQuery ] : [ {
    [notField]: {
      lessThan: notValue
    },
    ...baseQuery
  }, {
    [notField]: {
      greaterThan: notValue
    },
    ...baseQuery
  }];
}
