const repeatOptions = [
  'daily',                              // Every day
  'weekDays',                           // Every weekday
  'weekendDays',                        // Every weekend day
  'specificDays',                       // Only on these days of the week...
  // 'specific-dates',                     // Only on these days of the month...
  'never',                              // Never (On-Hold)
];

export default repeatOptions;
