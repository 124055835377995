import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isBeforeToday } from '@lifetools/shared-utils-time';
import IntentEditDialog from 'components/app/intents/IntentEditDialog';
import TodoQuickViewHistory from 'components/app/todos/TodoQuickViewHistory';
import ActionBar from 'components/library/ActionBar';
import QuickView from 'components/library/QuickView';
import { IsAccountReadOnly } from 'contexts/IsAccountReadOnly';
import withTranslations from 'helpers/withTranslations';
import IntentQuickViewSummary from './components/IntentQuickViewSummary';

const i18nPath = 'data.intents.fields';

// TODO: This should be testing whether the schedule date is before the context date for this
//       component. Right now the Uncommit action doesn't appear if you are in an editable view in
//       the past, e.g. the Today screen looking at yesterday's schedule. [twl 20.Mar.20]
const isIntentEditable = intent => intent.schedule && !isBeforeToday(intent.schedule.date);

const IntentQuickView = ({ value, actionMode, onUpdate, onDelete, onStatusChange, t }) => {
  const isAccountReadOnly = useContext(IsAccountReadOnly);
  const [ view, setView ] = useState('summary');
  const ViewComponent = view === 'summary' ? IntentQuickViewSummary : TodoQuickViewHistory;
  const actionView = view === 'summary' ? 'history' : 'summary';
  const actions = [];

  if (value.todo.type !== 'appointment') {
    actions.push(
      <ActionBar.Button
        i18nPath={i18nPath}
        action={actionView}
        onClick={() => setView(actionView)}
      />
    );
  }

  if (!isAccountReadOnly && isIntentEditable(value)) {
    if (onUpdate) {
      actions.push(
        <ActionBar.Button
          i18nPath={i18nPath}
          action="edit"
          pane={
            <IntentEditDialog value={value} onSave={onUpdate} />
          }
        />
      );
    }

    if (onDelete) {
      actions.push(
        <ActionBar.Button
          i18nPath={i18nPath}
          action="uncommit"
          confirmAction
          onClick={() => onDelete(value)}
        />
      );
    }
  }

  return (
    <QuickView actions={actions}>
      <ViewComponent
        value={view === 'summary' ? value : value.todo}
        actionMode={actionMode}
        onUpdate={onUpdate}
        onStatusChange={status => onStatusChange(status, value)}
      />
    </QuickView>
  );
};

IntentQuickView.propTypes = {
  /** The intent object to render */
  value: PropTypes.shape({
    todo: PropTypes.shape({
      title: PropTypes.string,
      desc: PropTypes.string,
      status: PropTypes.object,
      type: PropTypes.oneOf([ 'activity', 'appointment', 'task' ]),
      start: PropTypes.string,
      end: PropTypes.string,
      location: PropTypes.string,
    }).isRequired,
    start: PropTypes.object,
    duration: PropTypes.number,
  }),

  /** Called to update the intent */
  onUpdate: PropTypes.func,

  /** Called to delete the intent */
  onDelete: PropTypes.func,

  /** A function used to provide translations of text. Provided by `withTranslations` */
  t: PropTypes.func.isRequired,
};

export default withTranslations(IntentQuickView);
