import React from 'react';
import withStyles from 'helpers/withStyles';
import Spinner from 'components/library/Spinner';

const styles = theme => ({
  root: {
    textAlign: 'center',
    marginTop: '20%',
    padding: theme.spacing.unit * 2,
  },
  logo: {
    display: 'block',
    margin: `0 auto ${theme.spacing.unit * 2}px auto`,
    maxWidth: '100%',
  },
});

const Splash = ({ classes }) => (
  <div className={classes.root}>
    <img className={classes.logo} src="/images/logo-square.png" alt="Day Optimizer" />
    <Spinner />
  </div>
);

export default withStyles(styles)(Splash);
