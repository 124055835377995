import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteDocument from 'services/store/utils/actions/updateRemoteDocument';
import { collection, statusTypes, types } from '../definitions';

// TODO: Think of changing this method signature to `changes, todo` [twl 31.Aug.18]
const updateSchedule = makeAsyncActionCreator({
  type: types.UPDATE_SCHEDULE,
  creator: async (id, changes) => await updateRemoteDocument(collection, id, changes),
  status: {
    path: collection,
    type: statusTypes.updateSchedule,
    parameters: (id, _changes) => [ id ],
  }
});

export default updateSchedule;
