import get from 'lodash/get';
import { unflattenPathKeys } from '@lifetools/shared-utils';
import { DocumentUpdates, IDocumentData } from '@lifetools/shared-database';

/**
 * Returns whether a value exists at the `path` within the `updates`, checking all path combinations
 * of update paths and regular nested objects.
 *
 * @param updates - A document update whose keys can be paths to keys in an object tree
 * @param path    - A path to check
 *
 * @returns `true` if a value exists at the path; `false` otherwise
 */
export const updateExistsAtPath = (
  updates: DocumentUpdates<IDocumentData>,
  path: string,
): boolean => (
  typeof get(unflattenPathKeys(updates), path) !== 'undefined'
);
