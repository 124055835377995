import React from 'react';
import Page from 'components/app/navigation/Page';
import ViewLayout from 'components/layouts/ViewLayout';
import NotificationSettings from './components/NotificationSettings';
import CalendarSettings from './components/CalendarSettings';
import ProfileSettings from './components/ProfileSettings';
import SubscriptionSettings from './components/SubscriptionSettings';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'scenes.users.Account';

const Account = ({ t }) => (
  <Page title={t(i18nPath, 'title')}>
    <ViewLayout layout="split">
      <ProfileSettings
        viewId="profile"
        viewColumn={1}
        viewTitle={t(i18nPath, 'components.ProfileSettings.title')}
      />

      <CalendarSettings
        viewId="calendars"
        viewColumn={1}
        viewTitle={t(i18nPath, 'components.CalendarSettings.title')}
      />

      <NotificationSettings
        viewId="notifications"
        viewColumn={2}
        viewTitle={t(i18nPath, 'components.NotificationSettings.title')}
      />

      <SubscriptionSettings
        viewId="subscriptions"
        viewColumn={2}
        viewTitle={t(i18nPath, 'components.SubscriptionSettings.title')}
      />
    </ViewLayout>
  </Page>
);

export default withTranslations(Account);
