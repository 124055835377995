import React from 'react';
import Todos from 'scenes/todos/Todos';

// TODO: Use a static object rather than generating it for each render. Investigate further why we
//       need to do this. The compiler should be optimizing inline static objects to be static.
//       [twl 30.Nov.18]
const query = { type: 'task' };

const Tasks = props => (
  <Todos
    defaultType="task"
    query={query}
    variant="normal:task"
    actionMode="statusMenu"
    i18nPath="scenes.todos.Tasks"
    settingsPath="defaults.tasks"
    defaultSettings={{
      filter: 'all',
      sort: 'status',
      search: '',
    }}
    backTo="/tasks"
    sortOptions={[ 'title', 'status', 'tagsStatus', 'snoozedUntil', 'newestFirst', 'oldestFirst' ]}
    filterOptions={[
      'all', 'notStarted', 'inProgress', 'done', 'notDone', 'wontDo', 'isSnoozed', 'wasSnoozed',
      'neverSnoozed', 'isScheduled', 'notScheduled'
    ]}
  />
);

export default Tasks;
