import get from 'lodash/get';
import React from 'react';
import Loader from 'components/library/Loader';
import { buildScene } from 'scenes/utils';

const LoaderWidget = buildScene(({ status, children, ...props }) => (
  <Loader {...props} status={status}>
    {children}
  </Loader>
), {
  state: (state, { statusPath }) => ({
    status: get(state, statusPath) || {}
  }),
});

export default LoaderWidget;
