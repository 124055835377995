export const theme = {
  typography: {
    useNextVariants: true,

    // Using a Major Second typographic scale. Explore more at http://type-scale.com/
    h1: {
      fontSize: '1.602rem',
      fontWeight: 500,
      letterSpacing: '0.01602rem',
    },
    h2: {
      fontSize: '1.424rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.266rem',
    },
    h4: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.889rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.889rem',
    },
    subtitle1: {
      fontSize: '1rem',
    },
    subtitle2: {
      fontSize: '0.889rem',
    },
    caption: {
      fontSize: '0.79rem'
    },
  },
  palette: {
    primary: {
      main: '#9CC04F',
      contrastText: '#1B232B',
    },

    info: {
      main: '#768fb5',
    },

    error: {
      main: '#e54949',
    },

    // TODO: Not currently using secondary theme colors. Consider using these for the `actionBar`
    //       below. [twl 30.Nov.18]
    //
    // secondary: {
    //   main: '#FF0000',
    // },

    // TODO: Convert to the normal palette here & in the components using these. [twl 21.Nov.18]
    custom: {
      actionBar: {
        background: '#dad9c4',
        color: '#444',
      },
      activeSettings: {
        background: 'rgba(255, 204, 0, 0.2)',
      },
      helpTip: {
        background: '#fff8db',
      },
      notifications: {
        highlight: '#f3e39a',
        warn: '#ffcc00',
      },
      page: {
        background: '#eeeeed',
      },
      pageTitle: {
        color: '#222',
      },
      statuses: {
        done: '#9ccc65',
        new: '#ffee58',
        missed: '#fefefe',
        notScheduled: '#eee',
      },
      pending: {
        background: 'rgba(255, 204, 0, 0.08)',
      },
    },
  },
  layout: {
    pageDrawer: {
      width: 240,
    },
    pageDrawerLogo: {
      width: 200,
    },
  },
};
