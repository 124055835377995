import createTodo from 'services/store/todos/actions/createTodo';

// NOTE: `createTodo` is an async action creator, so this executes asynchronously. [twl 30.Aug.18]
const createIntent = (todo, schedule, duration) => {
  if (!schedule || !schedule.id) {
    throw new Error(`An intent requires a schedule to be created`);
  }

  if (todo.scheduleIds) {
    throw new Error(`A new todo cannot have its schedule properties already set`);
  }

  let newTodo = {
    ...todo,
    scheduleIds: [ schedule.id ],
    intents: {
      [schedule.id]: {
        status: {
          state: 'new',
        },
        date: schedule.date,
        ...duration && { duration },
      },
    },
  };

  return createTodo(newTodo);
}

export default createIntent;
