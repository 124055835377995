import React from 'react';
import NotesEditDialog from 'components/app/common/NotesEditDialog';
import NotesView from 'components/app/common/NotesView';
import ActionBar from 'components/library/ActionBar';
import { buildScene } from 'scenes/utils';
import updateSchedule from 'services/store/schedules/actions/updateSchedule';

const i18nPathDefault = 'widgets.schedules.ScheduleNotesWidget';

const ScheduleNotesWidget = buildScene(({
  i18nPath = i18nPathDefault,
  schedule,
  readOnly,
  doUpdateSchedule,
}) => (
  <NotesView
    i18nPath={i18nPath}
    boxActions={!readOnly && [
      <ActionBar.Button
        i18nPath={i18nPath}
        action={schedule.notes ? 'edit' : 'add'}
        pane={
          <NotesEditDialog
            i18nPath={i18nPath + '.views.EditDialog'}
            value={schedule}
            onSave={async (ignore, changes) => await doUpdateSchedule(schedule.id, changes)}
          />
        }
      />,
    ]}
    value={schedule.notes}
    onChange={!readOnly ? async notes => await doUpdateSchedule(schedule.id, { notes }) : undefined}
  />
), {
  dispatch: {
    doUpdateSchedule: updateSchedule,
  },
});

export default ScheduleNotesWidget;
