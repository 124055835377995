import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withTranslations from 'helpers/withTranslations';
import {isAuthenticated } from 'services/store/auth/selectors';
import AnonymousPage from './components/AnonymousPage';
import AuthenticatedPage from './components/AuthenticatedPage';

const siteTitle = document.title;

const Page = ({ i18nPath, docTitle, title, subtitle, t, isAuthenticated, ...pageProps }) => {
  useEffect(() => {
    const mainTitle = i18nPath ? t(i18nPath, 'doc-title', docTitle || title) : (docTitle || title);

    document.title = siteTitle +
                     (mainTitle ? ' | '  + mainTitle : '') +
                     (subtitle ? ' | ' + subtitle : '');
  }, [ docTitle, title, subtitle, i18nPath, t ]);

  const PageComponent = isAuthenticated ? AuthenticatedPage : AnonymousPage;

  return <PageComponent {...pageProps} title={title} subtitle={subtitle} />;
};

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
});

export default compose(
  withTranslations,
  connect(mapStateToProps),
)(Page);
