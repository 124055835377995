import { LocalDate, ChronoUnit } from 'js-joda';
import { IUserSettings } from '@lifetools/shared-app-data';
import { toLocalDate } from '@lifetools/shared-utils-time';
import { ActivePlanInfo } from '../../../types/ActivePlanInfo';
import { ActivePlanStatus } from '../../../types/ActivePlanStatus';
import { getDefaultPlan } from './getDefaultPlan';
import { getDefaultTrial } from './getDefaultTrial';
import { getPlanTitle } from './getPlanTitle';
import planDefaults from './planDefaults';

const daysUntil = (date: LocalDate) => LocalDate.now().until(date, ChronoUnit.DAYS);

/**
 * Returns the extended details about the subscription plan for the current user.
 *
 * @param userSettings - The settings for this user
 *
 * @return The subscription plan details for the current user
 */
export function getActivePlanInfo(userSettings: IUserSettings): ActivePlanInfo {
  const plan = userSettings.plan || getDefaultPlan(userSettings);
  const type = plan.type;
  const promotion = plan.promotion;

  let endsOn: LocalDate | undefined;
  let daysUntilEnd: number;
  let graceDays: number;
  let status: ActivePlanStatus;
  let toCancel: boolean = false;

  switch (type) {
    case 'free':
      endsOn = undefined;
      daysUntilEnd = 0;
      graceDays = 0;
      status = 'active';
      break;

    case 'paid':
      const subscription = plan.subscription;

      if (!subscription) {
        throw new Error('Missing subscription details');
      }

      status = subscription.status;
      endsOn = toLocalDate(
        subscription.toCancelAt || subscription.canceledAt ||
        ['incomplete', 'incomplete_expired'].includes(status)
          ? subscription.periodStart
          : subscription.periodEnd
      )!;
      daysUntilEnd = daysUntil(endsOn);
      graceDays = subscription.graceDays || planDefaults.paidGraceDays;
      toCancel = subscription.toCancel || false;
      break;


    default:
      // Add to `plan` if it doesn't have it--requires for `getPlanTitle` after `switch`
      plan.trial = plan.trial || getDefaultTrial(plan);

      endsOn = toLocalDate(plan.trial.ends)!;
      daysUntilEnd = daysUntil(endsOn);
      graceDays = plan.trial.graceDays || planDefaults.trialGraceDays;
      status = daysUntilEnd > 0 ? 'active' : 'ended';
      break;
  }

  const title = getPlanTitle(plan);
  const isActive = status === 'active';
  const gracePeriodEnabled = !['canceled', 'unpaid'].includes(status);
  const graceDaysLeft = gracePeriodEnabled
    ? daysUntilEnd > 0 ? graceDays : Math.max(graceDays + daysUntilEnd, 0)
    : 0;
  const inGracePeriod = !isActive && gracePeriodEnabled && graceDaysLeft > 0;

  return {
    type,
    status,
    title,
    endsOn,
    isActive,
    inGracePeriod,
    toCancel,
    isAccountReadOnly: !isActive && !inGracePeriod,
    daysUntilEnd,
    graceDaysLeft,
    promotion,
  };
}
