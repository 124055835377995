import React from 'react';
import PropTypes from 'prop-types';
import Box from 'components/library/Box';
import Calendar from 'components/library/Calendar';
import ViewLayout from 'components/layouts/ViewLayout';
import withStyles from 'helpers/withStyles';

const styles = {
  layout: {
    maxHeight: '100%',
  },
  calendarBox: {
    flex: '1 1 auto',
    alignSelf: 'stretch',

    '@media print': {
      display: 'none',
    },
  },
  calendar: {
    flex: '1 1 auto',
  },
};

/**
 * A view which has a calendar on the left side and a detail column on the right, in which the
 * children of this component are rendered. In a mobile view, the calendar is placed on the top and
 * the detail below it.
 *
 * Note that when rendering multiple children, a margin is placed between each child. To render
 * children without a margin, wrap them in another component.
 */
const CalendarDetails = ({ i18nPath, boxActions, children, classes, ...props }) => (
  <ViewLayout className={classes.layout} layout="split" scrollColumns="2">
    <Box
      className={classes.calendarBox}
      i18nPath={i18nPath}
      actions={boxActions}
      viewColumn={1}
      viewOrder={1}
      hideHeaderOnMobile
    >
      <Calendar className={classes.calendar} {...props} />
    </Box>

    {children}
  </ViewLayout>
);

CalendarDetails.propTypes = {
  /** The path from which to load localization strings for this component */
  i18nPath: PropTypes.string,

  /** The components to render next to the calendar */
  children: PropTypes.node,

  /** An object mapping semantic class names to compiled class names. Provided by HOC. */
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CalendarDetails);
