import { getDocument } from '@lifetools/shared-database';
import sentry from 'services/sentry';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';
import { collection, types } from '../definitions';

// TODO: Set up subscriptions, and then only run this query the first time; thereafter any updates
//       via cloud functions will be updated automatically. [twl 27.Apr.22]
//
const loadAppSettings = makeAsyncActionCreator({
  type: types.SET_APP_SETTINGS,
  when: firstRunOrAfterOldFailure,
  creator: async () => {
    try {
      return await getDocument('app', 'settings', { skipAuthenticationCheck: true });
    } catch (e) {
      sentry.captureException(e);
    }
  },

  status: {
    path: collection,
    type: 'read',
    parameters: () => 'app',
  }
});

export default loadAppSettings;
