import React from 'react';
import withStyles from 'helpers/withStyles';
import IntentAllocationTableCell from '../IntentAllocationTableCell';

const styles = theme => ({
  row: {
    display: 'flex',
  },
});

export const IntentAllocationTableRow = ({ record, columns, classes }) => (
  <div className={classes.row}>
    {columns.map((column, index) => (
      <IntentAllocationTableCell key={index} record={record} column={column} />
    ))}
  </div>
);

export default withStyles(styles)(IntentAllocationTableRow);
