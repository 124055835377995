import { SimpleExpression, operators } from '../../types/SimpleExpression';

const isInteger = /^\d+$/;
const isFloat = /^(\d*\.)?\d+$/;

/**
 * Parses a simple logical expression (no parentheses) into an object tree where each non-leaf
 * node includes:
 *
 * `operator`
 * : The operator to use when evaluating the expression
 *
 * `operands`
 * : The operands to use with the operator
 *
 * Leaf nodes will either be a value or an object whose type is `variable` and whose `name` is the
 * name of the variable.
 *
 * @param expression An expression to parse that is a series of comparisons connected with && or ||
 *
 * @returns An object tree that can be used to evaluate the expression
 */
export function parseSimpleExpression(expression: string): SimpleExpression {
  const operator = operators.find(operator => expression.includes(operator));

  if (operator) {
    return {
      type: 'operation',
      operator,
      operands: expression
        .split(operator)
        .filter(expression => expression !== '')
        .map(parseSimpleExpression),
    };
  }

  if (typeof expression === 'string') {
    expression = expression.trim();

    return isInteger.test(expression)
      ? parseInt(expression)
      : isFloat.test(expression)
        ? parseFloat(expression)
        : expression.startsWith('"') && expression.endsWith('"')
          ? expression.substring(1, expression.length - 1)
          : expression === 'null'
            ? null
            : expression === 'undefined'
              ? undefined
              : {
                  type: 'variable',
                  name: expression,
                };
  }

  return expression;
}
