import {
  DataOperationType, ITriggerRule, TriggerContext, TriggerStage
} from '@lifetools/shared-database';

/**
 * Executes the `trigger` for the `operation` within the `collection`.
 *
 * @param trigger    - The trigger to execute
 * @param collection - The collection the document triggering the trigger belongs to
 * @param before     - The data before any trigger was executed, including the `id`
 * @param changes    - The changes from previous triggers
 * @param operation  - The type of operation being performed
 * @param stage      - The stage at which the trigger is being executed, e.g. `before

 * @returns A `TriggerContext` after the trigger has been executed against it
 */
export async function executeTrigger(
  trigger: ITriggerRule,
  collection: string,
  before: any,
  changes?: any,
  operation: DataOperationType = 'update',
  stage: TriggerStage = 'before',
): Promise<TriggerContext> {
  const context = new TriggerContext(stage, operation, collection, before && before.id, changes,
                                     before);

  await trigger.func(context);

  return context;
}
