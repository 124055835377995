import { mergePathKeys } from '@lifetools/shared-utils';
import { IDocumentData } from '../documents/types/IDocumentData';
import { Id } from '../documents/types/Id';
import { DataOperationType } from '../operations/types/DataOperationType';
import { resolveFieldValues } from '../utils/resolveFieldValues';
import DocumentChanges from './DocumentChanges';
import DocumentSnapshot from './DocumentSnapshot';
import { DocumentDataFetcher } from './types/DocumentDataFetcher';
import { TriggerStage } from './types/TriggerStage';

/**
 * The execution context for a trigger being executed due to a write operation.
 */
class TriggerContext {
  /**
   * The collection the operation is being performed on.
   */
  public collection: string;

  /**
   * The stage of operation being performed.
   */
  public operation: DataOperationType;

  /**
   * The stage in the data pipeline when the trigger is executed.
   */
  public stage: TriggerStage;

  /**
   * The changes to the document being applied.
   */
  public changes: DocumentChanges;

  /**
   * A snapshot of the document before the changes are applied.
   */
  public before: DocumentSnapshot;

  /**
   * A snapshot of the document after the changes are applied.
   */
  public after: DocumentSnapshot;

  /**
   * Constructs a new context for a trigger.
   *
   * @param stage      - The stage when the trigger is executed, e.g. `before`
   * @param operation  - The type of operation, e.g. `create`, `update`, `delete`
   * @param collection - The collection the document belongs to
   * @param id         - The ID of the document, if it already exists
   * @param changes    - The changes being applied to the document
   * @param before     - The existing stored data or a function used to return the data when passed
   *                     `(collection, id)`
   */
  constructor(
    stage: TriggerStage,
    operation: DataOperationType,
    collection: string,
    id?: Id,
    changes?: IDocumentData,
    before?: IDocumentData | DocumentDataFetcher,
  ) {
    this.stage = stage;
    this.operation = operation;
    this.collection = collection;
    this.changes = new DocumentChanges(changes);
    this.before = new DocumentSnapshot(id, id != null, () => (
      typeof before === 'function' ? before(this.collection, id) : before
    ));
    this.after = new DocumentSnapshot(id, operation !== 'delete', () => {
      const before = this.before.data() || {};

      return resolveFieldValues(before, mergePathKeys(before, this.changes.values));
    }, this.changes);
  }
}

export default TriggerContext;
