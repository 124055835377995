import { invert } from 'lodash';
import { replaceAll } from '@lifetools/shared-utils';
import { pathEncodings } from './pathEncodings';

const pathDecodings = invert(pathEncodings);

/**
 * Decodes an `element` that was previously encoded so it could be used as a single path element
 * within a Firestore document path, and returns the original value.
 *
 * @param element - The element to decode
 *
 * @returns The decoded element
 */
export function decodeDocumentPathElement(element: string) {
  if (element === '#undefined') {
    return undefined;
  } else if (element === '#null') {
    return null;
  }

  let result = element;

  for (const char in pathDecodings) {
    if (result.indexOf(char) !== -1) {
      result = replaceAll(result, char, pathDecodings[char]);
    }
  }

  return replaceAll(result, '%25', '%');
}
