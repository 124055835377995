/**
 * The valid meta properties for documents.
 */
export const DocumentMetaProperties = {
  /**
   * The version of data schema.
   */
  Version: '$v',

  /**
   * Indicates this is a template document and not a user document. The value indicates the scope
   * this template applies to, which should be `default` if no specific scope.
   */
  Template: '$template',

  /**
   * Whether this document has been archived; if `true`, the document is effectively "deleted"
   */
  Archived: '$archived',

  /**
   * A timestamp for when this document was archived.
   */
  ArchivedAt: '$archivedAt',

  /**
   * A timestamp for when this document was migrated to the current data schema.
   */
  MigratedAt: '$migratedAt',

  /**
   * A timestamp for when the backup was created. This should only exist on backups.
   */
  BackupAt: '$backupAt',

  /**
   * An action that should be taken when syncing this document, such as between the back-end and the
   * front-end. When set to `delete`, the document should be deleted; when set to `remove`, the
   * document should be removed from the current query result (but it still exists in the database).
   */
  SyncAction: '$action',

  /**
   * Whether this document has a write pending.
   */
  PendingWrite: '$pending',
};
