import { ITodo, calculateTodoStatus, isManualOverrideStatus } from '@lifetools/shared-app-data';
import { TriggerContext, ITriggerRule } from '@lifetools/shared-database';

/**
 * Sets the status of a todo based on the presence and status of its intents.
 */
const todoStatusFromIntent: ITriggerRule = {
  stage: 'before',
  operation: 'write',
  collection: 'todos',
  func: ({ before, changes, after }: TriggerContext) => {
    const changesTodoState = changes.get('status.state');
    const type = after.get('type');

    // Ignore deletes, any writes that don't involve changes to intents & any writes to activities
    if (!changes.values || (!changesTodoState && !changes.has('intents')) || type === 'activity') {
      return;
    }

    const changesFrom = changes.get('status.from');

    if (changesFrom) {
      throw new Error(`'status.from' can only be set to 'undefined' outside the` +
                      ` 'todoStatusFromIntent' trigger. Value was ${changesFrom}`);
    }

    if (['intent', 'doing'].includes(changesTodoState)) {
      throw new Error(`'status.state' cannot be manually set to 'intent' or 'doing'`);
    }

    if (changesTodoState === 'new' && before.get('intents') != null && after.get('intents') != null) {
      throw new Error(`'status.state' cannot be set to 'new' when intents exist`);
    }

    // Don't change the state if the todo has been explicitly set to this state
    if (!isManualOverrideStatus(after.get('status'))) {
      const { state, from } = calculateTodoStatus(after.data() as ITodo);

      if (state !== after.get('status.state')) {
        changes.set('status.state', state);
      }

      if (from !== after.get('status.from')) {
        changes.set('status.from', from);
      }
    }
 },
};

export default todoStatusFromIntent;
