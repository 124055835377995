import get from 'lodash/get';
import React from 'react';
import ConnectButton from 'components/app/integrations/calendars/ConnectButton';
import ConnectStatus from 'components/app/integrations/calendars/ConnectStatus';
import Box from 'components/library/Box';
import Typography from 'components/library/Typography';
import { buildScene } from 'scenes/utils';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import CalendarList from './components/CalendarList';

const i18nPath = 'scenes.users.Account.components.CalendarSettings';

async function loadData() {
  this.props.doLoadSettings();
}

const CalendarSettings = buildScene(({
  config,
  doUpdateSettings,
  isAccountReadOnly,
  t,
  classes,
}) => (
  <Box
    title={t(i18nPath, 'title')}
    actions={[
      config.integration === 'cronofy' && !isAccountReadOnly &&
        <ConnectButton variant="actionBar" integration="cronofy" redirectPath="/account" />,
    ]}
  >
    {!isAccountReadOnly &&
      <ConnectStatus
        className={classes.status}
        redirectPath="/account"
        hideWhen={['loadingAuthorization', 'authorized:existing']}
      />
    }

    <CalendarList noItems={isAccountReadOnly &&
      <div className={classes.noItems}>
        <Typography>{t(i18nPath, 'no-items-found')}</Typography>
      </div>
    } />
  </Box>
), {
  styles: theme => ({
    status: {
      background: theme.palette.common.white,
    },
    noItems: {
      padding: theme.spacing.unit * 2,
      textAlign: 'center',
    },
  }),
  context: 'isAccountReadOnly',
  state: state => ({
    config: get(selectUserSettings(state), 'calendars.config', { integration: 'gcalendar' }),
  }),
  dispatch: {
    doLoadSettings: loadSettings,
    doUpdateSettings: updateSettings,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default CalendarSettings;
