import React from 'react';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  list: {
    listStyle: 'disc outside none',
    marginLeft: 0,
    paddingLeft: '1.5rem',

    '& li': {
      paddingLeft: '0.1rem',
      lineHeight: 1.3,
    },
  },
});

const ErrorMessage = ({ value, classes }) => (
  !Array.isArray(value) ?
    value
    :
    <ul className={classes.list}>
      {value.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
);

export default withStyles(styles)(ErrorMessage);
