import React from 'react';
import { LocalDate } from 'js-joda';
import { withRoutedDate } from 'helpers/withRoutedDate';
import withDeviceInfo from 'helpers/withDeviceInfo';

const CalendarBase = ({
  detailPage: DetailPage,
  mainPage: MainPage,
  routeDate,
  routeView,
  onChangeRouteDate,
  isMobileDevice,
  ...props
}) => (
  isMobileDevice && routeDate && routeView === 'detail' ?
    <DetailPage date={routeDate} /> :
    <MainPage
      showDetail={!isMobileDevice || (routeDate && routeView === 'detail')}
      date={routeDate || LocalDate.now()}
      onChangeDate={(date, view) => onChangeRouteDate(date, isMobileDevice ? view : undefined)} />
);

export default withDeviceInfo(withRoutedDate(CalendarBase));
