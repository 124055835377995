import { ITodo } from '@lifetools/shared-app-data';
import { parseTags, tagFormat } from './parseTags';

/**
 * Parses tags in the title and adds them to the `tags` array if they don't already exist there.
 *
 * Tags must start with a letter (in any script) and then can contain letters, numbers, the dash or
 * underscore (in any script).
 *
 * @param todo - A todo
 *
 * @returns A new todo with the tags removed from the `title` and added to `tags`
 */
export function parseTagsInTitle({ title, ...todo }: ITodo): ITodo {
  return {
    ...todo,
    tags: parseTags(title),
    title: title && title.replace(tagFormat, '').trim(),          // remove tags and extra spaces
  };
}
