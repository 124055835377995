import React, { memo } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'helpers/withStyles';
import TodoItemVariantType from 'types/TodoItemVariant';
import ActivityInfo from './components/ActivityInfo';
import AppointmentInfo from './components/AppointmentInfo';
import TaskInfo from './components/TaskInfo';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const TodoInfo = ({ value, intent, date, variant, classes }) => (
  <div className={classes.root}>
    {value.type === 'appointment' ? <AppointmentInfo value={value} variant={variant} /> :
      value.type === 'activity' ? <ActivityInfo value={value} intent={intent} variant={variant} /> :
                                  <TaskInfo value={value} variant={variant} date={date} />
    }
  </div>
);

TodoInfo.propTypes = {
  /** The todo object to render */
  value: PropTypes.object,

  /** The variant to use */
  variant: TodoItemVariantType,

  /** An object mapping semantic class names to compiled class names. Provided by HOC. */
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(memo(TodoInfo));
