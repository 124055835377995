import React, { useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import RadioSelector from 'components/library/RadioSelector';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { resolveRoute } from 'utils/app/routes/resolveRoute';
import { toRouteDate } from 'utils/app/routes/toRouteDate';
import { fromRouteDate } from 'utils/app/routes/fromRouteDate';
import PlanDateOption from './components/PlanDateOption';

const i18nPath = 'scenes.app.Today.components.NoTodayViewSurveyWithButtons.components.WhichPlanDate';

const styles = theme => ({
  header: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 2}px 0`,
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit,
    }
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: 320,

    '& > *': {
      marginBottom: theme.spacing.unit * 2,
    },
  },
});

const isFuture = date => date && !['today', 'tomorrow'].includes(date);

const WhichPlanDate = ({ planDate, history, match, classes, t }) => {
  const [routeDate, setRouteDate] = useState(isFuture(planDate) ? 'future' : (planDate || 'none'));
  const setDate = date => history.push(resolveRoute(match.path, { planDate: toRouteDate(date) }));

  return (
    <div>
      <Typography className={classes.header} variant="h2">
        {t(i18nPath, 'title')}
      </Typography>

      <div className={classes.options}>
        <RadioSelector
          value={routeDate}
          options={[{
            label: <PlanDateOption planDate="today" />,
            value: 'today'
          }, {
            label: <PlanDateOption planDate="tomorrow" />,
            value: 'tomorrow'
          }, {
            label: (
              <PlanDateOption
                planDate="future"
                currentPlanDate={isFuture(planDate) ? planDate : undefined}
                date={fromRouteDate(planDate)}
                onChange={setDate}
              />
            ),
            value: 'future'
          }]}
          onChange={date => {
            setRouteDate(date);
            setDate(!isFuture(date) ? date : undefined);
          }}
        />
      </div>
    </div>
  );
}

export default compose(
  withRouter,
  withStyles(styles),
  withTranslations,
)(WhichPlanDate);
