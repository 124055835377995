import { AuthenticationError } from '../../../types/AuthenticationError';
import { getActiveProvider } from './getActiveProvider';

/**
 * Asserts that the provider associated with `email` is the same as `providerId` and throws an error
 * if this is not the case.
 */
export async function assertProvider(email: string, providerId: string): Promise<void> {
  const activeProvider = await getActiveProvider(email);

  if (activeProvider !== providerId) {
    throw new AuthenticationError(
      'auth/account-exists-with-different-credential',
      'Signing in with a secondary provider is not supported.',
      {
        email: email,
        activeProvider,
        providerId,
      }
    );
  }
}
