import { EmbedOptions } from './EmbedOptions';
import { isEmbedUri } from './isEmbedUri';
import { parseAspectRatio } from './parseAspectRatio';
import { hostnameDefaults } from './hostnameDefaults';

export interface EmbedInfo extends EmbedOptions {
  uri: string;
  aspectWidth?: number;
  aspectHeight?: number;
}

/**
 * Parses the options in an embed URI.
 *
 * @param text - A string of comma-separated key=value pairs, enclosed within parentheses
 *
 * @returns An object containing the parsed options
 */
function parseEmbedOptions(text: string): EmbedOptions | undefined {
  if (!text.startsWith('(') || !text.endsWith(')')) {
    return undefined;
  }

  return text.substring(1, text.length - 1).split(',').reduce((options, option) => {
    const [ name, value ] = option.split('=');

    options[name] = value;

    return options;
  }, {} as EmbedOptions);
}

/**
 * Parses an embed URI into an info object that describes the URI.
 *
 * @param embedUri - The URI to parse
 * @param defaults - The default options to apply to the embed info
 *
 * @returns An embed info object for the URI, or `undefined` if `embedUri` is not an embed URI
 */
export function parseEmbedUri(embedUri: string, defaults: EmbedOptions): EmbedInfo | undefined {
  if (!isEmbedUri(embedUri)) {
    return undefined;
  }

  // works for both `http` and `https` since we just want the first index
  const uriIndex = embedUri.indexOf(':http') + 1;
  const uri = embedUri.substring(uriIndex);
  const url = new URL(uri);
  const options = parseEmbedOptions(embedUri.substring('embed'.length, uriIndex - 1));
  const aspectRatio = parseAspectRatio(
    (options && options.aspectRatio) ||
    (hostnameDefaults[url.hostname] && hostnameDefaults[url.hostname].aspectRatio)
  );

  return {
    ...defaults,
    ...hostnameDefaults[url.hostname],
    ...options,
    ...aspectRatio && {
      aspectWidth: aspectRatio.width,
      aspectHeight: aspectRatio.height,
    },
    uri,
  };
}
