import get from 'lodash/get';
import { addDocument } from '@lifetools/shared-database';
import store from 'services/store';
import analytics from 'services/analytics';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import loadSettings from 'services/store/settings/actions/loadSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { collection, types } from '../definitions';

// TODO: Think about how to track status here. I disabled status updates (by not passing in
// `status`)
const addSchedule = makeAsyncActionCreator({
  type: types.ADD_SCHEDULE,
  creator: async (schedule) => {
    await store.dispatch(loadSettings());

    const { startTime, endTime, defaultStartTime, defaultEndTime, ...other } = schedule;
    const userSettings = selectUserSettings(store.getState());
    const defaultSchedule = get(userSettings, 'defaults.scheduleTimes', {});

    const newSchedule = await addDocument(collection, {
      startTime: startTime || defaultSchedule.startTime || defaultStartTime,
      endTime: endTime || defaultSchedule.endTime || defaultEndTime,
      ...other,
    });

    analytics.track('workflow_plan_start', {
      workflow_type: newSchedule.type,
      schedule_date: newSchedule.date,
      schedule_count: get(userSettings, 'info.schedules.count', 0),
    });

    return newSchedule;
  },
});

export default addSchedule;
