import React from 'react';
import contexts from 'contexts/contexts.ts';
import configureWrappedComponent from 'helpers/utils/configureWrappedComponent';

/**
 * Enhances a component by consuming a context and passing the value to the wrapped component.
 */
function withContext(context) {
  const Context = contexts[context];

  return WrappedComponent => (
    configureWrappedComponent(WrappedComponent, 'WithContext',
      props => (
        <Context.Consumer>
          {value => <WrappedComponent {...{ [context]: value }} {...props} />}
        </Context.Consumer>
      )
    )
  );
}

export default withContext;
