import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import StrengthIndicator from 'components/app/indicators/StrengthIndicator';
import './StrengthInputHelper.css';

const i18nPath = 'components.library.InputObject.components.StrengthInputHelper';

class StrengthInputHelper extends Component {
  componentDidMount() {
    const { property, value, onPropertyChange } = this.props;

    // Ensure when this helper is first displayed, the value is reset to 1
    onPropertyChange && onPropertyChange(property, 1, value);
  }

  onKeyInput = (info) => {
    const { property, value, onPropertyChange, onCancel } = this.props;

    // If trigger has been removed (because user pressed delete, for instance), cancel the helper
    if (info.trigger === '') {
      onCancel && onCancel();
    }

    let newValue = 1;

    for (const char of info.text) {
      if (char === info.trigger) {
        newValue++;
      } else {
        onCancel && onCancel();
        return;
      }
    }

    onPropertyChange && onPropertyChange(property, newValue, value);
  };

  render() {
    const { t, property, value = 1 } = this.props;

    return (
      <div className="StrengthInputHelper">
        <div className="StrengthInputHelper_input">
          <span className="StrengthInputHelper_prompt">
            {t(`${i18nPath}.properties.${property}.prompt`)}
          </span>

          <StrengthIndicator property={property} value={value} />
        </div>

        <div className="StrengthInputHelper_help">
          {t(`${i18nPath}.help`)}
        </div>
      </div>
    );
  }
}

export default withTranslation('translations', { withRef: true })(StrengthInputHelper);

