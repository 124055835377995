import get from 'lodash/get';
import { ITodo } from '@lifetools/shared-app-types';
import { DateValue, isAfterToday } from '@lifetools/shared-utils-time';

/**
 * Returns whether a todo is snoozed (i.e. hidden from the task flow) on a given date.
 *
 * @param todo - The todo to check
 * @param date - The date on which to check if the todo is snoozed. Defaults to the current date.
 *
 * @return `true` if the todo is snoozed on the `date`; `false` otherwise
 */
export function isSnoozed(todo: ITodo, date: DateValue): boolean {
  const hideUntil = get(todo, 'status.hide.date');

  return hideUntil != null && (hideUntil === '' || isAfterToday(hideUntil, date));
}
