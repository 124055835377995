import React from 'react';
import Typography from 'components/library/Typography';
import withTranslations from 'helpers/withTranslations';

// TODO: Update this path to match the path to this component if we decide to use this variation.
//       [twl 4.Apr.22]
const i18nPath = 'components.app.schedules.StartWorkflowButton';

const PlanTypeOption = ({ workflow, t }) => (
  <Typography variant="subtitle1">{t(i18nPath, `title-${workflow}`)}</Typography>
);

export default withTranslations(PlanTypeOption);
