import { get } from 'lodash';
import { Children, cloneElement } from 'react';
import { toLocalDate } from '@lifetools/shared-utils-time';

// TODO: Figure out how to make this part of the theme style. [twl 29.Nov.18]
const styles = {
  hasSchedule: {
    background: '#d6e5b7',
    cursor: 'pointer',
  },
  noSchedule: {
    cursor: 'pointer',
  },
};

const ScheduledDay = ({ value, schedules, onSelect, children }) => {
  const date = toLocalDate(value);
  const schedule = schedules && schedules.find(item => item.date.equals(date));
  const hasSchedule = get(schedule, 'info.intents.count');

  // HACK: Temporary hack to make sure dates can be selected until the bug in `react-big-calendar`
  //       is fixed. The issue to monitor is
  //       https://github.com/intljusticemission/react-big-calendar/issues/1005
  //       [twl 12.Dec.18]
  const onTouchEnd = event => {
    event.preventDefault();

    onSelect(date, 'detail');
  };

  return Children.map(children, child => (
    cloneElement(child, { style: hasSchedule ? styles.hasSchedule : styles.noSchedule, onTouchEnd })
  ));
}

export default ScheduledDay;
