import React from 'react';
import Page from 'components/app/navigation/Page';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import Login from 'scenes/users/Login';

const i18nPath = 'scenes.public.Home';

const styles = theme => ({
  noAccount: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 4,
  },
});

const Home = ({ classes, t }) => (
  <Page docTitle={t(i18nPath, 'doc-title')} theme="darkForm">
    <Login noPage allowNewAccount />
  </Page>
);

export default withTranslations(withStyles(styles)(Home));