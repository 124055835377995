/**
 * Asserts that the `condition` is truthy and if it is not, throws an error with the `message`.
 *
 * @param condition - A condition to test for truthiness
 * @param message   - The message of the error to be thrown when the `condition` is falsey
 */
export const assert = (condition: any, message: string) => {
  if (!condition) {
    throw new Error(message);
  }
}
