import get from 'lodash/get';
import { ITodo } from '@lifetools/shared-app-types';
import { isAfterToday } from '@lifetools/shared-utils-time';

/**
 * Returns whether a todo was snoozed (i.e. hidden from the task flow), based on today's date, but
 * is now active again.
 *
 * @param todo - The todo to check
 *
 * @return `true` if the todo was snoozed; `false` otherwise
 */
export function wasSnoozed(todo: ITodo): boolean {
  const date = get(todo, 'status.hide.date');

  // TODO: Think about whether this should also test whether `status.hide` exists. If `status.hide`
  //       exists, it means the todo was snoozed at some point, but it was explicitly cleared.

  return date != null && date !== '' && !isAfterToday(date);
}
