import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from 'components/library/AppBar';
import Toolbar from 'components/library/Toolbar';
import Typography from 'components/library/Typography';

const styles = theme => ({
  root: {
    marginLeft: theme.layout.pageDrawer.width,

    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.layout.pageDrawer.width}px)`,
    },

    '@media print': {
      width: '100%',
      background: '#fff',
      color: '#000',
      boxShadow: 'none',
    },
  },
  header: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  titleBox: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '0 auto',
    color: theme.palette.custom.pageTitle,

    '& > h2': {
      lineHeight: '1 !important',
    },
  },
  subtitle: {
    marginTop: theme.spacing.unit / 2,
  },
});

const PageHeader = ({ classes, title, subtitle, primaryButton, secondaryButton }) => (
  <AppBar className={classes.root} position="fixed" color="primary" elevation={0}>
    <Toolbar className={primaryButton ? classes.header : undefined}>
      <div className={classes.titleBox}>
        <Typography variant="h2" color="inherit">{title}</Typography>

        {subtitle &&
          <Typography className={classes.subtitle} variant="h6" color="inherit">
            {subtitle}
          </Typography>
        }
      </div>

      {primaryButton}
      {secondaryButton}
    </Toolbar>
  </AppBar>
);

PageHeader.propTypes = {
  /** The title of this page */
  title: PropTypes.string.isRequired,

  /** The subtitle of this page */
  subtitle: PropTypes.string,

  /** The button used to perform the primary action */
  primaryButton: PropTypes.node,

  /** The button used to perform the secondary action */
  secondaryButton: PropTypes.node,
};

export default withStyles(styles)(PageHeader);
