import React from 'react';
import Button from 'components/library/ButtonMui';
import Form from 'components/library/Form';
import Switch from 'components/library/Switch';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'scenes.users.Account.components.NotificationSettings';

const styles = theme => ({
  root: {
    display: 'flex',

    '& > *:first-child': {
      flex: '1 1 auto',
    },
  },
});

const NotificationOption = ({ notifications, option, canEdit, onUpdateSettings, t, classes }) => (
  <div className={classes.root}>
    <Form.ControlLabel
      control={
        <Switch
          color="primary"
          checked={notifications[option] && notifications[option].enabled ? true : false}
          value={true}
          onChange={(e, value) =>
            onUpdateSettings(
              value,
              { [`messages.settings.${option}.enabled`]: value },
              notifications[option]
            )
          }
        />
      }
      label={t(i18nPath, `options.${option}.text`)}
    />
    {canEdit && <Button>Edit</Button>}
  </div>
);

export default withStyles(styles)(withTranslations(NotificationOption));
