interface AuthenticationErrorContext {
  email?: string;
  providerId?: string;
  activeProvider?: string;
  credential?: Record<string, unknown>;
}

/**
 * Represents an error that occurs during authentication.
 */
export class AuthenticationError extends Error {
  /**
   * A code that describes the error that occurred.
   */
  public code: string;

  /**
   * The email associated with this error.
   */
  public email?: string;

  /**
   * The provider ID associated with this error.
   */
  public providerId?: string;

  /**
   * The active provider ID associated with this error.
   */
  public activeProvider?: string;

  /**
   * The credential associated with this error.
   */
  public credential?: Record<string, unknown>;

  /**
   * Constructs a new `AuthenticationError` object.
   *
   * @param code    - A unique code to indentify this type of error
   * @param message - A message describing the error that occurred
   * @param context - Additional parameters that provide context for this error
   */
  constructor(code: string, message?: string, context?: AuthenticationErrorContext) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthenticationError);
    }

    this.name = 'AuthenticationError';
    this.code = code;
    this.email = context?.email;
    this.providerId = context?.providerId;
    this.activeProvider = context?.activeProvider;
    this.credential = context?.credential;
  }
}
