import { parseTags } from './parseTags';

/**
 * Parses tags in the string specified with hashtags or separated by a comma or space, and returns
 * the result as an array of tags.
 *
 * Tags must start with a letter (in any script) and then can contain letters, numbers, the dash or
 * underscore (in any script).
 *
 * @param value - A string that may contain tags
 *
 * @returns An array of the tags in the string, or an empty array if no tags are found
 */
export function parseTagText(value: string): string[] {
  if (!value || value === '') {
    return [];
  }

  // Convert spaces and commas into hashes, so string becomes space separated list of hashtags
  const toParse = (!value.trim().startsWith('#') ? '#' : '') + value.replace(/[\s,]+(?!#)/g, ' #');

  return parseTags(toParse);
}
