import moment, { Moment } from 'moment';
import { DateTimeFormatter, LocalDate, nativeJs } from 'js-joda';
import { DateValue } from '../types/DateValue';
import { ITimestamp } from '../types/ITimestamp';

// `dateFormat` is a `moment` format, while `dateFormatter` uses a `LocalDate` format
const dateFormat = 'YYYY-MM-DD';
const dateFormatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');
const dateFormatters: { [key: string]: DateTimeFormatter } = {};


/**
 * Converts the `value` to a `LocalDate` instance, using the `format` to parse the time. If not
 * specified, the format parses the time as an ISO local time.
 *
 * The `format` is the format used by `LocalDate`. The supported pattern letters are:
 *
 * ------------------------------------------------------------------------------------
 * | Letter | Date Segment               | Type             | e.g.                    |
 * ------------------------------------------------------------------------------------
 * | G      | era                        | number/text      | 1; 01; AD; Anno Domini  |
 * | u      | year                       | year             | 2004; 04                |
 * | y      | year-of-era                | year             | 2004; 04                |
 * | D      | day-of-year                | number           | 189                     |
 * | M      | month-of-year              | number/text      | 7; 07; Jul; July; J     |
 * | d      | day-of-month               | number           | 10                      |
 * | Q      | quarter-of-year            | number/text      | 3; 03; Q3               |
 * | Y      | week-based-year            | year             | 1996; 96                |
 * | w      | week-of-year               | number           | 27                      |
 * | W      | week-of-month              | number           | 27                      |
 * | e      | localized day-of-week      | number           | 2; Tue; Tuesday; T      |
 * | E      | day-of-week                | number/text      | 2; Tue; Tuesday; T      |
 * | F      | week-of-month              | number           | 3                       |
 * ------------------------------------------------------------------------------------
 *
 * Other pattern letters may work, but may not be supported in the future.
 *
 * @param value  - The date to convert
 * @param format - The format to use for the conversion
 *
 * @returns An `LocalDate` instance of the time
 */
export function toLocalDate(value: DateValue, format?: string): LocalDate | undefined {
  if (!value) {
    return undefined;
  }

  if (value instanceof LocalDate) {
    return value;
  }

  if (format && !dateFormatters[format]) {
    dateFormatters[format] = DateTimeFormatter.ofPattern(format);
  }

  let formatter = format ? dateFormatters[format] : undefined;

  if (value instanceof moment) {
    value = (value as Moment).format(dateFormat);     // This must be a `moment` format
    formatter = dateFormatter;
  } else if (typeof value !== 'string' && !(value instanceof Date) && value.seconds) {
    const { seconds, nanoseconds } = value as ITimestamp;

    value = new Date(seconds * 1000 + (nanoseconds / 1000000));
  }

  return value instanceof Date ? LocalDate.from(nativeJs(value)) :
                                 LocalDate.parse(value.toString(), formatter);
}
