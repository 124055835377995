import { makeSyncActionCreator } from 'services/store/utils-actions';
import { path, types } from '../definitions';

const setUser = makeSyncActionCreator({
  type: types.SET_USER,
  creator: user => user,
  status: {
    path,
    type: 'read',
    parameters: () => undefined,
  }
});

export default setUser;
