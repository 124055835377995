import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/library/ListItem';
import Markdown from 'components/library/Markdown';
import Typography from 'components/library/Typography';
import isVariant from 'components/utils/isVariant';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import TodoItemVariantType from 'types/TodoItemVariant';
import todoText from 'utils/todos/todoText';

const animationOptions = '0.6s linear 0s 1 forwards';
const descLines = 2;

const styles = theme => ({
  desc: {
    fontSize: theme.typography.caption.fontSize,
    letterSpacing: theme.typography.caption.letterSpacing,
    lineHeight: theme.typography.caption.lineHeight,

    // Restricts height to `descLines` in WebKit browsers & adds ellipses
    display: '-webkit-box',
    '-webkit-line-clamp': descLines,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    // Restricts height in all other browsers with no ellipses
    maxHeight: `calc(${theme.typography.caption.lineHeight} * ${theme.typography.caption.fontSize} * ${descLines})`,
  },
  compactTitle: {
    display: 'inline',
  },
  compactSubtitle: {
    marginLeft: '0.25rem',
    display: 'inline',
  },
  done: {
    position: 'relative',
    color: theme.palette.text.secondary,
    animation: `strikeText ${animationOptions}`,

    '&::after': {
      content: '\' \'',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      height: 1,
      background: theme.palette.text.primary,
      animation: `strike  ${animationOptions}`,
    }
  },
  doneSubtitle: {
    color: theme.palette.text.secondary,
  },
  wontDo: {
    color: theme.palette.grey['400'],
  },
  '@keyframes strikeText': {
    '0%': {
      color: theme.palette.text.primary,
    },
    '90%': {
      color: theme.palette.text.primary,
    },
    '100%': {
      color: theme.palette.text.secondary,
    },
  },
  '@keyframes strike': {
    '0%': {
      width: 0,
    },
    '90%': {
      background: theme.palette.text.primary,
    },
    '100%': {
      width: '100%',
      background: theme.palette.text.secondary,
    },
  },
});

function formatDescription(desc) {
  if (!desc) {
    return desc;
  }

  desc = desc.replace('\r\n', '\n').replace('\r', '\n');

  const uncheckedItem = desc.indexOf('[ ] ');
  const start = uncheckedItem !== -1 ? uncheckedItem : 0;
  const nextBreak = desc.indexOf('\n', start);
  const end = nextBreak !== -1 ? nextBreak : desc.length;

  return desc.substring(start, end) + (end < desc.length ? ' ...' : '');
}

const TodoSummary = ({ value, intent, variant, classes, t }) => {
  const title = todoText(value, 'title', t);
  const subtitle = isVariant(variant, 'compact', 'schedule:unscheduled', 'schedule:scheduling') &&
                   todoText(value, 'duration', t, ' (', ')');
  const description = (intent || value.type !== 'activity') &&
                 formatDescription((intent && todoText(intent, 'desc')) || todoText(value, 'desc'));
  const intentStatus = intent && intent.status && intent.status.state;
  const todoStatus = value && value.status && value.status.state;
  const isWontDo = [ 'wontdo', 'wontdo-todo' ].includes(intentStatus) || (todoStatus === 'wontdo');
  const isDone = (intentStatus && intentStatus !== 'new') || todoStatus === 'done';
  const titleClass = isWontDo ? classes.wontDo : (isDone ? classes.done : undefined);
  const subtitleClass = isWontDo ? classes.wontDo : (isDone ? classes.doneSubtitle : undefined);
  const useCompactClass = isVariant(variant, 'compact', 'schedule');

  return (
    <ListItem.Text
      disableTypography
      primary={
        <Fragment>
          {title &&
            <Typography
              className={useCompactClass ? classes.compactTitle : undefined}
              variant={isVariant(variant, 'summary', 'schedule') ? 'body2' : 'subtitle2'}
              component="span"
            >
              <span className={titleClass}>{title}</span>
            </Typography>
          }

          {subtitle &&
            <Typography
              className={useCompactClass ? classes.compactSubtitle : undefined}
              variant="body2"
              color="textSecondary"
              component="span"
            >
              <span className={subtitleClass}>{subtitle}</span>
            </Typography>
          }
        </Fragment>
      }
      secondary={description &&
        <Markdown className={classes.desc} variant="plain" value={description} />
      }
    />
  );
}

TodoSummary.propTypes = {
  /** The todo object to render */
  value: PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
  }),

  /** The variant to use */
  variant: TodoItemVariantType,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /** A function used to provide translations of text. Provided by `withTranslations`. */
  t: PropTypes.func.isRequired,
};

export default withTranslations(withStyles(styles)(memo(TodoSummary)));
