import React, { Fragment } from 'react';
import classnames from 'classnames';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import Icon from 'components/library/Icon';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { resolveRoute } from 'utils/app/routes/resolveRoute';

const i18nPath = 'components.app.navigation.PrimaryMenu';

const styles = theme => ({
  item: {
    display: 'flex',
    padding: '10px 18px',
    color: '#e0e0e0',

    '&:last-child': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },

    ':not($itemActive)&:last-child': {
      background: '#333',
    },

    '&:hover, &:active, &:focus': {
      textDecoration: 'none',
    },

    ':not($itemActive)&:hover, :not($itemActive)&:active': {
      background: '#666',
      color: '#e0e0e0',
    },
  },
  itemActive: {
    background: '#eee',
    borderRight: 'solid 1px #ccc',
    color: '#666',
  },
  item1: {
    background: '#222',
    color: '#ccc',
    paddingLeft: theme.spacing.unit * 2.5,

    '& $text': {
      marginLeft: 0,
      fontSize: 17,
      lineHeight: '28px',
    },

    '& $icon': {
      lineHeight: '28px',
    },
  },
  item1Active: {
    background: '#222',
    borderRight: 'solid 1px #333',
    color: '#fff',

    '& $text': {
      fontWeight: 500,
    },

    '&:hover, &:active': {
      background: '#666',
      color: '#e0e0e0',
    },
  },
  text: {
    color: 'inherit',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 'normal',
    fontSize: 19,
    lineHeight: '32px',
  },
  icon: {
    width: 30,
    textAlign: 'center',
    fontSize: 24,
    lineHeight: '32px',
  },
});

const BasePrimaryMenuItem = ({ route, level = 0, items, location, match, history, t, classes }) => {
  const routeId = route.split('/').filter(element => !element.startsWith(':')).join('/');
  const resolvedRoute = resolveRoute(route, match.params);
  const icon = t(i18nPath, `options.${routeId}.icon`, { defaultValue: null });
  const path = location ? location.pathname : '';
  const isActive = path === `/${resolvedRoute}` || path.startsWith(`/${resolvedRoute}/`);

  return (
    <Fragment>
      <NavLink
        data-action={routeId}
        className={classnames(classes.item, classes[`item${level}`])}
        activeClassName={classnames(classes.itemActive, classes[`item${level}Active`])}
        to={'/' + resolvedRoute}
      >
        {icon && <Icon className={classes.icon} type={t(i18nPath, `options.${routeId}.icon`)} />}

        <Typography className={classes.text}>
          {t(`${i18nPath}.options.${routeId}.label`)}
        </Typography>
      </NavLink>

      {isActive && items && items.map(item =>
        <PrimaryMenuItem key={item.route} route={route + '/' + item.route} level={level + 1} />
      )}
    </Fragment>
  );
}

const PrimaryMenuItem = compose(
  withStyles(styles),
  withTranslations,
  withRouter
)(BasePrimaryMenuItem);

export default PrimaryMenuItem;
