import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { isAfterToday } from '@lifetools/shared-utils-time';
import Typography from 'components/library/Typography';
import withTranslations from 'helpers/withTranslations';
import TodoItemVariantType from 'types/TodoItemVariant';
import formatRelativeDateTime from 'utils/time/formatRelativeDateTime';
import nextScheduledIntent from 'utils/intents/nextScheduledIntent';
import todoText from 'utils/todos/todoText';

const i18nPath = "components.app.todos.TodoInfo.components.TaskInfo";

function getStatusDetail(todo, date, variant, t) {
  const hideUntilDate = get(todo, 'status.hide.date');

  if (variant === 'normal:task' && !['done', 'wontdo'].includes(get(todo, 'status.state'))) {
    const nextIntent = nextScheduledIntent(todo, date);

    if (nextIntent) {
      return t(i18nPath, 'prefix-status-intent') + ' ' +
        formatRelativeDateTime(nextIntent.date, null, t);
    }
  }

  // If `hideUntilDate` is an empty string, task is snoozed indefinitely
  if (hideUntilDate === '' || (hideUntilDate != null && isAfterToday(hideUntilDate, date))) {
    return `${t(i18nPath, 'prefix-status-hide')} ${todoText(todo, 'hide', t)}`;
  }

  return undefined;

  // TODO: Delete these properties from `todoText` if we decide to get rid of this entirely.
  //       [twl 27.Apr.20]
  //
  // switch (todo.status.state) {
  //   case 'new':
  //     return todoText(todo, 'createdAt', t, `${t(i18nPath, 'prefix-status-new')} `);

  //   default:
  //     return todoText(todo, 'statusAt', t, `${t(i18nPath, `prefix-status-${todo.status.state}`)} `);
  // }
}

const TaskInfo = ({ value, date, variant, t }) => {
  const info = getStatusDetail(value, date, variant, t);

  return !info
    ? null
    : <Typography variant="caption" color="textSecondary"><i>{info}</i></Typography>;
}

TaskInfo.propTypes = {
  /** The todo object to render */
  value: PropTypes.shape({
    createdAt: PropTypes.object,
    status: PropTypes.object,
    updatedAt: PropTypes.object,
  }),

  /** The variant to use */
  variant: TodoItemVariantType,

  /** A function used to provide translations of text. Provided by `withTranslations`. */
  t: PropTypes.func.isRequired,
};

export default withTranslations(TaskInfo);
