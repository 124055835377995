import get from 'lodash/get';
import { filterUndefinedValues, waitUntilTrue } from '@lifetools/shared-utils';
import analytics from 'services/analytics';
import { createUser } from 'services/server/auth';
import store from 'services/store';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteUserDocument from 'services/store/utils/actions/updateRemoteUserDocument';
import { collection as settingsCollection } from 'services/store/settings/definitions';
import { path, types } from '../definitions';
import { normalizeProfile } from '../utils/normalizeProfile';
import { getReferralInfo } from '../utils/getReferralInfo';

const registerUser = makeAsyncActionCreator({
  type: types.REGISTER_USER,
  creator: async user => {
    const { email, password, profile, ...initialData } = user;
    const { displayName, firstName, lastName } = normalizeProfile(profile);
    const referral = getReferralInfo();

    if (email && password) {
      // Remove trailing and leading spaces in email, which happens when you auto-complete on a
      // iPhone keyboard
      await createUser({
        email: email.trim(),
        displayName,
        password,
      });
    }

    // HACK: Immediately after `createUser` is called, the `onAuthenticatedUserSet` event is
    //       triggered, which fires the events from the event system, including the `updateLastAuth`
    //       event. This event also sets `forceCreate` to `true`. Because both events are running
    //       asynchronously, the two can overwrite each other. This hack forces this method to wait
    //       until the `lastAuth` has been updated for the new user before trying to update the
    //       settings, thus allowing `updateLastAuth` to create the settings and this function to
    //       update the settings. [twl 21.May.19]
    await waitUntilTrue(() => get(selectUserSettings(store.getState()), 'lastAuth'));

    await updateRemoteUserDocument(settingsCollection, {
      // NOTE: The `cohort` is being used to detect when registration is complete before showing the
      //       user the first page in `App`. Do not remove this unless an alternative method is
      //       created to delay rendering the first page until after registration. [twl 3.May.22]
      cohort: process.env.REACT_APP_USER_COHORT || 'unknown',
      // TODO: Remove `flags.realtimeData`. It is now ignored & always enabled. [twl 27.May.24]
      'flags.realtimeData': true,
      'flags.stackedMobileUi': true,
      'messages.settings.remind-schedule.enabled': true,
      profile: filterUndefinedValues({
        // Only save `displayName` when explicitly set, so we can check `parseDisplayName` later
        displayName: profile?.displayName,
        firstName,
        lastName,
      }),
      ...filterUndefinedValues(initialData),
      ...referral && { referral },
    }, true);

    analytics.track('register', {
      cohort: process.env.REACT_APP_USER_COHORT,
    });
  },
  status: {
    path,
    type: 'write',
    parameters: () => undefined,
  }
});

export default registerUser;
