import React from 'react';
import Box from 'components/library/Box';
import Spinner from 'components/library/Spinner';
import Splash from 'components/app/navigation/Splash';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  spinner: {
    alignSelf: 'center',
    marginTop: theme.spacing.unit * 4,
  },
});

const FailureView = ({ error }) => (
  <div>An internal error occurred</div>
);

const LoaderContent = ({ loader, status, children, classes }) => {
  // HACK: `status` can be `{}` when checking `todoStatus` in passed via `withSchedule` when there
  //       is no schedule. Testing for `status.state` beforehand ensures we use the first status,
  //       which is the schedule status. [twl 25.Aug.23]
  const activeStatus = Array.isArray(status)
    ? status.find(status => status.state && status.state !== 'success') ?? status[0]
    : status;

  switch (activeStatus.state) {
    case 'success':
      return children;

    case 'failure':
      return <FailureView error={activeStatus.error} />;

    default:
      switch (loader) {
        case 'none':
          return null;

        case 'splash':
          return <Splash />;

        case 'spinner':
        default:
          return <div className={classes.spinner}><Spinner /></div>;
      }
  }
};

const Loader = ({ withBox, boxClassName, i18nPath, ...props }) => (withBox ?
  <Box className={boxClassName} i18nPath={i18nPath}>
    <LoaderContent {...props} />
  </Box>
  :
  <LoaderContent {...props} />
);

export default withStyles(styles)(Loader);
