import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Dialog from 'components/library/Dialog';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import PageHeader from 'components/library/PageHeader';
import Typography from 'components/library/Typography';
import { MaxHeight } from 'contexts/MaxHeight';
import withDeviceInfo from 'helpers/withDeviceInfo';
import ModalDialogButton from './components/ModalDialogButton';

const styles = theme => ({
  content: {
    padding: theme.spacing.unit * 3,

    [theme.breakpoints.down('sm')]: {       // correct extra space due to `toolbarSpacer`
      paddingTop: 0,
    },
  },
  actions: {
    margin: 0,
    padding: theme.spacing.unit * 2,

    '& > *:not(:first-child)': {            // if there's more than 1 button, add space between them
      marginLeft: theme.spacing.unit,
    },
  },
  titleBar: {
    display: 'flex',
    background: theme.palette.grey['A400'],
    padding: theme.spacing.unit * 2,
  },
  title: {
    color: theme.palette.common.white,
  },
  closeButton: {
    color: theme.palette.grey['300'],
    marginLeft: 'auto',
    padding: 0,
    alignSelf: 'stretch',
    fontSize: '1rem',

    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  toolbarSpacer: {
    ...theme.mixins.toolbar,
  },
  backButton: {},                           // placeholders to be overridden via `classes` prop
});

const DialogContainer = ({ children }) => (
  <Fragment>
    {children}
  </Fragment>
);

const ModalDialog = ({
  open,
  title,
  maxWidth,
  fullWidth,
  primaryButton,
  secondaryButton,
  tertiaryButtons,
  container: Container = DialogContainer,
  containerProps,
  onClose,
  children,
  isMobileDevice,
  classes,
}) => {
  const maxHeight = useContext(MaxHeight);

  useEffect(() => {
    const pageTitle = document.title;

    document.title = `${pageTitle} | ${title}`;

    return () => document.title = pageTitle;
  }, [ title ]);

  return (
    <Dialog
      open={open}
      fullScreen={isMobileDevice}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      style={maxHeight ? { height: maxHeight } : undefined}
    >
      <Container {...containerProps}>
        {isMobileDevice ?
          <PageHeader
            title={title}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
          />
          :
          <Dialog.Title className={classes.titleBar} disableTypography>
            <Typography className={classes.title} variant="h3">{title}</Typography>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <Icon type="fas:times" />
            </IconButton>
          </Dialog.Title>
        }

        <Dialog.Content className={classes.content}>
          {isMobileDevice &&
            <div className={classes.toolbarSpacer} />
          }
          {children}
        </Dialog.Content>

        {!isMobileDevice &&
          <Dialog.Actions className={classes.actions}>
            {secondaryButton}
            {tertiaryButtons}
            {primaryButton}
          </Dialog.Actions>
        }
      </Container>
    </Dialog>
  );
}

// TODO: Finish PropTypes

ModalDialog.propTypes = {
  /** The title of the dialog */
  title: PropTypes.string,

  /** The content to render for this dialog */
  children: PropTypes.node,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /**
   * Whether the device is considered a mobile device by this application. Provided by
   * `withDeviceInfo`.
   */
  isMobileDevice: PropTypes.bool.isRequired,
}

ModalDialog.Button = ModalDialogButton;

export default compose(
  withDeviceInfo,
  withStyles(styles),
)(ModalDialog);

