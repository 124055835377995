import selectTodos from './selectTodos';
import { matchesQuery } from 'services/store/utils-selectors';

const selectTodosBySingleQuery = (state, query, includeArchived = false) => (
  selectTodos(state, includeArchived).filter(task => matchesQuery(task, query))
);

// TODO: Consider renaming this `selectTodosByQueries` and using that everywhere. The `query` then
//       can be an object or an array of objects. [twl 11.Oct.18]
const selectTodosByQuery = (state, query, includeArchived = false) => (
  Array.isArray(query)
    ? query.reduce((allTasks, query) => (
        allTasks.concat(selectTodosBySingleQuery(state, query, includeArchived))
      ), [])
    : selectTodosBySingleQuery(state, query, includeArchived)
);

export default selectTodosByQuery;
