import get from 'lodash/get';
import { ITodo } from '@lifetools/shared-app-data';
import { ITimestamp, isAfterToday, toNativeDate } from '@lifetools/shared-utils-time';

export function sortHideStatus(a: ITodo, b: ITodo): number {
  const aDate = get(a, 'status.hide.date');
  const bDate = get(b, 'status.hide.date');
  const aIndefinite = aDate === '';
  const bIndefinite = bDate === '';
  const aActive = aIndefinite || (aDate ? isAfterToday(aDate) : false);
  const bActive = bIndefinite || (bDate ? isAfterToday(bDate) : false);

  if (aActive !== bActive) {
    return aActive ? -1 : 1;                        // Sort active snooze todos to the top
  }

  if (aActive && bActive) {
    if (aIndefinite || bIndefinite) {
      return aIndefinite ? 1 : -1;                  // Sort indefinite snoozes to bottom of active
    } else {
      return aDate.localeCompare(bDate);            // Sort remainder of snoozes by snooze date
    }
  }

  const aCreatedAt = toNativeDate(get(a, 'createdAt') as ITimestamp)!.toISOString();
  const bCreatedAt = toNativeDate(get(b, 'createdAt') as ITimestamp)!.toISOString();

  // Sort never snoozed or inactive snooze todos by the creation date descending
  return aCreatedAt.localeCompare(bCreatedAt) * -1;
};
