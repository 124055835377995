import React, { Children, Component, cloneElement } from 'react';
import { withRouter } from 'react-router';
import ActionBarButton from './components/ActionBarButton';
import ActionBarComponent from './components/ActionBarComponent';
import ActionBarPane from './components/ActionBarPane';

/**
 * Renders a list of actions in an action bar. An "action" in this context is a component that
 * implements any of the following properties:
 *
 * key
 * : The key for the component. Used to ensure the correct pane is displayed. If not specified,
 *   defaults to the index of the component in the `actions` array. Use `key` when dynamically
 *   generating the list of actions to ensure the action works properly.
 *
 * pane
 * : A component to render when the action is activated. Used by `ActionBar.Button`.
 *
 * onClick
 * : An event handler to call instead of doing one of the default actions defined above. Note that
 *   when an `onClick` handler is provided, the `pane` and other action properties are not used.
 */
class ActionBar extends Component {
  static defaultProps = {
    component: ActionBarComponent,
    componentProps: {},
  };

  state = {
    paneIndex: -1,
  };

  doClose = () => {
    this.setState({
      paneIndex: -1,
    });
  };

  render() {
    const { actions, component: Component, componentProps, children, newMobileUi } = this.props;
    const { paneIndex } = this.state;
    const controls = actions || Children.toArray(children);

    return (
      <Component
        controls={controls.map((child, index) => {
          if (!child) {
            return null;
          }

          const key = child.props.key || index;

          return (
            cloneElement(child, child.props.onClick ? {
              key,
              newMobileUi,
            } : {
              key,
              selected: paneIndex === index,
              onClick: () => this.setState({ paneIndex: paneIndex !== index ? index : -1 }),
              newMobileUi,
            })
          );
        })}
        pane={paneIndex >= 0
          ? cloneElement(controls[paneIndex], { renderMode: 'pane', onPaneClose: this.doClose })
          : undefined
        }
        newMobileUi={newMobileUi}
        {...componentProps} />
    );
  }
}

ActionBar.Button = ActionBarButton;
ActionBar.Pane = ActionBarPane;

export default withRouter(ActionBar);
