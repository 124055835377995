import React from 'react';
import { compose } from 'recompose';
import Paper from 'components/library/Paper';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  activePlanType: {
    marginBottom: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 4,
    border: `solid 1px ${theme.palette.grey['400']}`,
  },
  uplevel: {
    marginBottom: theme.spacing.unit * 4,
  },
});

const ExistingPlanMessage = ({ workflow, activePlanType, i18nPath, classes, t }) => {
  const plan = t(i18nPath, `scheduleTypes.${activePlanType}.label`);
  const count = activePlanType === 'commit' && workflow === 'schedule' ? 2 : 1;

  return (
    <>
      <Paper className={classes.activePlanType} elevation={0}>
        <Typography variant="h2">
          {t(i18nPath, 'message', { plan })}
        </Typography>
      </Paper>

      <Typography className={classes.uplevel} variant="h4">
        {t(i18nPath, 'prompt', { count })}
      </Typography>
    </>
  )
};

export default compose(
  withStyles(styles),
  withTranslations,
)(ExistingPlanMessage);
