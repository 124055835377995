import { queryDocuments } from '@lifetools/shared-database';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';
import { collection, types, statusTypes } from '../definitions';

const queryTodos = makeAsyncActionCreator({
  type: types.SET_TODOS,
  when: firstRunOrAfterOldFailure,
  creator: async query => ({ todos: await queryDocuments(collection, query) }),
  status: {
    path: collection,
    type: statusTypes.queryTodos,
  }
});

export default queryTodos;
