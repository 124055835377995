import React from 'react';
import withStyles from 'helpers/withStyles';
import { toRouteDate } from 'utils/app/routes/toRouteDate';
import WhichPlanDate from './components/WhichPlanDate';
import WhichPlanType from './components/WhichPlanType';

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,

    '& > *': {
      marginTop: theme.spacing.unit * 2,
    }
  },
});

const NoTodayViewSurveyWithRadioButtons = ({ routeDateParam, date, schedule, classes }) => (
  <div className={classes.root}>
    <WhichPlanDate planDate={toRouteDate(routeDateParam)} />

    {routeDateParam &&
      <WhichPlanType date={date} />
    }
  </div>
);

export default withStyles(styles)(NoTodayViewSurveyWithRadioButtons);
