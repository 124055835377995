import React from 'react';
import classnames from 'classnames';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 3,
    textAlign: 'center',
  },
});

const SectionTitle = ({ className, i18nPath, i18nKey, classes, t }) => (
  <Typography className={classnames(classes.root, className)} variant="h4">
    {t(i18nPath, i18nKey)}
  </Typography>
);

export default withStyles(styles)(withTranslations(SectionTitle));
