import get from 'lodash/get';
import omit from 'lodash/omit';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemList from 'components/library/ItemList';

function resolveProps(props, context) {
  return typeof props === 'function' ? props(context) : props;
}

/**
 * A wrapper around `ItemList` that allows items to be selected.
 */
class SelectableItemList extends Component {
  static propTypes = {
    /**
     * The props to pass to the list item component, or a function that takes the current item and
     * returns the props to use
     */
    componentProps: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),

    /** A property path to the `id` property for each item. Defaults to `id` */
    idPath: PropTypes.string,

    /**
     * The name of the event handler prop on the `component` that is called when an item is
     * selected. Defaults to `onClick`
     */
    selectHandler: PropTypes.string,

    /**
     * The name of the event handler prop on the `component` that is called when an item is
     * unselected. Defaults to `onClick`
     */
    unselectHandler: PropTypes.string,
  };

  static defaultProps = {
    idPath: 'id',
    selectHandler: 'onClick',
    unselectHandler: 'onClick',
  };

  state = {
    selected: {},
  };

  doSetSelected = (item, isSelected) => {
    const { idPath } = this.props;
    const { selected } = this.state;
    const id = get(item, idPath);

    this.setState({
      selected: isSelected ? { ...selected, [id]: item } : omit(selected, id),
    });
  };

  render() {
    const { idPath, componentProps, selectHandler, unselectHandler, ...otherProps } = this.props;
    const { selected } = this.state;

    return (
      <ItemList
        {...otherProps}
        idPath={idPath}
        componentProps={item => {
          const isSelected = selected[get(item, idPath)] != null;
          const handler = isSelected ? unselectHandler : selectHandler;

          return {
            ...resolveProps(componentProps, item),
            selected: isSelected,
            [handler]: () => this.doSetSelected(item, !isSelected),
          };
        }}
      />
    );
  }
}

export default SelectableItemList;
