/**
 * Waits until the `test` function returns a truthy value, then returns.
 *
 * @param test  - The function to execute to wait for a truthy value
 * @param delay - The time to wait in between calls to the test function
 * @param limit - The maximum number of calls to make before throwing an error
 */
export async function waitUntilTrue(
  test: () => boolean,
  delay: number = 100,
  limit: number = 100,
): Promise<void> {
  return new Promise((resolve, reject) => {
    let count: number = 0;

    const waitUntilTrueTest = () => {
      try {
        if (!test()) {
          if (count > limit) {
            throw new Error('Exceeded number of tries to wait for truthy value');
          }

          count++;

          setTimeout(waitUntilTrueTest, delay);
        } else {
          resolve();
        }
      }
      catch (e) {
        reject(e);
      }
    }

    waitUntilTrueTest();
  });
}
