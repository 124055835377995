import { types as globalTypes } from 'services/store/actions';
import { makeAction } from 'services/store/utils-actions';
import { collection } from '../definitions';

const setSettingsStatusDirty = () => makeAction(globalTypes.SET_STATUS, {
  type: 'read',
  path: collection,
  parameters: 'user',
  state: 'failure',      // HACK TODO: There is no `dirty` state, so using `failure` to force reload
})

export default setSettingsStatusDirty;
