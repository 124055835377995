import { LocalTime, ChronoUnit } from 'js-joda';

/**
 * Returns the number of minutes between the start time and the end time, rounded to the nearest
 * multiple of minutes.
 *
 * @param startTime     - The start time
 * @param endTime       - The end time
 * @param endsOnNextDay - Whether the `endTime` is in the same day as the `startTime`
 * @param multiple      - The number of minutes to round the time to
 *
 * @return The number of minutes between the start time and the end time, rounded to the nearest
 *         multiple of minutes
 */
export const minutesBetween = (
  startTime: LocalTime,
  endTime: LocalTime,
  endsOnNextDay: boolean,
  multiple: number,
) => (
  Math.round(startTime.until(endTime, ChronoUnit.MINUTES) /  multiple) * multiple +
  (endsOnNextDay ? 60 * 24 : 0)
);
