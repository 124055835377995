import { difference, isPlainObject, union } from 'lodash';
import { PlainObject } from '@lifetools/shared-utils';
import { toTimestamp } from '@lifetools/shared-utils-time';

/**
 * Resolves the field values in `after` using the `before` version of the object for field values
 * that rely on the old value to determine the new one.
 *
 * NOTE: This won't necessarily resolve the values in exactly the same way as the server would, nor
 *       does it resolve server timestamps in the same way as `resolveServerTimestamps` currently.
 *       In particular, the server will handle concurrent modifications, and this only knows about
 *       the current before. For server timestamps, this method may resolve timestamps as having
 *       slightly different values, while `resolveServerTimestamps` ensures that all
 *       `serverTimestamp` field values in an object resolve to the same timestamp. [twl 28.Oct.20]
 *
 * @param before - The object before any changes were applied
 * @param after  - The object after changes were applied
 *
 * @returns A copy of `after` where all fieldValues placeholders have been replaced with the actual
 *          values they represent, with the caveats noted above
 */
export const resolveFieldValues = (before: PlainObject, after: PlainObject): PlainObject => (
  Object.keys(after).reduce((result: PlainObject, key: string) => {
    const oldValue = before?.[key];
    const newValue = after[key];
    const type = newValue?.constructor.name;

    if (type === 'ArrayUnionTransform') {
      result[key] = union(oldValue, newValue.elements);
    } else if (type === 'ArrayRemoveTransform') {
      result[key] = difference(oldValue, newValue.elements);
    } else if (type === 'NumericIncrementTransform') {
      result[key] = (typeof oldValue === 'number' ? oldValue : 0) + newValue.operand;
    } else if (type === 'NumericDecrementTransform') {
      result[key] = (typeof oldValue === 'number' ? oldValue : 0) - newValue.operand;
    } else if (type === 'ServerTimestampTransform') {
      result[key] = toTimestamp(new Date());
    } else if (type !== 'DeleteTransform') {
      result[key] = isPlainObject(newValue) ? resolveFieldValues(oldValue, newValue) : newValue;
    }

    return result;
  }, {})
);
