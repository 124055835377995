/**
 * Returns true if the `value` is a special field value to indicate the key should be deleted from
 * the document.
 *
 * @param value - The value to test
 *
 * @returns `true` if the value is a field value representing a delete operation; `false` otherwise
 */
export const isDeleteField = (value: any): boolean => (
  value != null && value.methodName === 'FieldValue.delete'
);
