import { services } from '@lifetools/shared-services';

/**
 * Retrieves the value belonging to the `key` from the session storage, if set; otherwise returns
 * `null`.
 *
 * @param key          - The key to retrieve
 * @param decodeAsJson - Whether to decode the value retrieved as JSON to maintain the original type
 *
 * @returns The value of the key or `null` if the key could not be found
 */
export function getSessionValue(key: string, decodeAsJson: boolean = false): any {
  if (window.sessionStorage) {
    const value = window.sessionStorage.getItem(key);

    return decodeAsJson && value ? JSON.parse(value) : value || null;
  }
  else {
    // TODO: Think about logging this to Sentry so we know how often it occurs. [twl 12.Jul.22]
    services.logger.warn(`Session storage not supported: could not get value for ${key}`);

    return null;
  }
}
