import updateTodo from 'services/store/todos/actions/updateTodo';
import { minutesBetween } from 'utils/time';

// NOTE: `updateTodo` is an async action creator, so this executes asynchronously. [twl 30.Aug.18]
const addIntent = (todo, schedule) => {
  if (!schedule || !schedule.id) {
    throw new Error(`An todo requires a schedule to be added as an intent`);
  }

  // TODO: Think about using `services.database.fieldValues.arrayUnion` here so this is less
  //       suspectible to issues with simultaneous changes. Probably minimal chance, since the user
  //       would have to be editing the same intent simultanously in two places. [twl 21. Oct.20]
  const scheduleIds = todo.scheduleIds || [];
  const values = {
    scheduleIds: [
      ...scheduleIds,
      schedule.id,
    ],
  };

  if (todo.type === 'appointment') {
    const duration = minutesBetween(todo.start, todo.end);

    if (duration) {
      values[`intents.${schedule.id}.duration`] = duration;
    }
  } else if (todo.type === 'activity' && todo.defaultDuration != null) {
    values[`intents.${schedule.id}.duration`] = todo.defaultDuration;
  }

  values[`intents.${schedule.id}.status.state`] = 'new';
  values[`intents.${schedule.id}.date`] = schedule.date;

  // TODO: Scan all previous intents to determine the starting duration, copy over any start &
  //       duration from the todo if it exists (as an appointment). [twl 31.Aug.18]
  return updateTodo(todo, values);
}

export default addIntent;
