import { mergePathKeys } from '@lifetools/shared-utils';
import { types as globalTypes } from 'services/store/actions';
import { applyAction, createStatusReducer } from 'services/store/utils-reducers';
import { collection, types } from './definitions';

const initialData = {
  app: {},
  user: {},
  global: {},
};

const actionReducers = {
  [globalTypes.LOGOUT]: () => initialData,
  [globalTypes.SET_DATA]: (state, { collection: target, data }) => (
    ![collection, 'app'].includes(target) || !data || !data.length
      ? state
      : target === 'app'
        ? { ...state, app: data[0] }
        : { ...state, user: data[0] }
  ),

  [types.SET_USER_SETTINGS]: (state, payload) => ({
    ...state,
    user: mergePathKeys(state.user, payload.settings)
  }),
  [types.SET_GLOBAL_SETTINGS]: (state, payload) => ({
    ...state,
    global: mergePathKeys(state.global, payload.settings)
  }),
  [types.SET_APP_SETTINGS]: (state, payload) => ({
    ...state,
    // App settings are loaded all at once and they can never be set to a non-object
    app: { ...payload },
  }),
}

const dataReducer = (state = initialData, action) => applyAction(actionReducers, state, action);
const statusReducer = createStatusReducer('settings');

const settingsReducer = (state = {}, action) => ({
  status: statusReducer(state.status, action),
  data: dataReducer(state.data, action)
});

export default settingsReducer;