import omit from 'lodash/omit';
import React from 'react';
import { Link as RoutedLink } from 'react-router-dom';
import classnames from 'classnames';
import LinkMui from '@material-ui/core/Link';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  underlineAlways: {
    textDecoration: 'none',
    borderBottom: 'dashed 1px',
  },
  routedLink: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing.unit / 2,
    borderBottom: `dashed 1px ${theme.palette.primary.main}`,

    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.dark,
      borderBottomColor: theme.palette.primary.dark,
    },
    '&:focus': {
      color: theme.palette.primary.dark,
      borderBottom: `solid 1px ${theme.palette.primary.dark}`,
    },
  }
});

const Link = ({ to, className, useRoutedStyle, classes, ...props }) => (
  to
  ? <RoutedLink to={to} className={classnames(classes.routedLink, className)} {...props} />
  : <LinkMui
      className={useRoutedStyle ? classnames(classes.routedLink, className) : className}
      classes={omit(classes, 'routedLink')}
      {...props}
    />
);

export default withStyles(styles)(Link);
