import React from 'react';
import { LocalDate, LocalTime, ChronoUnit } from 'js-joda';
import Typography from 'components/library/Typography';
import withBox from 'helpers/withBox';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { useNow } from 'hooks/useNow';
import { formatTime } from 'utils/time';
import SchedulerCell from './components/SchedulerCell';
import SchedulerIntentCell from './components/SchedulerIntentCell';
import { addIntents } from './utils/addIntents';
import { getTimes } from './utils/getTimes';

const i18nPath = 'components.app.schedules.Scheduler';

const timeFormat = 'h:mm a';
const minutesInDay = 24 * 60;

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '[time] min-content [intent] auto',
    gridTemplateRows: 'auto',
  },
  time: {
    position: 'relative',                                    // required for fills
    gridArea: `auto / time / auto / auto`,
    padding: theme.spacing.unit,
    background: theme.palette.grey['50'],
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: `0 1px 1px 0`,
    whiteSpace: 'nowrap',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeFill: {
    position: 'absolute',
    top: 0,
    background: 'rgba(0, 0, 0, 0.06)',
    width: '100%',
    height: '100%',
  },
  intent: {
    gridArea: `auto / intent / auto / auto`,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX: 'hidden',

    '&:hover': {
      background: theme.palette.grey['100'],
    }
  },
});

function calculateFill({ time, intent }, now, interval, compact) {
  // Future times should have no fill
  if (!time.isBefore(now)) {
    return 0;
  }

  const duration = compact ? intent.duration : interval;

  return time.plusMinutes(duration).isAfter(now)
    ?  time.until(now, ChronoUnit.MINUTES) / duration
    : 1;
}

export const SchedulerRow = ({ rowIndex, record, columns }) => (
  columns.map((column, index) => (
    <SchedulerCell key={index} record={record} column={column} rowIndex={rowIndex} />
  ))
);

const Scheduler = ({
  startTime = LocalTime.parse('09:00'),
  endTime = LocalTime.parse('18:00'),
  interval = 15,
  intents = [],
  actionMode,                           // Current options: edit, statusReadOnly, statusChange
  compact = false,
  variant = 'schedule:scheduling',
  fillActive = false,
  header,
  emptyComponent,
  onIntentSchedule = () => {},
  onIntentUpdate,
  onIntentDelete,
  onStatusChange = () => {},
  onScheduleExtend,
  classes,
  t,
}) => {
  const { now, isNowActive } = useNow(LocalDate.now(), startTime, null, !fillActive ? 0 : undefined);
  const scheduledIntents = intents.filter(intent => intent.start);
  const unscheduledIntents = intents.filter(intent => !intent.start);

  /**
   * HACK: Until we get everything converted to the new wrapped time class, convert to a `LocalTime`
   *       by unwrapping the internal value. [twl 23.Aug.18]
   *
   * TODO: Remove this hack once we've standardized on a time library / wrapper. [twl 23.Aug.18]
   */
  if (startTime._time) {
    startTime = startTime._time;
  }

  if (endTime._time) {
    endTime = endTime._time;
  }

  // The (x + minutesInDay) % minutesInDay handles when the endTime is after midnight
  const length = startTime.equals(endTime) ? minutesInDay :
                   (startTime.until(endTime, ChronoUnit.MINUTES) + minutesInDay) % minutesInDay;

  let rows = addIntents(getTimes(startTime, interval, length), scheduledIntents, interval);
  let intentCount = rows.reduce((count, row) => count + (row.intent ? 1 : 0), 0);

  if (compact) {
    rows = rows.filter(row => row.intent && !row.continuation).map(row => ({ ...row, span: 1 }));
  }

  const columns = [{
    name: 'time',
    className: classes.time,
    ...isNowActive && {
      fillClass: classes.timeFill,
      fill: record => calculateFill(record, now, interval, compact),
    },
    render: ({ record }) => (
      <Typography variant="body2" color="textSecondary">
        {formatTime(record.time, timeFormat)}
      </Typography>
    ),
  }, {
    name: 'intent',
    className: classes.intent,
    state: record => (
      record.intent && !record.continuation
        ? 'intent'
        : (!record.intent && actionMode === 'edit' ? 'editor' : 'empty')
    ),
    render: ({ record }) => (
      <SchedulerIntentCell
        record={record}
        variant={variant}
        actionMode={actionMode}
        unscheduledIntents={unscheduledIntents}
        onIntentUpdate={onIntentUpdate}
        onIntentDelete={onIntentDelete}
        onStatusChange={onStatusChange}
        onIntentSchedule={onIntentSchedule}
        onScheduleExtend={onScheduleExtend}
      />
    ),
  }];

  return (
    <div>
      {header}

      {intentCount === 0 && emptyComponent}

      {(intentCount > 0 || !emptyComponent) &&
        <div className={classes.root}>
          {rows.map((row, index) =>
            <SchedulerRow key={index} rowIndex={index} record={row} columns={columns} />
          )}
        </div>
      }
    </div>
  );
};

export default withBox(withStyles(styles)(withTranslations(Scheduler)), { i18nPath });
