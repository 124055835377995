import React from 'react';
import MultiStateIcon from 'components/library/MultiStateIcon';
import withTranslations from 'helpers/withTranslations';
import { getStates } from './utils/getStates';
import { getState } from './utils/getState';
import { nextState } from './utils/nextState';

const config = {
  todos: {
    statuses: [ 'unknown', 'new', 'intent', 'doing', 'defer', 'delegate', 'done', 'wontdo' ],
    nextState: {
      activity: {},                    // activities don't have statuses
      appointment: {
        'undefined': 'new',
        'new': 'done',
        'done': 'new',
      },
      task: {
        'unknown': 'new',
        'new': 'done',
        'intent': 'done',
        'doing': 'done',
        'done': 'unknown',             // Recalculate based on intents
      },
    },
  },
  intents: {
    statuses: [
      'unknown', 'new', 'doing', 'defer', 'delegate', 'done', 'done-todo', 'wontdo', 'wontdo-todo'
    ],
    nextState: {
      activity: {
        'unknown': 'new',
        'new': 'done',
        'done': 'new',
        'wontdo': 'new',
      },
      appointment: {
        'unknown': 'new',
        'new': 'done',
        'done': 'new',
        'wontdo': 'new',
      },
      task: {
        'unknown': 'new',
        'new': 'done',
        'done': 'done-todo',
        'done-todo': 'new',
        'wontdo': 'new',
        'wontdo-todo': 'new',
      },
    },
  }
}

const doAction = (e, config, todo, intent, onStatusChange, onStatusMenu) => {
  if (onStatusChange || onStatusMenu) {
    e.stopPropagation();

    if (onStatusMenu && (e.altKey || !onStatusChange)) {
      onStatusMenu(e);
    } else if (onStatusChange) {
      onStatusChange(nextState(config, todo, intent), todo);
    }
  }
}

// TODO: Once we move to the status menu being the default everywhere for the status button, remove
//       the `onStatusChange` prop. [twl 24.Mar.20]
const StatusActionButton = ({ className, value, intent, onStatusChange, onStatusMenu, t }) => {
  const localConfig = config[intent ? 'intents' : 'todos'];

  return (
    <MultiStateIcon
      className={className}
      disabled={onStatusChange == null && onStatusMenu == null}
      states={getStates(config, t, value, intent)}
      value={getState(localConfig, value, intent)}
      onClick={e => doAction(e, localConfig, value, intent, onStatusChange, onStatusMenu )}
      useIconButton
      data-action={onStatusChange ? 'statusChange' : (onStatusMenu ? 'statusMenu' : 'undefined')}
    />
  );
}

export default withTranslations(StatusActionButton);
