import { IPlan, IPlanTrial } from '@lifetools/shared-app-data';
import { toLocalDate } from '@lifetools/shared-utils-time';
import planDefaults from './planDefaults';

export const getDefaultTrial = (plan: IPlan): IPlanTrial => {
  const started = plan.createdAt;
  const length = planDefaults.trialLength;

  return {
    started,
    length,
    ends: toLocalDate(started)!.plusDays(length).toString(),
  };
}
