import { IStatus, ITodoStatusState } from '@lifetools/shared-app-types';

const manualStates = ['defer', 'delegate', 'done', 'wontdo'];

/**
 * Returns whether the status is a "manual override" status. This occurs when the user modifies a
 * todo directly to set the status independently of any intent.
 *
 * @param status - The status of a todo
 *
 * @returns `true` if the status is set as a manual override; false otherwise
 */
const isManualOverrideStatus = ({ state, from }: IStatus<ITodoStatusState>): boolean => (
  typeof from === 'undefined' && manualStates.includes(state)
);

export default isManualOverrideStatus;
