import store from 'services/store';
import registerUser from 'services/store/auth/actions/registerUser';
import { deleteNewUser } from './deleteNewUser';
import { handleFederatedIdentityLogin } from './handleFederatedIdentityLogin';
import { parseProfile } from './parseProfile';

export async function handleFederatedIdentityRegister(user, additionalUserInfo) {
  const { isNewUser, providerId, profile, username } = additionalUserInfo ?? {};

  if (isNewUser) {
    try {
      // NOTE: Don't await here, since it then blocks the authentication events from firing, which
      //       are required to fire during `registerUser`. [twl 25.Jul.22]
      store.dispatch(registerUser({
        email: user.email,
        profile: parseProfile(user, profile),
        auth: {
          username,
          provider: providerId,
        }
      }));
    }
    catch (e) {
      await deleteNewUser();

      throw e;
    }
  } else {
    // Allow a user to login when attempting to register
    await handleFederatedIdentityLogin(user, additionalUserInfo);
  }
}
