import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './SelectInputHelper.css';

const i18nPath = 'components.library.InputObject.components.SelectInputHelper';

const DefaultOption = ({ value }) => <span>{value}</span>;

class SelectInputHelper extends Component {
  static propTypes = {
    /** The name of the property this helper configures */
    property: PropTypes.string.isRequired,

    /** The current value of the property */
    value: PropTypes.any,

    /** The current value of the property */
    config: PropTypes.shape({
      /** The component used to render each option */
      option: PropTypes.func,

      /** An array of the valid options for this property */
      options: PropTypes.arrayOf(PropTypes.any),
    }),

    /** Called whenever the value of the property changes */
    onPropertyChange: PropTypes.func,

    /** Called whenever the user takes an action detected by this helper to cancel the helper */
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    config: {},
  };

  state = {
    text: undefined
  };

  /**
   * Called when this component is first mounted.
   */
  componentDidMount() {
    const { property, value, onPropertyChange } = this.props;

    // TODO: Think about whether we want this for this helper. Also, we may need to reset the
    //       internal state. [twl 23.Aug.18]
    //
    // Ensure when this helper is first displayed, the value is reset to undefined
    onPropertyChange && onPropertyChange(property, undefined, value);
  }

  /**
   * Processes key events from the `InputObject`.
   *
   * @param {string} info.trigger The trigger text causing this helper to activate
   * @param {string} info.text    The text after the trigger
   */
  onKeyInput = ({ trigger, text }) => {
    const { property, value, onPropertyChange, onCancel } = this.props;
    const newValue = this.matchedOption(text);

    // If trigger has been removed (because user pressed delete, for instance), cancel the helper
    if (trigger === '') {
      onCancel && onCancel();
      return;
    }

    this.setState({ text: text });

    onPropertyChange && onPropertyChange(property, newValue, value);
  };

  /**
   * Returns the option that `text` matches, or `undefined` if no single option matches.
   *
   * @param {string} text The starting text to search for
   *
   * @return {any} The option that uniquely matches the `text`
   */
  matchedOption(text) {
    if (text === '') {
      return undefined;
    }

    const options = this.matchedOptions(text);

    return options.length > 0 ? options[0] : undefined;
  }

  /**
   * Returns an array of options that start with the `text`.
   *
   * @param {string} text The starting text to search for
   *
   * @return {any[]} An array of options matching the text
   */
  matchedOptions(text) {
    const { config: { options } } = this.props;
    const normalizedText = text ? text.toLowerCase() : '';

    return options.filter(option => option.indexOf(normalizedText) === 0);
  }

  /**
   * Returns whether the `value` is valid for the property managed by this helper.
   *
   * @param {any} value The value to test
   *
   * @return {boolean} true if the value is valid; false otherwise
   */
  validate(value) {
    // Technically we should check whether the value is in `options`, but since the value can only
    // be one that we've set, as long as it's not undefined, it should be good
    return typeof value !== 'undefined';
  }

  /**
   * Render a DOM representation of this component
   */
  render() {
    const { t, property, value, config } = this.props;
    const { option: Option = DefaultOption } = config;
    const { text } = this.state;
    const options = this.matchedOptions(text);

    return (
      <div className="SelectInputHelper">
        <div className="SelectInputHelper_input">
          {options.map(option =>
            <Option key={option} property={property} value={option} selected={value === option} />
          )}
        </div>

        <div className="SelectInputHelper_help">
          {t(`${i18nPath}.help`)}
        </div>
      </div>
    );
  }
}

export default withTranslation('translations', { withRef: true })(SelectInputHelper);
