import pick from 'lodash/pick';
import React, { Children, cloneElement, createElement } from 'react';
import ReactMarkdown, { uriTransformer } from 'react-markdown';
import gfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import classnames from 'classnames';
import Embed from 'components/library/Embed';
import Link from 'components/library/Link';
import Checkbox from 'components/library/Checkbox';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import { isEmbedUri } from 'utils/app/embeds/isEmbedUri';
import { iconDirective } from './utils/iconDirective';
import { italicsDirective } from './utils/italicsDirective';

const styles = theme => ({
  root: {
    '& strong > span': {          // Override the font-weight provided by `Typography`
      fontWeight: 'bolder',
    },
    '& em': {                     // HACK: Convert emphasis to underline. [twl 6.Mar.19]
      fontStyle: 'normal',
      textDecoration: 'underline',
    },
  },
  plain: {
    '& p, & a, & span, & pre, & blockquote': {
      margin: 0,
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary,
    },
    '& h1, & h2, & h3, & h4': {
      margin: 0,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
    },
    '& ol, & ul': {
      margin: 0,
      padding: `0 ${theme.spacing.unit * 2}px`,
    },

    // For checkbox
    '& $checkbox': {
      padding: `0 ${theme.spacing.unit / 2}px 0 ${theme.spacing.unit / 4}px`,
    },
    '& $checkboxLabel': {
      padding: `0 ${theme.spacing.unit / 2}px 0 ${theme.spacing.unit / 4}px`,
    },
    '& $unorderedCheckbox': {
      marginLeft: -theme.spacing.unit * 2.5,
    },
    '& label': {
      alignItems: 'center',
    },
    '& label span:not(:first-child)': {
      padding: 0,
      fontSize: '0.79rem !important',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    '& svg': {
      fontSize: 16,
    },
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'flex-start',

    '& > span:not(:first-child)': {
      padding: `${theme.spacing.unit}px 0`,
    },
  },
  checkbox: {
    padding: theme.spacing.unit,
  },
  orderedCheckbox: {
    position: 'relative',         // HACK: To align number with checkbox [twl 6.Mar.19]
    top: '0.35em',                // HACK: Magic value to make things look right [twl 6.Mar.19]
    left: theme.spacing.unit,
    marginleft: 0,
  },
  unorderedCheckbox: {
    marginLeft: -theme.spacing.unit * 4,
  },
});

const renderers = {
  // NOTE: Since all text is wrapped below using `Typography`, we need to unwrap the text before
  //       rendering it within the link. [twl 5.Mar.19]
  link: (classes, variant, textStyle) => ({ href, target, children, ...props }) => {
    const labelElements = Children.toArray(children);
    const label = labelElements.length === 1 ? labelElements[0] : labelElements;

    // `label` will be the same as a parsed `href` if using `<url>` syntax; otherwise it will be the
    // text of the link from `[label](url)`
    return isEmbedUri(href)
      ? <Embed variant={variant} source={href} label={label !== href ? label :  undefined} />
      : <Link
          variant={textStyle}
          href={href}
          target={target}
          color="textSecondary"
          rel="noopener"
          underline="always"
          onClick={e => e.stopPropagation()}
        >
          {Children.map(children, removeNestedTypography)}
        </Link>;
  },
  list: ({ ordered, start, children }) => (
    <Typography variant="body1" component="span" inline>
      {createElement(
        ordered ? 'ol' : 'ul',
        {
          start: start !== null && start !== 1 ? start : undefined
        },
        children
      )}
    </Typography>
  ),
  heading: ({ level, children }) => (
    <Typography variant={`h${level}`} gutterBottom>
      {Children.map(children, removeNestedTypography)}
    </Typography>
  ),
};

// Remove `Typography` elements nested within the node. Note that this works in concert with the
// `skipTypographyFor` list further down, which prevents `Typography` elements from being rendered
// if text is a direct child of an HTML element that is already using `Typography`
const removeNestedTypography = node => (
  typeof node === 'string'
    ? node
    : node.type === Typography
      ? node.props.children
      : cloneElement(node, { children: Children.map(node.props.children, removeNestedTypography) })
);

const createListItem = (classes, onChange) => ({ ordered, children, ...props }) => {
  const itemProps = pick(props, 'data-sourcepos');
  const isCheckbox = props.checked !== null;
  const contents = !isCheckbox ? children : (
    <Checkbox
      labelClasses={{
        root: classnames(
          classes.checkboxLabel,
          ordered ? classes.orderedCheckbox : classes.unorderedCheckbox
        ),
      }}
      classes={{
        root: classes.checkbox,
      }}
      checked={props.checked}
      color="primary"
      disabled={!onChange}
      onChange={e => onChange && onChange(itemProps['data-sourcepos'], e.target.checked)}
      label={Children.toArray(children).filter(child => child.type !== 'input')}
      {...itemProps}
    />
  );

  return isCheckbox && !ordered ? contents : <li {...itemProps}>{contents}</li>;
}

function updateCheckbox(value, position, checked, onChange) {
  const [ row ] = position.split(':');
  const rowIndex = row - 1;
  const lines = value ? value.split('\n') : [];

  if (rowIndex < lines.length) {
    lines[rowIndex] = checked ? lines[rowIndex].replace(/\[ \]/, '[x]')
                              : lines[rowIndex].replace(/\[x\]/, '[ ]');

    onChange(lines.join('\n'));
  } else {
    console.warn(`Attempt to edit a position that could not be found in the Markdown text.` +
                 ` Position: ${position}, # Lines: ${lines.length}`);
  }
}

function preprocess(value) {
  // Add support for simplified formatting: `*bold*` `_underline_`
  //
  // NOTE: Did not add support for italics here because the regular expression I was using to
  //       capture `/italic/` was picking up URLs as well. I think we need a full parser for this.
  //       Instead, styling the emphasis as an underline above in `styles`. [twl 6.Mar.19]
  value = value.replace(/\*(.*?)\*/g, '**$1**');

  // Allow a simplied checkbox syntax if the user starts a line with `[ ]`
  value = value.replace(/^\[( |x)\] /gm, '- [$1] ');

  // ... and make angle brackets that start with a bang automatically embed link; make !embed: also
  //     work in case the user confuses the two syntaxes
  value = value.replace(/<!(?:embed:)?(http|https):\/\/(.*)>/gi, '<embed:$1://$2>');
  value = value.replace(/<!(?:embed\(+(.*)\):)+(.*)>/gi, '<embed($1):$2>');

  return value;
}

const transformLinkUri = uri => isEmbedUri(uri) ? uri : uriTransformer(uri);

const skipTypographyFor = new Set(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a']);

// HACK: `react-markdown` isn't converting CR into a break unless there are two spaces before the
//       CR. To ensure that people get a hard break, we're replacing all CR with two spaces and a CR
//       which fixes the issue, [twl 5.Mar.19]
const Markdown = ({ className, variant, textStyle = 'body1', value, classes, onChange }) => (
  <ReactMarkdown
    className={classnames(classes.root, variant === 'plain' && classes.plain, className)}
    components={{
      ...renderers,
      a: renderers.link(classes, variant, textStyle),
      ul: renderers.list,
      ol: renderers.list,
      li: createListItem(
        classes,
        onChange && ((position, checked) => updateCheckbox(value, position, checked, onChange))
      ),
      h1: renderers.heading,
      h2: renderers.heading,
      h3: renderers.heading,
      h4: renderers.heading,
      h5: renderers.heading,
      h6: renderers.heading,
    }}
    linkTarget="_blank"
    unwrapDisallowed={false}
    disallowedElements={['html']}
    sourcePos={true}
    transformLinkUri={transformLinkUri}
    transformText={(text, index, node, parentNode) =>
      !text.match(/^[\s\n]+$/) && !skipTypographyFor.has(parentNode.tagName)
        ? <Typography key={index} variant={textStyle} component="span" inline>{text}</Typography>
        : text
    }
    remarkPlugins={[gfm, remarkDirective, iconDirective, italicsDirective]}
  >
    {value && preprocess(value)}
  </ReactMarkdown>
);

export default withStyles(styles)(Markdown);
