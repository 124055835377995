import get from 'lodash/get';
import pull from 'lodash/pull';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionBar from 'components/library/ActionBar';
import Box from 'components/library/Box';
import DurationSelector from 'components/library/DurationSelector';
import Intent from 'components/app/intents/Intent';
import TodoEditor from 'components/app/todos/TodoEditor';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { sort } from 'utils/sort';
import EditScheduleDialog from 'widgets/schedules/EditScheduleDialog';
import IntentAllocationTableRow from './components/IntentAllocationTableRow';

const i18nPath = 'scenes.todos.Estimate.components.IntentAllocationTable';

const styles = theme => ({
  root: {
    '& > *:nth-child(even)': {
      background: '#eee',
    },
  },
  timeEstimation: {
    flex: '0 0 auto',
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
  },
  title: {
    display: 'flex',
    flex: '1 1 auto',

    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
    }
  },
  intent: {
    flex: '1 1 auto',
    paddingLeft: theme.spacing.unit,
  },
  editor: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
  }
});

class IntentAllocationTable extends Component {
  static propTypes = {
    /** The path to the duration to configure within each intent */
    durationPath: PropTypes.string,

    /** The array of intents to allow te user to allocate */
    intents: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      todo: PropTypes.object,
      duration: PropTypes.number,
    })),

    /** Called to create a new intent; passed `(todo, duration)` */
    onCreate: PropTypes.func,

    /** Called to update the allocation on an existing intent; passed `(intent, duration)` */
    onAllocation: PropTypes.func,

    /** Called when an intent is edited */
    onUpdate: PropTypes.func,

    /** Called when an intent is deleted */
    onDelete: PropTypes.func,

    /** Called when the status of an intent is changed */
    onUpdateStatus: PropTypes.func,
  };

  static defaultProps = {
    durationPath: 'duration',
    onAllocation: () => {},
    onCreate: () => {},
  };

  state = {
    newItems: []
  };

  columns = ({ t, durationPath, classes }) => [{
    className: classes.timeEstimation,
    accessor: record => get(record, durationPath),
    render: ({ value, record }) => (
      <DurationSelector value={value} onChange={value => this.handleAllocation(record, value)} />
    ),
  }, {
    className: classes.title,
    accessor: record => record.todo.title,
    render: ({ record }) => (
      record.id ?
        <Intent
          className={classes.intent}
          value={record}
          variant="summary"
          component="div"
          onUpdate={this.handleUpdate}
          onDelete={this.handleDelete}
          onStatusChange={this.handleUpdateStatus}
        />
        :
        <TodoEditor
          className={classes.editor}
          value={record.todo}
          onSave={() => this.handleCreate(record)}
          onCancel={this.handleRemoveAddedItem}
          onPropertyChange={this.handlePropertyChange}
          autoFocus
        />
    )
  } ];

  data = ({ intents }) => {
    const { newItems } = this.state;

    intents = sort(intents, 'todo.title');

    return [
      ...newItems,
      ...intents
    ];
  };

  handleAddItem = () => {
    const { newItems } = this.state;

    // HACK: This should be refactored to only accept a single new item, but we're going to change
    //       the entire UI metaphor soon, so no point wasting the time. [twl 24.Apr.20]
    if (newItems.length === 0) {
      newItems.push({
        todo: {
          title: null,
        },
        duration: null,           // Ensures sorting on time spend doing column is at the top
      });
    }

    this.setState({ newItems });
  };

  handleRemoveAddedItem = () => {
    const { newItems } = this.state;

    newItems.pop();

    this.setState({ newItems });
  };

  handlePropertyChange = (property, value, todo) => {
    todo[property] = value;
  };

  handleAllocation = (intent, duration) => {
    const { onAllocation } = this.props;
    const { newItems } = this.state;

    if (newItems.includes(intent)) {
      intent.duration = duration;

      this.setState({ newItems });
    } else if (onAllocation) {
      onAllocation(intent, duration);
    }
  };

  handleCreate = (intent) => {
    const { onCreate } = this.props;
    const { newItems } = this.state;

    if (newItems.includes(intent)) {
      pull(newItems, intent);
      this.setState({ newItems });
    }

    if (onCreate) {
      // Reconvert `intent.duration` back to `undefined` if null
      onCreate(intent.todo, intent.duration != null ? intent.duration : undefined);
    }
  };

  handleUpdate = (intent, changes) => this.props.onUpdate && this.props.onUpdate(intent, changes);
  handleDelete = intent => this.props.onDelete && this.props.onDelete(intent);
  handleUpdateStatus = (status, intent) => (
    this.props.onUpdateStatus && this.props.onUpdateStatus(status, intent)
  );

  render() {
    const { boxId, header, workflow, schedule, classes } = this.props;
    const records = this.data(this.props);
    const columns = this.columns(this.props);

    return (
      <Box
        id={boxId}
        i18nPath={i18nPath}
        actions={[
          workflow === 'allocate' &&
            <ActionBar.Button i18nPath={i18nPath} action="set-times" pane={
              <EditScheduleDialog schedule={schedule} />
            } />,
          <ActionBar.Button i18nPath={i18nPath} action="add" onClick={this.handleAddItem}/>
        ]}>

        {header}

        <div className={classes.root}>
          {records.map(record =>
            <IntentAllocationTableRow key={record.id || 'add'} record={record} columns={columns} />
          )}
        </div>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslations(IntentAllocationTable));
