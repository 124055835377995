import React from 'react';
import DayOfWeekSelector from 'components/library/DayOfWeekSelector';
import MaterialUiField from '../MaterialUiField';

const DayOfWeekSelectorField = ({ value, ...props }) => (
  <MaterialUiField
    {...props}
    muiComponent={DayOfWeekSelector}
    useSetFieldValue
    useSetFieldTouched
    useI18nPath
  />
);

export default DayOfWeekSelectorField;
