import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const Dialog = props => <MuiDialog {...props} />;

Dialog.muiName = 'Dialog';

Dialog.Actions = MuiDialogActions;
Dialog.Content = MuiDialogContent;
Dialog.Title = MuiDialogTitle;

export default Dialog;
