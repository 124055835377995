import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import Markdown from 'components/library/Markdown';
import Paper from 'components/library/Paper';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.custom.helpTip.background,
  },
  tip: {
    margin: theme.spacing.unit * 2,
    lineHeight: 2,

    '& > p:first-child': {
      marginTop: 0,
    },

    '& > p:last-child': {
      marginBottom: 0,
    },

    '& i.icon-size-medium': {
      position: 'relative',
      bottom: -1,
    },
  },
  button: {
    flex: '0 0 auto',
    width: '2rem',
    height: '2rem',
    fontSize: '1rem',
    color: theme.palette.grey['700'],
    padding: theme.spacing.unit,
    marginRight: theme.spacing.unit,

    '&:hover': {
      color: theme.palette.grey['700'],
    },
  },
  boxHeader: {
    borderBottom: `solid 1px ${theme.palette.grey['400']}`,
  },
  card: {
    borderTop: `solid 4px ${theme.palette.grey['A400']}`,

    [theme.breakpoints.up('md')]: {
      borderTop: `solid 1px ${theme.palette.grey['400']}`,
    },
  },
});

const HelpTip = ({ variant = 'boxHeader', className, i18nPath, onClose, classes, t }) => {
  const fullClassName = classnames(classes.root, classes[variant], className);
  const content =
    <>
      <Markdown className={classes.tip} value={t(i18nPath)} />
      <IconButton className={classes.button} onClick={onClose}>
        <Icon type="fas:times" />
      </IconButton>
    </>;

  return variant === 'card'
    ? <Paper elevation={1} className={fullClassName}>{content}</Paper>
    : <div className={fullClassName}>{content}</div>
}

HelpTip.propTypes = {
  /** The style variant to use for this component */
  variant: PropTypes.oneOf([ 'boxHeader', 'card' ]),

  /** The custom class to add to this component */
  className: PropTypes.string,

  /** The path from which to load localization strings for this component */
  i18nPath: PropTypes.string.isRequired,

  /** Called when the close button is clicked */
  onClose: PropTypes.func,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /** A function used to provide translations of text. Provided by `withTranslations` */
  t: PropTypes.func.isRequired,
};

export default withTranslations(withStyles(styles)(HelpTip));
