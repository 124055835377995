import { getUserDocument } from 'services/server/data';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';
import { collection, types } from '../definitions';

// TODO: Set up subscriptions, and then only run this query the first time; thereafter any updates
//       via cloud functions (e.g. to `integrations`) will be updated automatically. [twl 28.Sep.18]
//
const loadSettings = makeAsyncActionCreator({
  type: types.SET_USER_SETTINGS,
  when: firstRunOrAfterOldFailure,
  creator: async () => ({ settings: await getUserDocument(collection) }),
  status: {
    path: collection,
    type: 'read',
    parameters: () => 'user',
  }
});

export default loadSettings;
