import React from 'react';
import { callFunction } from 'services/server/data';
import AsyncButton from 'components/library/AsyncButton';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'scenes.users.Account.components.SubscriptionSettings.components.OpenBillingPortalButton';

async function openPortal(t) {
  // NOTE: Calling the function directly here, rather than going through the store, since this is a
  //       temporary URL we don't want to store. Also, i's better security not to add it into the
  //       store which can be observed. [twl 5.Apr.21]
  const url = await callFunction('plans_getBillingPortalUrl', {
    returnUrl: window.location.href,
  });

  if (url) {
    window.open(url);
  } else {
    window.alert(t(i18nPath, 'no-url-returned'));
  }
};

// NOTE: The `tooltip` is causing this button to not be centered in `SubscriptionSettings` when it
//       is disabled (e.g. when the `openPortal` function is running). This is because `AsyncButton`
//       delegates to the MUI `Button`, which, when a button is disabled with a tooltip, inserts a
//       `span` as a parent of the button, so it can still display the tooltip. This is incredibly
//       minor, and may be fixed once we upgrade Material UI, so ignoring it for now. [twl 5.Apr.21]
const OpenBillingPortalButton = ({ className, color, t }) => (
  <AsyncButton
    className={className}
    color={color}
    variant="contained"
    tooltip={t(i18nPath, 'tooltip')}
    onClick={() => openPortal(t)}
  >
    {t(i18nPath, 'label')}
  </AsyncButton>
);

export default withTranslations(OpenBillingPortalButton);
