import React from 'react';
import { Switch } from 'react-router-dom';
import { TrackedRoute } from '@lifetools/react-router-tracker';
import Page from 'components/app/navigation/Page';
import Redirect from 'components/router/Redirect';
import { withSchedule } from 'helpers/withSchedule';
import Itemize from 'scenes/todos/Itemize';
import Estimate from 'scenes/todos/Estimate';
import { formatLocalDate } from 'utils/time';

const i18nPath = 'routes.schedules.Schedule';
const baseRoute = '/:planPath/:planDate/allocations';

const AllocationsRoute = ({ t, location, date, schedule, intents }) => (
  <Page
    title={formatLocalDate(date, t(i18nPath, 'date-format'))}
    docTitle={t(i18nPath, 'title', { step: location.pathname.split('/').pop() })}
  >
    <Switch>
      <TrackedRoute path={`${baseRoute}/commit`} render={props =>
        <Itemize {...props} workflow="allocate" date={date} schedule={schedule} intents={intents} />
      } />
      <TrackedRoute path={`${baseRoute}/allocate`} render={props =>
        <Estimate
          {...props}
          workflow="allocate"
          date={date}
          schedule={schedule}
          intents={intents}
        />
      } />
      <Redirect to={`${baseRoute}/commit`} />
    </Switch>
  </Page>
);

export default withSchedule(AllocationsRoute, 'match.params.planDate');
