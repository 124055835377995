import React from 'react';
import classnames from 'classnames';
import Button from 'components/library/ButtonMui';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import Paper from 'components/library/Paper';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import PlanTypeHelpCard from './components/PlanTypeHelpCard';

const i18nPath = 'scenes.app.Today.components.PlanTypeHelp';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 3,
    },
  },
  closeButtonTop: {
    color: theme.palette.grey['500'],
    padding: 0,
    float: 'right',
    fontSize: '1rem',

    '&:hover': {
      color: theme.palette.common.black,
    },
  },
  closeButtonBottom: {
    display: 'block',
    margin: `${theme.spacing.unit * 2}px auto`,
  },
});

const PlanTypeHelp = ({ className, closeButtons = 'top', onClose, classes, t }) => (
  <Paper className={classnames(classes.root, className)}>
    <Typography variant="h2" paragraph>
      {t(i18nPath, 'title')}

      {['top', 'both'].includes(closeButtons) &&
        <IconButton className={classes.closeButtonTop} onClick={onClose}>
          <Icon type="fas:times" />
        </IconButton>
      }
    </Typography>

    <Typography variant="subtitle1">
      {t(i18nPath, 'intro')}
    </Typography>

    <PlanTypeHelpCard type="commit" />
    <PlanTypeHelpCard type="allocate" />
    <PlanTypeHelpCard type="schedule" />

    {['bottom', 'both'].includes(closeButtons) &&
      <Button
        className={classes.closeButtonBottom}
        variant="contained"
        color="primary"
        onClick={onClose}
      >
        {t(i18nPath, 'button-close')}
      </Button>
    }
  </Paper>
);

export default withTranslations(withStyles(styles)(PlanTypeHelp));
