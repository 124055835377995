import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import events from 'services/server/events';
import rootEvents from './rootEvents';
import rootReducer from './rootReducer';

// Register events with server library
for (const event of Object.keys(events)) {
  if (rootEvents[event]) {
    events[event] = [ ...events[event], ...rootEvents[event] ];
  }
}

// TODO: Consider only loading redux devtools in development, but see this article first:
//       https://medium.com/@zalmoxis/using-redux-devtools-in-production-4c5b56c5600f
//       [twl 15.Jun.18]
//
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
//
// OR
//
// import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
