import get from 'lodash/get';
import { FirebaseApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { IFunctionsService } from '@lifetools/shared-services';
import { services } from '@lifetools/shared-services';

const SSL_PORT = 443;

export function initializeFunctions(firebaseApp: FirebaseApp): IFunctionsService {
  const functions = getFunctions(firebaseApp);
  const functionEmulationUrl = get(services, 'config.firebase.functionsEmulationURL');

  if (functionEmulationUrl) {
    const { hostname, port } = new URL(functionEmulationUrl);

    services.logger.info(`Using locally emulated functions from ${functionEmulationUrl}`);

    connectFunctionsEmulator(functions, hostname, port ? parseInt(port, 10) : SSL_PORT);
  }

  return {
    httpsCallable: httpsCallable.bind(null, functions),
  }
}
