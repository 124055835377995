import { services } from '@lifetools/shared-services';
import store from 'services/store';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';

/**
 * Update the last authentication stats for the current user.
 */
async function updateLastAuth(authInfo) {
  await store.dispatch(loadSettings());

  const state = store.getState();
  const settings = selectUserSettings(state);
  const lastAuth = settings.lastAuth || {};

  lastAuth.at = services.database.fieldValues.serverTimestamp();
  lastAuth.count = lastAuth.count ? lastAuth.count + 1 : 1;
  lastAuth.version = process.env.REACT_APP_VERSION;

  await store.dispatch(updateSettings({ lastAuth }));
}

export default updateLastAuth;
