import * as assert from 'assert';
import { has, omit } from 'lodash';
import { PlainObject } from '../types/PlainObject';

/**
 * Converts an array into an object map that maps keys to objects, where the key has removed from
 * each object.
 *
 * @param array       - An array of object
 * @param keyProperty - The name of the property to use as the key in the returned object
 *
 * @returns An object map that maps the key property of each object to a copy of the object without
 *          the key.
 */
export const arrayToObjectMap = (
  array: object[],
  keyProperty: string = 'id',
): PlainObject => {
  const object: PlainObject = {};

  // NOTE: Decided to keep signaure of method for `array` to be `object[]`, not `PlainObject[]`,
  //       to avoid implying that it can only be used with plain objects (classes work too).
  //       [twl 29.Jul.19]
  for (const item of array as PlainObject[]) {
    const key = item[keyProperty];

    assert.ok(!has(object, key), `Duplicate key detected converting an array to a map: ${key}`);

    object[key] = omit(item, keyProperty);
  }

  return object;
}
