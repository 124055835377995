import React from 'react';
import classnames from 'classnames';
import { compose } from 'recompose';
import { isSnoozed } from '@lifetools/shared-app-data';
import TodoEditDialog from 'components/app/todos/TodoEditDialog';
import ViewLayout from 'components/layouts/ViewLayout';
import ActionBar from 'components/library/ActionBar';
import { FeatureFlags } from 'contexts/FeatureFlags';
import withDeviceInfo from 'helpers/withDeviceInfo';
import withImportedAppointments from 'helpers/withImportedAppointments';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import clearTodoStatuses from 'services/store/todos/actions/clearTodoStatuses';
import createTodo from 'services/store/todos/actions/createTodo';
import activeTasks from 'services/store/todos/queries/activeTasks';
import inactiveAppointmentsOnDate from 'services/store/todos/queries/inactiveAppointmentsOnDate';
import inactiveTasksVisibleOnDate from 'services/store/todos/queries/inactiveTasksVisibleOnDate';
import weeklyActivitiesOnDate from 'services/store/todos/queries/weeklyActivitiesOnDate';
import { isPlanEditable } from 'utils/app/plans/isPlanEditable';
import TodoListWidget from 'widgets/todos/TodoList';
import { buildScene } from 'scenes/utils';
import NoTodayBase from './components/NoTodayBase';

const i18nPath = 'scenes.app.TodayBase.components.NoTodayBaseView';

const styles = theme => ({
  noSchedule: {
    flex: '1 0 auto',
    alignSelf: 'stretch',
    // TODO: Figure out how to calculate this accurately, probably using `theme.mixins.toolbar`.
    //       For now, using an approximate value, since this just affects the alignment of the
    //       Create Schedule button. [twl 1.May.20]
    maxHeight: 'calc(100vh - 64px)',       // Should be height of page header + carousel
  },
  noScheduleNewMobileUi: {
    maxHeight: 'initial',
  },
});

// NOTE: `inactiveAppointmentsOnDate` should really be `activeAppointmentsOnDate`, but if we have
//       no schedule yet, these should be equivalent and by reusing `inactiveAppointmentsOnDate` we
//       avoid the need to create another index. [twl 30.Mar.20]

const NoTodayBaseView = buildScene(({
  i18nPath: i18nPathNoSchedule,
  date,
  schedule,
  scheduleType,
  isAuthorized,
  isPlanEditable = true,
  newMobileUi,
  doCreate,
  doClearTodoStatuses,
  isAccountReadOnly,
  t,
  classes,
}) => (
  <ViewLayout layout="split">
    <NoTodayBase
      boxId="NoSchedule"
      boxClassName={classnames(classes.noSchedule, newMobileUi && classes.noScheduleNewMobileUi)}
      viewId="schedule"
      i18nPath={i18nPathNoSchedule + '.components.NoTodayBase'}
      date={date}
      schedule={schedule}
      scheduleType={scheduleType}
      isPlanEditable={isPlanEditable}
      viewColumn={1}
      newMobileUi={newMobileUi}
    />

    <TodoListWidget
      viewId="unfinishedTasks"
      i18nPath={i18nPath + '.components.UnfinishedTasks'}
      date={date}
      sortBy="title"
      query={activeTasks}
      filter={todo => !isSnoozed(todo, date)}
      groupBy="tags"
    />

    <TodoListWidget
      boxActions={[
        isAuthorized && !isAccountReadOnly && isPlanEditable &&
          <ActionBar.Button
            i18nPath={i18nPath}
            action="resync"
            onClick={doClearTodoStatuses} />,
        !isAccountReadOnly && isPlanEditable &&
          <ActionBar.Button
            i18nPath={i18nPath}
            action="add"
            pane={
              <TodoEditDialog defaultType="appointment" options={{ date }} onSave={doCreate} />
            } />,
      ]}
      viewId="appointments"
      i18nPath={i18nPath + '.components.Appointments'}
      sortBy="start"
      groupBy="tags"
      query={inactiveAppointmentsOnDate(date)}
    />

    <TodoListWidget
      viewId="activities"
      i18nPath={i18nPath + '.components.Activities'}
      sortBy="title"
      groupBy="tags"
      query={weeklyActivitiesOnDate(date)}
      boxActions={[
        !isAccountReadOnly && isPlanEditable &&
          <ActionBar.Button
            i18nPath={i18nPath}
            action="add"
            pane={
              <TodoEditDialog defaultType="activity" onSave={doCreate} />
            } />,
      ]}
    />

    <TodoListWidget
      viewId="tasks"
      i18nPath={i18nPath + '.components.Tasks'}
      date={date}
      sortBy="title"
      groupBy="tags"
      query={inactiveTasksVisibleOnDate(date)}
      boxActions={[
        !isAccountReadOnly && isPlanEditable &&
          <ActionBar.Button
            i18nPath={i18nPath}
            action="add"
            pane={
              <TodoEditDialog defaultType="task" onSave={doCreate} />
            } />,
      ]}
    />

  </ViewLayout>
), {
  context: 'isAccountReadOnly',
  state: (state, { date }) => ({
    isPlanEditable: isPlanEditable(date, state)
  }),
  dispatch: {
    doCreate: createTodo,
    doClearTodoStatuses: clearTodoStatuses,
  },
});

/**
 * Temporary wrapper that alters the props used based on a feature flag.
 */
const NoTodayBaseViewWrapper = withDeviceInfo(({ isMobileDevice, ...props }) => (
  <FeatureFlags.Consumer>
    {flags =>
      <NoTodayBaseView {...props} newMobileUi={isMobileDevice && flags.stackedMobileUi} />
    }
  </FeatureFlags.Consumer>
));

export default compose(
  withStyles(styles),
  withTranslations,
  withImportedAppointments,
)(NoTodayBaseViewWrapper);
