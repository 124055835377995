import React from 'react';
import { LocalDate } from 'js-joda';
import { useFeature } from "@growthbook/growthbook-react";
import Page from 'components/app/navigation/Page';
import Loader from 'components/library/Loader';
import Redirect from 'components/router/Redirect';
import { withRoutedDate } from 'helpers/withRoutedDate';
import { withSchedule } from 'helpers/withSchedule';
import withTranslations from 'helpers/withTranslations';
import { routes } from 'routes/routes';
import { getActivePlanType } from 'utils/schedules/getActivePlanType';
import { formatLocalDate } from 'utils/time';
import NoTodayView from './components/NoTodayView';
import NoTodayViewSurveyWithButtons from './components/NoTodayViewSurveyWithButtons';
import NoTodayViewSurveyWithRadioButtons from './components/NoTodayViewSurveyWithRadioButtons';

const i18nPath = 'scenes.app.Today';

const BaseContent = ({ schedule, intents, scheduleStatus, ...props }) => {
  const noTodayView = useFeature('no-today-view').value || 'default';

  return (
    <Loader status={scheduleStatus}>
      {getActivePlanType(schedule, intents)
        ? <Redirect to={routes.today[schedule.type || 'schedule']} />
        : noTodayView === 'dateTypeButtons'
          ?  <NoTodayViewSurveyWithButtons {...props} />
          : noTodayView === 'dateTypeSurvey'
            ? <NoTodayViewSurveyWithRadioButtons {...props} />
            : <NoTodayView {...props} schedule={schedule} />
      }
    </Loader>
  );
}

const BaseContentWithSchedule = withSchedule(BaseContent, 'date');

const Today = ({ t, routeDate = LocalDate.now(), userSettings, ...props }) => (
  <Page
    title={formatLocalDate(routeDate, t(i18nPath, 'date-format'))}
    docTitle={t(i18nPath, 'doc-title')}
  >
    <BaseContentWithSchedule {...props} date={routeDate} />
  </Page>
)

export default withRoutedDate(withTranslations(Today));
