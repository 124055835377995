import React from 'react';
import { toLocalTime } from '@lifetools/shared-utils-time';
import FormDialog from 'components/library/FormDialog';
import Form from 'components/library/Form';
import withTranslations from 'helpers/withTranslations';
import compileSchema from 'utils/data/compileSchema';
import isValidTimeValidation from 'utils/data/isValidTimeValidation';
import startDayOffsetOptions from 'utils/schedules/startDayOffsetOptions';
import toLocalTimeNoThrow from 'utils/time/toLocalTimeNoThrow';

const i18nPath = 'scenes.todos.Schedule.components.EditScheduleDialog';
const fieldsI18nPath = i18nPath + '.fields';

const defaultStartDayOffset = '0';

const enableStartDayOffset = ({ startTime, endTime }) => {
  startTime = toLocalTimeNoThrow(startTime);
  endTime = toLocalTimeNoThrow(endTime);

  return startTime && endTime ? !startTime.isBefore(endTime) : undefined;
}

const schema = {
  startTime: {
    type: 'string',                                // From the form's perspective, this is a string
    validation: [ isValidTimeValidation ],
  },
  endTime: {
    type: 'string',                                // From the form's perspective, this is a string
    validation: [ isValidTimeValidation ],
  },
};

const toFormTypes = schedule => ({
  ...schedule,
  startTime: schedule.startTime.toString(),
  endTime: schedule.endTime.toString(),
  startDayOffset: schedule.startDayOffset
    ? schedule.startDayOffset.toString()
    : (enableStartDayOffset(schedule) ? defaultStartDayOffset : undefined),
});

// HACK: This isn't accurate. Basically it's setting all the fields as modified each time the
//       form is submitted. [twl 13.Oct.18]
//
// TODO: Come back and fix this. [twl 13.Oct.18]
const getChanges = values => ({
  startTime: toLocalTime(values.startTime),
  endTime: toLocalTime(values.endTime),
  setAsDefault: values.setAsDefault,
  startDayOffset: enableStartDayOffset(values)
    ? Number(values.startDayOffset || defaultStartDayOffset)
    : undefined,
});

const EditScheduleDialog = ({ schedule = {}, onSave, onCancel, onClose, t }) => (
  <FormDialog
    formId="EditScheduleDialog"
    i18nPath={i18nPath}
    values={toFormTypes(schedule)}
    schema={compileSchema(schema, t, fieldsI18nPath)}
    onSave={values => onSave(values, getChanges(values))}
    onCancel={onCancel}
    onClose={onClose}>

    <Form.TimeField i18nPath={fieldsI18nPath} name="startTime" fullWidth autoFocus />
    <Form.TimeField i18nPath={fieldsI18nPath} name="endTime" fullWidth />
    <Form.CheckboxField i18nPath={fieldsI18nPath} name="setAsDefault" />

    <Form.RadioSelectorField
      showIf={enableStartDayOffset}
      i18nPath={fieldsI18nPath}
      name="startDayOffset"
      options={startDayOffsetOptions}
      defaultValue={defaultStartDayOffset}
      fullWidth
    />
  </FormDialog>
);

export default withTranslations(EditScheduleDialog);
