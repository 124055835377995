import React from 'react';
import DailyPlanInfoCard from './components/DailyPlanInfoCard';
import DailyPlanInfoCompactCard from './components/DailyPlanInfoCompactCard';
import DailyPlanInfoListItem from './components/DailyPlanInfoListItem';

const i18nBasePath = 'components.app.schedules.DailyPlanInfo';

const layouts = {
  listItem: DailyPlanInfoListItem,
  compactCard: DailyPlanInfoCompactCard,
  card: DailyPlanInfoCard,
};

const DailyPlanInfo = ({ layout, workflow, ...props }) => {
  const i18nPath = `${i18nBasePath}.types.${workflow}`;
  const Layout = layouts[layout || 'card'];

  return <Layout {...props} workflow={workflow} i18nPath={i18nPath} />;
}

export default DailyPlanInfo;
