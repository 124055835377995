import React from 'react';
import PropTypes from 'prop-types';
import ViewLayout from 'components/layouts/ViewLayout';
import { getActivePlanType } from 'utils/schedules/getActivePlanType';
import NoPlanView from './components/NoPlanView';
import PlanViews from './components/PlanViews';

const i18nPath = 'scenes.todos.Schedules.components.ScheduleView';

const ScheduleView = ({
  date,
  schedule,
  scheduleStatus,
  intents,
  onIntentUpdate,
  onIntentDelete,
}) => {
  const activePlanType = getActivePlanType(schedule, intents);

  return (
    <ViewLayout layout="stacked">
      {scheduleStatus.state === 'success' && activePlanType
        ? <PlanViews
            i18nPath={i18nPath}
            date={date}
            schedule={schedule}
            intents={intents}
            activePlanType={activePlanType}
            onIntentUpdate={onIntentUpdate}
            onIntentDelete={onIntentDelete}
          />
        : <NoPlanView
            id="NoScheduleBox"
            viewId="noSchedule"
            i18nPath={i18nPath}
            date={date}
            schedule={schedule}
            activePlanType={activePlanType}
          />
      }
    </ViewLayout>
  );
}

ScheduleView.propTypes = {
  /** The date of the schedule */
  date: PropTypes.object.isRequired,

  /** The schedule */
  schedule: PropTypes.object,

  /** The loading status of the schedule */
  scheduleStatus: PropTypes.object,

  /** The intents for this schedule */
  intents: PropTypes.arrayOf(PropTypes.object),

  /** Called when the user updates an intent on the schedule */
  onIntentUpdate: PropTypes.func,

  /** Called when the user deletes an intent */
  onIntentDelete: PropTypes.func,
};

export default ScheduleView;
