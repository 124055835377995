import React, { useCallback } from 'react';
import HelpTip from 'components/app/common/HelpTip';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { buildScene } from 'scenes/utils';
import { parseTip } from './utils/parseTip';
import { isTipShowing } from './utils/isTipShowing';

const HelpTipWidget = buildScene(({
  helpPath,
  tip,
  doUpdateSettings,
  ...props
}) => {
  const helpKey = `help.${helpPath}.${tip?.name}`;
  const doClose = useCallback(
    async () => await doUpdateSettings({ [helpKey]: true }),
    [helpKey, doUpdateSettings]
  );

  return tip ? <HelpTip {...props} i18nPath={helpKey} onClose={doClose} /> : null;
}, {
  memos: {
    parsedTips: tips => tips.map(parseTip),
  },
  state: (state, { helpPath, tips = [], memos }) => {
    const userSettings = selectUserSettings(state);
    const parsedTips = memos.parsedTips(tips);
    const tip = parsedTips.find(tip => isTipShowing(tip, helpPath, userSettings) && tip);

    return {
      tip,
    };
  },
  dispatch: {
    doUpdateSettings: updateSettings,
  },
});

export default HelpTipWidget;
