import { get, has } from 'lodash';

/**
 * Returns whether the value at the `path` within `object1` and `object2` are equal.

 * @param object1 - An object
 * @param object2 - An object
 * @param path    - The property path to a value in `object1` and `object2`.
 *
 * @returns `true` if the values at the path within `object1` and `object2` are equal, or `false` if
 *          the values are different or the path does not exist in either or both objects
 */
export const isEqualAtPath = (object1: object, object2: object, path: string): boolean => (
  has(object1, path) && has(object2, path) && get(object1, path) === get(object2, path)
);
