import pull from 'lodash/pull';
import updateTodo from 'services/store/todos/actions/updateTodo';

// NOTE: `updateTodo` is an async action creator, so this executes asynchronously. [twl 30.Aug.18]
const removeIntent = (intent) => {
  // TODO: Think about using `services.database.fieldValues.arrayRemove` here so this is less
  //       suspectible to issues with simultaneous changes. Probably minimal chance, since the user
  //       would have to be editing the same intent simultanously in two places. [twl 21. Oct.20]
  const scheduleIds = pull([ ...intent.todo.scheduleIds ], intent.scheduleId);
  const values = {
    scheduleIds,
    [`intents.${intent.scheduleId}`]: undefined,
  };

  if (scheduleIds.length === 0) {
    values.scheduleIds = undefined;
  }

  if (intent.todo.intents && Object.keys(intent.todo.intents).length === 1 &&
      intent.todo.intents[intent.scheduleId]) {
    values.intents = undefined;
  }

  return updateTodo(intent.todo, values);
}

export default removeIntent;
