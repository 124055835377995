import { isPlainObject } from 'lodash';
import { PlainObject } from '../types/PlainObject';

/**
 * Removes undefined values recursively while maintaining any keys which are path keys.
 *
 * @param object - An object
 *
 * @returns The object with any undefined values removed
 */
export const filterUndefinedValues = (object: PlainObject): PlainObject => (
  Object.keys(object).reduce((result: PlainObject, key: string) => {
    const value = object[key];

    if (typeof value !== 'undefined') {
      result[key] = isPlainObject(value) ? filterUndefinedValues(value) : value;
    }

    return result;
  }, {})
);
