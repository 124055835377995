import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import Button from 'components/library/ButtonMui';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { resolveRoute } from 'utils/app/routes/resolveRoute';

const i18nPath = 'scenes.app.Today.components.NoTodayViewSurveyWithButtons.components.WhichPlanDate.components.PlanDateButton';

const styles = theme => ({
  selected: {
    background: theme.palette.grey['A100'],
  },
});

const isFutureDate = date => date && !['today', 'tomorrow'].includes(date);

const PlanDateButton = ({ currentPlanDate, planDate, history, match, classes, t }) => {
  const selected = currentPlanDate === planDate ||
    (isFutureDate(currentPlanDate) && isFutureDate(planDate));

  return (
    <Button
      className={selected ? classes.selected : undefined}
      variant={selected ? 'contained' : 'outlined'}
      onClick={() => history.push(resolveRoute(match.path, { planDate }))}
    >
      {t(i18nPath, 'button-' + (isFutureDate(planDate) ? 'future' : planDate))}
    </Button>
  );
}

export default compose(
  withRouter,
  withTranslations,
  withStyles(styles),
)(PlanDateButton);
