import get from 'lodash/get';

export const sortByCreatedAt = {
  ASC: (a, b) => (a.createdAt > b.createdAt),
  DESC: (a, b) => (a.createdAt < b.createdAt),
};

function advancedComparator(options, aValue, bValue) {
  const {
    accessor, desc, order, topNulls, topUndefineds, topMissing, sortEquals, caseSensitive
  } = options;

  let a = accessor ? accessor(aValue) : aValue;
  let b = accessor ? accessor(bValue) : bValue;

  if (order) {
    a = order.indexOf(a);
    b = order.indexOf(b);

    // Only need to change the value if sorting missing values to the top; otherwise they are -1
    // which sorts to the top by default
    if (!topMissing) {
      a = a === -1 ? Infinity : a;
      b = b === -1 ? Infinity : b;
    }
  }

  if (a === null) {
    return b === null ? (sortEquals ? sortEquals(aValue, bValue) : 0) : (topNulls ? -1 : 1);
  } else if (b === null) {
    return (topNulls ? 1 : -1);
  }

  if (typeof a === 'undefined') {
    return typeof b === 'undefined'
      ? (sortEquals ? sortEquals(aValue, bValue) : 0)
      : (topUndefineds ? -1 : 1);
  } else if (typeof b === 'undefined') {
    return (topUndefineds ? 1 : -1);
  }

  if (!caseSensitive) {
    a = typeof a === 'string' ? a.toLowerCase() : a;
    b = typeof b === 'string' ? b.toLowerCase() : b;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    const compare = a.localeCompare(b);

    return compare === 0 ? (sortEquals ? sortEquals(aValue, bValue) : 0) : compare * desc;
  }

  if (a > b) { return 1 * desc; }
  if (a < b) { return -1 * desc; }

  return sortEquals ? sortEquals(aValue, bValue) : 0;
}

/**
 * Creates a comparator function for sorting given a set of options.
 *
 * @param {String}   options.property       The path to the property to sort by
 * @param {Function} options.accessor       The function used to calculate the property to sort by.
 *                                          Takes the item being sorted and returns the value to
 *                                          use for the sorting comparison
 * @param {String}   options.direction      The direction of sort: `asc` or `desc`. Defaults to
 *                                          `asc`
 * @param {Array}    options.order          An array defining a custom sort order. Defaults to
 *                                          `undefined`
 * @param {String}   options.sortNulls      Where null values should be sorted: `top` or `bottom`.
 *                                          Defaults to `top`
 * @param {String}   options.sortUndefineds Where undefined values should be sorted: `top` or
 *                                          `bottom`. Defaults to the value of `sortNulls`
 * @param {String}   options.sortMissing    Where values not in the custom `order` array should be
 *                                          sorted: `top` or `bottom`. Defaults to `bottom`
 * @param {Function} options.sortEquals     A function used to sort values that are equal according
 *                                          to this comparator; essentially a sub-sorting function
 * @param {boolean}  options.caseSensitive  Whether to compare strings with the case of letters
 *                                          affecting the sort. Defaults to `false`
 *
 * @return {Function} A comparator function that can be used with `Array.sort` to sort an array
 */
function createAdvancedComparator({
  property,
  accessor,
  direction = 'asc',
  order,
  sortNulls = 'top',
  sortUndefineds,
  sortMissing,
  sortEquals,
  caseSensitive = false,
}) {
  const topNulls = sortNulls === 'top';

  // Convert binary parameters to boolean to avoid doing it for each comparison
  return advancedComparator.bind(null, {
    accessor: property ? value => get(value, property) : accessor,
    desc: direction === 'desc' ? -1 : 1,
    order,
    topNulls,
    topUndefineds: sortUndefineds != null ? sortUndefineds === 'top' : topNulls,
    topMissing: sortMissing === 'top',
    caseSensitive,
    sortEquals,
  });
}

function createComparator(sortBy, sortEquals) {
  if (typeof sortBy === 'string') {
    const [ property, direction ] = sortBy.split(' ');

    return createAdvancedComparator({ property, direction, sortEquals });
  }

  if (Array.isArray(sortBy)) {
    return sortBy.reduceRight(
      (comparator, sort) => createComparator(sort, comparator),
      // (comparator, sort) => createAdvancedComparator({ ...sort, sortEquals: comparator }),
      null
    );
  }

  if (typeof sortBy === 'object') {
    return createAdvancedComparator({ ...sortBy, sortEquals });
  }

  if (typeof sortBy === 'function') {
    return sortBy;
  }

  return undefined;
}

/**
 * Returns a new array containing the elements from `array` in the order specified by `sortBy`.
 * This method performs a stable sort, i.e. it preserves the original order when two elements are
 * equal.
 *
 * The `sortBy` parameter can be either a comparison function or a string in the form
 * `property [direction]`, where `direction` is optional and can either be `asc` or `desc`.
 *
 * @param {Array}           array  The array of elements to be sorted
 * @param {string|Function} sortBy A comparision function, or a string indicating what property to
 *                                 sort on and in what direction
 *
 * @return {Array} A sorted copy of `array`
 */
export function sort(array, sortBy) {
  if (!array || !sortBy) {
    return array;
  }

  // TODO: Maybe cache the comparators created via the options, but first test performance before
  //       and after. Test the performance of the deep compare or JSON.stringify to find the
  //       correct comparator for the sort versus just constructing a new one. [twl 30.Sep.20]
  const comparator = createComparator(sortBy);

  if (!comparator) {
    throw new Error('Could not parse sortBy parameter');
  }

  return [ ...array ].sort(comparator);
}
