/**
 * Returns whether the `value` represents a server timestamp `FieldValue`.
 *
 * @param value - The value to test
 *
 * @returns `true` if the value represents a server timestamp `FieldValue`; `false` otherwise
 */
export const isServerTimestamp = (value: any): boolean => (
  value ? value._methodName === 'FieldValue.serverTimestamp' : false
);
