import { toLocalDate } from '@lifetools/shared-utils-time';

const inactiveTasksVisibleOnDate = date => [{
  'type': 'task',
  'status.state': 'new',
  'status.hide.date': {
    beforeOrOn: toLocalDate(date).toString(),              // snooze expired
  }
}, {
  'type': 'task',
  'status.state': 'new',
  'status.hide': null,                                         // not snoozed
}];

export default inactiveTasksVisibleOnDate;
