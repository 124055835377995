import { deleteDocument } from '@lifetools/shared-database';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from '../definitions';

export const deleteTodo = makeAsyncActionCreator({
  type: types.ARCHIVE_TODO,
  creator: async todo => {
    await deleteDocument(collection, todo.id);

    return todo.id;
  },
  status: {
    path: collection,
    type: 'write',
  }
});

export default deleteTodo;
