import { todoStatusFromIntent } from '@lifetools/shared-app-triggers';
import addCreatedFields from './any/triggers/addCreatedFields';
import addDataVersion from './any/triggers/addDataVersion';
import updateUpdatedFields from './any/triggers/updateUpdatedFields';
import intentStatusHistory from './intents/triggers/intentStatusHistory';
import todoStatusHistory from './todos/triggers/todoStatusHistory';
import todoStatusHideHistory from './todos/triggers/todoStatusHideHistory';

const rootTriggers = [
  todoStatusFromIntent,
  todoStatusHistory,
  todoStatusHideHistory,
  intentStatusHistory,
  addCreatedFields,
  addDataVersion,
  updateUpdatedFields,
];

export default rootTriggers;
