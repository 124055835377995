import store from 'services/store';
import loadAppSettings from 'services/store/settings/actions/loadAppSettings';

/**
 * Sets the application settings in the store for both authenticated and anonymous users.
 */
async function setAppSettings() {
  await store.dispatch(loadAppSettings());
}

export default setAppSettings;
