import { ChronoField, ChronoUnit, LocalTime as JodaLocalTime } from 'js-joda';
import { toLocalTime } from './convert/toLocalTime';
import { TimeUnit } from './types/TimeUnit';
import { TimeValue } from './types/TimeValue';

const chronoFields: { [K in TimeUnit]: ChronoField }  = {
  hours: ChronoField.HOUR_OF_DAY,
  minutes: ChronoField.MINUTE_OF_HOUR,
  seconds: ChronoField.SECOND_OF_MINUTE,
};

const chronoUnits: { [K in TimeUnit]: ChronoUnit } = {
  hours: ChronoUnit.HOURS,
  minutes: ChronoUnit.MINUTES,
  seconds: ChronoUnit.SECONDS,
}

export class LocalTime {
  private time: JodaLocalTime;

  static now() {
    return new LocalTime(JodaLocalTime.now());
  }

  /**
   * Constructs a new `LocalTime` from the time value.
   *
   * @param timeValue - a value representing a time
   */
  constructor(time: TimeValue) {
    const localTime = toLocalTime(time);

    if (!localTime) {
      throw new Error(`The 'time' parameter must be a value time. It was '${time}'`);
    }

    this.time = localTime;
  }

// TODO: Think about converting these to getters, so we can use startTime.hour, rather than startTime.hour().
//       The writable version of these are startTime.withHour(12), which returns a new LocalTime.

  hour() {
    return this.time.hour();
  }

  minute() {
    return this.time.minute();
  }

  second() {
    return this.time.second();
  }

  /**
   * Returns true if this time represents midnight.
   */
  isMidnight() {
    return this.time.toSecondOfDay() === 0;
  }

  add(interval: number, unit: TimeUnit) {
    const newTime = this.time.plus(interval, chronoUnits[unit]);

    return new LocalTime(newTime);
  }

  roundUp(interval: number, unit: TimeUnit) {
    const value = this.time.get(chronoFields[unit]);
    const diff = interval - (value % interval);
    const newTime = this.time.plus(diff, chronoUnits[unit]).truncatedTo(chronoUnits[unit]);

    return new LocalTime(newTime);
  }

  toString() {
    return this.time.toString();
  }

  // TODO: Remove this once we've converted to the time object. [twl 6.Feb.18]
  //
  // HACK: Make it easy to convert back to a Joda Local Time object. [twl 6.Feb.18]
  toJodaLocalTime() {
    return JodaLocalTime.from(this.time);
  }
}
