import get from 'lodash/get';
import React from 'react';
import { LocalDate, LocalTime } from 'js-joda';
import { toLocalTime } from '@lifetools/shared-utils-time';
import EditPlanButton from 'components/app/buttons/EditPlanButton';
import PrintButton from 'components/app/buttons/PrintButton';
import ViewsPaneButton from 'components/app/buttons/ViewsPaneButton';
import Scheduler from 'components/app/schedules/Scheduler';
import ScheduleProjections from 'components/app/schedules/ScheduleProjections';
import WorkflowPromptCard from 'components/app/schedules/WorkflowPromptCard';
import ViewLayout from 'components/layouts/ViewLayout';
import withStyles from 'helpers/withStyles';
import { buildScene } from 'scenes/utils';
import removeIntent from 'services/store/intents/actions/removeIntent';
import updateIntent from 'services/store/intents/actions/updateIntent';
import updateIntentStatus from 'services/store/intents/actions/updateIntentStatus';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import hasIntent from 'services/store/intents/utils/hasIntent';
import { isDevEnvironment } from 'utils/app/isDevEnvironment';
import { isPlanEditable } from 'utils/app/plans/isPlanEditable';
import { getActivePlanType } from 'utils/schedules/getActivePlanType';
import DeletePlanButton from 'widgets/buttons/DeletePlanButton';
import HelpTipWidget from 'widgets/common/HelpTipWidget';
import UnscheduledIntentsWidget from 'widgets/intents/UnscheduledIntentsWidget';
import ScheduleNotesWidget from 'widgets/schedules/ScheduleNotesWidget';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';
import TodoListWidget from 'widgets/todos/TodoList';

const i18nPath = 'scenes.app.DailySchedule.components.DailyScheduleView';
const i18nPathScheduler = `${i18nPath}.components.Scheduler`;
const viewSettingsPath = 'defaults.dashboard.view';
const showProjectionsPath = 'defaults.dashboard.projections';

const styles = theme => ({
  // HACK: On Safari on iOS 13, when touch scrolling is enabled, Safari doesn't scroll automatically
  //       to the end of the schedule. To get to it, you have to slowly scroll once you reach the
  //       end. This may be due to the combination of scrolling attributes in the app, but I've used
  //       up my debugging time for this. Adding a bottom margin allows momentum scroll to reach the
  //       bottom of the schedule. [twl 1.May.20]
  // iosScheduleHack: {
  //   marginBottom: 44 + 48,          // The width of the carousel and the action bar
  // },
  empty: {
    padding: theme.spacing.unit * 6,
  },
});

async function loadData() {
  this.props.doLoadSettings();
}

const areProjectionsActive = (date, nextStartTime) => {
  const today = LocalDate.now();

  return (
    !date.isBefore(today) ||
    (today.minusDays(1).equals(date) && LocalTime.now().isBefore(toLocalTime(nextStartTime)))
  );
};

const DailyScheduleView = buildScene(({
  // view configuration
  planBoxTitle,
  layout = 'split',
  actionMode = 'statusMenu',
  isPlanEditable = true,
  showFutureTasks = true,

  // data
  date,
  nextStartTime,
  schedule,
  intents,

  // computed
  viewMode,
  showProjections,
  projectionsActive,
  isPlanActive,
  activePlanType,

  // context
  isAccountReadOnly,

  // actions
  doUpdateSettings,
  doUpdateIntent,
  doUpdateIntentStatus,
  doDeleteIntent,

  // injections
  classes,
  t,
}) => (
  <ViewLayout layout={layout}>
    <Scheduler
      boxId="Schedule"
      viewId="schedule"
      viewColumn={1}
      i18nPath={i18nPathScheduler}
      boxClassName={classes.iosScheduleHack}
      boxTitle={planBoxTitle}
      boxActions={!isPlanActive
        ? [
          <StartWorkflowButton
            variant="actionBar"
            workflow="schedule"
            existingSchedule={schedule}
            activePlanType={activePlanType}
            date={date}
          />
        ]
        : [
          <ViewsPaneButton
            expanded={viewMode === 'expanded'}
            projectionsActive={projectionsActive}
            showProjections={showProjections}
            onToggleViewCompact={() => doUpdateSettings({
              [viewSettingsPath]: viewMode === 'expanded' ? 'compact' : 'expanded'
            })}
            onToggleShowProjections={() => doUpdateSettings({
              [showProjectionsPath]: !showProjections
            })}
          />,
          <PrintButton />,
          isPlanEditable && !isAccountReadOnly &&
            <EditPlanButton workflow="schedule" step="schedule" date={date} />,
          isPlanEditable && !isAccountReadOnly && isDevEnvironment() &&
            <DeletePlanButton schedule={schedule} />,
        ]
      }
      header={
        projectionsActive && showProjections
          ? <div>
              <HelpTipWidget
                helpPath="today.schedule.plan"
                tips={['intro[newUser]','projections[newUser]']}
              />
              <ScheduleProjections
                date={date}
                nextStartTime={nextStartTime}
                schedule={schedule}
                intents={intents}
              />
            </div>
          : <HelpTipWidget
              helpPath="today.schedule.plan"
              tips={['intro[newUser]']}
            />
      }
      emptyComponent={
        <WorkflowPromptCard
          className={classes.empty}
          date={date}
          workflow="schedule"
          existingSchedule={schedule}
          activePlanType={activePlanType}
        />
      }
      intents={intents}
      startTime={schedule.startTime}
      endTime={schedule.endTime}
      actionMode={actionMode}
      compact={viewMode === 'compact'}
      variant="summary"
      fillActive={projectionsActive && showProjections}
      onIntentUpdate={doUpdateIntent}
      onIntentDelete={doDeleteIntent}
      onStatusChange={doUpdateIntentStatus}
    />

    <UnscheduledIntentsWidget
      viewId="unscheduled"
      i18nPath={i18nPath + `.views.${isPlanActive ? 'UnscheduledIntents' : 'Intents'}`}
      actionMode={actionMode}
      variant="compact"
      createEnabled={actionMode !== 'statusReadOnly'}
      schedule={schedule}
      intents={intents}
      date={date}
      groupBy={isPlanActive ? 'todo.tags' : undefined}
      sortBy={isPlanActive ? undefined : [{ property: 'todo.tags[0]', sortNulls: 'bottom' }, 'todo.title']}
      header={
        isPlanActive
        ? <HelpTipWidget
            helpPath="today.shared.sideIntents"
            tips={['intro[newUser]']}
          />
        : undefined
      }
    />

    {showFutureTasks &&
      <TodoListWidget
        viewId="future"
        i18nPath={i18nPath + '.components.FutureTasks'}
        actionMode={actionMode}
        date={date}
        query={{
          type: 'task',
          'status.state': 'new',
          createdAt: {
            greaterThan: new Date(date),
          },
        }}
        filter={todo => !hasIntent(todo)}
        groupBy="tags"
        header={
          <HelpTipWidget
            helpPath="today.shared.future"
            tips={['intro[newUser]']}
          />
        }
        createEnabled
      />
    }

    {(actionMode !== 'statusReadOnly' || schedule.notes) &&
      <ScheduleNotesWidget schedule={schedule} readOnly={actionMode === 'statusReadOnly'} />
    }
  </ViewLayout>
), {
  context: 'isAccountReadOnly',
  state: (state, { actionMode, date, schedule, intents }) => {
    const userSettings = selectUserSettings(state);
    const nextStartTime = get(userSettings, 'defaults.scheduleTimes.startTime', schedule.startTime);
    const activePlanType = getActivePlanType(schedule, intents);
    const isPlanActive = activePlanType === 'schedule';

    return {
      nextStartTime,
      viewMode: get(userSettings, viewSettingsPath, 'compact'),
      showProjections: get(userSettings, showProjectionsPath, true),
      projectionsActive: isPlanActive && areProjectionsActive(date, nextStartTime),
      isPlanEditable: isPlanEditable(date, state),
      isPlanActive,
      activePlanType,
    };
  },
  dispatch: {
    doLoadSettings: loadSettings,
    doUpdateSettings: updateSettings,
    doUpdateIntent: updateIntent,
    doUpdateIntentStatus: updateIntentStatus,
    doDeleteIntent: removeIntent,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default withStyles(styles)(DailyScheduleView);
