/**
 * Parses the Firebase `authUser` object returned from Firebase Auth into an info object containing
 * the details of the authorization for the local application.
 *
 * @param {object} authUser A Firebase Auth user object
 *
 * @return {object} An object containing separate `auth` and `user` keys. The `auth` key contains
 *                  all details related to authentication, while the `user` key contains general
 *                  user details. If `authUser` is null or undefined, returns an anonymous user
 *                  object.
 */
export function parseFirebaseAuthUser(authUser) {
  // To prevent Google taking over a password account always give preference to `password`
  const profile = authUser?.providerData.find(data => data.providerId === 'password')
    ?? authUser?.providerData[0];

  return authUser ? {
    auth: {
      userId: authUser.uid,
      isAnonymous: authUser.isAnonymous,
      isEmailVerified: authUser.emailVerified,
      lastSignedIn: authUser.metadata.lastSignInTime,
    },
    user: {
      id: authUser.uid,
      createdAt: authUser.metadata.creationTime,
      displayName: profile.displayName ?? authUser.displayName,
      email: profile.email ?? authUser.email,
      isEmailVerified: authUser.emailVerified,
      photoUrl: profile.photoURL,
      provider: profile.providerId,
    }
  } : {
    auth: {
      isAnonymous: true,
    },
    user: {}
  };
}
