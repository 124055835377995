import { schema as schedules } from './schedules/schema';
import { schema as settings } from './settings/schema';
import { schema as todos } from './todos/schema';
import { schema as user } from './user/schema';

export const schemas = {
  schedules,
  settings,
  todos,
  user,
}
