export const operators = ['&&', '||', '==', '!=', '>=', '<=', '>', '<', '!'] as const;

interface SimpleExpressionOperation {
  type: 'operation',
  operator: typeof operators[number];
  operands: SimpleExpression[];
}

interface SimpleExpressionVariable {
  type: 'variable';
  name: string;
}

type SimpleExpressionValue = string | boolean | number | null | undefined;

export type SimpleExpression = SimpleExpressionOperation
  | SimpleExpressionVariable
  | SimpleExpressionValue;
