export function getTimes(startTime, interval, length) {
  const times = [];

  for (let offset = 0; offset < length; offset += interval) {
    times.push({
      key: offset,
      time: startTime.plusMinutes(offset)
    });
  }

  return times;
}
