import { selectStatus } from 'services/store/utils-selectors';

export const hasAuthentication = (state) => state.auth && state.auth.data != null;
export const isAuthenticated = (state) => (
  hasAuthentication(state) && state.auth.data.isAnonymous === false
);

export const selectAuthRedirectStatus = (state) => (
  selectStatus(state, 'auth', 'redirectResult', '*') || {}
);
