import React from 'react';
import classnames from 'classnames';
import Icon from 'components/library/Icon';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  root: {
    color: theme.palette.info.main,
    fontSize: 18,
  },
});

const HelpIcon = ({ className, i18nPath, format = 'message', classes, t }) => (
  <Icon
    className={classnames(classes.root, className)}
    type="fas:info-circle"
    tooltipVariant={format}
    tooltip={t(i18nPath)}
  />
);

export default withStyles(styles)(withTranslations(HelpIcon));
