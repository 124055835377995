import get from 'lodash/get';
import { LocalDate } from 'js-joda';
import { IUserSettings } from '@lifetools/shared-app-data';
import { ITimestamp, daysBetween, toLocalDate } from '@lifetools/shared-utils-time';

type UserVariableResolver = (userSettings: IUserSettings, name: string) => any;

// TODO: Remove `activateHelpOnlyForCohort` once it has been activated for at least 7 days), so help
//       can be added for users in prior cohorts. What this is trying to avoid is adding an
//       avalanche of help prompts to people who've already started using the system [twl 31.Aug.22]
const activateHelpOnlyForCohort = 'beta:6';
const daysConsideredNewUser = 7;

function newUser(userSettings: IUserSettings) {
  const newUserOverride = get(userSettings, 'help.settings.newUser');
  const createdAt = get(userSettings, 'createdAt') as ITimestamp;
  const createdAtDate = createdAt ? toLocalDate(createdAt) : undefined;

  return newUserOverride !== false &&
    (newUserOverride === true ||
      (createdAtDate && daysBetween(createdAt, LocalDate.now()) < daysConsideredNewUser &&
       get(userSettings, 'cohort') === activateHelpOnlyForCohort)
    );
}

const resolvers: Record<string, UserVariableResolver> = {
  default: (userSettings: IUserSettings, name: string) => get(userSettings, name),
  newUser,
}

export function resolveVariable(userSettings: IUserSettings, name: string): any {
  const resolver = resolvers[name] ?? resolvers.default;

  return resolver(userSettings, name);
}
