import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import Tooltip from 'components/library/Tooltip';

export default class MultiStateIcon extends Component {
  static propTypes = {
    value: PropTypes.any,
    states: PropTypes.arrayOf(PropTypes.shape({
      icon: PropTypes.string,
      value: PropTypes.any,
    })),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    states: [{
      icon: 'far:square',
      value: null,
    }, {
      icon: 'far:plus-square',
      value: true,
    }, {
      icon: 'far:minus-square',
      value: false,
    }],
  };

  state = {
    value: undefined,
  };

  activeIndex = (prop) => {
    const { states } = this.props;
    const value = typeof this.props.value === 'undefined' ? this.state.value : this.props.value;
    const index = states.findIndex(item => item.value === value);

    return index !== -1 ? index : 0;
  };

  // TODO: The current code path isn't using this any more. This was used by the old `DoneCheckbox`.
  //       Consider removing this. [twl 13.Jan.20]
  handleClick = (e) => {
    const { states, onChange } = this.props;
    const index = (this.activeIndex(this.props) + 1) % states.length;
    const value = states[index].value;

    this.setState({ value });

    if (onChange) {
      e.stopPropagation();

      onChange(value);
    }
  };

  render() {
    const { className, states, disabled, useIconButton, onClick } = this.props;
    const activeIndex = this.activeIndex(this.props);
    const label = states[activeIndex].label;
    const icon = (
      <Icon
        className={!useIconButton ? className : undefined}
        type={states[activeIndex].icon}
        onClick={!useIconButton ? onClick || this.handleClick : undefined}
      />
    );
    const button = !useIconButton ? icon : (
      <IconButton className={className} disabled={disabled} onClick={onClick || this.handleClick}>
        {icon}
      </IconButton>
    );

    return label ? <Tooltip title={label}>{button}</Tooltip> : button;
  }
}
