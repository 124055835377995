import React from 'react';
import BasicSteps from 'components/app/schedules/BasicSteps';
import ViewLayout from 'components/layouts/ViewLayout';
import { buildScene } from 'scenes/utils';
import allocateIntent from 'services/store/intents/actions/allocateIntent';
import createIntent from 'services/store/intents/actions/createIntent';
import removeIntent from 'services/store/intents/actions/removeIntent';
import updateIntent from 'services/store/intents/actions/updateIntent';
import updateIntentStatus from 'services/store/intents/actions/updateIntentStatus';
import saveScheduleStep from 'services/store/schedules/actions/saveScheduleStep';
import HelpTipWidget from 'widgets/common/HelpTipWidget';
import IntentAllocationTable from './components/IntentAllocationTable';
import './Estimate.css';

const i18nPath = 'scenes.todos.Estimate';

export default buildScene(({
  workflow,
  schedule,
  intents,
  date,
  doAllocateIntent,
  doCreateIntent,
  doUpdateIntent,
  doDeleteIntent,
  doSaveScheduleStep,
  doUpdateIntentStatus,
}) => (
    <div className="Estimate">
      <BasicSteps
        currentStep="allocate"
        workflow={workflow}
        date={date}
        onNext={async () => doSaveScheduleStep(schedule, intents, workflow, 'allocated')}
      />

      <ViewLayout>
        <IntentAllocationTable
          boxId="AllocationBox"
          viewI18nPath={i18nPath + '.views.IntentAllocationTable'}
          workflow={workflow}
          schedule={schedule}
          intents={intents}
          onAllocation={doAllocateIntent}
          onCreate={(todo, duration) => doCreateIntent(todo, schedule, duration)}
          onUpdate={doUpdateIntent}
          onDelete={doDeleteIntent}
          onUpdateStatus={doUpdateIntentStatus}
          header={
            <HelpTipWidget
              helpPath="workflow.allocate.table"
              tips={['intro[newUser]']}
            />
          }
        />
      </ViewLayout>
    </div>
  ), {
    dispatch: {
      doAllocateIntent: allocateIntent,
      doCreateIntent: createIntent,
      doUpdateIntent: updateIntent,
      doDeleteIntent: removeIntent,
      doSaveScheduleStep: saveScheduleStep,
      doUpdateIntentStatus: (status, intent) => updateIntentStatus(intent, status),
    },
  }
);
