import React, { Children, cloneElement, useEffect, useRef } from 'react';
import { Formik, Field, Form as FormikForm } from 'formik';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import sentry from 'services/sentry';
import Typography from '../Typography';
import CheckboxField from './components/CheckboxField';
import DateField from './components/DateField';
import DayOfWeekSelectorField from './components/DayOfWeekSelectorField';
import DurationField from './components/DurationField';
import GenericField from './components/GenericField';
import RadioSelector from './components/RadioSelector';
import PasswordField from './components/PasswordField';
import SectionTitle from './components/SectionTitle';
import SelectorField from './components/SelectorField';
import SwitchField from './components/SwitchField';
import SubmitButton from './components/SubmitButton';
import TextField from './components/TextField';
import TimeField from './components/TimeField';
import { isDevEnvironment } from 'utils/app/isDevEnvironment';

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
  },
});

async function doSubmit(
  values,
  actions,
  isMountedRef,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  i18nPath,
  t,
) {
  try {
    actions.setStatus(undefined);

    onSubmit && await onSubmit(values);

    if (isMountedRef.current) {
      actions.setSubmitting(false);
    }

    onSubmitSuccess && onSubmitSuccess();
  } catch (e) {
    let message = e.code ? t(i18nPath, 'errors.' + e.code, e.message) : e.message;

    if (message === e.message) {
      message = t(i18nPath, 'validation.unknown-error');

      sentry.withScope(scope => {
        scope.setTag('type', 'formError');
        scope.setLevel('warning');
        scope.setExtras({ i18nPath, values });

        sentry.captureException(e);
      });
    }

    // Errors with `code` properties should be coming from an API endpoint and represent a server
    // error to display to the user
    if (!e.code) {
      console.error(e);
    }

    if (isMountedRef.current) {
      actions.setSubmitting(false);
      actions.setStatus({
        type: 'error',
        code: e.code,
        message,
      });
    }

    onSubmitError && onSubmitError(e);
  }
}

const Form = ({
  id,
  i18nPath,
  className,
  children,
  values = {},
  schema,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  classes,
  t,
}) => {
   const isMounted = useRef(false);

   useEffect(() => {
     isMounted.current = true;

     return () => {
       isMounted.current = false;
     };
   }, []);

  return (
    <Formik
      initialValues={values}
      validationSchema={schema}
      validateOnBlur={false}
      onSubmit={(values, actions) => (
        doSubmit(values, actions, isMounted, onSubmit, onSubmitSuccess, onSubmitError, i18nPath, t)
      )}
    >
      {({ status, ...renderProps }) => (
        <FormikForm id={id} className={className}>
          {status && status.message &&
            <Typography
              className={status.type === 'error' ? classes.error : undefined}
              variant="body1"
              component="div"
              data-code={isDevEnvironment() ? status.code : undefined}
            >
              {status.message}
            </Typography>
          }

          {Children.map(children, child =>
            child instanceof Field ? cloneElement(child, renderProps) : child
          )}
        </FormikForm>
      )}
    </Formik>
  );
}

// Pass-through components from Material UI
Form.Group = FormGroup;
Form.ControlLabel = FormControlLabel;

// Controls designed for this component
Form.CheckboxField = CheckboxField;
Form.DateField = DateField;
Form.DayOfWeekSelectorField = DayOfWeekSelectorField;
Form.DurationField = DurationField;
Form.GenericField = GenericField;
Form.RadioSelectorField = RadioSelector;
Form.PasswordField = PasswordField;
Form.SectionTitle = SectionTitle;
Form.SelectorField = SelectorField;
Form.SwitchField = SwitchField;
Form.SubmitButton = SubmitButton;
Form.TextField = TextField;
Form.TimeField = TimeField;

export default withTranslations(withStyles(styles)(Form));
