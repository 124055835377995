import { toLocalDate } from '@lifetools/shared-utils-time';

// Use `any` types since the data is not yet in the form of the interface
export const schema = (data: any) => {
  if (data.intents) {
    for (const intent of Object.values(data.intents) as any) {
      if (intent.date) {
        intent.date = toLocalDate(intent.date);
      }
    }
  }

  return data;
};
