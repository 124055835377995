import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteUserDocument from 'services/store/utils/actions/updateRemoteUserDocument';
import { collection, types } from '../definitions';

const updateSettings = makeAsyncActionCreator({
  type: types.SET_USER_SETTINGS,
  creator: async (changes) => ({
    settings: await updateRemoteUserDocument(collection, changes, true)
  }),
  status: {
    path: collection,
    type: 'write',
    parameters: () => 'user',
  }
});

export default updateSettings;
