import React from 'react';
import classnames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from 'helpers/withStyles';
import { testProps } from 'utils/test/testProps';
import MaterialUiField from '../MaterialUiField';

const styles = theme => ({
  checkbox: {
    alignSelf: 'flex-start',
  },
  errorCheckbox: {
    color: theme.palette.error.main,
  },
  label: {},
});

const MuiCheckboxField = ({
  className,
  fullWidth,
  name,
  value,
  label,
  color = 'primary',
  onChange,
  children,
  helperText,
  error,
  classes,
  CheckboxProps,
  FormControlLabelProps,
  ...props
}) => (
  <FormControl className={className} fullWidth={fullWidth}>
    <FormControlLabel
      control={
        <Checkbox
          className={classnames(classes.checkbox, error && classes.errorCheckbox)}
          name={name}
          color={color}
          value={value != null ? value.toString() : value}
          onChange={onChange}
          {...testProps(name)}
          {...CheckboxProps}
        />
      }
      label={
        <div className={classes.label}>
          {children || label}
          {helperText &&
            <FormHelperText error>{helperText}</FormHelperText>
          }
        </div>
      }
      {...FormControlLabelProps}
    />
  </FormControl>
);

const MuiCheckboxFieldWithStyles = withStyles(styles)(MuiCheckboxField);

const CheckboxField = ({ ...props }) => (
  <MaterialUiField {...props} muiComponent={MuiCheckboxFieldWithStyles}  />
);

export default CheckboxField;
