import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import ActionButton from 'components/app/todos/ActionButton';
import Popper from 'components/library/Popper';
import withStyles from 'helpers/withStyles';
import ActionInputPane from './components/ActionInputPane';

const debounceTime = 100;

const styles = theme => ({
  button: {
    color: theme.palette.primary.main,
    padding: theme.spacing.unit,
  },
  popper: {
    margin: `${theme.spacing.unit / 2}px 0`,
  },
});

/**
 * An action button that can be triggered to perform a primary action, or display an action menu.
 * The action menu is displayed when the user interacts with the button in specific ways (e.g.
 * clicks the button while holding the ALT key). The specific trigger for the menu depends on the
 * `actionMode` and the type of `todo`.
 */
const ActionMenuButton = ({
  className,
  todo,
  intent,
  actionMode = "statusChange",
  onStatusChange,
  classes,
  ...props
}) => {
  // HACK: Debouncing events because the action button isn't aware of the popper and the popper has
  //       a click-away handler that closes it when the user clicks somewhere other than the popper.
  //       This means when the user clicks the action button, the click away handler closes the
  //       popper, then the action button opens it right back up. [twl 28.May.20]
  //
  // TODO: This may be able to be fixed by pulling the click-away listener into this component and
  //       wrapping both the action button and popper in it. But I have everything working the way
  //       it is on desktop & mobile and don't have time right now to do that. [twl 28.May.20]
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ lastToggle, setLastToggle ] = useState(Date.now());
  const hideStatusMenu = () => {
    setAnchorEl(null);
    setLastToggle(Date.now());
  };
  const showStatusMenu = e => {
    // Activity todos have no status to change (but activity intents do)
    if (Date.now() - lastToggle > debounceTime && (todo.type !== 'activity' || intent)) {
      setAnchorEl(e.currentTarget);
      setLastToggle(Date.now());
    }
  };

  return (
    <Fragment>
      <ActionButton
        className={classnames(classes.button, className)}
        value={todo}
        intent={intent}
        actionMode={actionMode}
        onStatusMenu={showStatusMenu}
        onStatusChange={onStatusChange}
        {...props}
      />

      <Popper
        open={anchorEl !== null}
        anchorEl={anchorEl}
        placement="bottom-start"
        PaperProps={{
          className: classes.popper,
        }}
        onClose={hideStatusMenu}
      >
        <ActionInputPane
          todo={todo}
          intent={intent}
          onStatusChange={onStatusChange}
          onInputDone={hideStatusMenu}
        />
      </Popper>
    </Fragment>
  );
}

export default withStyles(styles)(ActionMenuButton);

