import { EmbedOptions } from './EmbedOptions';

const hostnameDefaults: { [hostname: string]: EmbedOptions } = {
  'todoist.com': {
    maxWidth: 750,
    height: 450,
  },
  'www.youtube.com': {
    aspectRatio: '16:9',
  },
};

export {
  hostnameDefaults
};
