import moment from 'moment';
import { DateTimeFormatter } from 'js-joda';
import { toMomentDate, toMomentTime, toNativeDate } from '@lifetools/shared-utils-time';

// `dateFormat` is a `moment` format, while `dateFormatter` uses a `LocalDate` format
const dateFormat = 'YYYY-MM-DD';
const defaultDateFormatter = DateTimeFormatter.ofPattern('uuuu-MM-d');

const timeFormat = 'h:mma';
const timeRangeFormat = timeFormat;

/**
 * Formats a duration using the form `5h` for even hours or `5h 15m` for durations that fall between
 * hours.
 *
 * @param {number}  value                A number of minutes
 * @param {boolean} alwaysIncludeMinutes Whether to always include the minutes, even if they are 0.
 *                                       Defaults to `true`.
 *
 * @return {string} The formatted value
 */
export function formatDuration(value, alwaysIncludeMinutes = true) {
  const minutes = value % 60;
  const hours = (value - minutes) / 60;

  return (hours > 0 ? hours + 'h' : '') +
         (minutes > 0 || alwaysIncludeMinutes ? ` ${minutes}m` : '');
}

/**
 * Formats a time range given a `start` date-time and either an `end` date-time or a `duration`,
 * using the optional `variant` to alter the format.
 *
 * Currently, `variant` only supports `compact`, which formats times on the hour without the minutes
 * (`:00`) and with `am/pm` shorted to `a/p`.
 *
 * @param {moment|LocalDate|Date|string} start    The start date-time
 * @param {moment|LocalDate|Date|string} end      The end date-time
 * @param {number}                       duration The number of minutes in the range
 * @param {string}                       variant  The format variant to use
 *
 * @return {string} A formatted time range
 */
export const formatTimeRange = (start, end, duration, variant) => (
  formatTime(start, timeRangeFormat, variant) +
  (end || duration > 0 ? (variant === 'compact' ? '\u2013' : ' \u2013 ') : '') +
  (end ? formatTime(end, timeRangeFormat, variant) :
         (duration > 0 ? start.plusMinutes(duration).toString() : ''))
);

/**
 * Returns the number of minutes between `startDateTime` and `endDateTime`.
 *
 * @param {moment|LocalDate|Date|string} startDateTime The start date-time
 * @param {moment|LocalDate|Date|string} endDateTime   The end date-time
 *
 * @return {number} The number of minutes between the start and end date-times
 */
export function getDuration(startDateTime, endDateTime) {
  const startMoment = toMomentDate(startDateTime);
  const endMoment = endDateTime && toMomentDate(endDateTime);

  return endMoment && endMoment.diff(startMoment, 'minutes');
}

/**
 * Formats the date-time `value` using the `format`.
 *
 * @param {LocalDate} value  The date-time to format
 * @param {string}    format The format to use, using `moment` format rules
 *
 * @return {string} A formatted string representing the `value`
 */
export function formatLocalDate(value, format = dateFormat) {
  const momentDate = moment(value.format(defaultDateFormatter), dateFormat);

  return momentDate.format(format);
}

/**
 * Formats the date-time `value` using the `format`.
 *
 * @param {moment|LocalDate|LocalTime|Date} value  The date-time to format
 * @param {string}                          format The format to use, using `moment` format rules
 *
 * @return {string} A formatted string representing the `value`
 */
export function formatDateTime(value, format) {
  const momentDateTime = moment(toNativeDate(value));

  return momentDateTime.format(format);
}

/**
 * Formats the date specified by `value` into text that describes the date as a relative time in the
 * past, such as `5 seconds ago` or `3 days ago`.
 *
 * @param {moment|LocalDate|LocalTime|Date|Timestamp} value  The date-time to format
 *
 * @return {string} The formatted value
 */
export function fromNow(value) {
  return toMomentDate(value).fromNow();
}

/**
 * Formats the time `value` using a moment format, using the optional `variant` to alter the format.
 *
 * Currently, `variant` only supports `compact`, which formats times on the hour without the minutes
 * (`:00`) and with `am/pm` shorted to `a/p`.
 *
 * @param {LocalTime|moment|string} value   The time to convert
 * @param {string}                  format  The format to use for the formatting
 * @param {string}                  variant The format variant to use
 *
 * @return {string} A formatted version of the time value
 */
export function formatTime(value, format = timeFormat, variant) {
  if (!value) {
    return value;
  }

  const formatted = toMomentTime(value).format(format);

  return variant === 'compact'
    ? formatted.replace(':00', '').replace('am', 'a').replace('pm', 'p')
    : formatted;
}

/**
 * Returns the number of minutes between the `start` time and the `end` time.
 *
 * @param {string|Date} start A date object or ISO formatted date string
 * @param {string|Date} end   A date object or ISO formatted date string
 *
 * @return {number} The number of minutes between the start time and the end time
 */
export function minutesBetween(start, end) {
  const startDateTime = start && toNativeDate(start);
  const endDateTime = end && toNativeDate(end);

  return startDateTime && endDateTime && (endDateTime.valueOf() - startDateTime.valueOf()) / 60000;
}
