import { toNativeDate } from '@lifetools/shared-utils-time';
import { LocalDate } from 'js-joda';

/**
 * Parses `text` into a `LocalDate` using a more liberal parsing algorithm than `toLocalDate`,
 *
 * @param text - The text to parse into a date
 *
 * @returns A `LocalDate` representing the date specified by `text` or `undefined` if the text was
 *          an empty string
 */
export function parseLocalDateLoose(text: string): LocalDate | undefined {
  // `toNativeDate` is more liberal in date parsing than `toLocalDate`
  const date = toNativeDate(text);

  // Cannot use `toLocalDate` here because `date` is shifted to the local time zone when it is
  // parsed, which, for time zones west of UTC puts the date one day in the past
  return date
    ? LocalDate.of(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate())
    : undefined;
}
