import { AuthenticationError } from 'types/AuthenticationError';
import { assertProvider } from './assertProvider';
import { deleteNewUser } from './deleteNewUser';

export async function handleFederatedIdentityLogin(user, additionalUserInfo) {
  const { isNewUser, providerId } = additionalUserInfo ?? {};

  if (!isNewUser) {
    // Enforce a single email, single provider login. See notes in `auth/overview.md` for the
    // security implications of removing this
    await assertProvider(user.email, providerId);
  } else {
    // Prevent automatic registration via login--force users to use register page
    await deleteNewUser();

    throw new AuthenticationError(
      'auth/account-not-found',
      'No account could be found from this provider.',
      {
        email: user.email,
        providerId,
      }
    );
  }
}
