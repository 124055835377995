import { LocalDate } from 'js-joda';

/**
 * Ensures the date is within the range from `daysInPast` to `daysInFuture` based on today's date.
 *
 * @param date         The date to clamp
 * @param daysInPast   The maximum number of days in the past the date can be
 * @param daysInFuture The maximum number of days in the future the date can be
 *
 * @returns The date, unless it is too far in the past, in which case the date `daysInPast` from
 *          today is returned, or it is too far in the future, in which case the date `daysInFuture`
 *          from today is returned
 */
export function clampDate(date: LocalDate, daysInPast: number, daysInFuture: number): LocalDate {
  const minDate = LocalDate.now().minusDays(daysInPast);
  const maxDate = LocalDate.now().plusDays(daysInFuture);

  return date.isAfter(maxDate) ? maxDate : (date.isBefore(minDate) ? minDate : date);
}
