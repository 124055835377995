/**
 * The data schema versions of each collection in this app.
 */
export const versions: { [collection: string]: number } = {
  imports: 1,
  logs: 1,
  schedules: 3,
  settings: 6,
  todos: 4,
}
