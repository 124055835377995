import React, { useState } from 'react';
import { compose } from 'recompose';
import RadioSelector from 'components/library/RadioSelector';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';
import PlanTypeOption from './components/PlanTypeOption';

// TODO: Update these paths to match the path to this component if we decide to use this variation.
//       [twl 4.Apr.22]
const i18nPath = 'scenes.app.Today.components.NoTodayViewSurveyWithButtons.components.WhichPlanType';

const styles = theme => ({
  root: {
    textAlign: 'center',
  },
  header: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 2}px 0`,
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing.unit,
    }
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: 320,

    '& > *': {
      marginBottom: theme.spacing.unit * 2,
    },
  },
});

const WhichPlanType = ({ date, classes, t }) => {
  const [workflow, setWorkflow] = useState('none');

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h2">
        {t(i18nPath, 'title')}
      </Typography>

      <div className={classes.options}>
        <RadioSelector
          value={workflow}
          options={
            ['commit', 'allocate', 'schedule'].map(workflow => ({
              label: <PlanTypeOption workflow={workflow} />,
              value: workflow,
            }))
          }
          onChange={workflow => setWorkflow(workflow)}
        />
      </div>

      <StartWorkflowButton date={date} workflow={workflow} disabled={workflow === 'none'} />
    </div>
  );
}

export default compose(
  withStyles(styles),
  withTranslations,
)(WhichPlanType);
