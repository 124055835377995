import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteDocument from 'services/store/utils/actions/updateRemoteDocument';
import { collection, types } from '../definitions';

const updateTodo = makeAsyncActionCreator({
  type: types.UPDATE_TODO,
  creator: async (todo, changes) => await updateRemoteDocument(collection, todo.id, changes),
  status: {
    path: collection,
    type: 'write',
    parameters: (todo, _changes) => [ todo.id ],
  }
});

export default updateTodo;
