import { types as globalTypes } from 'services/store/actions';
import { makeAction } from 'services/store/utils-actions';
import { path } from '../definitions';

const setRedirecthResult = (error) => makeAction(globalTypes.SET_STATUS, {
  type: 'redirectResult',
  path,
  state: error != null ? 'failure' : 'success',
  error,
  parameters: '*',
})

export default setRedirecthResult;
