import React from 'react';
import withStyles from 'helpers/withStyles';
import PrimaryMenuItem from './components/PrimaryMenuItem';

const items = [
  {
    route: 'plan',
    items: [
      { route: ':planDate[today]/commitments' },
      { route: ':planDate[today]/allocations' },
      { route: ':planDate[today]/schedule' },
    ]
  },
  { route: 'plans/:planDate[today]',
    items: [
      { route: 'commitments' },
      { route: 'allocations' },
      { route: 'schedule' },
    ]
  },
  { route: 'appointments' },
  { route: 'tasks' },
  { route: 'activities' },
  { route: 'coaching' },
  { route: 'help' },
  { route: 'account' },
];

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const PrimaryMenu = ({ classes }) => (
  <div className={classes.root} data-role="menu">
    {items.map(item =>
      <PrimaryMenuItem
        key={item.route}
        route={item.route}
        items={item.items}
      />
    )}
   </div>
);

export default withStyles(styles)(PrimaryMenu);

