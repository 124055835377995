import moment, { Moment } from 'moment';
import { DateTimeFormatter, LocalDate } from 'js-joda';
import { DateValue } from '../types/DateValue';
import { ITimestamp } from '../types/ITimestamp';

// `dateFormat` is a `moment` format, while `dateFormatter` uses a `LocalDate` format
const dateFormat = 'YYYY-MM-DD';
const dateFormatter = DateTimeFormatter.ofPattern('yyyy-MM-dd');

/**
 * Converts the `value` to a `moment` instance, using the `format` to parse the date. If not
 * specified, the format parses the date as an ISO 8601 local date.
 *
 * The `format` is the format used by `moment`. The supported pattern letters are:
 *
 * -----------------------------------------------------------------
 * | Letter | Date Segment               | Type             | e.g. |
 * -----------------------------------------------------------------
 * | YYYYY  | month                      | number           | 2012 |
 * | M      | month                      | number           | 3    |
 * | D      | day of month               | number           | 5    |
 * -----------------------------------------------------------------
 *
 * Other pattern letters may work, but may not be supported in the future. See the `moment`
 * documentation for the full list.
 *
 * @param value  - The date-time to convert
 * @param format - The format to use for the conversion
 *
 * @returns A `moment` instance of the `value`
 */
export function toMomentDate(value: DateValue, format?: string): Moment | undefined {
  if (!value) {
    return undefined;
  }

  if (value instanceof moment) {
    return value as Moment;
  }

  if (value instanceof LocalDate) {
    value = value.format(dateFormatter);
    format = dateFormat;
  } else if (typeof value !== 'string' && !(value instanceof Date) && value.seconds) {
    const { seconds, nanoseconds } = value as ITimestamp;

    value = new Date(seconds * 1000 + (nanoseconds / 1000000));
  }

  return moment(value, format);
}
