import { visit } from 'unist-util-visit';

export const italicsDirective = function remarkItalicsDirectivePlugin() {
  return (tree, file) => {
    visit(tree, 'textDirective', (node) => {
      if (node.name === 'i') {
        node.data = node.data ?? {};
        node.data.hName = 'i';
      }
    });
  };
}
