import React, { useState } from 'react';
import Icon from 'components/library/Icon';
import ItemList from 'components/library/ItemList';
import Popper from 'components/library/Popper';
import withStyles from 'helpers/withStyles';
import IntentOption from './components/IntentOption';

const debounceTime = 100;

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  selector: {
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
    background: theme.palette.common.white,
    borderRadius: theme.spacing.unit / 2,
    border: `solid 1px ${theme.palette.grey['300']}`,
    textAlign: 'right',
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.grey['300'],
  },
  popper: {
    borderRadius: `0 0 ${theme.spacing.unit / 2}px ${theme.spacing.unit / 2}px`,
    margin: `1px 0`,
    maxHeight: 300,
    overflow: 'scroll',
  },
});

const IntentSelector = ({ className, intents, sortBy, value, onChange = () => {}, classes }) => {
  // HACK: Debouncing events because the selector isn't aware of the popper and the popper has a
  //       click-away handler that closes it when the user clicks somewhere other than the popper.
  //       This means when the user clicks the selector, the click away handler closes the popper,
  //       then the action button opens it right back up. [twl 30.Sep.20]
  //
  // TODO: This may be able to be fixed by pulling the click-away listener into this component and
  //       wrapping both the selector and popper in it. But I copied this code from
  //       `ActionMenuButton` and I don't have time to test that when it works now. [twl 30.Sep.20]
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ lastToggle, setLastToggle ] = useState(Date.now());
  const hideStatusMenu = () => {
    setAnchorEl(null);
    setLastToggle(Date.now());
  };
  const showStatusMenu = e => {
    if (Date.now() - lastToggle > debounceTime) {
      setAnchorEl(e.currentTarget);
      setLastToggle(Date.now());
    }
  };
  const onClick = intent => {
    hideStatusMenu();
    onChange(intent);
  };

  // NOTE: Need to disable `preventOverflow` so the popper is positioned correctly on mobile
  //       devices. Doesn't seem to be affecting the desktop either way. [twl 30.Sep.20]
  return (
    <div className={classes.root} onClick={showStatusMenu}>
      <div className={classes.selector}>
        <Icon className={classes.icon} type="fas:chevron-down" />
      </div>

      <Popper
        open={anchorEl !== null}
        anchorEl={anchorEl}
        placement="bottom-start"
        PaperProps={{
          className: classes.popper,
        }}
        onClose={hideStatusMenu}
        modifiers={{
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          }
        }}
        matchWidth
      >
        <ItemList
          className={classes.list}
          items={intents}
          component={IntentOption}
          componentProps={{ onClick }}
          sortBy={sortBy}
        />
      </Popper>
    </div>
  );
}

export default withStyles(styles)(IntentSelector);
