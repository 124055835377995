const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Generates a random string of characters of `length`.
 *
 * @param length - The length of the generated string
 *
 * @returns a string composed of random characters
 */
export function randomString(length: number): string {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += validChars[Math.floor(Math.random() * validChars.length) % validChars.length];
  }

  return result;
}
