import React from 'react';
import ActionBar from 'components/library/ActionBar';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    whiteSpace: 'normal',            // HACK: React Table in Estimate forces `nowrap` [twl 2.Feb.20]

    // Force word wrapping on long URLs and other blocks of text
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  actionBar: {
    background: theme.palette.grey['100'],
  },
  view: {
    background: theme.palette.background.paper,
  },
});

const QuickView = ({ actions, classes, children }) => (
  <div className={classes.root}>
    {actions && actions.length > 0 &&
      <ActionBar componentProps={{ className: classes.actionBar }} actions={actions} />
    }

    {children &&
      <div className={classes.view}>
        {children}
      </div>
    }
  </div>
);

export default withStyles(styles)(QuickView);
