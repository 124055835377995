import compact from 'lodash/compact';
import { Children } from 'react';

/**
 * Returns the number of non-null children in `children`. The `children` array for React components
 * contains nulls as placeholders for components that are conditionally rendered.
 *
 * @param {node[]} children An array of React nodes
 *
 * @return {number} The number of nodes that are non-null
 */
const countNonNullChildren = children => compact(Children.toArray(children)).length;

export default countNonNullChildren;
