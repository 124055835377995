import { services } from '@lifetools/shared-services';
import { toTimestamp } from '@lifetools/shared-utils-time';
import store from 'services/store';
import { types as globalTypes } from 'services/store/actions';

function addCreatedAtHack(data) {
  if (data && data.$pending && !data.createdAt) {
    data.createdAt = toTimestamp(new Date());
  }
}

const listener = (data, collection, subscriptionId) => {
  // HACK: When real-time data is enabled and a new object is first created, the first update will
  //       be a pending object with the `createdAt` field set to `null`. Because some code requires
  //       the `createdAt` field, we set it to the current time (which, since it's a new object,
  //       should only be a few milliseconds off from the real time). Once the data is saved to the
  //       server, a new non-pending update is issued with the correct `createdAt`. [twl 23.Apr.20]
  if (Array.isArray(data)) {
    data.forEach(item => addCreatedAtHack(item));
  } else if (data) {
    addCreatedAtHack(data);
  }

  store.dispatch({
    type: globalTypes.SET_DATA,
    payload: {
      collection,
      data,
    },
  });
}

/**
 * Turns on realtime data subscriptions for this instance of the application.
 *
 * @param authInfo - The authentication information for the current user
 */
export default async function enableRealtimeData(authInfo) {
  // NOTE: If the user was logged in, this used to check `flags.realtimeData` to determine the
  //       enabled status. But it's been enabled by default on everyone's account for so long, I
  //       just made it the default and removed the option here. [twl 27.May.24]
  services.data.listener = listener;
}
