import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { compile as compilePath } from 'path-to-regexp';
import { toLocalDate } from '@lifetools/shared-utils-time';
import getDisplayName from 'helpers/utils/getDisplayName';
import { fromRouteDate } from 'utils/app/routes/fromRouteDate';
import { formatLocalDate } from 'utils/time';

/**
 * Changes the route to use the new `date`.
 *
 * @param {object} history The router history
 * @param {object} match   The match results for the current route
 * @param {date}   date    The date to switch the route to
 */
function changeRoute(history, match, date, view) {
  const toPath = compilePath(match.path);
  const dateParam = formatLocalDate(toLocalDate(date));
  const newPath = toPath({ ...match.params, date: dateParam, planDate: dateParam, view });

  history.push(newPath);
}

// HACK: The `/appointments` route uses a `date` URL parameter, but also uses
//       `/appointments/callback` for connecting with Google Calendar. The `callback` can't be
//       parsed as a date. [twl 10.Oct.18]
//
// TODO: Figure out a better way to handle this. [twl 10.Oct.18]
function parseDate(date) {
  if (date === 'callback') {
    return fromRouteDate('today');
  }

  const parsedDate = fromRouteDate(date);

  if (date && !parsedDate) {
    console.warn(`The 'date' route parameter could not be parsed to a date: ${date}`);
  }

  return parsedDate;
}

/**
 * Enhances a component by grabbing the date from the route and passing it in as a prop. Use the
 * `onChangeDate` event to change the routed date.
 */
export function withRoutedDate(WrappedComponent) {
  // TODO: Make this use a memoized version of `onChangeRouteDate` so when this is passed into
  //       children, it doesn't force a re-render unless the other URL parameters have changed. See
  //       `withRouteId` for details. [twl 27.Nov.18]

  const WithDate = ({ history, match, ...props }) => {
    const doChangeRouteDate = useCallback(
      (date, view) => changeRoute(history, match, date, view),
      [ history, match ],
    );
    const routeDate = useMemo(
      () => parseDate(match.params.date || match.params.planDate),
      [ match.params.date, match.params.planDate ]
    );

    return (
      <WrappedComponent
        {...props}
        match={match}
        routeDate={routeDate}
        routeDateParam={match.params.planDate}
        routeView={match.params.view}
        onChangeRouteDate={doChangeRouteDate} />
    );
  }

  const WithRoutedDate = withRouter(WithDate);

  hoistNonReactStatics(WithRoutedDate, WrappedComponent);

  WithRoutedDate.displayName = `WithRoutedDate(${getDisplayName(WrappedComponent)})`;

  return WithRoutedDate;
}
