import { DocumentPath } from './types/DocumentPath';
import { IDocumentReference } from './types/IDocumentReference';
import { getDocumentReference } from './getDocumentReference';

/**
 * Returns the document reference for the document at the `path`, creating a new empty document at
 * that path if one does not already exist.
 *
 * @param path - The path to the document
 *
 * @returns A document reference that can be used to manage the document
 */
export async function getOrCreateDocumentReference(
  path: DocumentPath
): Promise<IDocumentReference> {
  const docRef = getDocumentReference(path);
  const doc = await docRef.get();

  if (!doc.exists) {
    await docRef.set({});
  }

  return docRef;
}
