import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteDocument from 'services/store/utils/actions/updateRemoteDocument';
import { collection, types } from '../definitions';

const updateTodoStatus = makeAsyncActionCreator({
  type: types.UPDATE_TODO_STATUS,
  creator: async (todo, state) => {
    if (todo.type === 'activity') {
      throw new Error(`Cannot update the status for an activity; update the intent instead`);
    }

    return await updateRemoteDocument(collection, todo.id, {
      'status.state': state,
      'status.from': undefined,                  // Force a manual override, depending on `state`
    });
  },
  status: {
    path: collection,
    type: 'write',
    parameters: (todo, _status) => [ todo.id ],
  }
});

export default updateTodoStatus;
