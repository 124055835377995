import transform from 'lodash/transform';

export function addIntents(times, intents, interval) {
  const newTimes = times.map(time => Object.assign({}, time));
  const intentMap = transform(intents, (map, intent) => map[intent.start.toString()] = intent, {});

  let activeIntent, activeTime, remainingDuration;

  return newTimes.map((time, index) => {
    const timeIntent = intentMap[time.time.toString()];

    if (timeIntent) {
      time.intent = timeIntent;
      time.span = 1;

      if (activeTime && remainingDuration > 0) {
        activeTime.conflictType = 'nextIntent';
        activeTime.conflictDuration = remainingDuration;
      }

      remainingDuration = timeIntent.duration - interval;

      activeTime = time;
      activeIntent = timeIntent;
    } else if (remainingDuration > 0) {
      time.intent = activeIntent;
      time.continuation = true;
      remainingDuration -= interval;
      activeTime.span++;
    } else {
      time.span = 1;
      activeIntent = undefined;
      activeTime = undefined;
      remainingDuration = undefined;
    }

    // If active intent goes past end of the schedule
    if (index === newTimes.length - 1 && remainingDuration > 0) {
      activeTime.conflictType = 'scheduleEnd';
      activeTime.conflictDuration = remainingDuration;
    }

    return time;
  });
}
