import moment, { Moment } from 'moment';
import { DateTimeFormatter, LocalTime as JodaLocalTime } from 'js-joda';
import { LocalTime } from '../LocalTime';
import { TimeValue } from '../types/TimeValue';
import { ITimestamp } from '../types/ITimestamp';

const highPrecisionTimeFormat = 'HH:mm:ss.SSS';
const highPrecisionTimeFormatter = DateTimeFormatter.ofPattern(highPrecisionTimeFormat);

/**
 * Converts the `value` to a `moment` instance, using the `format` to parse the time. If not
 * specified, the format parses the time as an ISO 8601 local time.
 *
 * The `format` is the format used by `moment`. The supported pattern letters are:
 *
 * -----------------------------------------------------------------
 * | Letter | Time Segment               | Type             | e.g. |
 * -----------------------------------------------------------------
 * | H      | hour-of-day (0-23)         | number           | 0    |
 * | h      | clock-hour-of-am-pm (1-12) | number           | 12   |
 * | m      | minute-of-hour             | number           | 30   |
 * | s      | second-of-minute           | number           | 55   |
 * | S      | fraction-of-second         | fraction         | 978  |
 * | a      | am-pm-of-day               | text             | pm   |
 * | A      | AM-PM-of-day               | text             | PM   |
 * -----------------------------------------------------------------
 *
 * Other pattern letters may work, but may not be supported in the future.
 *
 * @param value  - The time value to convert
 * @param format - The format to use for the conversion
 *
 * @returns A `moment` instance of the time
 */
export function toMomentTime(value: TimeValue, format?: string): Moment | undefined {
  if (!value) {
    return undefined;
  }

  if (value instanceof moment) {
    return value as Moment;
  }

  // HACK: To support the new app `LocalTime` class until we can migrate to it. [twl 6.Feb.18]
  if (value instanceof LocalTime) {
    value = (value as LocalTime).toJodaLocalTime();
  }

  if (value instanceof JodaLocalTime) {
    value = value.format(highPrecisionTimeFormatter);
    format = highPrecisionTimeFormat;
  }

  return moment(value.toString(), format);
}
