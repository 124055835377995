import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
});

const FullWidthLayout = ({ classes, className, children }) => (
  <div className={classNames(classes.root, className)}>
    {children}
  </div>
)

export default withStyles(styles)(FullWidthLayout);
