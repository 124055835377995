import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Button from 'components/library/ButtonMui';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import Tooltip from 'components/library/Tooltip';
import Spinner from 'components/library/Spinner';
import withStyles from 'helpers/withStyles';

const spinnerSize = 28;

const styles = {
  root: {
    fontSize: '1em',
  },
  spinner: {
    color: 'inherit',
    marginRight: '0.5em',
    padding: 4,                              // HACK: Magic number to make size work [twl 23.Jun.20]
  },
  iconSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'inherit',
    marginTop: -(spinnerSize / 2),
    marginLeft: -(spinnerSize / 2),
  },
};

/**
 * Renders a button that, when pressed, displays a spinner until the `onClick` handler resolves or
 * rejects.
 */
const AsyncIconButton = ({
  icon,
  tooltip,
  tooltipVariant,
  className,
  disabled,
  onClick,
  children,
  classes,
  ...props
}) => {
  // NOTE: While `isMounted` is normally considered an antipattern (see
  //       https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html), it is required here
  //       because we don't actually want to cancel the async event if this component is unmounted
  //       (often because the async event changes the store state which triggers a re-render). We
  //       only want to prevent `setState` from being called here. This doesn't cause a memory leak
  //       because we're just await a single Promise that will fulfill when done. [twl 18.Sep.19]
  const isMounted = useRef(true);
  const [running, setRunning] = useState(false);
  const Component = icon ? IconButton : Button;

  async function handleClick() {
    if (!running) {
      setRunning(true);

      await onClick();

      if (isMounted.current) {
        setRunning(false);
      }
    }
  }

  useEffect(() => (
    () => { isMounted.current = false; }
  ), []);  // use an empty dependency array to ensure this only runs on the mount & umount

  const button = (
    <Component
      className={classnames(classes.root, className)}
      disabled={running || disabled}
      onClick={handleClick}
      {...props}
    >
      {icon &&
        <Icon type={icon} />
      }

      {running &&
        <Spinner className={icon ? classes.iconSpinner : classes.spinner} size={spinnerSize} />
      }

      {children}
    </Component>
  );

  return tooltip ? <Tooltip variant={tooltipVariant} title={tooltip}>{button}</Tooltip> : button;
}

export default withStyles(styles)(AsyncIconButton);
