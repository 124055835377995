import { callFunction } from 'services/server/data';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from 'services/store/settings/definitions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';
import { getReferralInfo } from 'services/store/auth/utils/getReferralInfo';

const loadPromotion = makeAsyncActionCreator({
  type: types.SET_GLOBAL_SETTINGS,
  when: firstRunOrAfterOldFailure,
  creator: async () => {
    const referralInfo = getReferralInfo();

    return {
      settings: {
        plan: {
          promotion:
            referralInfo ? await callFunction('referrals_getPromotion', referralInfo) : undefined,
        }
      }
    };
  },
  status: {
    path: collection,
    type: 'read',
    parameters: () => 'plan.promotion',
  }
});

export default loadPromotion;
