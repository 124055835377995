import React from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiChip from '@material-ui/core/Chip';
import Icon from 'components/library/Icon';

function avatar({ avatar, icon }) {
  return avatar || (icon ? <MuiAvatar><Icon type={icon} /></MuiAvatar> : undefined);
}

const Chip = props => <MuiChip {...props} avatar={avatar(props)} />;

Chip.muiName = 'Chip';

export default Chip;
