import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Box from 'components/library/Box';
import getDisplayName from 'helpers/utils/getDisplayName';

/**
 * Enhances a component by wrapping a Box component around it.
 */
export default function withBox(WrappedComponent, boxProps = {}) {
  const WithBox = ({
    i18nPath,
    boxId,
    boxClassName,
    boxActions,
    boxTitle,
    noBox,
    noBoxHeader,
    ...props
  }) => (noBox ?
    <WrappedComponent i18nPath={i18nPath} {...props} />
    :
    <Box
      {...boxProps}
      id={boxId}
      noHeader={noBoxHeader}
      className={boxClassName}
      actions={boxActions}
      title={boxTitle}
      i18nPath={i18nPath || boxProps.i18nPath} >

      <WrappedComponent i18nPath={i18nPath} {...props} />

    </Box>
  );

  hoistNonReactStatics(WithBox, WrappedComponent);

  WithBox.displayName = `WithBox(${getDisplayName(WrappedComponent)})`;

  return WithBox;
}
