import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName, setDisplayName, wrapDisplayName } from 'recompose';

/**
 * Configures a wrapper component using properties from the wrapped component, including setting
 * the display name and hoisting non-React statics from the wrapped component.
 *
 * This function can be called with 2 or 3 parameters. If called with 2 parameters, the base display
 * name of the wrapping component will be derived from the component itself; otherwise the base
 * display name of the wrapping component should be passed as the 2nd parameter.
 *
 * For example, using 2 parameters:
 *
 * ```
 * const WithMemos = props => <WrappedComponent {...props} memos={memos} />;
 *
 * return configureWrappedComponent(WrappedComponent, WithMemos);
 * ```
 *
 * And using 3 parameters:
 *
 * ```
 * return configureWrappedComponent(WrappedComponent, 'WithMemos',
 *                                  props => <WrappedComponent {...props} memos={memos} />);
 * ```
 *
 * @param {React.Component}        component            the wrapped component
 * @param {React.Component|string} wrapperOrWrapperName the wrapping component or the name to use
 *                                                      for the wrapping component
 * @param {React.Component|string} wrapper              the wrapping component if the 2nd parameter
 *                                                      is the name of the wrapping component
 *
 * @return {React.Component} The same wrapper component passed in, with additional static properties
 */
export default function configureWrappedComponent(component, ...args) {
  const wrapper = args.length > 1 ? args[1] : args[0];
  const wrapperName = args.length > 1 ? args[0] : getDisplayName(wrapper);

  hoistNonReactStatics(wrapper, component);
  setDisplayName(wrapDisplayName(component, wrapperName))(wrapper);

  return wrapper;
}
