import { LocalDate, ChronoUnit } from 'js-joda';
import { toLocalDate } from '../convert/toLocalDate';
import { DateValue } from '../types/DateValue';

/**
 * Calculates the number of days between two dates.
 *
 * @param dateValue1 - The first date value
 * @param dateValue2 - The second date value. Defaults to today if not provided
 *
 * @returns The number of days between `dateValue1` and `dateValue2`, or `-1` if either of the date
 *          values could not be parsed.
 */
export function daysBetween(dateValue1: DateValue, dateValue2?: DateValue): number {
  const date1 = toLocalDate(dateValue1);
  const date2 = dateValue2 ? toLocalDate(dateValue2) : LocalDate.now();

  return date1 && date2 ? Math.abs(date1.until(date2, ChronoUnit.DAYS)) : -1;
}
