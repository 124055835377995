import get from 'lodash/get';
import React from 'react';
import { objectMapToArray } from '@lifetools/shared-utils';
import ItemList from 'components/library/ItemList';
import { buildScene } from 'scenes/utils';
import syncAccountInfo from 'services/store/integrations/calendars/actions/syncAccountInfo';
import revokeProfile from 'services/store/integrations/calendars/actions/revokeProfile';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateCalendarSettings from 'services/store/settings/actions/updateCalendarSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import selectUserSettingsStatus from 'services/store/settings/selectors/selectUserSettingsStatus';
import { sort } from 'utils/sort';
import ProfileList from './components/ProfileList';

async function loadData() {
  const { settingsStatus, doLoadSettings, doSyncAccountInfo } = this.props;

  if (!settingsStatus || settingsStatus.state !== 'success') {
    doLoadSettings();
  } else {
    doSyncAccountInfo();
  }
}

function getCalendarObjects(userSettings, path) {
  const integration = get(userSettings, 'calendars.config.integration', 'gcalendar');

  const objects = objectMapToArray(get(userSettings, `calendars.${path}`, {})).filter(
    object => object.integration === integration &&
    !['deleted', 'revoked'].includes(get(object, 'sync.state'))
  );

  return sort(objects, 'label');
}

const CalendarList = buildScene(({
  noItems,
  integration,
  profiles,
  infos,
  doRevokeProfile,
  doUpdateCalendar,
  t,
}) => (
  profiles.length === 0 ? (noItems || null) :
    <ItemList
      items={profiles}
      component={ProfileList}
      componentProps={{
        useProviders: integration === 'cronofy',
        calendars: infos,
        onRevokeProfile: doRevokeProfile,
        onUpdateCalendar: doUpdateCalendar,
      }}
    />
), {
  state: state => {
    const userSettings = selectUserSettings(state);
    const profiles =  getCalendarObjects(userSettings, 'profiles');

    return {
      integration: get(userSettings, 'calendars.config.integration', 'gcalendar'),
      settingsStatus: selectUserSettingsStatus(state, 'read'),
      profiles: profiles.filter(profile => (
        !['revoked', 'deleted'].includes(get(profile, 'connection.state'))
      )),
      infos: getCalendarObjects(userSettings, 'infos'),
    };
  },
  dispatch: {
    doLoadSettings: loadSettings,
    doRevokeProfile: revokeProfile,
    doSyncAccountInfo: syncAccountInfo,
    doUpdateCalendar: updateCalendarSettings,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default CalendarList;
