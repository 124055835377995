import mapKeys from 'lodash/mapKeys';
import { FirebaseApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import routeTracker from '@lifetools/react-router-tracker';
import { IAnalyticsService } from '@lifetools/shared-services';
import analyticsService from 'services/analytics';

export function initializeAnalytics(firebaseApp: FirebaseApp): IAnalyticsService {
  const analytics = getAnalytics(firebaseApp);

  routeTracker.onRouteChange = (path, params, props, location) => {
    if (!params || Object.keys(params).length === 0) {
      params = props.computedMatch.params;
    }

    const screenName = `${path}${location?.hash}`;
    const prefixedParams = mapKeys(params, (value, key) => `path_${key}`);

    analyticsService.visit(screenName, prefixedParams);
  }

  return {
    logEvent: logEvent.bind(null, analytics),
    setUserId: setUserId.bind(null, analytics),
    setUserProperties: setUserProperties.bind(null, analytics),
  }
}
