/**
 * Retrieves the value of the cookie with the `name`, if set; otherwise returns
 * `null`.
 *
 * @param name - The name of the cookie to retrieve
 *
 * @returns The value of the cookie or `null` if the cookie could not be found
 */
export function getCookieValue(name: string): string | null {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const [ key, value ] = cookie.split('=');

    if (key.trim() === name) {
      return decodeURIComponent(value);
    }
  }

  return null;
}
