import React from 'react';
import { compose } from 'recompose';
import ActionMenuButton from 'components/app/todos/ActionMenuButton';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing.unit / 2,
    alignItems: 'center',

    '& i': {
      fontSize: '1.5rem',
      marginRight: theme.spacing.unit,
    },

    'label + &': {
      marginTop: theme.spacing.unit * 2.5,        // extra .5 is to mimic extra margin with Input
    },
  },
  button: {
    padding: 0,
  },
  buttonMenu: {
    marginLeft: -theme.spacing.unit,
    paddingRight: 0,
  },
});

// TODO: Once the status menu is the default for all status buttons, remove the `actionMode` prop
//       below and in any component that calls this component. [twl 24.Mar.20]
const StatusSelector = ({ todo, intent, actionMode, onStatusChange, classes, t }) => {
  return (
    <div className={classes.root}>
      <ActionMenuButton
        className={onStatusChange ? classes.buttonMenu : classes.button}
        actionMode={onStatusChange ? actionMode : 'statusReadOnly'}
        todo={todo}
        intent={intent}
        onStatusChange={onStatusChange}
      />
      <Typography variant="body2">
        {t(
          `data.${intent ? 'intents' : 'todos'}.fields.` +
          `status.options.${intent ? intent.status.state : todo.status.state}.label`
        )}
      </Typography>
    </div>
  );
}

export default compose(
  withStyles(styles),
  withTranslations,
)(StatusSelector);
