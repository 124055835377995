import { services } from '@lifetools/shared-services';

/**
 * Returns the active auth provider registered for the email, or `undefined` if the email has no
 * account on this app.
 *
 * @param email - An email address
 *
 * @returns The provider ID of the active auth provider for the email
 */
export const getActiveProvider = async (email) => {
  const providers = await services.auth.api.fetchSignInMethodsForEmail(email);

  // To prevent Google taking over a password account always give preference to `password`
  return providers?.includes('password') ? 'password' : providers?.[0];
}
