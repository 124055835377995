import { IPlan } from '@lifetools/shared-app-data';
import i18n from '../../../services/i18n';

const i18nPath = '*.plans';

const t = (childPath: string, variables?: object): string => (
  i18n.t(`${i18nPath}.${childPath}`, variables)
);

/**
 * Returns the title of the plan, either specified in the plan or computed from the plan properties.
 *
 * @param plan - A plan
 *
 * @returns The title of the plan
 */
export function getPlanTitle(plan: IPlan): string {
  switch (plan.type) {
    case 'free':
      return t('types.free');

    case 'paid':
      const subscription = plan.subscription;
      const price = subscription && subscription.price;
      const tierTitle = subscription && subscription.title;
      const priceTitle = price && (price.title || t(`intervals.${price.interval}`));

      return tierTitle && priceTitle
        ? `${tierTitle} (${priceTitle})`
        : tierTitle || priceTitle || t('types.paid');;

    default:
      const trial = plan.trial;

      return trial
        ? trial.title ||
          (trial.length ? t('types.trial-limited', { days: trial.length }) : t('types.trial'))
        : t('types.unknown');
  }
}
