import getValue from 'lodash/get';
import hasKey from 'lodash/has';
import { IDocumentData } from '../documents/types/IDocumentData';
import { Id } from '../documents/types/Id';
import DocumentChanges from './DocumentChanges';

type GetDataFunction = (id?: string) => IDocumentData | undefined;

/**
 * A snapshot of a document at a point in time.
 */
class DocumentSnapshot {
  /**
   * The ID of the document this snapshot is for.
   */
  public id?: Id;

  /**
   * True if the document already exists.
   */
  public exists: boolean;

  /**
   * A cached version of the data generated by `getData`.
   */
  private cachedData?: IDocumentData;

  /**
   * Retrieves all fields in the document as an object. Returns 'undefined' if the document
   * doesn't exist.
   */
  private getData: GetDataFunction;

  /**
   * Constructs a new snapshot.
   *
   * @param id - The ID of the snapshot, or `undefined` if the document has not been created yet
   * @param exists - True if the document already exists
   * @param getData - A function that returns the data for this snapshot when passed the `id`
   * @param dataChanges - The changes to monitor to determine when to call `getData`
   */
  constructor(
    id: Id | undefined,
    exists: boolean,
    getData: GetDataFunction,
    dataChanges?: DocumentChanges,
  ) {
    this.id = id;
    this.exists = exists;
    this.getData = getData;

    if (dataChanges) {
      dataChanges.onChange = () => this.cachedData = undefined;
    }
  }

  /**
   * Returns the data for this snapshot, or `undefined` if this snapshot has no data.
   */
  public data(): IDocumentData | undefined {
    if (typeof this.cachedData === 'undefined') {
      this.cachedData = this.getData(this.id);
    }

    return this.cachedData;
  }

  /**
   * Returns the value at the specified property path.
   *
   * @param path - The path to lookup the value
   *
   * @returns The value at the path for the data of this snapshot
   */
  public get(path: string): any {
    return getValue(this.data(), path);
  }

  /**
   * Returns true if a key exists at the `path` in this snapshot.
   *
   * @param path - The path to check
   *
   * @returns `true` if the path exists in this snapshot; `false` otherwise
   */
  public has(path: string): any {
    return hasKey(this.data(), path);
  }
}

export default DocumentSnapshot;
