import React from 'react';
import classNames from 'classnames';
import Tooltip from 'components/library/Tooltip';
import { parseIconType } from 'utils/app/parseIconType';

/**
 * Renders an icon from the Font Awesome icon library.
 *
 * # Props
 *
 * type
 * : The name of the icon to render. To render a Font Awesome icon, prefix with the namespace prefix
 *   of the icons you want to render and a colon (e.g. `fas:`, `fab:`). For instance, to display a
 *   star from the Font Awesome solid collection use `fas:star`.
 *
 *   Use the special namespace `app` to lookup the icon to render in the translation table. For
 *   instance `app:create` will render whatever icon type is specified at `icons.create`.
 *
 * tooltip
 * : The tooltip to display when the user hovers over this icon.
 *
 * tooltipVariant
 * : The style of tooltip to display.
 *
 * className
 * : The class name to use for this icon.
 *
 * style
 * : A `style` attribute to use for this icon. Use this to change the color, margins, etc.
 *
 * onClick
 * : A function to call when the icon is clicked.
 */
const Icon = ({ type, tooltip, tooltipVariant, className, onClick, style }) => {
  const info = parseIconType(type);
  const icon = (
    <i
      className={classNames(`${info.namespace} fa-${info.icon}`, className)}
      style={style}
      onClick={onClick} />
  );

  return tooltip ? <Tooltip variant={tooltipVariant} title={tooltip}>{icon}</Tooltip> : icon;
};

export default Icon;
