import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MuiPopper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

const sameWidth = {
  enabled: true,
  order: 350,              // after `preventOverflow.` modifier
  fn: data => ({
    ...data,
    styles: {
      ...data.styles,
      width: `${data.instance.reference.offsetWidth}px`,
    },
  }),
};

const Popper = ({ PaperProps, matchWidth, onClose, children, ...props }) => (
  <MuiPopper
    {...props}
    modifiers={{
      ...props.modifiers,
      ...matchWidth && {
        sameWidth
      },
    }}
  >
    <ClickAwayListener onClickAway={onClose}>
      <Paper {...PaperProps}>
        {children}
      </Paper>
    </ClickAwayListener>
  </MuiPopper>
);

export default Popper;
