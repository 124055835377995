import { services } from '@lifetools/shared-services';
import { mergePathKeys } from '@lifetools/shared-utils';
import { resolveServerTimestamps } from '@lifetools/shared-utils-time';
import { callTriggers } from '../triggers/callTriggers';
import { IDocumentData } from './types/IDocumentData';
import { Id } from './types/Id';
import { toDatabaseJson } from './utils/toDatabaseJson';
import { getDocument } from './getDocument';

/**
 * Updates the document in the `collection` whose ID is `id` with the new `values`.
 *
 * @param collection  - The name of the collection the document belongs to
 * @param id          - The ID of the document
 * @param values      - The values to update; any `undefined` values will be deleted
 * @param forceCreate - Whether the document should be created if it doesn't exist. Defaults to
 *                      `false`.
 *
 * @returns the values sent in the update, including any changes made by triggers
 */
export async function updateDocument(
  collection: string,
  id: Id,
  values: IDocumentData,
  forceCreate: boolean = false,
): Promise<IDocumentData> {
  // TODO: Assert document has an `id` [twl 15.Jun.18]

  // TODO: Make `before` load from the local store (maybe use `getLocalDocument`) instead of loading
  //       from the remote database each time. Though this may be irrelevant due to Firebase caching
  //       [twl 12.Apr.19]

  const docRef = services.database.collection(collection).doc(id);
  const before = await getDocument(collection, id, { noSubscription: true });
  const action = before || !forceCreate ? 'update' : 'create';
  const toSave = await callTriggers('before', action, collection, id, values, before);

  if (!toSave) {
    // TODO: Maybe just abort here and return `undefined` [twl 11.Apr.19]
    throw new Error(`The 'before' triggers for '${action}' returned no values`);
  }

  if (action === 'update') {
    await docRef.update(toDatabaseJson(toSave));
  } else {
    // If no document existed before, this is a create operation, not an update
    await docRef.set(toDatabaseJson(mergePathKeys({}, toSave), !!before));
  }

  return resolveServerTimestamps(toSave);
}
