import firebase from 'firebase/compat/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import events from '../events';
import { checkForAuthenticationResult } from '../utils/checkForAuthenticationResult';
import { parseFirebaseAuthUser } from '../utils/parseFirebaseUser';
import { getCurrentUser, logout, setCurrentUser } from '../auth';

// Add bindings to `firebase` import
import 'firebase/compat/firestore';

let currentAuthChangeId = 0;

export function initializeFirebase(config) {
  const firebaseApp = firebase.initializeApp(config.firebase);
  const auth = getAuth(firebaseApp);

  // NOTE: `enablePersistance` was causing problems in Safari when I tested it in `firebase` 5.8.3.
  //       Upgrade to a newer version of `firebase` before exploring this further. [twl 18.Apr.20]
  //
  // NOTE: `experimentalTabSynchronization` was upgraded to `synchronizeTabs` in `firebase` 6.0.0
  //       [twl 23.Jun.20]
  //
  // firebase.firestore().enablePersistence({
  //   synchronizeTabs: true,
  // }).then(function() {
  //   console.log('init done');
  // }).catch(function(err){
  //   console.log('init fail', err);
  // });

  // There can only be one logged in user at a time, so this is a singleton
  // HACK: See note in `onAuthStateChanged` below for why I'm using `setCurrentUser` [twl 30.Oct.19]
  setCurrentUser(auth.currentUser);

  async function fireEvents(authChangeId, action, user) {
    const authType = user && !user.isAnonymous ? 'Authenticated' : 'Anonymous';
    const actionType = action === 'set' ? 'Set' : 'Unset';
    const eventType = `on${authType}User${actionType}`;
    const authInfo = parseFirebaseAuthUser(user);

    for (const event of events[eventType]) {
      if (currentAuthChangeId !== authChangeId) {
        console.log(`Aborting '${eventType}' before calling '${event.name}' because auth state` +
                    ` changed while firing the event.`);
        break;
      }

      try {
        await event(authInfo);
      } catch (e) {
        if (currentAuthChangeId === authChangeId) {
          throw e;
        }

        console.log(`Aborting '${eventType}' and ignoring error because auth state changed while` +
                    ` running '${event.name}'. Error was ${e}`);
        break;
      }
    }
  }

  onAuthStateChanged(auth, async function(authUser) {
    try {
      const authChangeId = ++currentAuthChangeId;
      const authenticationResult = await checkForAuthenticationResult(auth);

      // NOTE: When Firebase Auth denies authentication via an
      //       `auth/account-exists-with-different-credential` error (e.g., attempting to log in via
      //       Facebook when the associated email already has a password account), then no
      //       `authUser` exists here and falling through allows the anonymous events to be fired.
      //       However, when we deny authentication (e.g., attempting to log in via Google when
      //       a Twitter account exists), then the `authUser` is set. In this case, we need to log
      //       out the user immediately and thus want to avoid firing any events with `authUser`.
      //       [tw] 9.Aug.22]
      if (authenticationResult === 'deny' && authUser) {
        await logout();

        return;
      }

      await fireEvents(authChangeId, 'unset', getCurrentUser());

      // HACK: `auth.currentUser` may not be set at this point, even though this event is
      //       called `onAuthStateChanged`. Because triggers fired from this observer use the
      //       current user to update the user settings, this can cause problems when `authUser` is
      //       set, but `currentUser` is null. To fix this, we need to rely *only* on the `authUser`
      //       from this observer. The code below allows this to be passed into the `auth`
      //       initialization method via a hacked `setCurrentUser` method. For more info, see
      //       https://github.com/firebase/firebase-js-sdk/issues/2306 [twl 30.Oct.19]
      setCurrentUser(authUser);

      await fireEvents(authChangeId, 'set', authUser);
    } catch (e) {
      console.error(e);

      // Set the user to an anonymous user
      setCurrentUser(parseFirebaseAuthUser(null));
    }
  });

  return [firebase, firebaseApp];
}
