import { toMomentDate } from '@lifetools/shared-utils-time';
import { formatTime } from 'utils/time';

const i18nPath = '*.time.relative';
const formatKeys = [ 'sameDay', 'nextDay', 'nextWeek', 'lastDay', 'lastWeek', 'sameElse' ];

let formats = undefined;

const calendarFormats = t => {
  if (!formats) {
    formats = formatKeys.reduce((formats, key) => {
      formats[key] = t(i18nPath, key);

      return formats;
    }, {});
  }

  return formats;
};

/**
 * Formats the date & time specified either entirely by `datetime` or by `datetime` and `time` into
 * a human-readable string that uses relative time words like "Today" and "Next Thursday".
 *
 * If `time` is `undefined`, the time from the `datetime` is used. If `time` is null, then no time
 * is included in the formatted string.
 *
 * @param {moment|LocalDate|Date|string}  datetime  A date and optional time
 * @param {LocalTime}                     time      A time to use instead of the one from `datetime`
 * @param {Function}                      t         The function used for looking up translations
 *
 * @return {string} A formatted string containing the relative date and time
 */
function formatRelativeDateTime(datetime, time, t) {
  const datePart = toMomentDate(datetime).calendar(null, calendarFormats(t));
  const timePart = typeof time !== 'undefined' ? (time ? formatTime(time) : '')
                                               : formatTime(datetime);

  return timePart ? t(i18nPath, 'dateTimeFormat', { date: datePart, time: timePart }) : datePart;
}

export default formatRelativeDateTime;
