import get from 'lodash/get';
import formatValue from 'utils/string/formatValue';
import { fromNow, getDuration, formatTimeRange } from 'utils/time';
import formatDuration from 'utils/time/formatDuration';
import formatRelativeDateTime from 'utils/time/formatRelativeDateTime';
import parseRepeatType from './parseRepeatType';

const fieldsI18nPath = 'data.todos.fields';
const timeI18nPath = '*.time';

const timeRangeInfoText = (value, t) => {
  const timeRange = todoText(value, 'timeRange', t);
  const duration = todoText(value, 'timeRangeDuration', t, ' (', ')');

  return timeRange != null || duration != null ? `${timeRange || ''}${duration || ''}` : undefined;
}

/**
 * Parses a name into the base property and its variant.
 *
 * @param {string} name A name of a virtual property used for presentation
 *
 * @return {object} An object containing the `property` and `variant` of the name; if no variant is
 *                  provided `normal` is returned as the variant
 */
function parseName(name) {
  if (name.indexOf(':') > 0) {
    const [ property, variant ] = name.split(':');

    return {
      property,
      variant,
    };
  } else {
    return {
      property: name,
      variant: 'normal',
    }
  }
}

/**
 * Returns a formatted string for a property or virtual property of the `value`.
 *
 * NOTE: I decided to overload the `name` parameter with the variant for two reasons: 1) I want to
 *       avoid an object options syntax for this function, since that gets hard to read when using
 *       string templates, and 2) the `name` is really presentation name, not just a property name,
 *       so I wanted to be thinking about it from the presentation aspect. That's why there's also
 *       virtual properties supported. You can think of the variant as just an implementation detail
 *       of the virtual properties (i.e. `duration:expanded` is just a virtual property of
 *       `duration`). [twl 14.Dec.18]
 *
 * @param {object}   value        A todo object
 * @param {string}   name         The name of the property or virtual property, with an optional
 *                                variant appended at the end as `:${variant}`
 * @param {Function} t            The function used for looking up translations
 * @param {string}   prefix       Optional string to prepend to a non-empty formatted value
 * @param {string}   postfix      Optional string to append to a non-empty formatted value
 * @param {string}   defaultValue The value to use if `value` is null or undefined
 *
 * @return {string} A formatted version of the property
 */
function todoText(value, name, t, prefix, postfix, defaultValue) {
  const { property, variant } = parseName(name);

  switch (property) {
    case 'createdAt':
      const createdAt = value.createdAt;

      // TODO: Figure out a way to pass localization into `fromNow`, here and elsewhere this is used
      //       in this file [twl 14.Dec.18]
      return formatValue(createdAt && fromNow(createdAt), prefix, postfix, defaultValue);

    case 'defaultDuration':
      return formatValue(formatDuration(value.defaultDuration, variant, t), prefix, postfix,
                         defaultValue);

    case 'duration':
      return formatValue(formatDuration(value.duration, variant, t), prefix, postfix, defaultValue);

    case 'hide':
      const hide = get(value, 'status.hide');
      const hideIndefinitely = hide.date === '';
      const hideUntil = !hideIndefinitely && hide.date != null;

      if (hideUntil) {
        prefix = (prefix || '') + t(fieldsI18nPath, 'hide.prefix-until');
        value = formatRelativeDateTime(hide.date, null, t);
      } else if (hideIndefinitely) {
        value = t(fieldsI18nPath, 'hide.value-indefinite');
      } else {
        value = undefined;
      }

      return formatValue(
        value,
        prefix,
        postfix,
        defaultValue,
      );

    case 'repeatInfo':
      let repeatType = parseRepeatType(value.repeat);
      let repeatDays = repeatType === 'specificDays'
        ? value.repeat.weekly.map(day => t(timeI18nPath, `days-of-week.${day}`))
        : undefined;

      if (repeatDays) {
        if (repeatDays.length > 0) {
          repeatType = repeatType + '-listed';
          repeatDays = repeatDays.length > 1
            ? repeatDays.slice(0, -1).join(', ') + ' & ' + repeatDays[repeatDays.length - 1]
            : repeatDays[0];
        } else {
          repeatType = 'never';
          repeatDays = undefined;
        }
      }

      return formatValue(value.repeat &&
                         t(fieldsI18nPath, 'repeatType.options.' + repeatType, { repeatDays }),
                         prefix, postfix, defaultValue);

    case 'statusAt':
      const statusAt = value.status && value.status.at;

      return formatValue(statusAt && fromNow(statusAt), prefix, postfix, defaultValue);

    case 'startDate':
      return formatValue(value.start && formatRelativeDateTime(value.start, null, t), prefix,
                         postfix, defaultValue);

    case 'startDateAndTimeRange':
      const date = todoText(value, 'startDate', t);
      const timeRange = todoText(value, 'timeRange');

      return formatValue(
        date && timeRange
          ? t(timeI18nPath + '.relative', 'dateTimeRangeFormat', { date, timeRange })
          : (date || timeRange),
        prefix,
        postfix,
        defaultValue,
      );

    case 'timeRange':
      return formatValue(value.start && formatTimeRange(value.start, value.end, value.duration),
                         prefix, postfix, defaultValue);

    case 'timeRangeDuration':
      return formatValue(formatDuration(getDuration(value.start, value.end), variant, t), prefix,
                         postfix, defaultValue);

    case 'timeRangeInfo':
      return formatValue(timeRangeInfoText(value, t), prefix, postfix, defaultValue);

    case 'title':
      return formatValue(value[name], prefix, postfix, defaultValue ||
                                                       t(fieldsI18nPath, 'title.missing'));

    default:
      return formatValue(value[name], prefix, postfix, defaultValue);
  }
}

export default todoText;
