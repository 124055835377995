import React from 'react';
import TodoList from 'components/app/todos/TodoList';
import createTodo from 'services/store/todos/actions/createTodo';
import deleteTodo from 'services/store/todos/actions/deleteTodo';
import queryTodos from 'services/store/todos/actions/queryTodos';
import updateTodo from 'services/store/todos/actions/updateTodo';
import updateTodoStatus from 'services/store/todos/actions/updateTodoStatus';
import selectTodosByQuery from 'services/store/todos/selectors/selectTodosByQuery';
import { buildScene } from 'scenes/utils';
import ensureArray from 'utils/array/ensureArray';

function loadData() {
  // TODO: Think about renaming the prop to `queries`. [twl 12.Oct.18]
  const { query, doQueryTodos } = this.props;

  ensureArray(query).map(singleQuery => doQueryTodos(singleQuery));
}

/**
 * Wraps the `TodoList` component and provides default values for some props.
 *
 * # Props
 *
 * query
 * : An object query used to select the `todos` for this list
 *
 * filter
 * : A function used to filter the todos for this list. The function should be in the form `(todo)`
 *   and return true if the todo should remain in the list
 *
 * # Overloaded Props
 *
 * todos
 * : If not specified, loaded from the store via the `query` prop
 *
 * onCreate
 * : If not specified, connected to `createTodo` from the `todos` store
 *
 * onUpdate
 * : If not specified, connected to `updateTodo` from the `todos` store
 *
 * onDelete
 * : If not specified, connected to `deleteTodo` from the `todos` store
 *
 * onStatusChange
 * : If not specified, connected to `updateTodoStatus` from the `todos` store
 *
 * All other props are passed through unchanged.
 */
const TodoListWidget = buildScene(({
  todos,
  onCreate,
  onUpdate,
  onDelete,
  onStatusChange,
  doCreateTodo,
  doUpdateTodo,
  doDeleteTodo,
  doUpdateTodoStatus,
  ...props
}) => (
  <TodoList
    {...props}
    todos={todos}
    onCreate={onCreate || doCreateTodo}
    onUpdate={onUpdate || doUpdateTodo}
    onDelete={onDelete || doDeleteTodo}
    onStatusChange={onStatusChange || doUpdateTodoStatus}
  />
), {
  memos: {
    // TODO: Figure out how to memoize this properly. Every time `state` changes, which is
    //       frequently, this function recalculates. I suspect we'll have to go lower in the state
    //       hierarchy to create a proper memoization (e.g. `state.todos.data`). [twl 17.Mar.20]
    calculateTodos: (state, query, filter) => {
      const todos = selectTodosByQuery(state, query);

      return filter ? todos.filter(filter) : todos;
    },
  },
  state: (state, { todos, query, filter, memos }) => ({
    todos: typeof todos !== 'undefined' ? todos : memos.calculateTodos(state, query, filter),
  }),
  dispatch: {
    doQueryTodos: queryTodos,
    doCreateTodo: createTodo,
    doUpdateTodo: updateTodo,
    doDeleteTodo: deleteTodo,
    doUpdateTodoStatus: (status, task) => updateTodoStatus(task, status),
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default TodoListWidget;
