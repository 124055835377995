import React from 'react';
import Typography from 'components/library/Typography';

const SettingsField = ({ label, value }) => (
  <div>
    <Typography variant="subtitle2">
      {label}
    </Typography>

    <Typography variant="body2">
      {value}
    </Typography>
  </div>
);

export default SettingsField;
