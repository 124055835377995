import has from 'lodash/has';
import { getParentPath } from './getParentPath';

/**
 * Returns the `path` if it exists underneath the object tree, or the nearest ancestor to the `path`
 * which has a key in the object tree.
 *
 * @param object - The root of the object tree
 * @param path   - The path to search
 *
 * @returns The nearest path that has a key (even if it's value is `undefined`) that leads to the
 *          `path`, including the `path` itself if the path exists in the object tree. If no key
 *          exists, returns an empty string to represent the root of the object tree.
 */
export function getExistingPathTo(object: object, path: string) {
  while (path !== '' && !has(object, path)) {
    path = getParentPath(path);
  }

  return path;
}
