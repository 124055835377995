import { SimpleExpression } from '../../types/SimpleExpression';

const comparisonOperators = ['==', '!=', '>=', '<=', '>', '<'] as const;
const logicalOperators = ['&&', '||'] as const;

type ComparisonOperator = typeof comparisonOperators[number];
type LogicalOperator = typeof logicalOperators[number];

function doComparison(
  operator: ComparisonOperator,
  leftOperand: any,
  rightOperand: any,
): any {
  switch (operator) {
    case '>':
      return leftOperand > rightOperand;

    case '>=':
      return leftOperand >= rightOperand;

    case '<':
      return leftOperand < rightOperand;

    case '<=':
      return leftOperand <= rightOperand;

    case '==':
      // eslint-disable-next-line eqeqeq
      return leftOperand == rightOperand;

    case '!=':
      // eslint-disable-next-line eqeqeq
      return leftOperand != rightOperand;

    default:
      throw new Error(`Unknown comparison operator: ${operator}`);
  }
}

function doLogicalOperation(
  operator: LogicalOperator,
  operands: any[],
): any {
  let leftOperand = operands[0];

  for (let i = 1; i < operands.length; i++) {
    const rightOperand = operands[i];

    switch (operator) {
      case '&&':
        leftOperand = leftOperand && rightOperand;
        break;

      case '||':
        leftOperand = leftOperand || rightOperand;
        break;

      default:
        throw new Error(`Unknown logical operator: ${operator}`);
    }
  }

  return leftOperand;
}

function doOperation(
  operator: ComparisonOperator | LogicalOperator | '!',
  operands: any[],
): any {
  if (operator === '!') {
    return !operands[0];
  } else if (operator === '&&' || operator === '||') {
    return doLogicalOperation(operator, operands);
  } else if (operands.length === 2 && comparisonOperators.includes(operator)) {
    return doComparison(operator, operands[0], operands[1]);
  } else {
    throw new Error(`Invalid number of operands or unknown operator: ${operator}`);
  }
}

export function evaluateSimpleExpression(
  expression: SimpleExpression,
  variableResolver: (name: string) => any,
): any {
  if (expression == null || typeof expression !== 'object') {
    return expression;
  }

  if (expression.type === 'operation') {
    const operands = expression.operands.map(
      operand => evaluateSimpleExpression(operand, variableResolver)
    );

    return doOperation(expression.operator, operands);
  } else if (expression.type === 'variable') {
    return variableResolver(expression.name);
  } else {
    return expression;
  }
}
