import selectSchedules from './selectSchedules';

const findScheduleByDate = (schedules, date) => (
  schedules && schedules.find(item => date.equals(item.date))
);

const selectScheduleByDate = (state, date) => (
  date && findScheduleByDate(selectSchedules(state), date)
);

export default selectScheduleByDate;
