import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/library/Typography';
import isVariant from 'components/utils/isVariant';
import withTranslations from 'helpers/withTranslations';
import TodoItemVariantType from 'types/TodoItemVariant';
import todoText from 'utils/todos/todoText';

const AppointmentInfo = ({ value, variant, t }) => {
  const timeRangeInfo = !isVariant(variant, 'schedule:scheduling', 'schedule:scheduled')
                        && todoText(value, 'timeRangeInfo', t);

  return (isVariant(variant, 'schedule:scheduled') ? null :
    <Fragment>
      {timeRangeInfo &&
        <Typography variant="caption" color="textSecondary" component="span">
          {timeRangeInfo}
        </Typography>
      }

      {!isVariant(variant, 'schedule:scheduling', 'schedule:scheduled') &&
        <Typography variant="caption" color="textSecondary" noWrap>
          {todoText(value, 'location', t)}
        </Typography>
      }
    </Fragment>
  );
};

AppointmentInfo.propTypes = {
  /** The todo object to render */
  value: PropTypes.shape({
    duration: PropTypes.number,
    end: PropTypes.string,
    location: PropTypes.string,
    start: PropTypes.string,
  }),

  /** The variant to use */
  variant: TodoItemVariantType,

  /** A function used to provide translations of text. Provided by `withTranslations`. */
  t: PropTypes.func.isRequired,
};

export default withTranslations(AppointmentInfo);
