import React from 'react';
import classnames from 'classnames';
import WorkflowPromptCard from 'components/app/schedules/WorkflowPromptCard';
import Box from 'components/library/Box';
import Typography from 'components/library/Typography';
import withDeviceInfo from 'helpers/withDeviceInfo';
import { buildScene } from 'scenes/utils';
import addSchedule from 'services/store/schedules/actions/addSchedule';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';

const NoTodayBase = buildScene(({
  i18nPath,
  scheduleType,
  boxId,
  boxClassName,
  date,
  schedule,
  isPlanEditable = true,
  newMobileUi,
  doAddSchedule,
  classes,
  isMobileDevice,
  isAccountReadOnly,
  t
}) => (
  <Box
    id={boxId}
    i18nPath={i18nPath}
    className={boxClassName}
    actions={!isMobileDevice && !isAccountReadOnly && isPlanEditable && [
      <StartWorkflowButton
        variant="actionBar"
        workflow={scheduleType}
        date={date}
        existingSchedule={schedule}
      />,
    ]}>

    <div className={classes.root}>
      <div className={classnames(classes.content, newMobileUi && classes.contentNewMobileUi)}>
        <Typography className={classes.message} variant="h2">
          {t(i18nPath, 'message')}
        </Typography>

        {isPlanEditable &&
          <WorkflowPromptCard
            className={classes.prompt}
            date={date}
            workflow={scheduleType}
            existingSchedule={schedule}
          />
        }
      </div>
    </div>
  </Box>
), {
  styles: theme => ({
    root: {
      flex: '1 0 auto',
      display: 'flex',
      padding: theme.spacing.unit * 2,
    },
    content: {
      flex: '1 1 auto',
      padding: theme.spacing.unit * 4,
      alignSelf: 'center',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing.unit * 2,

        // HACK: On iOS, the root Box of this page is a scrollable pane, but if you scroll it, it
        //       cannot scroll past the chrome at the bottom of Safari, which overlaps the Start
        //       Workflow button, preventing it from being pressed. There are two scrollable panes,
        //       so it is possible, with a bunch of effort, to scroll the outer pane, which contains
        //       the carousel itself, but that's a bug I want to fix. Regardless, setting this to a
        //       large enough size that it allows scrolling past the Safari chrome makes this
        //       component usable in Safari when we're not in PWA mode. [twl 11.Dec.20]
        marginBottom: theme.spacing.unit * 16,
      }
    },
    contentNewMobileUi: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      }
    },
    message: {
      lineHeight: 2,
    },
    prompt: {
      marginTop: theme.spacing.unit * 6,
    },
  }),
  context: 'isAccountReadOnly',
  dispatch: {
    doAddSchedule: addSchedule,
  },
});

export default withDeviceInfo(NoTodayBase);
