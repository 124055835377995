import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import OptionIndicator from '../OptionIndicator';

const OptionIndicators = ({ value, ...props }) => {
  const indicators = Array.isArray(value) ? [ ...value ] : ( value ? [ value ] : []);

  return (
    <Fragment>
      {indicators.sort().map(value => <OptionIndicator key={value} {...props} value={value} />)}
    </Fragment>
  )
};

OptionIndicators.propTypes = {
  /** The value of the property */
  value: PropTypes.any,
}

export default OptionIndicators;
