import { LocalTime } from '@lifetools/shared-utils-time';
import formatValue from 'utils/string/formatValue';
import { formatTime } from 'utils/time';
import formatRelativeDateTime from 'utils/time/formatRelativeDateTime';
import todoText from 'utils/todos/todoText';

const fieldsI18nPath = 'data.intents.fields';

/**
 * Returns a formatted string for a property or virtual property of the `value`.
 *
 * NOTE: I decided to overload the `name` parameter with the variant for two reasons: 1) I want to
 *       avoid an object options syntax for this function, since that gets hard to read when using
 *       string templates, and 2) the `name` is really presentation name, not just a property name,
 *       so I wanted to be thinking about it from the presentation aspect. That's why there's also
 *       virtual properties supported. You can think of the variant as just an implementation detail
 *       of the virtual properties (i.e. `duration:expanded` is just a virtual property of
 *       `duration`). [twl 14.Dec.18]
 *
 * @param {object}   value        A todo object
 * @param {string}   name         The name of the property or virtual property, with an optional
 *                                variant appended at the end as `:${variant}`
 * @param {Function} t            The function used for looking up translations
 * @param {string}   prefix       Optional string to prepend to a non-empty formatted value
 * @param {string}   postfix      Optional string to append to a non-empty formatted value
 * @param {string}   defaultValue The value to use if `value` is null or undefined
 *
 * @return {string} A formatted version of the property
 */
function intentText(value, name, t, prefix, postfix, defaultValue) {
  switch (name) {

    case 'durationEndTime':
      const endTime = intentText(value, 'endTime', t);

      return formatValue(endTime && t(fieldsI18nPath, 'duration.endTimeFormat', { endTime }),
                         prefix, postfix, defaultValue);

    case 'endTime':
      const start = value.start && new LocalTime(value.start);
      const end = start && value.duration != null ? start.add(value.duration, 'minutes')
                                                  : undefined;

      return formatValue(end && formatTime(end), prefix, postfix, defaultValue);

    case 'relativeDateTime':
      return formatValue(formatRelativeDateTime(value.schedule.date, value.start || null, t),
                         prefix, postfix, defaultValue);

    default:
      return todoText(value, name, t, prefix, postfix, defaultValue);
  }
}

export default intentText;
