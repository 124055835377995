import { services } from '@lifetools/shared-services';
import sentry from '../../sentry';

/**
 * Deletes a newly created user.
 *
 * NOTE: This function may not work for an existing user, since Firebase relies on a recent
 *       authentication before allowing `delete` to execute. If the account was just created, it
 *       meets this criteria, but other accounts may not. [twl 25.Jul.22]
 */
export async function deleteNewUser(): Promise<void> {
  try {
    await services.auth.api.currentUser().delete();
  }
  catch (e) {
    sentry.captureException(e);
  }
}
