import values from 'lodash/values';
import { LocalDate } from 'js-joda';
import { ITodo } from '@lifetools/shared-app-types';
import { DateValue, daysBetween, toLocalDate } from '@lifetools/shared-utils-time';

/**
 * Returns the last intent date on or before `compareDate`.
 */
const getLastIntentDate = (todo: ITodo, compareDate: LocalDate): LocalDate | undefined => (
  !todo.intents
    ? undefined
    : values(todo.intents)
        .map(intent => intent.date)
        .filter(date => {
          const localDate = toLocalDate(date);

          return localDate && compareDate.isAfter(localDate);
        })
        .sort()
        .pop()
);

function getDaysSinceActive(date: DateValue | undefined): number {
  const daysSince = date ? daysBetween(date) : -1;

  return daysSince !== -1 ? daysSince : Number.MAX_VALUE;
}

/**
 * Returns the number of days since the todo was last active, with active being defined as being
 * committed, waking up from snooze or having a status change.
 *
 * @param todo - A todo
 *
 * @returns The number of days since the todo was last active
 */
const daysSinceActive = (todo: ITodo): number => (
  Math.min(
    getDaysSinceActive(getLastIntentDate(todo, LocalDate.now())),
    getDaysSinceActive(todo.status.at),
    getDaysSinceActive(todo.status.hide?.date)
  )
);

export default daysSinceActive;
