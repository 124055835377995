import { queryOperators } from './queryOperators';

const queryOperatorKeys = Object.keys(queryOperators);

/**
 * Returns whether the `object` contains a query operator.
 *
 * @param object - The object to test
 *
 * @returns true if the `object` contains a query operator; false otherwise.
 */
export function hasQueryOperator(object: object) {
  return object && queryOperatorKeys.find(operator => object.hasOwnProperty(operator)) != null;
}
