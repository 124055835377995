import React from 'react';
import { compose } from 'recompose';
import Link from 'components/library/Link';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nPathDefault = 'components.app.common.ListSettingsInfo';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.custom.activeSettings.background,
    borderBottom: `solid 1px ${theme.palette.grey['400']}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  group: {
    marginRight: theme.spacing.unit * 2,
  },
  groupInline: {
    marginRight: '0.25em',
  },
  reset: {
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',

    '@media print': {
      display: 'none',
    },
  },
});

const ListSettingsInfo = ({ i18nPath, settings, onReset, classes, t }) => {
  const inlinePrefix = t([i18nPath, i18nPathDefault], 'prefix');
  const filterEnabled = settings.filter && settings.filter !== 'all';
  const filterPrefix = t([i18nPath, i18nPathDefault], 'prefix-filter', { defaultValue: '' });
  const filterOption = t(i18nPath + '.filter.options', settings.filter);
  const searchEnabled = !!settings.search;
  const searchPrefix = t([i18nPath, i18nPathDefault], 'prefix-search', { defaultValue: '' });

  return !filterEnabled && !searchEnabled ? null :
    <div className={classes.root}>
      <div>
        <Typography variant="body2" inline>{inlinePrefix} </Typography>

        {filterEnabled &&
          <span className={inlinePrefix ? classes.groupInline : classes.group}>
            {filterPrefix && <Typography variant="body2" inline> {filterPrefix}</Typography>}
            {filterEnabled &&
              <Typography variant={inlinePrefix ? 'body2' : 'subtitle2'} inline>
                {filterOption}
              </Typography>
            }
          </span>
        }

        {searchEnabled &&
          <span className={inlinePrefix ? classes.groupInline : classes.group}>
            {searchPrefix && <Typography variant="body2" inline>{searchPrefix}</Typography>}
            {searchEnabled &&
              <Typography variant={inlinePrefix ? 'body2' : 'subtitle2'} inline>
                {settings.search}
              </Typography>
            }
          </span>
        }
      </div>

      {onReset &&
        <Link className={classes.reset} onClick={onReset}>
          {t([i18nPath, i18nPathDefault], 'button-reset')}
        </Link>
      }
    </div>;
}

export default compose(
  withStyles(styles),
  withTranslations,
)(ListSettingsInfo);
