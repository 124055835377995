import { DefaultLogger, Logger } from '@lifetools/shared-services-logger';
import { DataService } from './types/DataService';
import { IAnalyticsService } from './types/IAnalyticsService';
import { IAuthService } from './types/IAuthService';
import { IDatabaseService } from './types/IDatabaseService';
import { IFeaturesService } from './types/IFeaturesService';
import { IFunctionsService } from './types/IFunctionsService';

class SharedServices {
  /**
   * Specifies general configuration information.
   */
  public config: { [key: string]: any } = {};

  /**
   * Configures application-specific extensions for the database service.
   */
  public data: DataService = new DataService();

  /**
   * Service for managing the current user.
   */
  public auth!: IAuthService;

  /**
   * Service for tracking user interactions with the application.
   */
  public analytics!: IAnalyticsService;

  /**
   * Service for reading and writing data to a document-based key-value database.
   */
  public database!: IDatabaseService;

  /**
   * Services for logging messages.
   */
  public logger: Logger = DefaultLogger;

  /**
   * Service for accessing Firebase Cloud Functions.
   */
  public functions!: IFunctionsService;

  /**
   * Service for accessing other Firebase functionality not exposed through one of the other
   * services. Avoid using unless necessary, and abstract into a separate service whenever possible.
   */
  public firebase: any;

  /**
   * Instance of the initialized Firebase app. Avoid using unless necessary, and abstract into a
   * separate service whenever possible.
   */
  public firebaseApp: any;

  /**
   * Service for accessing feature flag service.
   */
  public features!: IFeaturesService;

  /**
   * Initializes the database.
   *
   * TODO: Maybe type the values coming in here. [twl 16.Apr.19]
   */
  public initializeDatabase(database: any, fieldValue: any) {
    this.database = {
      batch: database.batch.bind(database),
      collection: database.collection.bind(database),
      doc: database.doc.bind(database),
      fieldValues: {
        delete: fieldValue.delete,
        serverTimestamp: fieldValue.serverTimestamp,
        increment: fieldValue.increment,
        decrement: (value: number) => fieldValue.increment(-1 * value),
        arrayUnion: fieldValue.arrayUnion,
        arrayRemove: fieldValue.arrayRemove,
      },
    };
  }
}

const sharedServices = new SharedServices();

export default sharedServices;
