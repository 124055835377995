import React from 'react';
import IntentList from 'components/app/intents/IntentList';
import createIntent from 'services/store/intents/actions/createIntent';
import removeIntent from 'services/store/intents/actions/removeIntent';
import updateIntent from 'services/store/intents/actions/updateIntent';
import updateIntentStatus from 'services/store/intents/actions/updateIntentStatus';
import selectIntentsBySchedule from 'services/store/intents/selectors/selectIntentsBySchedule';
import queryTodosBySchedule from 'services/store/todos/actions/queryTodosBySchedule';
import { buildScene } from 'scenes/utils';

function loadData() {
  const { schedule, intents, doQueryTodosBySchedule } = this.props;

  if (!intents && schedule) {
    doQueryTodosBySchedule(schedule);
  }
}

/**
 * Wraps the `IntentList` component and provides default values for some props.
 *
 * # Overloaded Props
 *
 * intents
 * : If not specified, loaded from the store using the `schedule` prop.
 *
 * * sortBy
 * : If not specified, sorts the list descending by the intent's todo's `createdAt` field.
 *
 * onCreate
 * : If not specified, connected to `createIntent` from the `intents` store.
 *
 * onRemove
 * : If not specified, connected to `removeIntent` from the `intents` store.
 *
 * onStatusChange
 * : If not specified, connected to `updateIntentStatus` from the `intents` store
 *
 * onUpdate
 * : If not specified, connected to `updateIntent` from the `intents` store.
 *
 * onDelete
 * : If not specified, connected to `removeIntent` from the `intents` store.

 * All other props are passed through unchanged.
 */
const IntentListWidget = buildScene(({
  schedule,
  intents,
  sortBy = "todo.createdAt.seconds desc",
  onCreate,
  onUpdate,
  onDelete,
  onRemove,
  onStatusChange,
  doCreateIntent,
  doUpdateIntent,
  doDeleteIntent,
  doRemoveIntent,
  doUpdateIntentStatus,
  ...props
}) => (
  <IntentList
    {...props}
    schedule={schedule}
    intents={intents}
    sortBy={sortBy}
    onCreate={onCreate || doCreateIntent}
    onUpdate={onUpdate || doUpdateIntent}
    onDelete={onDelete || doDeleteIntent}
    onRemove={onRemove || doRemoveIntent}
    onStatusChange={onStatusChange || doUpdateIntentStatus}
  />
), {
  state: (state, { schedule, intents, filter, includeArchived }) => {
    if (!intents) {
      intents = selectIntentsBySchedule(state, schedule, includeArchived);
    }

    return {
      intents: filter ? intents.filter(filter) : intents,
    };
  },
  dispatch: {
    doQueryTodosBySchedule: queryTodosBySchedule,
    doCreateIntent: createIntent,
    doUpdateIntent: updateIntent,
    doDeleteIntent: removeIntent,
    doRemoveIntent: removeIntent,
    doUpdateIntentStatus: (status, intent) => updateIntentStatus(intent, status),
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default IntentListWidget;
