import { mapValues } from 'lodash';
import { DocumentUpdates } from './types/DocumentUpdates';
import { IDocumentData } from './types/IDocumentData';
import { IDocumentReference } from './types/IDocumentReference';
import { isDeleteField } from './utils/isDeleteField';
import { isServerTimestampField } from './utils/isServerTimestampField';

/**
 * Updates the data for a document referenced by `ref` with `updates`, returning the updates with
 * any server fields resolved.
 *
 * NOTE: This may not work on the client the same as the server. For now this has been written for
 *       the server. Be careful using it in functions that are used on both or client-only.
 *       [twl 19.Sep.19]
 *
 * @param ref     - A reference to the document
 * @param updates - The updates to the document
 */
export async function updateRef(ref: IDocumentReference, updates: DocumentUpdates<IDocumentData>) {
  const writeResult = await ref.update(updates);
  // NOTE: `WriteResult` only appears to be part of the Node API. The web API returns void for this
  //       call. See https://googleapis.dev/nodejs/firestore/latest/WriteResult.html and
  //       https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference.html#update
  //       [twl 19.Sep.19]
  // @ts-ignore
  const writeTime = writeResult && writeResult.writeTime ? writeResult.writeTime : undefined;

  return mapValues(updates, (value, key) => {
    if (isServerTimestampField(value)) {
      return writeTime;
    }

    if (isDeleteField(value)) {
      return undefined;
    }

    return value;
  });
}
