import { filterArchivedDocuments } from '../../utils/selectors/filterArchivedDocuments';

/**
 * Select the `todos` data from `state`.
 *
 * @param state           - The root state of the store or, to allow memoized optimizations, the
 *                          raw data array from the store
 * @param includeArchived - Whether to include archived items
 *
 * @returns An array of todo objects
 */
function selectTodos(state, includeArchived = false) {
  const documents = state.$collection === 'todos' ? state : state.todos && state.todos.data;

  return includeArchived ? (documents || []) : filterArchivedDocuments(documents);
}

export default selectTodos;
