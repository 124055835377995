import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { LocalDate } from 'js-joda';
import DailyPlanInfo from 'components/app/schedules/DailyPlanInfo';
import Box from 'components/library/Box';
import Typography from 'components/library/Typography';
import withContext from 'helpers/withContext';
import withDeviceInfo from 'helpers/withDeviceInfo';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { formatLocalDate } from 'utils/time';

const styles = theme => ({
  noPlan: {
    margin: theme.spacing.unit * 4,
    textAlign: 'center',
  },
  prompts: {
    margin: `0 ${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px`,

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing.unit * 4,
    },
  }
});

const NoPlanView = ({
  i18nPath,
  date,
  schedule,
  activePlanType,
  isAccountReadOnly,
  isMobileDevice,
  t,
  classes,
  ...props
}) => {
  const canCreate = !date.isBefore(LocalDate.now()) && !activePlanType && !isAccountReadOnly;
  const layout = isMobileDevice ? 'compactCard' : 'listItem';

  return (
    <Box {...props} title={formatLocalDate(date, t(i18nPath, 'date-format'))}>
      <Typography className={classes.noPlan} variant="h3">
        {t(i18nPath, 'no-plan-found') + (canCreate ? '. ' + t(i18nPath, 'plan-prompt') : '')}
      </Typography>

      {canCreate &&
        <div className={classes.prompts}>
          <DailyPlanInfo
            variant="flat"
            layout={layout}
            date={date}
            workflow="commit"
            existingSchedule={schedule}
          />

          <DailyPlanInfo
            variant="flat"
            layout={layout}
            date={date}
            workflow="allocate"
            existingSchedule={schedule}
          />

          <DailyPlanInfo
            variant="flat"
            layout={layout}
            date={date}
            workflow="schedule"
            existingSchedule={schedule}
          />
        </div>
      }
    </Box>
  );
}

NoPlanView.propTypes = {
  /** The path to the translation keys for this component */
  i18nPath: PropTypes.string,

  /** The date of the schedule */
  date: PropTypes.object.isRequired,

  /** The schedule */
  schedule: PropTypes.object,

  /** Whether the user's account is set to read-only */
  isAccountReadOnly: PropTypes.bool,

  /** Whether the current device is a mobile device */
  isMobileDevice: PropTypes.bool,

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,

  /** The path to the translation keys for this component. Provided by `withStyles` HOC. */
  classes: PropTypes.object,
};

export default compose(
  withStyles(styles),
  withTranslations,
  withDeviceInfo,
  withContext('isAccountReadOnly'),
)(NoPlanView);
