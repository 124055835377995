import { queryDocuments } from '@lifetools/shared-database';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';
import { collection, statusTypes, types } from '../definitions';

const queryTodosBySchedule = makeAsyncActionCreator({
  type: types.SET_TODOS,
  when: firstRunOrAfterOldFailure,
  creator: async schedule => ({
    todos: await queryDocuments(collection, {
      scheduleIds: { contains: schedule.id }
    })
  }),
  status: {
    path: collection,
    type: statusTypes.queryTodosBySchedule,
    parameters: schedule => [ schedule.id ],
  }
});

export default queryTodosBySchedule;
