import PropTypes from 'prop-types';

export default PropTypes.oneOf([
  'summary',
  'compact',
  'normal',
  'normal:task',
  'expanded',
  'schedule:unscheduled',
  'schedule:scheduling',
  'schedule:scheduled',
]);
