import { get } from 'lodash';
import { getUserDocument } from 'services/server/data';
import store from 'services/store';
import updateSettings from 'services/store/settings/actions/updateSettings';

const remindSchedulePath = 'messages.settings.remind-schedule.enabled';

/**
 * Updates the time zone for the current user.
 *
 * TODO: Maybe rename this to `updateSettings`. At the very least, we should collect all the changes
 *       to the `settings` document and write them all at once, rather than having each event update
 *       the settings separately. [twl 21.Jun.19]
 */
async function updateTimeZone() {
  const timeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timeZone) {
    const settings = await getUserDocument('settings');
    const oldTimeZone = get(settings, 'timeZone', null);
    const updateRemindSchedule = typeof get(settings, remindSchedulePath) === 'undefined';

    if (timeZone !== oldTimeZone || updateRemindSchedule) {
      await store.dispatch(updateSettings({
        timeZone,

        // HACK: Updating the remind-schedule enabled setting in this trigger to avoid creating
        //       another trigger. If we rename this to `updateSettings`, this will make more sense.
        //       Once existing users have all been upgraded, this code will no longer be necessary,
        //       since this setting is also being set to `true` when a user registers.
        //       [twl 21.Jun.19]
        ...updateRemindSchedule && { [remindSchedulePath]: true },
      }));
    }
  }
}

export default updateTimeZone;
