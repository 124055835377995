import React from 'react';
import { services } from '@lifetools/shared-services';
import withStyles from 'helpers/withStyles';
import FederatedIdentityAuthButton from 'widgets/buttons/FederatedIdentityAuthButton';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',

    '& button': {
      flex: '1 1 40%',
      margin: `${theme.spacing.unit}px ${theme.spacing.unit}px`,
    },
  },
});

const FederatedIdentityAuthButtons = ({
  providers = services.auth.preferredProviders,
  email,
  action,
  classes
}) => (
  <div className={classes.root}>
    {providers.map(provider =>
      <FederatedIdentityAuthButton
        key={provider}
        action={action}
        provider={provider}
        email={email}
        label={services.auth.providers[provider].label}
        icon={services.auth.providers[provider].icon}
      />
    )}
  </div>
);

export default withStyles(styles)(FederatedIdentityAuthButtons);
