// TODO: Re-evaluate how we want to handle retries [twl 9.Oct.18]
//
// Retry immediately, when back off linearly based on number of retries
const shouldRetry = ({ failures, at }) => (Date.now() - at) > 1000 * (failures - 1);

const firstRunOrAfterOldFailure = status => (
  !status || (status.state === 'failure' && shouldRetry(status))
);

export default firstRunOrAfterOldFailure;
