/**
 * Returns an object that can be used as a enum based on the arguments passed in.
 *
 * TODO: Review [this method](https://medium.com/@amcdnl/enums-in-es7-804a5a01bd70) of making
 *       compile-type safe enums, or use TypeScript enums if we convert to TypeScript.
 *       [twl 15.Jun.18]
 *
 * @param  {...string} args The keys for the enum (the values will be the same as the keys)
 *
 * @return {object} An object containing one key for every argument whose value is the same as the
 *                  key
 */
function makeEnum(...args: string[]) {
  const enumObject: { [key: string]: string } = {};

  for (const key of args) {
    enumObject[key] = key;
  }

  return enumObject;
}

export default makeEnum;
