import { makeEnum } from 'services/store/utils-actions';

export const collection = 'todos';

export const types = makeEnum(
  'ADD_TODO',
  'SET_TODO',
  'SET_TODOS',
  'ARCHIVE_TODO',
  'ALLOCATE_TODO',
  'SCHEDULE_TODO',
  'UPDATE_TODO',
  'UPDATE_TODO_STATUS',
);

export const statusTypes = {
  queryTodos: 'read',
  queryTodosBySchedule: 'read',
};
