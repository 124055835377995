import { getDocument, updateDocument } from '@lifetools/shared-database';
import { services } from '@lifetools/shared-services';

const functions = {};

/**
 * Calls the server function named `name` with the `parameters`.
 *
 * @param {string} name       The name of the function
 * @param {object} parameters The parameters to pass to the function
 *
 * @return {any} The return value of the function
 */
export async function callFunction(name, parameters) {
  if (!functions[name]) {
    functions[name] = services.functions.httpsCallable(name);
  }

  const response = await functions[name](parameters);

  return response.data;
}

/**
 * Updates the document in the `collection` whose document ID matches the current user ID with the
 * new `values`.
 *
 * @param {string}  collection  The name of the collection the document belongs to
 * @param {object}  values      The values to update; any `undefined` values will be deleted
 * @param {boolean} forceCreate Whether the document should be created if it doesn't exist. Defaults
 *                              to `false`.
 *
 * @return {Promise<object>} The values sent in the update, including any changes made by triggers
 */
export async function updateUserDocument(collection, values, forceCreate) {
  return await updateDocument(collection, services.auth.userId(), values, forceCreate);
}

/**
 * Returns the document from the collection whose document ID matches the current user ID.
 *
 * @param {string} collection The name of the collection to retrieve the documents from
 *
 * @return {object} The document, or `undefined` if no document for the current user could be found
 */
export async function getUserDocument(collection) {
  return await getDocument(collection, services.auth.userId());
}
