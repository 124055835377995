import get from 'lodash/get';

const defaultGroupBy = (groupBy, item) => {
  const value = get(item, groupBy);

  return Array.isArray(value) ? value[0] : value;
};

export function groupItems(items, groupBy, defaultGroup) {
  const groups = { names: [], items: {} };
  const groupByFunc = typeof groupBy !== 'string' ? groupBy : defaultGroupBy.bind(null, groupBy);

  if (groupByFunc) {
    for (const item of items) {
      const name = groupByFunc(item) || defaultGroup;

      if (!groups.items[name]) {
        groups.names.push(name);
        groups.items[name] = [];
      }

      groups.items[name].push(item);
    }

    groups.names.sort();

    // Move default group to the end
    if (groups.names.includes(defaultGroup)) {
      groups.names.push(groups.names.splice(groups.names.indexOf(defaultGroup), 1)[0]);
    }
  }

  return groups;
}
