import React from 'react';
import { compose } from 'recompose';
import Link from 'components/library/Link';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';

const i18nPath = 'scenes.app.Today.components.NoTodayViewSurveyWithButtons.components.WhichPlanType';

const styles = theme => ({
  header: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 4}px 0`,
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
    }
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: 200,

    '& > *': {
      marginBottom: theme.spacing.unit * 4,
    },
  },
  help: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'underline',
    margin: '0 auto',
  },
});

const WhichPlanType = ({ date, planDate, onShowHelp, classes, t }) => (
  <div className={classes.root}>
    <Typography className={classes.header} variant="h2">
      {t(i18nPath, 'title')}
    </Typography>

    <div className={classes.options}>
      <StartWorkflowButton variant="option" date={date} workflow="commit" />
      <StartWorkflowButton variant="option" date={date} workflow="allocate" />
      <StartWorkflowButton variant="option" date={date} workflow="schedule" />

      <Link className={classes.help} onClick={onShowHelp}>
        {t(i18nPath, 'help-link')}
      </Link>
    </div>
  </div>
);

export default compose(
  withStyles(styles),
  withTranslations,
)(WhichPlanType);
