import { services } from '@lifetools/shared-services';

/**
 * Sets the value belonging to the `key` in the session storage to `value`. If `value` is `null` or
 * `undefined`, remove the `key` from the session storage.
 *
 * @param key          - The key to set
 * @param value        - The value to set
 * @param encodeAsJson - Whether to encode the value as a JSON value to preserve its type
 */
export function setSessionValue(
  key: string,
  value?: any,
  encodeAsJson: boolean = false,
): void {
  const encodedValue = value != null && encodeAsJson ? JSON.stringify(value) : `${value}`;

  if (window.sessionStorage) {
    if (value != null) {
      window.sessionStorage.setItem(key, encodedValue);
    } else {
      window.sessionStorage.removeItem(key);
    }
  } else {
    // TODO: Think about logging this to Sentry so we know how often it occurs. [twl 12.Jul.22]
    services.logger.warn(`Session storage not supported: could not set ${key} to ${encodedValue}`);
  }
}
