import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AsyncButton from 'components/library/AsyncButton';
import Icon from 'components/library/Icon';
import Typography from 'components/library/Typography';
import withTranslations from 'helpers/withTranslations';

const i18nPathDefault = 'components.library.Steps';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: `0 ${theme.spacing.unit * 4}px`,
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.background.paper,
      padding: theme.spacing.unit,
    },
  },
  stepper: {
    flex: '1 1 auto',
    background: 'inherit',
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
  },
  button: {
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '16px',
      minWidth: '16px',
    },
  },
  buttonLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepLabel: {
    '&$alternativeLabel': {
      marginTop: theme.spacing.unit,
    }
  },
  alternativeLabel: {},
});

class Steps extends Component {
  static propTypes = {
    /** An array of the string keys of the steps to display */
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,

    /** The current active step */
    activeStep: PropTypes.string,

    /**
     * The step key to use when the Back button is pressed from the first step; if not specified
     * the Back button is disabled on the first step.
     */
    cancelStep: PropTypes.string,

    /**
     * The step key to use when the Next/Done button is pressed from the last step; if not
     * specified the Next/Done button is disabled on the last step.
     */
    doneStep: PropTypes.string,

    /** Whether the Next/Done button should be enabled; defaults to true */
    nextStepEnabled: PropTypes.bool,

    /** Called with the `(activeStep, activeIndex)` when the "Next" button is pressed */
    onNext: PropTypes.func,

    /** Called with the `(activeStep, activeIndex)` when the "Back" button is pressed */
    onBack: PropTypes.func,

    /** The path to the translation keys for this component */
    i18nPath: PropTypes.string,

    /** The name of the custom class for this component */
    className: PropTypes.string,

    /** An object mapping semantic class names to compiled class names. Provided by HOC. */
    classes: PropTypes.object.isRequired,

    /** The breakpoint of the current device screen width. Provided by HOC. */
    width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  };

  static defaultProps = {
    i18nPath: i18nPathDefault,
    nextStepEnabled: true,
    onBack: () => {},
    onNext: () => {},
  };

  doNext = async () => {
    const { steps, activeStep, doneStep, onNext } = this.props;
    const activeIndex = steps.indexOf(activeStep) + 1;

    if (activeIndex < steps.length) {
      await onNext(steps[activeIndex], activeIndex);
    } else if (doneStep) {
      await onNext(doneStep, activeIndex);
    }
  };

  doBack = async () => {
    const { steps, activeStep, cancelStep, onBack } = this.props;
    const activeIndex = steps.indexOf(activeStep) - 1;

    if (activeIndex >= 0) {
      await onBack(steps[activeIndex], activeIndex);
    } else if (cancelStep) {
      await onBack(cancelStep, activeIndex);
    }
  };

  render() {
    const { t, classes, id, className, i18nPath, width } = this.props;
    const { steps, activeStep, cancelStep, doneStep, nextStepEnabled, onNavigateTo } = this.props;
    const activeIndex = steps.indexOf(activeStep);
    const isDone = activeIndex === steps.length - 1;
    const showDetails = isWidthUp('md', width);

    return (
      <div id={id} className={classNames(classes.root, className)}>
        <AsyncButton
          data-action="back"
          className={classes.button}
          variant="outlined"
          disabled={!cancelStep && activeIndex === 0}
          onClick={this.doBack}>

          <Icon type="fas:chevron-left" />
          <span className={classes.buttonLabel} style={{ marginLeft: '0.25rem' }}>
            {t([i18nPath, i18nPathDefault], 'button-back')}
          </span>
        </AsyncButton>

         <Stepper className={classes.stepper} activeStep={activeIndex} alternativeLabel={true}>
          {steps.map((step, index) =>
            <Step key={step}>
              <StepButton
                data-action={`goto ${step}`}
                onClick={() => onNavigateTo && onNavigateTo(step)}
                optional={!showDetails ? undefined : (
                  <Typography
                    variant="body2"
                    color={index <= activeIndex ? 'textPrimary' : 'textSecondary'}
                  >
                    {t(`${i18nPath}.description-${step}`)}
                  </Typography>
                )}
              >
                <StepLabel classes={{
                  label: classes.stepLabel,
                  alternativeLabel: classes.alternativeLabel,
                }}>
                  {t(`${i18nPath}.title-${step}`)}
                </StepLabel>
              </StepButton>
            </Step>
          )}
        </Stepper>

        <AsyncButton
          data-action="next"
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={!nextStepEnabled || (isDone && !doneStep)}
          onClick={this.doNext}>

          <span className={classes.buttonLabel} style={{ marginRight: '0.25rem' }}>
            {t([i18nPath, i18nPathDefault], `button-${isDone ? 'done' : 'next'}`)}
          </span>
          <Icon type="fas:chevron-right" />
        </AsyncButton>
      </div>
    );
  }
}

export default compose(
  withTranslations,
  withStyles(styles),
  withWidth(),
)(Steps);
