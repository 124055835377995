import get from 'lodash/get';
import isObjectLike from 'lodash/isObjectLike';
import { LocalDate as JodaLocalDate } from 'js-joda';
import { toNativeDate } from '@lifetools/shared-utils-time';
import store from './store';
import { createStatusKey } from './utils-reducers';

const supportedOperators = [
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual',
  'after',
  'afterOrOn',
  'before',
  'beforeOrOn',
  'contains',
  'not',
];

/**
 * Returns the status object for the `type` of operation at the `path` within the store that has the
 * `parameters`.
 *
 * @param {string} path       [description]
 * @param {string} type       [description]
 * @param {any}    parameters [description]
 *
 * @return {object} An object representing the status of the `type` operation with the `parameters`
 *                  within the `path` section of the store
 */
export function getStatus(path, type, parameters) {
  return selectStatus(store.getState(), path, type, parameters);
}

export function selectStatus(state, path, type, parameters) {
  let key = createStatusKey(parameters);

  return state[path] && state[path].status && state[path].status[type] &&
         state[path].status[type][key];
}

/**
 * Returns whether the `object` matches the data `query`.
 *
 * @param {object} object The object to test
 * @param {object} query  A query, as defined by the data layer
 *
 * @return {boolean} true if the object matches the query; false otherwise
 */
export function matchesQuery(object, query) {
  for (const field of Object.keys(query)) {
    const value = normalizeForComparison(get(object, field));
    const compareTo = get(query, field);
    const operators = isWhereObject(compareTo) && Object.keys(compareTo);

    if (operators) {
      for (const operator of operators) {
        const compareValue = normalizeForComparison(compareTo[operator]);

        if (!evaluate(operator, value, compareValue)) {
          return false;
        }
      }
    } else if ((compareTo && compareTo.equals && !compareTo.equals(value)) ||
               ((!compareTo || !compareTo.equals) && compareTo !== value)) {
      return false;
    }
  }

  return true;
}


// =================================================================================================
// PRIVATE FUNCTIONS
// =================================================================================================
/**
 * Normalizes the value for comparison.
 *
 * @param {any} value The value to normalize
 *
 * @return {any} A normalized version of the value that can be used to compare against other
 *               normalized values
 */
function normalizeForComparison(value) {
  // TODO: Make this test instance of Timestamp [twl 22.Aug.18]
  if (value && value.seconds) {
    return value.seconds * 1000 + (value.nanoseconds / 1000000);
  } else if (value instanceof Date) {
    return value.valueOf();
  } else if (value instanceof JodaLocalDate) {
    return toNativeDate(value).valueOf();
  } else {
    return value;
  }
}

/**
 * Evaluates a comparison defined by the `operator`, `leftOperand` and `rightOperand` and returns
 * the result.
 *
 * @param {string} operator     The operator to compare the two operands
 * @param {any}    leftOperand  The left operator
 * @param {any}    rightOperand The right operator
 *
 * @return {boolean} The result of the comparison
 */
function evaluate(operator, leftOperand, rightOperand) {
  switch (operator) {
    case 'after':
    case 'greaterThan':
      return leftOperand > rightOperand;

    case 'afterOrOn':
    case 'greaterThanOrEqual':
      return leftOperand >= rightOperand;

    case 'before':
    case 'lessThan':
      return leftOperand < rightOperand;

    case 'beforeOrOn':
    case 'lessThanOrEqual':
      return leftOperand <= rightOperand;

    case 'not':
      return leftOperand !== rightOperand;

    case 'contains':
      return leftOperand && leftOperand.includes(rightOperand);

    default:
      throw new Error(`Unknown operator ${operator}`);
  }
}

function isWhereObject(value) {
  return isObjectLike(value) ?
    Object.keys(value).find(key => supportedOperators.includes(key)) != null :
    false;
}

