import React from 'react';
import { toLocalDate, toNativeDate } from '@lifetools/shared-utils-time';
import TodoListWidget from 'widgets/todos/TodoList';
import { formatLocalDate } from 'utils/time';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'scenes.todos.Appointments.components.AppointmentList';

const filterTodosByDate = (todos, date) => (
  todos.filter(todo =>
    todo.type === 'appointment' && date.equals(toLocalDate(toNativeDate(todo.start)))
  )
);

const AppointmentList = ({ t, date, noBoxHeader, todos, boxActions }) => (
  <TodoListWidget
    i18nPath={i18nPath}
    boxTitle={formatLocalDate(date, t(i18nPath, 'date-format'))}
    boxActions={boxActions}
    noBoxHeader={noBoxHeader}
    todos={filterTodosByDate(todos, date)}
    sortBy="start"
  />
);

export default withTranslations(AppointmentList);
