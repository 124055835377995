import React, { useCallback } from 'react';
import Button from 'components/library/ButtonMui';
import Icon from 'components/library/Icon';
import { useDispatch } from 'hooks/useDispatch';
import { loginWithProvider } from 'services/server/auth';
import setRedirectResult from 'services/store/auth/actions/setRedirectResult';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit / 2,
    // Eyeball min to make sure the button is wide enough for the current provider icons & labels
    minWidth: 100,
    borderRadius: theme.spacing.unit,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
});

const FederatedIdentityAuthButton = ({
  action,
  provider,
  email,
  label,
  icon,
  classes,
  ...props
}) => {
  const dispatch = useDispatch();
  const onClick = useCallback(async () => {
    const result = await loginWithProvider(action, provider, email);

    if (result) {
      dispatch(setRedirectResult(result));
    }
  }, [action, provider, dispatch, email]);

  return (
    <Button
      className={classes.root}
      variant="outlined"
      onClick={onClick}
      data-action={`${action}:${provider}`}
      {...props}
    >
      <Icon className={classes.icon} type={icon} />
      {label}
    </Button>
  );
}

export default withStyles(styles)(FederatedIdentityAuthButton);
