import React from 'react';
import ActionBar from 'components/library/ActionBar';
import Box from 'components/library/Box';
import Typography from 'components/library/Typography';
import logoutUser from 'services/store/auth/actions/logoutUser';
import { buildScene } from 'scenes/utils';

const i18nPath = 'scenes.users.Account.components.ProfileSettings';

const ProfileSettings = buildScene(({ user, doLogoutUser, classes, t }) => (
  <Box
    classes={{ content: classes.content }}
    title={t(i18nPath, 'title')}
    actions={[
      <ActionBar.Button i18nPath={i18nPath} action="logout" onClick={doLogoutUser} />,
    ]}
  >
    <Typography className={classes.identity} component="div">
      <div className={classes.message}>{t(i18nPath, 'text-logged-in-as')}</div>

      {user.photoUrl &&
        <img src={user.photoUrl} alt="" />
      }

      <div className={classes.username}>{user.displayName}</div>
      <div className={!user.isEmailVerified ? classes.unverifiedEmail : undefined}>
        {user.email}
      </div>

      {user.provider && user.provider !== 'password' &&
        <div className={classes.provider}>via {user.provider}</div>
      }
    </Typography>

    <Typography className={classes.version}>
      App Version: {process.env.REACT_APP_VERSION}
    </Typography>
  </Box>
), {
  styles: theme => ({
    content: {
      padding: theme.spacing.unit * 6,
      alignSelf: 'center',
      textAlign: 'center',
    },
    identity: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& img': {
        borderRadius: '50%',
        margin: theme.spacing.unit,
      },
    },
    message: {
      marginBottom: theme.spacing.unit,
    },
    username: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    unverifiedEmail: {
      fontStyle: 'italic',
    },
    provider: {
      marginTop: theme.spacing.unit * 2,
      fontStyle: 'italic',
    },
    launchButton: {
      display: 'block',
      margin: `${theme.spacing.unit * 6}px auto 0 auto`,
    },
    version: {
      marginTop: theme.spacing.unit * 4,
    },
  }),
  state: state => ({
    // TODO: Not setting `displayName` immediately when registering via email/password. If the user
    //       refreshes, then `displayName` is set. Appears to be Firebase not passing the
    //       `displayName` in `onAuthStateChanged`, but we should research more. [twl 12.Aug.22]
    user: state.user.data,
  }),
  dispatch: {
    doLogoutUser: logoutUser,
  }
});

export default ProfileSettings;
