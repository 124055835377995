import React, { cloneElement } from 'react';
import SwipeableViews from 'react-swipeable-views';
import extractViewInfos from 'components/utils/extractViewInfos';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import CarouselNavigator from './components/CarouselNavigator';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',            // HACK: Prevents `CarouselNavigator` scrolling over the `AppBar`
    top: 0,                        //       Remove once body lock scroll implemented [twl 5.Dec.19]
  },
  views: {
    flex: '1 1 auto',
    maxWidth: '100vw',
    display: 'flex',

    '& > *': {
      flex: '1 0 auto',
      maxWidth: '100vw',
    },
  },
  slide: {                            // used as raw style, not as compiled class name
    display: 'flex',
    flexDirection: 'column',
  },
};

function getHashIndex(viewInfos) {
  const id = window.location.hash ? window.location.hash.substring(1) : undefined;
  const index = viewInfos.findIndex(viewInfo => viewInfo.id === id);

  // TODO: Maybe check if `parseInt` returns `NaN` and then default to `0` [twl 7.May.20]
  return index >= 0 ? index : Math.min(Math.max(0, parseInt(id, 10)), viewInfos.length - 1);
}

const Carousel = ({ id, t, classes, children }) => {
  const viewInfos = extractViewInfos(children, t);
  const index = getHashIndex(viewInfos) || 0;
  const onChangeIndex = index => {
    window.location.hash = (viewInfos[index] && viewInfos[index].id) || index;
  };

  return (
    <div id={id} className={classes.root} data-role="carousel">
      {viewInfos.length > 1 &&
        <CarouselNavigator
          labels={viewInfos.map(item => item.title)}
          index={index}
          onChangeIndex={onChangeIndex} />
      }

      <SwipeableViews
        className={classes.views}
        slideStyle={styles.slide}
        index={index}
        onChangeIndex={onChangeIndex}>

        {viewInfos.map((item, index) => cloneElement(item.node, { key: item.id || index }))}

      </SwipeableViews>
    </div>
  );
};

export default withStyles(styles)(withTranslations(Carousel));
