import get from 'lodash/get';
import { LocalDate } from 'js-joda';
import { IIntent, IStatus, ITodo, ITodoStatusState } from '@lifetools/shared-app-types';
import { entityMapToArray } from '@lifetools/shared-database';
import createEarliestIntentStateMap from '../../intents/utils/createEarliestIntentStateMap';

const getOverrideState = (state: string): ITodoStatusState => (
  state.substring(0, state.indexOf('-todo')) as ITodoStatusState
);

/**
 * Returns the intent whose date is earlier, given two intents.
 *
 * @param intent1 - The first intent to check
 * @param intent2 - The second intent to check
 *
 * @returns The intent with the earliest date, or `undefined` if both intents are undefined
 */
const earlierIntent = (intent1?: IIntent, intent2?: IIntent): IIntent | undefined => (
  !intent1 || (intent2 && intent2.date.isBefore(intent1.date)) ? intent2 : intent1
)

/**
 * Calculates the `state` and `from` parts of the status for a `todo` based on the intents defined
 * on that todo.
 *
 * @param todo        - A todo
 * @param currentDate - The date to use to determine which intents are future vs current/past
 *
 * @returns The calculated status of the todo
 */
function calculateTodoStatus(
  todo: ITodo,
  currentDate: LocalDate = LocalDate.now(),
): IStatus<ITodoStatusState> {
  const intents = todo.intents && entityMapToArray<IIntent>(todo.intents);
  const states = createEarliestIntentStateMap(intents);

  // Remove any future intents
  for (const key of Object.keys(states)) {
    if (currentDate.isBefore(states[key].date)) {
      delete states[key];
    }
  }

  if (states['defer'] || states['delegate']) {
    throw new Error(`The intent states 'defer' and 'delegate' are not supported yet`);
  }

  let intent = earlierIntent(states['done-todo'], states['wontdo-todo']);

  if (intent) {
    return {
      state: getOverrideState(intent.status.state),
      from: intent.id,
    }
  }

  intent = earlierIntent(states['doing'], states['done']);

  if (intent) {
    return {
      state: 'doing',
      from: intent.id,
    };
  }

  intent = earlierIntent(states['new'], states['wontdo']);

  if (intent) {
    return {
      state: 'intent',
      from: intent.id,
    };
  }

  if (states['unknown']) {
    throw new Error(`The intent state 'unknown' is not supported`);
  }

  return {
    state: 'new',
    from: undefined,
  };
}

export default calculateTodoStatus;
