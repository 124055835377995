import React from 'react';
import classnames from 'classnames';
import OptionIndicators from 'components/app/indicators/OptionIndicators';
import StrengthIndicator from 'components/app/indicators/StrengthIndicator';
import { testProps } from 'utils/test/testProps';
import './Indicators.css';

const Indicators = ({ value, tags, className }) => (
  <div className={classnames('Indicators', className)} {...testProps('Indicators')}>
    {tags ?
      <OptionIndicators value={tags} show="titleOnly" /> :
      undefined
    }

    {value.importance ?
      <StrengthIndicator property="importance" value={value.importance} show="iconsOnly" /> :
      undefined
    }

    {value.urgency ?
      <StrengthIndicator property="urgency" value={value.urgency} show="iconsOnly" /> :
      undefined
    }
  </div>
);

export default Indicators;
