import React from 'react';
import TodayBase from 'scenes/app/TodayBase';
import CommitmentListView from './components/CommitmentListView';

const i18nPath = 'scenes.app.CommitmentList';

const CommitmentList = () => (
  <TodayBase i18nPath={i18nPath} scheduleType="commit" scheduleView={CommitmentListView} />
);

export default CommitmentList;
