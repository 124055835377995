import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { toNativeDate } from '@lifetools/shared-utils-time';
import Page from 'components/app/navigation/Page';
import TodoEditDialog from 'components/app/todos/TodoEditDialog';
import CalendarDetails from 'components/app/views/CalendarDetails';
import ConnectStatus from 'components/app/integrations/calendars/ConnectStatus';
import ActionBar from 'components/library/ActionBar';
import withImportedAppointments from 'helpers/withImportedAppointments';
import { buildScene } from 'scenes/utils';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import clearTodoStatuses from 'services/store/todos/actions/clearTodoStatuses';
import createTodo from 'services/store/todos/actions/createTodo';
import queryTodos from 'services/store/todos/actions/queryTodos';
import selectTodos from 'services/store/todos/selectors/selectTodos';
import { getCalendarColors } from 'utils/app/getCalendarColors';
import AppointmentList from '../AppointmentList';

const i18nPath = 'scenes.todos.Appointments.components.AppointmentsMain';

async function loadData() {
  const { date, doQueryTodos } = this.props;
  const startDate = date.withDayOfMonth(1);
  const endDate = startDate.plusMonths(1).minusDays(1);               // last day of the month

  // TODO: Need to query on `end` as well. [twl 2.Oct.18]
  doQueryTodos({
//    type: 'appointment',
    start: {
      afterOrOn: toNativeDate(startDate).toISOString(),
      beforeOrOn: toNativeDate(endDate).toISOString(),
    },
  });
}

const AppointmentsMain = buildScene(({
  date,
  showDetail,
  onChangeDate,
  isAuthorized,           // is `undefined` while loading, then resolves to `true` or `false`
  canSync,
  todos,
  doCreateTodo,
  doClearTodoStatuses,
  isAccountReadOnly,
  t,
}) => (
  <Page title={t(i18nPath, 'title')}>
    {!isAccountReadOnly &&
      <ConnectStatus
        redirectPath="/appointments"
        hideWhen={['loadingAuthorization', 'authorized:existing']} />
    }

    <CalendarDetails
      boxActions={[
        isAuthorized && !isAccountReadOnly &&
          <ActionBar.Button
            i18nPath={i18nPath}
            action="resync"
            disabled={!canSync}
            onClick={doClearTodoStatuses} />
      ]}
      i18nPath={`${i18nPath}.components.CalendarDetails`}
      events={todos}
      date={date}
      onSelectDate={onChangeDate}
      onSelectEvent={event => onChangeDate(toNativeDate(event.start), 'detail')}
      onNavigateDate={onChangeDate}>

      {showDetail &&
        <AppointmentList
          boxActions={!isAccountReadOnly && [
            <ActionBar.Button
              i18nPath={i18nPath}
              action="add"
              pane={
                <TodoEditDialog
                  defaultType="appointment"
                  options={{ date }}
                  onSave={doCreateTodo}
                />
              } />,
          ]}
          todos={todos}
          date={date}
        />
      }

    </CalendarDetails>
  </Page>
), {
  context: 'isAccountReadOnly',
  state: (state, props) => {
    const userSettings = selectUserSettings(state);
    const integration = get(userSettings, 'calendars.config.integration', 'gcalendar');
    const profiles = Object.values(get(userSettings, 'calendars.profiles', {}));
    const hasActiveProfile = !!(profiles.find(profile =>
      profile.integration === integration && get(profile, 'connection.state') === 'active'
    ));
    const calendarColors = getCalendarColors(userSettings);

    return {
      isAuthorized: get(userSettings, `integrations.${integration}.authorized`),
      canSync: hasActiveProfile,
      todos: selectTodos(state).map(todo =>
        calendarColors[todo.calendarId] ? { ...todo, color: calendarColors[todo.calendarId] } : todo
      )
    };
  },
  dispatch: {
    doQueryTodos: queryTodos,
    doCreateTodo: createTodo,
    doClearTodoStatuses: clearTodoStatuses,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  }
});

AppointmentsMain.propTypes = {
  /** The date of the appointments to load and view */
  date: PropTypes.object.isRequired,

  /** Whether the detail view should be shown on this page */
  showDetail: PropTypes.bool,

  /** Called when the user has requested the date on this view change */
  onChangeDate: PropTypes.func,

  /** Whether this user has authorized with Google Calendar yet. Loaded by `buildScene`. */
  isAuthorized: PropTypes.bool,

  /**
   * An array of all loaded todos. Loaded by `buildScene`.
   */
  todos: PropTypes.arrayOf(PropTypes.object),

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

export default withImportedAppointments(AppointmentsMain);
