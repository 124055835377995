import get from 'lodash/get';
import React from 'react';
import { isSnoozed } from '@lifetools/shared-app-data';
import ListSettingsInfo from 'components/app/common/ListSettingsInfo';
import BasicSteps from 'components/app/schedules/BasicSteps';
import TodoEditDialog from 'components/app/todos/TodoEditDialog';
import ViewLayout from 'components/layouts/ViewLayout';
import ActionBar from 'components/library/ActionBar';
import HelpIcon from 'components/library/HelpIcon';
import Selector from 'components/library/Selector';
import withImportedAppointments from 'helpers/withImportedAppointments';
import { buildScene } from 'scenes/utils';
import saveScheduleStep from 'services/store/schedules/actions/saveScheduleStep';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import clearTodoStatuses from 'services/store/todos/actions/clearTodoStatuses';
import createTodo from 'services/store/todos/actions/createTodo';
import activeTasks from 'services/store/todos/queries/activeTasks';
import inactiveAppointmentsOnDate from 'services/store/todos/queries/inactiveAppointmentsOnDate';
import inactiveTasksVisibleOnDate from 'services/store/todos/queries/inactiveTasksVisibleOnDate';
import weeklyActivitiesOnDate from 'services/store/todos/queries/weeklyActivitiesOnDate';
import addIntent from 'services/store/intents/actions/addIntent';
import hasIntent from 'services/store/intents/utils/hasIntent';
import daysInStatusStreak from 'utils/intents/daysInStatusStreak';
import daysSinceActive from 'utils/todos/daysSinceActive';
import HelpTipWidget from 'widgets/common/HelpTipWidget';
import TodoListWidget from 'widgets/todos/TodoList';
import IntentListWidget from 'widgets/intents/IntentListWidget';
import './Itemize.css';

const i18nPath = 'scenes.todos.Itemize';

const activeTasksSettingsPath = 'defaults.createPlan.commit.activeTasks';
const tasksSettingsPath = 'defaults.createPlan.commit.tasks';

const momentumStatuses = ['doing', 'done', 'done-todo'];
const noProgressStatuses = ['new', 'defer', 'wontdo', 'wontdo-todo'];

/**
 * NOTE: Explicitly not passing in `date` to these filters. This allows the user to plan future
 *       dates based on the status of items on the day they are building the plan, rather than the
 *       day the plan is for (e.g., when creating a plan for Friday on Monday, the active3D filter
 *       doesn't make much sense as it would only show unsnoozed items during the week; better to
 *       show items active within 3 days of the day the plan is being created). [twl 8.Mar.22]
 */
const filterDefinitions = {
  activeTasks: {
    active1D: todo => daysSinceActive(todo) <= 1,
    active3D: todo => daysSinceActive(todo) <= 3,
    active7D: todo => daysSinceActive(todo) <= 7,
    notActive7D: todo => daysSinceActive(todo) > 7,
    notActive14D: todo => daysSinceActive(todo) > 14,
    notActive30D: todo => daysSinceActive(todo) > 30,
    momentum3D: todo => daysInStatusStreak(todo, momentumStatuses) >= 3,
    momentum5D: todo => daysInStatusStreak(todo, momentumStatuses) >= 5,
    momentum10D: todo => daysInStatusStreak(todo, momentumStatuses) >= 10,
    notDone3D: todo => daysInStatusStreak(todo, noProgressStatuses) >= 3,
    notDone5D: todo => daysInStatusStreak(todo, noProgressStatuses) >= 5,
    notDone10D: todo => daysInStatusStreak(todo, noProgressStatuses) >= 10,
  },
  tasks: {
    // TODO: Think about whether we need a slightly different algorithm here. Maybe this shouldn't
    //       take into account status changes and only be tasks that are new or unsnoozed in the
    //       time period. [twl 8.Mar.22]
    arose1D: todo => daysSinceActive(todo) <= 1,
    arose3D: todo => daysSinceActive(todo) <= 3,
    arose7D: todo => daysSinceActive(todo) <= 7,
    arosePast7D: todo => daysSinceActive(todo) > 7,
    arosePast14D: todo => daysSinceActive(todo) > 14,
    arosePast30D: todo => daysSinceActive(todo) > 30,
  },
}

const Itemize = buildScene(({
  workflow,
  date,
  schedule,
  intents,
  activeTasksSettings,
  tasksSettings,
  doAddIntent,
  doCreate,
  doClearTodoStatuses,
  doSaveScheduleStep,
  doUpdateSettings,
  isAuthorized,
  classes,
  ...props
}) => (
  <div id="Itemize">
    <BasicSteps
      currentStep="commit"
      workflow={workflow}
      date={date}
      nextStepEnabled={intents.length > 0}
      onBack={async () => doSaveScheduleStep(schedule, intents, workflow, undefined)}
      onNext={async () => doSaveScheduleStep(schedule, intents, workflow, 'committed')}
    />

    <ViewLayout layout="split" noStickyPadding scrollColumns="*">
      <HelpTipWidget
        viewColumn={1}
        variant="card"
        helpPath="workflow.commit.todoColumn"
        tips={['intro[newUser]']}
      />

      <TodoListWidget
        boxId="UnfinishedTasksBox"
        viewId="unfinishedTasks"
        viewColumn={1}
        viewI18nPath={i18nPath + '.views.UnfinishedTasks'}
        i18nPath={i18nPath + '.components.UnfinishedTasks'}
        actionMode="add"
        date={date}
        sortBy="title"
        groupBy="tags"
        onAdd={todo => doAddIntent(todo, schedule)}
        query={activeTasks}
        filter={todo =>
          !hasIntent(todo, schedule) && !isSnoozed(todo, date) &&
          (activeTasksSettings.filter === 'all' ||
            !filterDefinitions.activeTasks[activeTasksSettings.filter] ||
            filterDefinitions.activeTasks[activeTasksSettings.filter](todo))
        }
        header={
          <div>
            <ListSettingsInfo
              i18nPath={i18nPath + '.components.UnfinishedTasks.info'}
              settings={activeTasksSettings}
              onReset={() => doUpdateSettings({[`${activeTasksSettingsPath}.filter`]: 'all'})}
            />
            <HelpTipWidget
              helpPath="workflow.commit.unfinished"
              tips={['intro[newUser]']}
            />
          </div>
        }
        boxActions={[
          <ActionBar.Button i18nPath={i18nPath} action="filter" pane={
            <ActionBar.Pane className={classes.settingsPane}>
              <Selector
                i18nPath={i18nPath + '.components.UnfinishedTasks.filter'}
                options={[
                  'all', 'active1D', 'active3D', 'active7D', 'notActive7D', 'notActive14D',
                  'notActive30D', 'momentum3D', 'momentum5D', 'momentum10D', 'notDone3D',
                  'notDone5D', 'notDone10D'
                ]}
                value={activeTasksSettings.filter}
                onChange={value => doUpdateSettings({[`${activeTasksSettingsPath}.filter`]: value})}
              />
              <HelpIcon
                i18nPath={i18nPath + '.components.UnfinishedTasks.filter.help'}
                format="markdown"
              />
            </ActionBar.Pane>
          } />,
        ]}
      />

      <TodoListWidget
        boxId="AppointmentsBox"
        viewId="appointments"
        viewColumn={1}
        viewI18nPath={i18nPath + '.views.Appointments'}
        boxActions={[
          isAuthorized &&
            <ActionBar.Button
              i18nPath={i18nPath}
              action="resync"
              onClick={doClearTodoStatuses} />,
          <ActionBar.Button
            i18nPath={i18nPath}
            action="add"
            pane={
              <TodoEditDialog defaultType="appointment" options={{ date }} onSave={doCreate} />
            } />,
        ]}
        i18nPath={i18nPath + '.components.Appointments'}
        actionMode="add"
        sortBy="start"
        groupBy="tags"
        onAdd={todo => doAddIntent(todo, schedule)}
        query={inactiveAppointmentsOnDate(date)}
        filter={todo => !hasIntent(todo, schedule)}
        header={
          <HelpTipWidget
            helpPath="workflow.commit.appointments"
            tips={['intro[newUser]']}
          />
        }
      />

      <TodoListWidget
        boxId="ActivitiesBox"
        viewId="activities"
        viewColumn={1}
        viewI18nPath={i18nPath + '.views.Activities'}
        i18nPath={i18nPath + '.components.Activities'}
        actionMode="add"
        sortBy="title"
        groupBy="tags"
        onAdd={todo => doAddIntent(todo, schedule)}
        query={weeklyActivitiesOnDate(date)}
        filter={todo => !hasIntent(todo, schedule)}
        boxActions={[
          <ActionBar.Button
            i18nPath={i18nPath}
            action="add"
            pane={
              <TodoEditDialog defaultType="activity" onSave={doCreate} />
            } />,
        ]}
        header={
          <HelpTipWidget
            helpPath="workflow.commit.activities"
            tips={['intro[newUser]']}
          />
        }
      />

      <TodoListWidget
        boxId="TasksBox"
        viewId="tasks"
        viewColumn={1}
        viewI18nPath={i18nPath + '.views.Tasks'}
        i18nPath={i18nPath + '.components.Tasks'}
        actionMode="add"
        date={date}
        sortBy="title"
        groupBy="tags"
        onAdd={todo => doAddIntent(todo, schedule)}
        query={inactiveTasksVisibleOnDate(date)}
        filter={todo =>
          !hasIntent(todo, schedule) &&
          (tasksSettings.filter === 'all' || !filterDefinitions.tasks[tasksSettings.filter] ||
            filterDefinitions.tasks[tasksSettings.filter](todo))
        }
        header={
          <div>
            <ListSettingsInfo
              i18nPath={i18nPath + '.components.Tasks.info'}
              settings={tasksSettings}
              onReset={() => doUpdateSettings({[`${tasksSettingsPath}.filter`]: 'all'})}
            />
            <HelpTipWidget
              helpPath="workflow.commit.tasks"
              tips={['intro[newUser]']}
            />
          </div>
        }
        boxActions={[
          <ActionBar.Button i18nPath={i18nPath} action="filter" pane={
            <ActionBar.Pane className={classes.settingsPane}>
              <Selector
                i18nPath={i18nPath + '.components.Tasks.filter'}
                options={[
                  'all', 'arose1D', 'arose3D', 'arose7D', 'arosePast7D', 'arosePast14D',
                  'arosePast30D'
                ]}
                value={tasksSettings.filter}
                onChange={value => doUpdateSettings({[`${tasksSettingsPath}.filter`]: value})}
              />
              <HelpIcon i18nPath={i18nPath + '.components.Tasks.filter.help'} />
            </ActionBar.Pane>
          } />,
          <ActionBar.Button
            i18nPath={i18nPath}
            action="add"
            pane={
              <TodoEditDialog defaultType="task" onSave={doCreate} />
            } />,
        ]} />

      <HelpTipWidget
        viewColumn={2}
        variant="card"
        helpPath="workflow.commit.commitColumn"
        tips={['intro[newUser]']}
      />

      <IntentListWidget
        boxId="CommitmentsBox"
        viewId="commitments"
        viewColumn={2}
        viewI18nPath={i18nPath + '.views.IntentList'}
        i18nPath={i18nPath + '.components.IntentList'}
        schedule={schedule}
        intents={intents}
        date={date}
        sortBy="todo.title"
        actionMode="remove"
        groupBy="todo.tags"
        createEnabled
        header={
          <HelpTipWidget
            helpPath="workflow.commit.commitments"
            tips={['intro[newUser]']}
          />
        }
      />
    </ViewLayout>
  </div>
), {
  styles: theme => ({
    settingsPane: {
      borderBottom: `solid 1px ${theme.palette.grey['400']}`,
    },
  }),
  state: (state, { actionMode, date, schedule, intents }) => {
    const userSettings = selectUserSettings(state);

    return {
      activeTasksSettings: get(userSettings, activeTasksSettingsPath, { filter: 'all' }),
      tasksSettings: get(userSettings, tasksSettingsPath, { filter: 'all' }),
    };
  },
  dispatch: {
    doAddIntent: addIntent,
    doCreate: createTodo,
    doClearTodoStatuses: clearTodoStatuses,
    doUpdateSettings: updateSettings,
    doSaveScheduleStep: saveScheduleStep,
  },
});

export default withImportedAppointments(Itemize);
