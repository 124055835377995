import get from 'lodash/get';
import React, { useMemo, useState } from 'react';
import { LocalDate } from 'js-joda';
import { unflattenPathKeys } from '@lifetools/shared-utils';
import EditPlanButton from 'components/app/buttons/EditPlanButton';
import PrintButton from 'components/app/buttons/PrintButton';
import ViewsPaneButton from 'components/app/buttons/ViewsPaneButton';
import ScheduleProjections from 'components/app/schedules/ScheduleProjections';
import WorkflowPromptCard from 'components/app/schedules/WorkflowPromptCard';
import withDeviceInfo from 'helpers/withDeviceInfo';
import withStyles from 'helpers/withStyles';
import { useNow } from 'hooks/useNow';
import { isDevEnvironment } from 'utils/app/isDevEnvironment';
import DeletePlanButton from 'widgets/buttons/DeletePlanButton';
import IntentListWidget from 'widgets/intents/IntentListWidget';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';
import { sort } from 'utils/sort';
import { minutesLeft } from 'utils/time/minutesLeft';

const roundMinutesTo = 5;
const minimumTimeBlock = 15;

const defaultSortBy = {
  property: 'status.state',
  order: [ 'new', 'done', 'done-todo', 'wontdo', 'wontdo-todo' ],
};

const styles = theme => ({
  empty: {
    padding: theme.spacing.unit * 6,
  },
});

const addRenderProperties = (intents, remainingTime, showFill) => (
  intents.map(intent => {
    const duration = intent.duration || 0;
    const percentUnavailable = remainingTime > duration
      ? 0
      : remainingTime > 0 && (duration - remainingTime) >= 0
        ? (duration - remainingTime) / duration
        : 1;

    remainingTime = duration > remainingTime ? 0 : remainingTime - duration;

    return {
      ...intent,
      span: (intent.duration || minimumTimeBlock) / minimumTimeBlock,
      fill: showFill ? percentUnavailable : undefined,
    };
  })
);

function getSortBy(schedule, groupBy = '$default') {
  const customSort = get(schedule, `sort.${groupBy}`);

  return !customSort ? defaultSortBy : [
    defaultSortBy,
    {
      property: 'todo.id',
      ...customSort,
    }
  ];
}

async function onReorder(schedule, intents, onUpdateSchedule, setScheduleUpdates) {
  const updates = {
    'sort.$default.order': intents.map(intent => intent.todo.id)
  };

  setScheduleUpdates(unflattenPathKeys(updates));

  onUpdateSchedule && await onUpdateSchedule(schedule.id, updates);

  setScheduleUpdates(undefined);
}

const AllocationListWidget = ({
  i18nPath,
  boxTitle,
  header,
  actionMode,
  isPlanEditable,
  date,
  nextStartTime,
  schedule,
  expanded,
  showProjections,
  isPlanActive,
  activePlanType,
  isAccountReadOnly,
  onToggleViewCompact,
  onToggleShowProjections,
  onUpdateSchedule,
  classes,
  isMobileDevice,
  ...props
}) => {
  const { now, isNowActive } = useNow(date, schedule.startTime, nextStartTime);
  const [ scheduleUpdates, setScheduleUpdates ] = useState();
  const projectionsActive = isPlanActive && (isNowActive || LocalDate.now().isBefore(date));
  const minutesRemaining = minutesLeft(schedule, date, now, roundMinutesTo)
  const sortBy = getSortBy({ ...schedule, ...scheduleUpdates });
  const intents = useMemo(
    () => addRenderProperties(
      sort(props.intents, sortBy), minutesRemaining, showProjections && projectionsActive
    ),
    [props.intents, sortBy, minutesRemaining, showProjections, projectionsActive]
  );

  // HACK: Wrap the widget in a `div`. Because it uses a `header`, which is just wrapped in via a
  //       `Fragment`, in Safari on iOS, the `ScheduleProjections` isn't given enough room to
  //       render. If I wrap this in a `div` it fixes it. We should figure out why this needs to be
  //       done, or maybe use a `div` instead of a `Fragment` in `TodoList`. [twl 19.Dec.20]
  return (
    <div>
    <IntentListWidget
      i18nPath={i18nPath}
      boxTitle={boxTitle}
      boxActions={!isPlanActive
        ? [
          <StartWorkflowButton
            variant="actionBar"
            workflow="allocate"
            existingSchedule={schedule}
            activePlanType={activePlanType}
            date={date}
          />
        ]
        : [
          <ViewsPaneButton
            expanded={expanded}
            projectionsActive={projectionsActive}
            showProjections={showProjections}
            onToggleViewCompact={onToggleViewCompact}
            onToggleShowProjections={onToggleShowProjections}
          />,
          <PrintButton />,
          isPlanEditable && !isAccountReadOnly &&
            <EditPlanButton workflow="allocate" step="allocate" date={date} />,
          isPlanEditable && !isAccountReadOnly && isDevEnvironment() &&
            <DeletePlanButton schedule={schedule} />,
        ]
      }
      header={
        projectionsActive && showProjections
          ? <div>
              {header}
              <ScheduleProjections
                date={date}
                nextStartTime={nextStartTime}
                schedule={schedule}
                intents={intents}
              />
            </div>
          : header
      }
      emptyComponent={
        <WorkflowPromptCard
          className={classes.empty}
          date={date}
          workflow="allocate"
          existingSchedule={schedule}
          activePlanType={activePlanType}
        />
      }
      actionMode={actionMode}
      variant="compact"
      expanded={expanded}
      schedule={schedule}
      intents={intents}
      date={date}
      sortBy={sortBy}
      filter={intent => intent.duration != null}
      droppableId="AllocationList"
      onReorder={intents => onReorder(schedule, intents, onUpdateSchedule, setScheduleUpdates)}
    />
    </div>
  );
};

export default withStyles(styles)(withDeviceInfo(AllocationListWidget));
