import { TFunction } from 'i18next';

const i18nPath = '*.time.days-from-now';

/**
 * Formats the the number of days from today.
 *
 * @param days - The number of days from today
 *
 * @returns A formatted string containing a relative description of the number of days from today
 */
export function formatDaysFromNow(days: number, t: TFunction): string {
  return t(`${i18nPath}.${days > 1 ? 2 : (days < -1 ? -2 : days)}`, { days: Math.abs(days) })
}
