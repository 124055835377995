import * as Sentry from '@sentry/browser';
import { getTrackingUserId } from '../../../../../utils/app/getTrackingUserId';

export default function setSentryUser(authInfo: any) {
  const { user } = authInfo;

  Sentry.configureScope(scope => {
    scope.setUser({ id: getTrackingUserId(user.id) });
  });
}
