const i18nPath = '*.time';

/**
 * Formats a duration using the form `h:mm`. Uses the translation specified under the key
 * `*.time.separator` to determine the separator to use.
 *
 * @param {number} value A number of minutes
 *
 * @return {string} The formatted value
 */
export function formatCompactDuration(value, t) {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  const separator = t(i18nPath + '.separator', { defaultValue: ':' });

  return `${hours}${separator}${minutes < 10 ? '0' : ''}${minutes}`;
}

/**
 * Formats a component of a duration using `t` to translate the word or abbreviation to use with
 * this component.
 *
 * @param {number}   value   A number of minutes
 * @param {string}   type    The type of component, e.g. `hour`, `minute`
 * @param {Function} t       The function used for looking up translations
 * @param {string}   variant The variant of the translation to use: `abbreviated` or `full`
 *
 * @return {string} The formatted component
 */
function formatDurationComponent(value, type, t, variant) {
  if (value > 0) {
    const word = t(i18nPath + '.' + type, { count: value, context: variant });
    const separator = variant === 'abbreviated' ? '' : ' ';

    return `${value}${separator}${word}`;
  } else {
    return ``;
  }
}

/**
 * Formats a duration using the form `5 hours` or `5 hours 15 minutes` if `variant` is `full` or
 * `5h or `5h 15m` for durations that fall between hours if `variant` is `abbreviated`. Uses the
 * translations for the time words or abbreviations specified under the key `*.time`.
 *
 * @param {number}   value   A number of minutes
 * @param {string}   variant The variant of the format to use
 * @param {Function} t       The function used for looking up translations
 *
 * @return {string} The formatted value
 */
function formatDurationWithWords(value, variant = 'full', t) {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  const separator = hours > 0 && minutes > 0 ? ' ' : '';

  return formatDurationComponent(hours, 'hour', t, variant) + separator +
         formatDurationComponent(minutes, 'minute', t, variant);
}

/**
 * Formats the duration using the `variant` to determine how to format the duration.
 *
 * ### Variants
 *
 * compact
 * : Formats a duration using symbols only
 *
 * normal
 * : Formats a duration using abbreviations
 *
 * expanded
 * : Formats a duration using full words
 *
 * @param {number}   value   A number of minutes
 * @param {string}   variant The variant of the format to use
 * @param {Function} t       The function used for looking up translations
 *
 * @return {string} A formatted duration
 */
function formatDuration(value, variant, t) {
  if (value == null) {
    return undefined;
  }

  switch (variant) {
    case 'compact':
      return formatCompactDuration(value, t);

    case 'expanded':
      return formatDurationWithWords(value, 'full', t);

    case 'normal':
      return formatDurationWithWords(value, 'abbreviated', t);

    default:
      throw new Error(`The duration variant '${variant}' is not supported`);
  }
}

export default formatDuration;
