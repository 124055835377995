import React, { Fragment } from 'react';
import { toNativeDate } from '@lifetools/shared-utils-time';
import { formatDateTime } from 'utils/time';
import './MonthEvent.css';

function formatDate(dateTime, wholeHourFormat, fractionalHourFormat, ampmFormat) {
  const nativeDateTime = toNativeDate(dateTime);
  const format = nativeDateTime.getMinutes() === 0 ? wholeHourFormat : fractionalHourFormat;
  const formattedTime = formatDateTime(nativeDateTime, format);
  const formattedAmPm = ampmFormat ? formatDateTime(nativeDateTime, ampmFormat) : '';

  return formattedTime + formattedAmPm.substring(0, 1);
}

const MonthEvent = ({ event }) => (
  <Fragment>
    <div className="rbc-event-marker" style={{ background: event.color }}><i /></div>
    <div>{formatDate(event.start, 'h', 'h:mm', 'a')}</div>
    <div className="rbc-event-title">{event.title}</div>
  </Fragment>
);

export default MonthEvent;
