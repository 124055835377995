import { selectStatus } from 'services/store/utils-selectors';

const selectUserSettingsStatus = (state, action, ...parameters) => (
  selectStatus(
    state,
    'settings',
    action,
    parameters.length > 0 ? [ 'user', ...parameters ] : 'user'
  ) || {}
);

export default selectUserSettingsStatus;
