import React, { Component } from 'react';
import Form from 'components/library/Form';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  notes: {
    '& label': {
      display: 'none',
    },
  },
  input: {
    padding: theme.spacing.unit,
  },
});

class NotesEditForm extends Component {
  // HACK: Currently there is no way for the form to return only the field that changed. Until we
  //       add this, the `editFields` static prop is used to only save these fields as the
  //       "changes". [twl 13.Oct.18]
  //
  // TODO: Add changes functionality to the Formik form, then remove this hack. [twl 13.Oct.18]
  static editFields = [ 'notes' ];

  /**
   * Renders the fields for this form. The `value` will be provided by the form this is inserted
   * into.
   */
  render() {
    const { i18nPath, classes } = this.props;

    return (
      <Form.TextField
        className={classes.notes}
        i18nPath={i18nPath + '.fields'}
        variant="outlined"
        name="notes"
        multiline
        fullWidth
        rows="10"
        rowsMax="20"
        autoFocus
        InputProps={{
          className: classes.input
        }}
      />
    );
  }
}

export default withStyles(styles)(NotesEditForm);
