import { SimpleExpression } from '../../../../types/SimpleExpression';
import { parseSimpleExpression } from '../../../../utils/data/parseSimpleExpression';

/**
 * Parses a tip spec into the `name` of the tip and an expression specifying `when` it should be
 * displayed.
 *
 * @param tip - A tip spec in the form `name[when expression]`
 *
 * @returns An object with a `name` and `when` properties
 */
export function parseTip(tip: string): { name: string, when?: SimpleExpression } {
  if (!tip.includes('[') ) {
    return {
      name: tip,
    };
  }

  const name = tip.substring(0, tip.indexOf('['));
  const when = parseSimpleExpression(tip.substring(tip.indexOf('[') + 1, tip.length - 1));

  return {
    name,
    when,
  };
}
