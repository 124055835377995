import get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from 'components/library/ListItem';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { onlyTestProps } from 'utils/test/onlyTestProps';
import { sort } from 'utils/sort';
import DragOverlayItem from './components/DragOverlayItem';
import SortableItem from './components/SortableItem';
import { groupItems } from './utils/groupItems';

const i18nPathDefault = 'components.library.ItemList';
const defaultGroup = '$default';

const styles = theme => ({
  noItems: {
    '&:not(:empty)': {                                 // only set style if translation is non-null
      display: 'inline-block',
      padding: theme.spacing.unit * 2,
      textAlign: 'center',
    }
  },
  header0: {
    backgroundColor: theme.palette.grey['100'],
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit * 2,
    lineHeight: 'inherit',

    '@media print': {
      display: 'none',
    },
  },
  header1: {
    backgroundColor: theme.palette.grey['200'],
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing.unit,
    lineHeight: 'inherit',
  },
});

function resolveProps(props, context) {
  return typeof props === 'function' ? props(context) : props;
}

/**
 * A list of items.
 *
 * Note that if no `no-items-found` translation key is provided at the `i18nPath`, then the list
 * will not appear at all.
 */
function ItemList(props) {
  const { classes, t, i18nPath = i18nPathDefault, className, droppableId, onReorder } = props;
  const { header, emptyComponent, items, sortBy, groupBy, level = 0, groups = [] } = props;

  // Grouping
  const groupInfo = useMemo(
    () => groupItems(items, groupBy, defaultGroup),
    [items, groupBy]
  );
  const hasGroups = groupBy &&
    (groupInfo.names.length > 1 || !groupInfo.names.includes(defaultGroup));
  const ungroupedItems = useMemo(
    () => !hasGroups ? sort(items || [], sortBy) : [],
    [hasGroups, items, sortBy]
  );

  // Drag & Drop
  const [ sortedItems, setSortedItems ] = useState(items);
  const [ draggingItem, setDraggingItem ] = useState(null);
  const ids = sortedItems.map(item => item.id);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const doDragStart = useCallback((event) => {
    setDraggingItem(items.find(item => item.id === event.active.id));
  }, [ setDraggingItem, items ]);

  const doDragEnd = useCallback((event) => {
    const { active, over } = event;

    setDraggingItem(null);

    if (over && active.id !== over.id) {
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);

      setSortedItems(newItems);
      onReorder(newItems, groups);
    }
  }, [ ids, items, groups, setDraggingItem, setSortedItems, onReorder ]);

  // Ensure the `items` get set everytime they are updated, which `useState(items)` doesn't do
  useEffect(() => {
    setSortedItems(items);
  }, [ items ]);

  // Item Rendering
  const renderItem = item => {
    const { component: ItemComponent, componentProps, idPath = 'id', getIdAttribute } = props;
    const key = get(item, idPath);
    const itemProps = {
      id: getIdAttribute ? getIdAttribute(item) : undefined,
      ...resolveProps(componentProps, item),
      value: item,
      groups,
    };

    return <ItemComponent {...itemProps} key={key} />;
  };

  const renderList = (
    <List className={className} disablePadding {...onlyTestProps(props)}>
      {header &&
        <ListSubheader classes={{ root: classes[`header${level}`] }} disableGutters>
          {header}
        </ListSubheader>
      }

      {(!items || items.length === 0) &&
        (emptyComponent
          ? emptyComponent
          : <ListItem classes={{ root: classes.noItems }} disableGutters>
              {t(i18nPath, 'no-items-found', { defaultValue: '' })}
            </ListItem>
        )
      }

      {!droppableId ? ungroupedItems.map(renderItem) :
        <SortableContext items={ids} strategy={verticalListSortingStrategy}>
          {sortedItems.map((item, index) => (
            <SortableItem key={item.id} id={item.id}>
              {renderItem(item)}
            </SortableItem>
          ))}
        </SortableContext>
      }

      {droppableId &&
        <DragOverlayItem
          item={draggingItem && renderItem(draggingItem)}
          modifiers={[restrictToWindowEdges]}
        />
      }

      {hasGroups && groupInfo.names.map(name => (
        <ItemList
          key={name}
          {...props}
          items={groupInfo.items[name]}
          groupBy={undefined}
          groups={[ ...groups, name ]}
          header={name !== defaultGroup ? name : t(i18nPath, 'default-group')}
          level={level + 1}
        />
      ))}
    </List>
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={doDragStart}
      onDragEnd={doDragEnd}
    >
      {renderList}
    </DndContext>
  );
}

ItemList.propTypes = {
  /** The path to the translation keys for this component */
  i18nPath: PropTypes.string,

  /** The custom class to add to this component */
  className: PropTypes.string,

  /** The items to render */
  items: PropTypes.array,

  /** The component to render a list item */
  component: PropTypes.func.isRequired,

  /**
   * The props to pass to the list item component, or a function that takes the current item and
   * returns the props to use
   */
  componentProps: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),

  /** A node to render instead of the default when there are no items in the list */
  emptyComponent: PropTypes.node,

  /** A node to render at the top of the list */
  header: PropTypes.node,

  /** The nesting level of this list. Defaults to 0 */
  level: PropTypes.number,

  /** A property path to the `id` property for each item. Defaults to `id` */
  idPath: PropTypes.string,

  /** A function to return the `id` attribute when rendering the list item */
  getIdAttribute: PropTypes.func,

  /** The property & direction to sort the items, or a comparison function to use to sort them */
  sortBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),

  /** The property path used to group items or a function that returns a group key */
  groupBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),

  /**
   * An ID to identify this list as a drop-zone. Setting this enables drag-n-drop for this list.
   */
  droppableId: PropTypes.string,

  /**
   * Called with the new order of the intents and the current groups for this list when the user
   * completes a drag operation.
   */
  onReorder: PropTypes.func,

  /** A function used to provide translations of text. Provided by `withTranslations`. */
  t: PropTypes.func.isRequired,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,
};

export default withTranslations(withStyles(styles)(ItemList));
