import { addDocument } from '@lifetools/shared-database';
import { mergePathKeys } from '@lifetools/shared-utils';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from '../definitions';

function addDefaults(todo) {
  switch (todo.type) {
    case 'appointment':
      return {
        start: null,      // TODO: Think about throwing an error if this is missing. [twl 12.Oct.18]
        'status.state': 'new',
        ...todo
      };

    case 'activity':
      return todo;

    default:
      return mergePathKeys({
        type: 'task',
        status: {
          state: 'new',
          hide: null,
        },
      }, todo);
  }
}

const createTodo = makeAsyncActionCreator({
  type: types.ADD_TODO,
  creator: async todo => await addDocument(collection, addDefaults(todo)),
  status: {
    path: collection,
    type: 'add',
  }
});

export default createTodo;
