import { IDocumentSetRequest } from '../documentSets/types/IDocumentSetRequest';
import { IDocumentSetOptions } from '../documentSets/types/IDocumentSetOptions';

/**
 * Builds a document request.
 */
function buildDocumentRequest(
  { paths, query, collection, userId }: IDocumentSetOptions,
): IDocumentSetRequest {
  if (paths) {
    return {
      type: 'pathSet',
      paths,
    };
  }

  if (!collection) {
    throw new Error('The `collection` parameter must be provided for a `collectionSet` request');
  }

  // HACK: `settings` don't have the `createdBy` property for some reason, only `updatedBy`.
  //       [twl 16.Nov.19]
  return {
    type: 'collectionSet',
    collection,
    query: {
      ...query,
      ...userId && (collection === 'settings' ? { updatedBy: userId } : { createdBy: userId }),
    }
  };
}

export default buildDocumentRequest;
