import { IDocumentChange } from '../../documents/types/IDocumentChange';
import { IDocumentData } from '../../documents/types/IDocumentData';
import { DocumentMetaProperties } from '../../extensions/types/DocumentMetaProperties';
import { parseDocumentSnapshot } from './parseDocumentSnapshot';

/**
 * Parses a document change object into document data used by this application.
 *
 * @param  collection - The collection the document belongs to
 * @param  change     - The object representing how the document changed
 *
 * @returns The data representing this document for the application
 */
export function parseDocumentChange(collection: string, change: IDocumentChange): IDocumentData {
  const data = parseDocumentSnapshot(collection, change.doc) || { id: change.doc.id };

  if (change.type === 'removed') {
    // If the document still exists, then it wasn't deleted, it was just removed from the
    // subscription this function is parsing data for, because it no longer matches its query
    data[DocumentMetaProperties.SyncAction] = change.doc.exists ? 'remove' : 'delete';
  }

  return data;
}
