// Registry for server events that can be subscribed to
const events = {
  // Called when the authenticated user is set, e.g. via login or first page load
  onAuthenticatedUserSet: [],

  // Called when the authenticated user is unset, e.g. via logout
  onAuthenticatedUserUnset: [],

  // Called when the anonymous user is set, e.g. via logout or first page load
  onAnonymousUserSet: [],

  // Called when the anonymous user is unset, e.g. via login
  onAnonymousUserUnset: [],
};

export default events;
