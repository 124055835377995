export const routes = {
  // To display a schedule type
  today: {
    commit: '/plan/:planDate[today]/commitments',
    allocate: '/plan/:planDate[today]/allocations',
    schedule: '/plan/:planDate[today]/schedule',
  },

  // To create a new schedule type
  workflows: {
    commit: '/:planPath[plan]/:planDate[today]/commitments/:step[commit]',
    allocate: '/:planPath[plan]/:planDate[today]/allocations/:step[commit]',
    schedule: '/:planPath[plan]/:planDate[today]/schedule/:step[commit]',
  },
};
