import mapKeys from 'lodash/mapKeys';
import React from 'react';
import EditScheduleDialog from 'components/app/schedules/EditScheduleDialog';
import { buildScene } from 'scenes/utils';
import updateSchedule from 'services/store/schedules/actions/updateSchedule';
import updateSettings from 'services/store/settings/actions/updateSettings';

async function saveScheduleTimes(schedule, changes, doUpdateSchedule, doUpdateSettings) {
  const { setAsDefault, ...scheduleChanges } = changes;

  await doUpdateSchedule(schedule.id, scheduleChanges);

  if (setAsDefault) {
    await doUpdateSettings(mapKeys(scheduleChanges, (_, key) => `defaults.scheduleTimes.${key}`));
  }
}

const EditScheduleDialogWidget = buildScene(({
  doUpdateSchedule,
  doUpdateSettings,
  ...props
}) => (
  <EditScheduleDialog
    {...props}
    onSave={(schedule, changes) => (
      saveScheduleTimes(schedule, changes, doUpdateSchedule, doUpdateSettings)
    )}
  />
), {
  dispatch: {
    doUpdateSchedule: updateSchedule,
    doUpdateSettings: updateSettings,
  },
});

export default EditScheduleDialogWidget;
