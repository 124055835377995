import pick from 'lodash/pick';
import { getAdditionalUserInfo, getRedirectResult } from 'firebase/auth';
import analytics from 'services/analytics';
import sentry from 'services/sentry';
import store from 'services/store';
import setRedirectResult from 'services/store/auth/actions/setRedirectResult';
import { AuthenticationError } from 'types/AuthenticationError';
import { getSessionValue } from 'utils/browser/getSessionValue';
import { setSessionValue } from 'utils/browser/setSessionValue';
import { getActiveProvider } from './getActiveProvider';
import { handleFederatedIdentityLogin } from './handleFederatedIdentityLogin';
import { handleFederatedIdentityRegister } from './handleFederatedIdentityRegister';

const redirectResultUserErrors = [
  'auth/account-exists-with-different-credential',
  'auth/credential-already-in-use',
  'auth/email-already-in-use',
  'auth/account-not-found',
  'auth/user-cancelled',

  // This was occurring while running unit tests against the Twitter API with the message
  // `Malformed response cannot be parsed from twitter.com for OAUTH1_REQUEST_TOKEN`. This
  // apparently was because of issues with Twitter being down. Keep this commented out to log to
  // Sentry so we know how often it occurs, since other issues may also trigger it.
  //
  // 'auth/invalid-credential',
];

/**
 * Checks to see if a federated identity authentication has a result waiting. This occurs when the
 * user attempts to register or log in via a federated identity provider like Google or Twitter and
 * are redirected back into this app.
 *
 * @returns One of `deny`, `pass` or `none`, depending on whether a result existed but failed
 *          authentication, a result existed & passed authentication, or no result existed at all
 */
export async function checkForAuthenticationResult(auth) {
  const { action } = getSessionValue('authAttempt', true) ?? {};

  try {
    const redirectResult = await getRedirectResult(auth);

    // If no results came back, then no authentication just happened
    if (!redirectResult) {
      return 'none';
    }

    const { operationType, user } = redirectResult ?? {};
    const additionalUserInfo = getAdditionalUserInfo(redirectResult) ?? {};
    const { isNewUser, providerId } = additionalUserInfo;
    const analyticsParams = { operationType, action, provider: providerId, isNewUser };

    analytics.track('auth_check', analyticsParams);

    switch (action) {
      case 'login':
        await handleFederatedIdentityLogin(user, additionalUserInfo);
        break;

      case 'register':
        await handleFederatedIdentityRegister(user, additionalUserInfo);
        break;

      default:
        throw new AuthenticationError('auth/invalid-auth-action');
    }

    analytics.track('auth_end', analyticsParams);

    setSessionValue('authAttempt', undefined);

    return 'pass';
  } catch (e) {
    const result = {
      action,
      ...pick(e, 'code', 'message', 'email'),
      provider: e.providerId,
    };

    if (e.code === 'auth/account-exists-with-different-credential') {
      result.activeProvider = e.activeProvider ?? await getActiveProvider(e.email);
    }

    analytics.track('auth_error', pick(result, 'action', 'provider', 'activeProvider', 'code'));

    // if not user error, log to sentry
    if (!redirectResultUserErrors.includes(e.code)) {
      sentry.withScope(scope => {
        scope.setTag('type', 'authError');
        scope.setExtras(pick(result, 'action', 'provider'));

        sentry.captureException(e);
      });
    }

    await store.dispatch(setRedirectResult(result));

    return 'deny';
  }
}
