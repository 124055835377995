import React from 'react';
import { Navigate } from 'react-big-calendar';
import Icon from 'components/library/Icon';
import './CalendarToolbar.css';

const CalendarToolbar = ({ label, onNavigate }) => (
  <div className="CalendarToolbar">
    <Icon type="fas:chevron-left" onClick={() => onNavigate(Navigate.PREVIOUS)} />
    <h2>{label}</h2>
    <Icon type="fas:chevron-right" onClick={() => onNavigate(Navigate.NEXT)} />
  </div>
);

export default CalendarToolbar;
