import { LocalDate } from 'js-joda';
import { DateValue, toLocalDate } from '@lifetools/shared-utils-time';

/**
 * Returns the date as a "route date", which uses special value for certain days like today and
 * tomorrow.
 *
 * @param date - The date to convert to a route date
 *
 * @returns A string representation of the date either in the format `YYYY-MM-DD` or using relative
 *          days like `today` and `tomorrow`
 */
export function toRouteDate(date?: DateValue | string): string | undefined {
  if (typeof date === 'string' && ['today', 'tomorrow'].includes(date)) {
    return date;
  }

  const today = LocalDate.now();
  const routeDate = date ? toLocalDate(date) : undefined;

  return routeDate
    ? today.equals(routeDate)
      ? 'today'
      : (today.plusDays(1).equals(routeDate) ? 'tomorrow' : routeDate.toString())
    : undefined;
}
