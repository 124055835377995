import React, { cloneElement } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { getLastPathElement } from '@lifetools/shared-utils';
import Button from 'components/library/ButtonMui';
import Icon from 'components/library/Icon';
import Tooltip from 'components/library/Tooltip';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { onlyTestProps } from 'utils/test/onlyTestProps';
import { testProps } from 'utils/test/testProps';

const styles = theme => ({
  root: {
    color: 'inherit',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)',

      [theme.breakpoints.down('sm')]: {
        background: 'inherit',
      },
    },
  },
  rootNewMobileUi: {
    color: 'inherit',
    minWidth: 32,
    background: 'rgba(255, 255, 255, 0.1)',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  },
  icon: {
    marginRight: 6,                               // fixed spacing not related to theme
  },
  iconNewMobileUi: {
    padding: 2,
  },
  selected: {
    background: 'rgba(255, 255, 255, 0.2)',       // desktop uses a dark toolbar

    [theme.breakpoints.down('sm')]: {
      background: 'rgba(0, 0, 0, 0.08)',          // mobile uses a light toolbar

      '@media (hover: none)': {                   // override default style for mobile
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.08)',
        },
      },
    },
  },
  selectedNewMobileUi: {
    '@media (hover: none)': {                   // override default style for mobile
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },
  label: {
    flexWrap: 'wrap',
  },
  disabled: {
    color: `${theme.palette.grey[500]} !important`,
  },
});

function resolveTranslation(t, i18nPath, i18nKey, subkey, defaultValue) {
  const subpath = `${i18nKey ? i18nKey + '.' : ''}${subkey}`;
  const options = typeof defaultValue !== 'undefined' ? { defaultValue } : undefined;

  return i18nPath ? t(i18nPath, subpath, options) : (i18nKey ? t(subpath, options) : undefined);
}

/**
 * Displays a button on an action bar.
 *
 * # Translation Keys
 *
 * icon
 * : The icon to display on the button
 *
 * label
 * : The label to display on the button
 *
 * confirmation
 * : The confirmation message to display in the confirm dialog, if active
 */
const ActionBarButton = ({
  i18nPath,
  action,
  selected,
  confirmAction,
  to,
  pane,
  disabled,
  renderMode,
  newMobileUi,
  onClick,
  onPaneClose,
  children,
  classes,
  t,
  history,
  ...props
}) => {
  const i18nKey = props.i18nKey || (action ? `actions.${action}` : undefined);
  const label = props.label || resolveTranslation(t, i18nPath, i18nKey, 'label');
  const icon = props.icon || resolveTranslation(t, i18nPath, i18nKey, 'icon', null);
  const tooltip = props.tooltip || resolveTranslation(t, i18nPath, i18nKey, 'tooltip', null);
  const confirmation = resolveTranslation(t, i18nPath, i18nKey, 'confirmation');
  const doClick = to ? () => history.push(to) : onClick;
  const button = (
    <Button
      className={
        newMobileUi
          ? classnames(classes.rootNewMobileUi, selected && classes.selectedNewMobileUi)
          : classnames(classes.root, selected && classes.selected)
      }
      classes={{ disabled: classes.disabled, label: classes.label }}
      size="small"
      disabled={disabled}
      onClick={e => (e.altKey || !confirmAction || window.confirm(confirmation)) && doClick()}
      {...testProps('ActionBarButton', getLastPathElement(i18nKey))}
      {...onlyTestProps(props)}
      data-action={action}
    >

      {icon &&
        <Icon
          className={newMobileUi ? classes.iconNewMobileUi : classes.icon}
          type={icon}
          tooltip={newMobileUi ? label : undefined}
        />
      }
      {(!icon || !newMobileUi) && label}
      {children}

    </Button>
  );

  return renderMode === 'pane'
    ? cloneElement(pane, { onClose: onPaneClose })
    : tooltip
      ? <Tooltip title={tooltip}>{button}</Tooltip>
      : button;
}

ActionBarButton.propTypes = {
  /** The path to the translation keys for this component */
  i18nPath: PropTypes.string,

  /**
   * The key underneath the `i18nPath` to search for translations. If not specified, but `action`
   * is defined, this is set to `actions.${action}`.
   */
  i18nKey: PropTypes.string,

  /** The type of action this button is for */
  action: PropTypes.string,

  /** Whether this button should display as actively selected. Defaults to `false` */
  selected: PropTypes.bool,

  /**
   * Whether a confirmation popup should be displayed to the user before calling `onClick`. Displays
   * the message defined by the 'confirmation' key underneath the `i18nPath`. Defaults to false
   */
  confirmAction: PropTypes.bool,

  /**
   * The route that should be navigated to when this button is clicked. If `confirmAction` is
   * set, only navigates if the user confirms the action.
   */
  to: PropTypes.string,

  /**
   * What this component should render. To allow the pane to be defined on the button itself, the
   * action bar uses a special architecture where every button with a pane defined can be rendered
   * either as a button or as the pane it activates. This avoids needing to "peek inside" the button
   * to grab the pane and more easily allows refactoring.
   */
  renderMode: PropTypes.oneOf(['pane']),

  /**
   * A component to render instead of the button when `renderMode` is set to `pane`.
   */
  pane: PropTypes.node,

  /**
   * Called when the button is clicked and, if `confirmAction` is set, the user proceeds with the
   * action in the confirmation dialog. Due to how the `ActionBar` clones child components, this
   * component has been designed so if `to` is set, `onClick` is not called.
   */
  onClick: PropTypes.func,

  /**
   * A function to pass into the pane that allows it to request itself to be closed.
   */
  onPaneClose: PropTypes.func,

  /** An object mapping semantic class names to compiled class names. Provided by HOC. */
  classes: PropTypes.object.isRequired,

   /** A function used to provide translations of text & UI elements. Provided by HOC. */
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withTranslations,
  withRouter,
)(ActionBarButton);
