import { IDocumentData } from '../../documents/types/IDocumentData';
import { IDocumentSnapshot } from '../../documents/types/IDocumentSnapshot';
import { applySchema } from '../../documents/utils/applySchema';
import { DocumentMetaProperties } from '../../extensions/types/DocumentMetaProperties';

/**
 * Parses a document snapshot into document data used by this application.
 *
 * @param  collection - The collection the document belongs to
 * @param  doc        - The snapshot of the document
 *
 * @returns The data representing this document for the application
 */
export function parseDocumentSnapshot(
  collection: string,
  doc: IDocumentSnapshot
): IDocumentData | undefined {
  if (!doc.exists) {
    return undefined;
  }

  const data: IDocumentData = {
    ...applySchema(doc.data(), collection),
    id: doc.id,
  };

  if (doc.metadata && doc.metadata.hasPendingWrites) {
    data[DocumentMetaProperties.PendingWrite] = true;
  }

  return data;
}
