import React, { Fragment } from 'react';
import IntentSelector from 'components/app/intents/IntentSelector';
import Intent from 'components/app/intents/Intent';
import ConflictWarning from './components/ConflictWarning';

const SchedulerIntentCell = ({
  record,
  variant,
  actionMode,
  unscheduledIntents,
  onIntentUpdate,
  onIntentDelete,
  onStatusChange,
  onIntentSchedule,
  onScheduleExtend,
}) => {
  if (record.intent && !record.continuation) {
    return (
      <Fragment>
        <Intent
          value={record.intent}
          variant={variant}
          component="div"
          actionMode={actionMode === 'edit' ? 'remove' : actionMode}
          onStatusChange={status => onStatusChange(record.intent, status)}
          onUpdate={onIntentUpdate}
          onDelete={onIntentDelete}
          onRemove={intent => onIntentSchedule(intent, undefined)}
        />

        {/* HACK: `onScheduleExtend` is only set in `Schedule` scene. This prevents the warning
                  being displayed on other pages that use the scheduler. [twl 30.Sep.20] */}
        {onScheduleExtend && record.conflictDuration > 0 &&
          <ConflictWarning
            intent={record.intent}
            type={record.conflictType}
            duration={record.conflictDuration}
            onUpdate={onIntentUpdate}
            onRemove={intent => onIntentSchedule(intent, undefined)}
            onScheduleExtend={onScheduleExtend}
          />
        }
      </Fragment>
    );
  } else if (!record.intent && actionMode === 'edit') {
    return (
      <IntentSelector
        intents={unscheduledIntents}
        sortBy={[
          { property: 'duration', direction: 'desc', sortNulls: 'bottom' },
          'todo.title',
        ]}
        onChange={intent => intent.duration && onIntentSchedule(intent, record.time)}
      />
    );
  }

  return ' ';
}

export default SchedulerIntentCell;
