import React from 'react';
import { Switch } from 'react-router-dom';
import { TrackedRoute } from '@lifetools/react-router-tracker';
import AnonymousRoute from 'components/router/AnonymousRoute';
import AuthenticatedRoute from 'components/router/AuthenticatedRoute';
import Redirect from 'components/router/Redirect';
import Help from 'scenes/app/Help';
import Coaching from 'scenes/app/Coaching';
import Today from 'scenes/app/Today';
import CommitmentList from 'scenes/app/CommitmentList';
import AllocationList from 'scenes/app/AllocationList';
import DailySchedule from 'scenes/app/DailySchedule';
import Register from 'scenes/users/Register';
import Commitments from 'routes/commitments';
import Allocations from 'routes/allocations';
import Schedule from 'routes/schedules';
import Appointments from 'scenes/todos/Appointments';
import Schedules from 'scenes/todos/Schedules';
import Tasks from 'scenes/todos/Tasks';
import Activities from 'scenes/todos/Activities';
import Account from 'scenes/users/Account';
import Login from 'scenes/users/Login';
import ResetPassword from 'scenes/users/ResetPassword';
import Home from 'scenes/public/Home';
import { isValidRouteDate } from 'utils/app/routes/isValidRouteDate';

const anonymousStart = '/';
const authenticatedStart = '/plan';

const isValidPlanRoute = match => (
  isValidRouteDate(match.params.planDate) && ['plan', 'plans'].includes(match.params.planPath)
);

const App = props => (
  <Switch>
    {/* Public */}
    <AnonymousRoute exact path="/" loader="splash" redirectTo={authenticatedStart} render={(props) =>
      <Home {...props} />
    } />

    {/* Workflow Routes */}
    {/* NOTE: Using `planDate` instead of `date` as the route parameter to isolate the Today menu
              items from the rest of the menu. With the way the new `Redirect` works, if there is a
              route parameter on the existing route, it is included on the redirected route. For the
              most part, this is what we want; however, we don't want the date shown on Appointments
              or Plans to transfer over to the Today items on the menu. [twl 25.Nov.20] */}
    <AuthenticatedRoute
      path="/:planPath/:planDate/commitments/:step"
      redirectTo={anonymousStart}
      readOnlyRedirectTo="/:planPath/:planDate/commitments"
      invalidRedirectTo={authenticatedStart}
      isValid={isValidPlanRoute}
      component={Commitments}
    />

    <AuthenticatedRoute
      path="/:planPath/:planDate/allocations/:step"
      redirectTo={anonymousStart}
      readOnlyRedirectTo="/:planPath/:planDate/allocations"
      invalidRedirectTo={authenticatedStart}
      isValid={isValidPlanRoute}
      component={Allocations}
    />

    <AuthenticatedRoute
      path="/:planPath/:planDate/schedule/:step"
      redirectTo={anonymousStart}
      readOnlyRedirectTo="/:planPath/:planDate/schedule"
      invalidRedirectTo={authenticatedStart}
      isValid={isValidPlanRoute}
      component={Schedule}
    />

    {/* Today Plan Routes */}
    <AuthenticatedRoute
      path="/:planPath/:planDate/commitments"
      redirectTo={anonymousStart}
      invalidRedirectTo={authenticatedStart}
      isValid={isValidPlanRoute}
      component={CommitmentList}
    />

    <AuthenticatedRoute
      path="/:planPath/:planDate/allocations"
      redirectTo={anonymousStart}
      invalidRedirectTo={authenticatedStart}
      isValid={isValidPlanRoute}
      component={AllocationList}
    />

    <AuthenticatedRoute
      path='/:planPath/:planDate/schedule'
      redirectTo={anonymousStart}
      invalidRedirectTo={authenticatedStart}
      isValid={isValidPlanRoute}
      component={DailySchedule}
    />

    <AuthenticatedRoute
      path="/plan/:planDate?"
      redirectTo={anonymousStart}
      invalidRedirectTo={authenticatedStart}
      isValid={match => match.params.planDate == null || isValidRouteDate(match.params.planDate)}
      component={Today}
    />

    {/* Rest of Primary Menu */}
    <AuthenticatedRoute path='/tasks' redirectTo={anonymousStart} component={Tasks} />
    <AuthenticatedRoute path='/activities' redirectTo={anonymousStart} component={Activities} />
    <AuthenticatedRoute
      path='/appointments/:date?/:view?'
      redirectTo={anonymousStart}
      component={Appointments} />

    {/* Also accessible anonymously */}
    <TrackedRoute path="/help/:topic?" component={Help} />
    <TrackedRoute path="/coaching/:topic?" component={Coaching} />
    <AuthenticatedRoute path='/account' redirectTo={anonymousStart} component={Account} />


    {/* Account Management */}
    <AnonymousRoute path='/register' redirectTo={authenticatedStart} component={Register} />
    <AnonymousRoute path='/login' redirectTo={authenticatedStart} component={Login} />
    <AnonymousRoute
      path='/reset-password'
      redirectTo={authenticatedStart}
      component={ResetPassword}
    />

    {/* Must be last route since it matches any route with at least one component */}
    <AuthenticatedRoute
      path='/:planPath/:planDate?/:view?'
      redirectTo={anonymousStart}
      invalidRedirectTo={authenticatedStart}
      isValid={isValidPlanRoute}
      component={Schedules}
    />

    {/* Everything else goes to root URL & redirects based on user login status */}
    <Redirect to='/'/>
  </Switch>
);

export default App;
