import React, { Component, Fragment } from 'react';
import Form from 'components/library/Form';
import { parseLocalDateLoose } from 'utils/data/parseLocalDateLoose';
import { parseTagText } from 'utils/todos/parseTagText';

const fieldsI18nPath = 'data.todos.fields';

class TaskEditForm extends Component {
  /**
   * The validation schema for this form.
   */
  static schema = {
    title: {
      type: 'string',
      validation: [
        'required',
      ],
    },
  };

  // HACK: Currently there is no way for the form to return only the field that changed. Until we
  //       add this, the `editFields` static prop is used by the `TodoTableEditor` to only save
  //       these fields as the "changes". [twl 13.Oct.18]
  //
  // TODO: Add changes functionality to the Formik form, then remove this hack. [twl 13.Oct.18]
  //
  // NOTE: Both `status.hide` and `status.hide.date` are included below because we are using
  //       property paths (that is, the key is `status.hide`) and these paths are each used
  //       separately. `status.hide.date` is used to set a specific snooze date, while `status.hide`
  //       is used to clear any snooze. [twl 16.Mar.20]
  static editFields = [ 'title', 'tags', 'desc', 'status.hide', 'status.hide.date' ];

  /**
   * The path to the translation keys for this form.
   */
  static i18nPath = 'components.app.todos.TodoEditDialog.components.TaskEditForm';

  /**
   * Returns a new data object to use as the default `value` when using this form to add a new item.
   *
   * @returns {object} a new data object
   */
  static getDefaultValue = () => ({
    type: 'task',
  });

  /**
   * Parses the `value` into a flat object containing the values for the form.
   *
   * @param {object} value a data object
   *
   * @returns {object} a form object
   */
  static toFormValues = ({ tags, ...values }) => ({
    ...values,
    tagText: tags ? tags.join(', ') : '',
  });

  /**
   * Parses the values returned from the form into a data object.
   *
   * @param {object} value a form object
   *
   * @returns {object} a data object
   */
  static fromFormValues = ({ status, tagText, ...values }) => {
    const hideDate = status && status.hide && parseLocalDateLoose(status.hide.date);

    // If `status.hide.date` is an empty string then set `status.hide` to null (no snooze)
    const statusHide = !status || !status.hide
      ? undefined
      : (hideDate ? { 'status.hide.date': hideDate.toString() } : { 'status.hide': null });

    return {
      ...values,
      ...statusHide,
      tags: parseTagText(tagText),
    };
  };

  /**
   * Renders the fields for this form. The `value` will be provided by the form this is inserted
   * into.
   */
  render() {
    return (
      <Fragment>
        <Form.TextField i18nPath={fieldsI18nPath} name="title" fullWidth autoFocus />
        <Form.TextField i18nPath={fieldsI18nPath} name="tagText" fullWidth />
        <Form.TextField i18nPath={fieldsI18nPath} name="desc" multiline fullWidth rowsMax="10" />
        <Form.DateField
          i18nPath={fieldsI18nPath}
          name="status.hide.date"
          placeholder="e.g. 2020-10-31, Oct 31, 2020"
          fullWidth
        />
      </Fragment>
    );
  }
}

export default TaskEditForm;
