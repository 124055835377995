import React from 'react';
import SubscriptionButton from 'components/app/account/SubscriptionButton';
import NotificationBar from 'components/library/NotificationBar';
import Typography from 'components/library/Typography';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { getActivePlanInfo } from 'utils/app/plans/getActivePlanInfo';
import { buildScene } from 'scenes/utils';

const i18nPath = 'widgets.account.SubscriptionNotification';

const SubscriptionNotification = buildScene(({
  type,
  isActive,
  inGracePeriod,
  daysUntilEnd,
  graceDaysLeft,
  classes,
  t,
}) => {
  return (isActive && daysUntilEnd > 3) || type !== 'trial'
    ? null
    : (
      <NotificationBar className={classes.root}>
        <Typography className={classes.message} variant="h5">
          {isActive
            ? t(i18nPath, 'message-expiring', { days: daysUntilEnd })
            : inGracePeriod
              ? t(i18nPath, 'message-grace-period', { days: graceDaysLeft })
              : t(i18nPath, 'message-expired')
          }

          <SubscriptionButton className={classes.upgrade} />
        </Typography>
      </NotificationBar>
    );
}, {
  styles: theme => ({
    root: {
      background: theme.palette.custom.notifications.warn,
      padding: theme.spacing.unit * 2,
    },
    upgrade: {
      marginLeft: theme.spacing.unit * 2,

      [theme.breakpoints.down('sm')]: {
        display: 'block',
        margin: `${theme.spacing.unit * 2}px auto ${theme.spacing.unit}px auto`,
      },
    },
  }),
  memos: {
    activePlanInfo: userSettings => getActivePlanInfo(userSettings),
  },
  state: (state, { memos }) => memos.activePlanInfo(selectUserSettings(state)),
});

export default SubscriptionNotification;
