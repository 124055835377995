import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import { Children } from 'react';

function extractViewInfo(node, viewOrder, t) {
  if (!node) {
    return undefined;
  }

  const props = node.props || {};
  const id = props.viewId || props.id;
  const i18nPath = props.viewI18nPath || props.i18nPath;
  const title = props.viewTitle != null ?  props.viewTitle : props.title;
  const help = props.viewHelp;
  const column = props.viewColumn;
  const order = props.viewOrder != null ? props.viewOrder : viewOrder;

  return {
    title: title || (i18nPath ? t(i18nPath, 'title') : undefined),
    help: help != null ? help : (i18nPath ? t(i18nPath, 'help') : undefined),
    id,
    column,
    order,
    node,
  };
}

/**
 * Extracts the view info from each of the children and returns an array of view info objects,
 * sorted by their view order.
 *
 * The view info includes:
 *
 * title
 * : The title of the child, specified by the `viewTitle` or `title` props, or the resolved
 *   translation under the `title` key.
 *
 * help
 * : The help text of the child, specified by the `viewHelp` prop, or the resolved translation under
 *   the `help` key.
 *
 * column
 * : The column number of the child for column views, specified by the `viewColumn` prop.
 *
 * order
 * : The order of the child in ordered views, specified by the `viewOrder` prop. If no `viewOrder`
 *   prop is specified, the index of the child in the `children` array is used.
 *
 * node
 * : The child component
 *
 * Translations are resolved using the provided `t` function, using either the `viewI18nPath`, if
 * specified, or the `i18nPath` otherwise, as the parent path. If no i18nPath is provided, no
 * translation lookup is done.
 */
const extractViewInfos = (children, t) => (
  compact(                         // remove null children (e.g. caused by `{false && <MyComp />}``)
    sortBy(
      Children.map(children, (child, index) => extractViewInfo(child, index, t)),
      'order'
    )
  )
);

export default extractViewInfos;
