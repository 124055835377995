import { ICollectionQueryOptions } from '../types/ICollectionQueryOptions';

/**
 * Adds options for how to process the query to the `queryRef` and returns a new query reference.
 *
 * @param queryRef - The reference
 * @param options  - The options for how to process the query, e.g. sorting, limits, etc.
 *
 * @returns a new reference with the query options added
 */
export function addQueryOptions(queryRef: any, options?: ICollectionQueryOptions) {
  if (options) {
    if (options.orderBy) {
      queryRef = queryRef.orderBy(options.orderBy, options.orderDesc ? 'desc' : 'asc');
    }

    if (options.limit != null) {
      queryRef = queryRef.limit(options.limit);
    }
  }

  return queryRef;
}
