import { login } from 'services/server/auth';
import { types as globalTypes } from 'services/store/actions';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { path } from '../definitions';

const loginUser = makeAsyncActionCreator({
  type: globalTypes.LOGIN,
  creator: async user => {
    const { email, ...other } = user;

    // Remove trailing and leading spaces in email, which happens when you auto-complete on a iPhone
    // keyboard
    await login({
      email: email.trim(),
      ...other
    });
  },
  status: {
    path,
    type: 'write',
    parameters: () => undefined,
  }
});

export default loginUser;
