import React from 'react';
import AllocationListView from 'scenes/app/AllocationList/components/AllocationListView';
import CommitmentListView from 'scenes/app/CommitmentList/components/CommitmentListView';
import DailyScheduleView from 'scenes/app/DailySchedule/components/DailyScheduleView';
import withTranslations from 'helpers/withTranslations';
import { formatLocalDate } from 'utils/time';

const PlanViews = ({ i18nPath, activePlanType, date, t, ...props }) => {
  const extraProps = {
    planBoxTitle: formatLocalDate(date, t(i18nPath, 'date-format')),
    layout: 'stacked',
    actionMode: 'statusReadOnly',
    showFutureTasks: false,
    activePlanType,
    date,
  };

  switch (activePlanType) {
    case 'commit':
      return <CommitmentListView {...props} {...extraProps} />;

    case 'allocate':
      return <AllocationListView {...props} {...extraProps} />;

    case 'schedule':
      return <DailyScheduleView {...props} {...extraProps} />;

    default:
      return null;
  }
};

export default withTranslations(PlanViews);
