import queryString from 'query-string';
import { getCookieValue } from 'utils/browser/getCookieValue';
import { getSessionValue } from 'utils/browser/getSessionValue';

/**
 * Returns the referral info stored in cookie or local storage.
 *
 * @returns The referral info, or `undefined` if no referral was stored
 */
export function getReferralInfo() {
  // Catch any errors here, since we don't want referral code to block registering
  try {
    const params = queryString.parse(window.location.search);

    // TODO: Convert this to a cookie, for two reasons: 1) we can persist it after the session for
    //       better attribution (while still expiring it, which `localStorage` doesn't provide), and
    //       2) it's available across domains--currently this only works if the `ref` value is
    //       passed directly to the app domain; values passed to the marketing domain aren't
    //       accessible. [twl 13.Aug.20]
    const ref = getSessionValue('ref');

    let type = null;
    let code = getCookieValue('_fprom_code');
    let data = getCookieValue('_fprom_track');

    if (code || data) {
      type = 'firstPromoterDisabled';
    } else if (ref || params.ref) {
      type = 'internal';
      code = ref || params.ref;
    } else if (params.via) {
      type = 'firstPromoterDisabled';
      code = params.via;
    }

    return !code && !data ? undefined : { type, code, data };
  } catch (e) {
    console.error(e);

    return undefined;
  }
}
