import React from 'react';
import IntentListWidget from 'widgets/intents/IntentListWidget';

const i18nPath = 'widgets.intents.UnscheduledIntentsWidget';

const UnscheduledIntentsWidget = props => (
  <IntentListWidget {...props} i18nPath={props.i18nPath || i18nPath} filter={intent => !intent.start} />
);

export default UnscheduledIntentsWidget;
