import get from 'lodash/get';
import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router';
import classnames from 'classnames';
import queryString from 'query-string';
import ConnectButton from 'components/app/integrations/calendars/ConnectButton';
import NotificationBar from 'components/library/NotificationBar';
import Spinner from 'components/library/Spinner';
import Typography from 'components/library/Typography';
import { buildScene } from 'scenes/utils';
import loadSettings from 'services/store/settings/actions/loadSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import selectUserSettingsStatus from 'services/store/settings/selectors/selectUserSettingsStatus';
import { validateCalendarCallbackPath } from 'utils/integrations/validateCalendarCallbackPath';
import SaveOauthCode from './components/SaveOauthCode';
import { getAuthorizationState } from './utils/getAuthorizationState';

const i18nPath = 'components.app.integrations.calendars.ConnectStatus';

async function loadData() {
  const { settingsStatus, doLoadSettings } = this.props;

  if (!settingsStatus || settingsStatus.state !== 'success') {
    doLoadSettings();
  }
}

function getNotificationContents(redirectPath, state, t, classes) {
  const errorHeading = (
    <Typography variant="h3" color="error" paragraph>
      {t(i18nPath + '.title-error')}
    </Typography>
  );

  const connectButtons = (
    <div className={classes.buttons}>
      <ConnectButton integration="gcalendar" redirectPath={redirectPath} />
      <ConnectButton integration="cronofy" redirectPath={redirectPath} />
    </div>
  );

  switch (state) {
    case 'savingOauthCode':
    case 'loadingAuthorization':
      return <Spinner />;

    case 'savingOauthCodeFailed':
      return (
        <Fragment>
          {errorHeading}

          <Typography className={classes.message} variant="h5" paragraph>
            {t(i18nPath, 'message-saving-code-failed')}
          </Typography>

          {connectButtons}
        </Fragment>
      );

    case 'loadingAuthorizationFailed':
      return (
        <Fragment>
          {errorHeading}

          <Typography className={classes.message} variant="h5" paragraph>
            {t(i18nPath, 'message-loading-auth-failed')}
          </Typography>

          <Typography className={classes.message} variant="h5">
            {t(i18nPath, 'message-loading-auth-failed-tip')}
          </Typography>
        </Fragment>
      );

    case 'authorized:new':
    case 'authorized:existing':
      return (
        <Typography className={classes.message} variant="h5">
          {t(i18nPath, 'message-authorized')}
        </Typography>
      );

    case 'notAuthorized':
      return (
        <Fragment>
          <Typography className={classes.message} variant="h5">
            {t(i18nPath, 'message-not-authorized')}
          </Typography>

          {connectButtons}
        </Fragment>
      );

    default:
      return (
        <Fragment>
          {errorHeading}

          <Typography className={classes.message} variant="h5">
            {t(i18nPath, 'message-unknown-error')}
          </Typography>
        </Fragment>
      );
  }
}

const ConnectStatus = buildScene(({
  className,
  callbackPath,
  redirectPath,
  hideWhen,
  isAuthorized,
  settingsStatus,
  oauthCodeStatus,
  t,
  classes,
}) => {
  const state = getAuthorizationState(oauthCodeStatus, settingsStatus, isAuthorized);
  const showNotification = Array.isArray(hideWhen) ? !hideWhen.includes(state) : hideWhen !== state;

  return (
    <Fragment>
      <Route path={callbackPath} render={props =>
        settingsStatus.state !== 'success' ? null :
          <SaveOauthCode callbackPath={callbackPath} redirectPath={redirectPath} />
      } />

      {showNotification &&
        <NotificationBar className={classnames(classes.root, className)}>
          {getNotificationContents(redirectPath, state, t, classes)}
        </NotificationBar>
      }
    </Fragment>
  )
}, {
  styles: theme => ({
    root: {
      padding: theme.spacing.unit * 2,
    },
    message: {
      lineHeight: 1.5,
    },
    buttons: {
      '& > *': {
        margin:  theme.spacing.unit * 2,
      },
    },
  }),
  state: (state, { callbackPath, redirectPath, location }) => {
    const userSettings = selectUserSettings(state);
    const urlParams = queryString.parse(location.search);
    const integration = urlParams.state
      ? urlParams.state.split('_')[0]
      : get(userSettings, 'calendars.config.integration', 'gcalendar');

    if (!callbackPath) {
      callbackPath = redirectPath + '/callback';
    }

    validateCalendarCallbackPath(callbackPath);

    return {
      // TODO: Encode any property path special characters in `callbackPath` [twl 5.Jul.19]
      callbackPath,
      isAuthorized: get(userSettings, `integrations.${integration}.authorized`),
      settingsStatus: selectUserSettingsStatus(state, 'read'),
      oauthCodeStatus: selectUserSettingsStatus(state, 'write', integration, callbackPath),
    };
  },
  dispatch: {
    doLoadSettings: loadSettings,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  }
});

export default withRouter(ConnectStatus);
