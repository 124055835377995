import { types as globalTypes } from 'services/store/actions';
import {
  applyAction,
  createStatusReducer,
  removeElementById,
  setElements,
  updatePayloadProperties,
} from 'services/store/utils-reducers';
import { collection, types } from './definitions';

const initialData = [];

const actionReducers = {
  [globalTypes.LOGOUT]: () => initialData,
  [globalTypes.SET_DATA]: (state, { collection: target, data }) => (
    target !== collection ? state : setElements(state, data)
  ),

  [types.ADD_SCHEDULE]: (state, payload) => setElements(state, payload ? [ payload ] : undefined),
  [types.SET_SCHEDULES]: (state, payload) => setElements(state, payload.schedules),
  [types.UPDATE_SCHEDULE]: (state, payload) => updatePayloadProperties(state, payload),
  [types.DELETE_SCHEDULE]: (state, payload) => removeElementById(state, payload),
}

const dataReducer = (state = initialData, action) => applyAction(actionReducers, state, action);
const statusReducer = createStatusReducer(collection);

const schedulesReducer = (state = {}, action) => ({
  status: statusReducer(state.status, action),
  data: dataReducer(state.data, action)
});

export default schedulesReducer;
