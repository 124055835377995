import React, { useState } from 'react';
import classnames from 'classnames';
import TodoItem from 'components/app/todos/TodoItem';
import IntentQuickView from 'components/app/intents/IntentQuickView';
import withStyles from 'helpers/withStyles';

const height = 48;

const styles = theme => ({
  expandedWrapper: {
    cursor: 'pointer',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  expandedIntent: {
    '&:hover': {
      background: 'inherit',
    },
  },
  fillWrapper: {
    position: 'relative',
  },
  fill: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: '100%',
    width: '100%',
    background: theme.palette.grey[100],
  },
});

const Intent = ({
  id,
  className,
  value,
  actionMode,
  divider,
  expanded,
  onUpdate,
  onDelete,
  onRemove,
  onStatusChange,
  onSelect,
  onUnselect,
  classes,
  ...props
}) => {
  const [ selected, setSelected ] = useState(false);
  const intent = { ...value.todo, duration: value.duration };
  const controlled = onSelect || onUnselect;
  const isSelected = controlled ? props.selected : selected;
  const doSelect = controlled ? onSelect : (!selected ? () => setSelected(true) : undefined);
  const hasFill = value.fill > 0;

  return (
    <div
      id={id}
      className={classnames({
        [classes.expandedWrapper]: expanded,
        [classes.fillWrapper]: hasFill
      })}
      style={!expanded || isSelected ? undefined : {
        minHeight: (value.span || 1) * height,
      }}
      onClick={doSelect}
    >
      {hasFill &&
        <div
          className={classes.fill}
          style={{
            [expanded ? 'height' : 'width']: `${value.fill * 100}%`
          }}
        />
      }

      <TodoItem
        {...props}
        className={classnames(className, { [classes.expandedIntent]: expanded })}
        value={intent}
        intent={value}
        actionMode={actionMode}
        divider={!expanded && divider}
        selected={isSelected}
        onRemove={onRemove && (() => onRemove(value))}
        onStatusChange={status => onStatusChange(status, value)}
        onSelect={doSelect}
        onUnselect={controlled ? onUnselect : (selected ? () => setSelected(false) : undefined)}
      />

      {selected &&
        <IntentQuickView
          value={value}
          actionMode={actionMode}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onStatusChange={onStatusChange}
        />
      }
    </div>
  );
}

export default withStyles(styles)(Intent);
