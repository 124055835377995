import { IDocumentData } from '../../documents/types/IDocumentData';
import { IDocumentSnapshot } from '../../documents/types/IDocumentSnapshot';
import { IQuerySnapshot } from '../../queries/types/IQuerySnapshot';
import { parseDocumentChange } from './parseDocumentChange';
import { parseDocumentSnapshot } from './parseDocumentSnapshot';

const isQuerySnapshot = (value: any): value is IQuerySnapshot => (
  (value as IQuerySnapshot).docChanges !== undefined
);

/**
 * Parses either a `DocumentSnapshot` or a `QuerySnapshot` into either a single data document or an
 * array of data documents.
 *
 * @param collection - The collection the snapshot belongs to
 * @param snapshot   - The snapshot from the database
 *
 * @returns The data related to this snapshot, as either a single document or an array of documents
 */
export function parseSnapshot(
  collection: string,
  snapshot: IDocumentSnapshot | IQuerySnapshot,
): IDocumentData | IDocumentData[] | undefined {
  if (isQuerySnapshot(snapshot)) {
    return snapshot.docChanges().map(change => parseDocumentChange(collection, change));
  }

  return parseDocumentSnapshot(collection, snapshot as IDocumentSnapshot);
}
