import { IIntent, ITodo } from '@lifetools/shared-app-data';
import { IStatusButtonConfig } from '../types/IStatusButtonConfig';

export function getStates(config: IStatusButtonConfig, t: any, todo: ITodo, intent?: IIntent) {
  const configKey = intent ? 'intents' : 'todos';
  const options: any = t(`data.${configKey}.fields.status.options`, { returnObjects: true });
  const statuses: any[] = config[configKey].statuses;

  return statuses.map(value => ({
    icon: options[value] && (options[value][`icon-${todo.type}`] || options[value].icon),
    label: options[value] && (options[value][`label-${todo.type}`] || options[value].label),
    value,
  }));
}
