import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import React from 'react';
import { withRouter } from 'react-router';
import { Trans } from 'react-i18next';
import { compose } from 'recompose';
import queryString from 'query-string';
import Form from 'components/library/Form';
import Link from 'components/library/Link';
import Typography from 'components/library/Typography';
import withPage from 'helpers/withPage';
import withTranslations from 'helpers/withTranslations';
import { buildScene } from 'scenes/utils';
import analytics from 'services/analytics';
import registerUser from 'services/store/auth/actions/registerUser';
import { selectAuthRedirectStatus } from 'services/store/auth/selectors';
import loadPromotion from 'services/store/integrations/referrals/actions/loadPromotion';
import selectGlobalSettings from 'services/store/settings/selectors/selectGlobalSettings';
import { goBack } from 'utils/app/goBack';
import planDefaults from 'utils/app/plans/planDefaults';
import compileSchema from 'utils/data/compileSchema';
import { testProps } from 'utils/test/testProps';
import FederatedIdentityAuthButtons from 'widgets/buttons/FederatedIdentityAuthButtons';

const i18nPath = 'scenes.users.Register';
const fieldsI18nPath = i18nPath + '.fields';

const schema = {
  firstName: {
    type: 'string',
    validation: [
      'required',
    ],
  },
  lastName: {
    type: 'string',
    validation: [
      'required',
    ],
  },
  email: {
    type: 'string',
    validation: [
      'trim',                 // Remove spaces before validating--does not modify actual data though
      'required',
      'email',
    ],
  },
  password: {
    type: 'string',
    validation: [
      'required',
      'securePassword',
    ],
  },
  confirmPassword: {
    type: 'string',
    validation: [
      'required',
      {
        type: 'test',
        name: 'matches-password',
        test: function(value) {
          return !this.parent.password || this.parent.password === value;
        }
      },
    ],
  },
};

const Register = buildScene(({
  schema,
  defaultEmail,
  trialLength,
  promotion,
  redirectError,
  doRegister,
  classes,
  t,
}) => (
  <div className={classes.root}>
    <Typography className={classes.formTitle} variant="h3" paragraph>
      {t(i18nPath, 'form-title', { trialLength })}
     </Typography>

    <Typography className={classes.formSubtitle} variant="body2" paragraph>
      {t(i18nPath, 'form-subtitle')}
     </Typography>

    {promotion && promotion.offer && promotion.status === 'active' &&
      <Typography className={classes.promotion} variant="h4" paragraph>
          {t(i18nPath, 'form-promotion')} {promotion.offer.name}
      </Typography>
    }

    <Form.SectionTitle i18nPath={i18nPath} i18nKey="section-federated-id" />

    {redirectError &&
      <div {...testProps('RedirectError', redirectError.code)}>
        <Typography className={classes.redirectError} variant="body1">
          {t([`${i18nPath}.errors.${redirectError.code}`, `${i18nPath}.errors.auth/unknown`])}
        </Typography>
      </div>
    }

    <FederatedIdentityAuthButtons action="register" />

    <hr className={classes.divider} size="1" />

    <Form.SectionTitle className={classes.section} i18nPath={i18nPath} i18nKey="section-email" />

    <Form
      className={classes.form}
      i18nPath={i18nPath}
      schema={schema}
      values={defaultEmail ? { email: defaultEmail } : undefined}
      onSubmit={({ firstName, lastName, email, password }) => doRegister({
        email,
        password,
        profile: {
          firstName,
          lastName,
        },
      })}
    >
      <Form.TextField
        className={classes.field}
        i18nPath={fieldsI18nPath}
        name="email"
        defaultValue={defaultEmail || ''}
        autoComplete="username"
        InputLabelProps={{ classes: { root: classes.label } }}
        fullWidth
        autoFocus
      />

      <div className={classes.columnLayout}>
        <Form.TextField
          style={{ marginRight: 16 }}
          className={classes.field}
          i18nPath={fieldsI18nPath}
          name="firstName"
          autoComplete="given-name"
          InputLabelProps={{ classes: { root: classes.label } }}
          fullWidth
        />

        <Form.TextField
          className={classes.field}
          i18nPath={fieldsI18nPath}
          name="lastName"
          autoComplete="family-name"
          InputLabelProps={{ classes: { root: classes.label } }}
          fullWidth
        />
      </div>

      <div className={classes.columnLayout}>
        <Form.PasswordField
          style={{ marginRight: 16 }}
          className={classes.field}
          i18nPath={fieldsI18nPath}
          name="password"
          autoComplete="new-password"
          InputLabelProps={{ classes: { root: classes.label } }}
          fullWidth
        />

        <Form.PasswordField
          className={classes.field}
          i18nPath={fieldsI18nPath}
          name="confirmPassword"
          autoComplete="new-password"
          InputLabelProps={{ classes: { root: classes.label } }}
          fullWidth
        />
      </div>

      <Form.SubmitButton className={classes.button} i18nPath={i18nPath} i18nKey="button-register" />

      <Typography className={classes.terms} variant="body2">
        <Trans i18nKey={`${i18nPath}.label-agreement`}>
          <span>By signing up, you agree to our</span>
          <Link href="https://dayoptimizer.com/terms/" rel="noopener noreferrer" target="_blank">
            Terms & Conditions
          </Link>
          and
          <Link href="https://dayoptimizer.com/privacy/" rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </Link>
        </Trans>
      </Typography>
    </Form>
  </div>
), {
  styles: theme => ({
    root: {
      maxWidth: 420,
      margin: '0 auto',
      textAlign: 'center',

      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing.unit * 4,
      },
    },
    form: {
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing.unit * 2,
      },
    },
    formTitle: {
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    formSubtitle: {
      textAlign: 'center',

      '&:first-child': {
        marginBottom: theme.spacing.unit * 6,
      }
    },
    section: {
      whiteSpace: 'nowrap',
      margin: '0 auto',
    },
    promotion: {
      background: theme.palette.custom.notifications.highlight,
      padding: theme.spacing.unit * 2,
    },
    redirectError: {
      color: theme.palette.error.main,
      textAlign: 'center',
      margin: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,
    },
    redirectErrorLink: {
      textAlign: 'center',
      marginTop: -theme.spacing.unit,
      marginBottom: theme.spacing.unit * 4,
    },
    columnLayout: {
      display: 'flex',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 4}px`,
      borderRadius: theme.spacing.unit,
    },
    label: {
      fontSize: '1.4rem',
    },
    field: {
      paddingTop: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
    },
    divider: {
      margin: `${theme.spacing.unit * 4}px 0`,
    },
    terms: {
      margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px 0 ${theme.spacing.unit}px`,
    },
  }),
  memos: {
    // NOTE: Must be compiled here & not in `Form` since memo only stores last value [twl 7.Dec.18]
    compileSchema: (t, i18nPath) => compileSchema(schema, t, i18nPath),
  },
  state: (state, { t, memos }) => {
    const promotion = get(selectGlobalSettings(state), 'plan.promotion');
    const redirectStatus = selectAuthRedirectStatus(state);

    return {
      defaultEmail: queryString.parse(window.location.search || '').email,
      schema: memos.compileSchema(t, i18nPath),
      trialLength:
        get(promotion, 'status') === 'active' && get(promotion, 'offer.type') === 'extendTrial'
          ? get(promotion, 'offer.amount', planDefaults.trialLength)
          : planDefaults.trialLength,
      promotion,
      redirectError: redirectStatus?.error,
    };
  },
  dispatch: {
    doRegister: registerUser,
    doLoadPromotion: loadPromotion,
  },
  lifecycle: {
    componentDidMount: function() {
      if (this.props.redirectError?.code?.startsWith('auth/account-exists')) {
        this.props.history.push('/');
      }

      this.props.doLoadPromotion();

      const params = queryString.parse(window.location.search || '');

      // Don't send actual email. Just indicate that it was present.
      if (params.email) {
        params.email = 1;
      }

      analytics.track('register_view', mapKeys(params, (value, key) => `register_${key}`));
    },
  }
});

export default compose(
  withTranslations,
  withRouter,
  withPage({
    i18nPath,
    theme: 'darkForm',
    // infoUrl: 'https://dayoptimizer.com/app/register/',
    onPageClose: goBack,
  }),
)(Register);
