import { services } from '@lifetools/shared-services';
import { Id } from './types/Id';
import { DocumentMetaProperties } from '../extensions/types/DocumentMetaProperties';
import { callTriggers } from '../triggers/callTriggers';
import { updateDocument } from './updateDocument';

/**
 * Removes the document from the `collection` whose ID is `id`.
 *
 * @param collection          - The name of the collection the document belongs to
 * @param id                  - The ID of the document
 * @param options.forceDelete - Whether to force deletion of the document instead of archiving it;
 *                              should only be used for testing
 */
export async function deleteDocument(
  collection: string,
  id: Id,
  options: { forceDelete: boolean } = { forceDelete: false },
): Promise<void> {
  await callTriggers('before', 'delete', collection, id);

  if (options.forceDelete) {
    await services.database.collection(collection).doc(id).delete();
  } else {
    // NOTE: We're no longer deleting the documents, but rather marking them archived. This ensures
    //       historical accuracy, so a user deleting a todo doesn't delete it from all schedules in
    //       the past. [twl 19.Apr.19]
    await updateDocument(collection, id, {
      [DocumentMetaProperties.Archived]: true,
      [DocumentMetaProperties.ArchivedAt]: services.database.fieldValues.serverTimestamp(),
    });
  }
}
