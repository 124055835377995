import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { LocalDate } from 'js-joda';
import CalendarDateSelector from 'components/library/CalendarDateSelector';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { resolveRoute } from 'utils/app/routes/resolveRoute';
import { toRouteDate } from 'utils/app/routes/toRouteDate';
import PlanDateButton from './components/PlanDateButton';

const i18nPath = 'scenes.app.Today.components.NoTodayViewSurveyWithButtons.components.WhichPlanDate';

const styles = theme => ({
  header: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 4}px 0`,
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing.unit}px ${theme.spacing.unit * 4}px`,
    }
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: 200,

    '& > *': {
      marginBottom: theme.spacing.unit * 4,
    },
  },
  calendar: {
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.unit * 6,
    },
  },
});

const WhichPlanDate = ({ planDate, history, match, classes, t }) => (
  <div>
    <Typography className={classes.header} variant="h2">
      {t(i18nPath, 'title')}
    </Typography>

    <div className={classes.options}>
      <PlanDateButton currentPlanDate={planDate} planDate="today" />
      <PlanDateButton currentPlanDate={planDate} planDate="tomorrow" />
      <PlanDateButton currentPlanDate={planDate} planDate={LocalDate.now().plusDays(2)} />
    </div>

    {planDate && !['today', 'tomorrow'].includes(planDate) &&
      <CalendarDateSelector
        className={classes.calendar}
        date={planDate}
        onSelect={date => history.push(resolveRoute(match.path, { planDate: toRouteDate(date) }))}
      />
    }
  </div>
);

export default compose(
  withRouter,
  withStyles(styles),
  withTranslations,
)(WhichPlanDate);
