import { services } from '@lifetools/shared-services';
import { unflattenPathKeys } from '@lifetools/shared-utils';
import { IDocumentData } from './types/IDocumentData';
import { getDocument } from './getDocument';
import { toDatabaseJson } from './utils/toDatabaseJson';
import { callTriggers } from '../triggers/callTriggers';

/**
 * Adds the `values` as a document to the `collection`, returning a new document instance as it was
 * saved on the server.
 *
 * @param collection - The name of the collection to add the document to
 * @param values     - The document data
 *
 * @returns The new document data
 */
export async function addDocument(
  collection: string,
  values: IDocumentData,
): Promise<IDocumentData> {
  // TODO: Assert values doesn't already have an `id` [twl 15.Jun.18]

  // TODO: Add a security check in there to ensure we are creating a document that belongs to this
  //       user. [twl 11.Apr.19]
  const toAdd = await callTriggers('before', 'create', collection, undefined, values);

  if (!toAdd) {
    throw new Error(`The 'before' triggers for 'addDocument' returned no values`);
  }

  // Added values need to be unflattened first
  const firebaseValues = toDatabaseJson(unflattenPathKeys(toAdd), false);
  const docRef = await services.database.collection(collection).add(firebaseValues);

  // TODO: Think about using the new `resolveServerTimestamps` in `shared-utils-time` to resolve
  //       the `createdAt` field [twl 17.Dec.19]

  // Retrieve the document from the server to get the calculated fields like `createdAt`
  const doc = await getDocument(collection, docRef.id);

  if (!doc) {
    throw new Error(`Failed to retrieve added document ${docRef.id} from '${collection}'`);
  }

  return doc;
}
