import { services } from '@lifetools/shared-services';
import { DocumentPath } from './types/DocumentPath';
import { IDocumentReference } from './types/IDocumentReference';

/**
 * Returns the document reference for the document at the `path`.
 *
 * @param path - The path to the document
 *
 * @returns A document reference that can be used to manage the document
 */
export const getDocumentReference = (path: DocumentPath): IDocumentReference => (
  typeof path === 'string'
    ? services.database.doc(path)
    : services.database.collection(path.collection).doc(path.id)
);
