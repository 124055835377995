import { mapValuesDeep } from '@lifetools/shared-utils';
import { isServerTimestamp } from '../detect/isServerTimestamp';
import { ITimestamp } from '../types/ITimestamp';
import { toTimestamp } from './toTimestamp';

/**
 * Converts any `FieldValue` objects that represent server timestamps to the current timestamp on
 * the client.
 *
 * @param object - An object whose timestamps should be resolved
 *
 * @returns A copy of the object with all `FieldValue.serverTimestamp` objects resolved to
 *          timestamps that represent the current time
 */
export function resolveServerTimestamps(object: any): any {
  const now = toTimestamp(new Date());

  return mapValuesDeep(object, (value: any, key: string | number, path: string) => (
    isServerTimestamp(value) ? now : value
  ));
}
