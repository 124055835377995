import { ChronoUnit, LocalTime } from 'js-joda';
import { IIntent } from '@lifetools/shared-app-data';
import { toLocalTime } from '@lifetools/shared-utils-time';

/**
 * Calculates the remaining amount of time until the `intent` is considered done, given the current
 * time. For most intents, this will be the entire duration of the intent, but if an intent is
 * currently being worked on, it will be the remaining scheduled time for the intent.
 *
 * @param intent - The intent whose remaining time is to be calculated
 * @param now    - The time representing the current point in time
 *
 * @returns The remaining number of minutes until the intent is done
 */
export function timeUntilDone(intent: IIntent, now: LocalTime = LocalTime.now()): number {
  if (!intent || !intent.status || intent.status.state !== 'new') {
    return 0;
  }

  const start = intent.start ? toLocalTime(intent.start) : undefined;

  // Assume the intent that it scheduled for now is being worked on and only contribute it's
  // remaining duration to the total remaining duration for all the intents
  if (intent.duration && start && start.isBefore(now)) {
    const timeSinceStart = start.until(now, ChronoUnit.MINUTES);

    if (timeSinceStart < intent.duration) {
      return intent.duration - timeSinceStart;
    }
  }

  return intent.duration || 0;
}
