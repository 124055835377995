import { get } from 'lodash';
import { services } from '@lifetools/shared-services';
import { toNativeDate } from '@lifetools/shared-utils-time';
import { getTrackingUserId } from '../../../../../utils/app/getTrackingUserId';
import { getVersionAsInteger } from '../../../../../utils/app/getVersionAsInteger';
import { isIntegrationTestEnvironment } from '../../../../../utils/app/isIntegrationTestEnvironment';
import selectAppSettings from '../../../settings/selectors/selectAppSettings';
import selectUserSettings from '../../../settings/selectors/selectUserSettings';
import store from '../../../store';

export default async function setFeatureUser(authInfo: any) {
  if (isIntegrationTestEnvironment()) {
    return;
  }

  const { user } = authInfo;
  const state = store.getState();
  const appSettings = selectAppSettings(state);
  const userSettings = selectUserSettings(state);
  const createdAt = toNativeDate(user.createdAt);

  services.features.setFeatures(appSettings.features);
  services.features.setAttributes({
    id: getTrackingUserId(user.id),
    loggedIn: true,
    appVersionNumber: getVersionAsInteger(process.env.REACT_APP_VERSION),
    cohort: get(userSettings, 'cohort', 'unknown'),
    scheduleCount: get(userSettings, 'info.schedules.count', 0),
    ...createdAt && { registeredAt: createdAt.toISOString() },
    // calendarIntegration: get(userSettings, 'calendars.config.integration'),
  });
}
