/**
 * Parses an icon type into its `namespace` and its `icon`,
 *
 * @param type - The icon type, in the form `[namespace:]icon`
 *
 * @returns An object with `namespace` and `icon` properties
 */
export function parseIconType(type: string): { namespace?: string, icon: string } {
  const separatorIndex = type?.indexOf(':') ?? -1;
  const namespace = separatorIndex >= 0 ? type.substring(0, separatorIndex) : undefined;
  const icon = namespace ? type.substring(type.indexOf(':') + 1) : type;

  return {
    namespace,
    icon,
  };
}
