import store from 'services/store';

/**
 * Returns a reference to the `dispatch` function in the Redux store. Designed to replicate the
 * functionality of https://react-redux.js.org/api/hooks#usedispatch until we can upgrade the
 * library.
 */
export function useDispatch() {
  return store.dispatch;
}
