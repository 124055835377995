import { services } from '@lifetools/shared-services';
import { initializeAnalytics } from './server/initialize/analytics';
import { initializeAuth } from './server/initialize/auth';
import { initializeData } from './server/initialize/data';
import { initializeDatabase } from './server/initialize/database';
import { initializeFeatures } from './server/initialize/features';
import { initializeFunctions } from './server/initialize/functions';
import { initializeFirebase } from './server/initialize/firebase';
import { initializeConfig } from './initializeConfig';

// Initialize internationalization support
import 'services/i18n';

/**
 * Initialize the services used by this package.
 */
export function initialize() {
  services.config = initializeConfig(process.env);
  [services.firebase, services.firebaseApp] = initializeFirebase(services.config);
  services.auth = initializeAuth(services.firebaseApp, services.config);
  services.features = initializeFeatures();

  const data = initializeData();
  const database = initializeDatabase(services.firebase);

  services.initializeDatabase(database, services.firebase.firestore.FieldValue);
  services.data.triggers = data.triggers;
  services.data.schemas = data.schemas;

  services.functions = initializeFunctions(services.firebaseApp);
  services.analytics = initializeAnalytics(services.firebaseApp);

  return services;
}
