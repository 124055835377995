import { types as globalTypes } from 'services/store/actions';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection } from '../definitions';

const clearTodoStatuses = makeAsyncActionCreator({
  type: globalTypes.CLEAR_ALL_STATUSES,
  creator: () => ({ path: collection }),
});

export default clearTodoStatuses;
