import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getKeyChar, wasEnterPressed, wasEscapePressed } from 'components/utils-events-key';
import SelectInputHelper from './components/SelectInputHelper';
import StrengthInputHelper from './components/StrengthInputHelper';
import './InputObject.css';

function wasTriggerPressed(triggers, event) {
  // Don't trigger on modifier key presses that don't alter the key (shift okay)
  return event.ctrlKey || event.altKey || event.metaKey ? false : triggers[event.key] != null;
}

function buildTriggerLookup(helpers) {
  const lookup = {};

  if (helpers) {
    for (const property in helpers) {
      lookup[helpers[property].trigger] = property;
    }
  }

  return lookup;
}

function getInputInfo(state, event) {
  const { triggerStart, triggerEnd } = state;
  const { selectionStart: cursorIndex, value } = event.target;
  const key = getKeyChar(event);

  let text = triggerStart != null ? value.substring(triggerStart, triggerEnd + 1) : '';

  // Insert pressed key into the text
  if (key && key.length === 1) {
    text = text.substring(0, cursorIndex) + key + text.substring(cursorIndex);
  }

  return {
    key,
    start: triggerStart,
    end: triggerEnd,
    trigger: text.substring(0, 1),
    text: text.substring(1),
  };
}

class InputObject extends Component {
  static propTypes = {
    /** The custom class to add to this component */
    className: PropTypes.string,

    /** The name of the property containing the editable text on `value` */
    textProperty: PropTypes.string.isRequired,

    /** The object to edit */
    value: PropTypes.object,

    /** The text to display as the placeholder on the text editor */
    placeholder: PropTypes.string,

    /**
     * A hash mapping property names to an options object for configuring the helper in this editor.
     * The `trigger` is a character used to trigger the helper when the user is typing, and the
     * `helper` is a component that is displayed when the trigger occurs.
     */
    helpers: PropTypes.object,

    /** Called when the user takes an action to commit the current changes to the `value` */
    onSave: PropTypes.func,

    /** Called when the user cancels all changes to the `value` */
    onCancel: PropTypes.func,

    /** Called each time a property on `value` is modified */
    onPropertySave: PropTypes.func,
  };

  state = {
    activeProperty: undefined,
    activeValue: undefined,
    triggerStart: undefined,
    triggerEnd: undefined,
  };

  triggers = helpers => buildTriggerLookup(helpers);

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  helperInstance() {
    const helper = this.helper;

    // When a helper is first triggered, it doesn't exist in the DOM yet, so `helper` will be
    // undefined; helpers using translation will be wrapped in a translation proxy component
    return helper && helper.getWrappedInstance && helper.getWrappedInstance() ?
            helper.getWrappedInstance() : helper;
  }

  // ----------------------------------
  // Text Input Event Handlers
  // ----------------------------------
  handleTextChange = (event) => {
    const { textProperty, value: object, onPropertySave } = this.props;
    const { value } = event.target;

    onPropertySave && onPropertySave(textProperty, value, object);
  };

  handleKeyDown = (event) => {
    let stateUpdate;

    const { activeProperty } = this.state;

    if (!activeProperty) {
      stateUpdate = this.handleTriggerKeys(event);
    } else {
      stateUpdate = this.handleActivePropertyKeys(event);
    }

    const nextState = { ...this.state, ...stateUpdate };
    let handled = false;

    if (nextState.activeProperty) {
      handled = this.handleHelperKeys(event, nextState);
    }

    if (!handled && !stateUpdate) {
      handled = this.handleInputKeys(event);
    }
  };

  handleActivePropertyKeys = (event) => {
    const { selectionStart: cursorIndex } = event.target;
    const key = getKeyChar(event);

    let { activeProperty, activeValue, triggerStart, triggerEnd } = this.state;
    let helper = this.helperInstance();
    let stateUpdate;

    switch (key) {
      case 'Tab':
      case 'Enter':
        if (helper && helper.validate && !helper.validate(activeValue)) {
          stateUpdate = {
            activeProperty: undefined,
          };
          break;
        }

        this.handlePropertySave(activeProperty, activeValue);

        const { textProperty, value: object, onPropertySave } = this.props;
        const newText = event.target.value.substring(0, triggerStart) +
                        event.target.value.substring(triggerEnd + 1);

        onPropertySave && onPropertySave(textProperty, newText, object);

        stateUpdate = {
          activeProperty: undefined,
        };
        break;

      case 'ArrowLeft':
      case 'ArrowRight':
        if (cursorIndex <= triggerStart) {
          stateUpdate = {
            activeProperty: undefined,
          };
        }
        break;

      case 'Escape':
        stateUpdate = {
          activeProperty: undefined,
        };
        break;

      case 'Backspace':
      case 'Delete':
        // Delete removes from the right of the cursor, so if we're past the trigger end, do nothing
        if (key === 'Delete' && cursorIndex > triggerEnd) {
          break;
        }

        stateUpdate = {
          triggerEnd: triggerEnd - 1,
        };

        if (stateUpdate.triggerEnd < triggerStart) {
          stateUpdate.activeProperty = undefined;
        }
        break;

      default:
        stateUpdate = {
          triggerEnd: triggerEnd != null ? Math.max(triggerEnd, cursorIndex) : cursorIndex,
        };
        break;
    }

    if (stateUpdate) {
      this.setState(stateUpdate);
    }

    return stateUpdate;
  }

  handleTriggerKeys = (event) => {
    const triggers = this.triggers(this.props.helpers);
    const { selectionStart: cursorIndex,  value } = event.target;
    const key = getKeyChar(event);

    let { activeProperty } = this.state;
    let stateUpdate;

    if (wasTriggerPressed(triggers, event) &&
        (cursorIndex === 0 || value.charAt(cursorIndex - 1) === " ")) {
      activeProperty = triggers[key];

      stateUpdate = {
        triggerStart: cursorIndex,
        triggerEnd: cursorIndex,
        activeProperty,
      };

      this.setState(stateUpdate);
    }

    return stateUpdate;
  };

  handleHelperKeys = (event, state) => {
    let helper = this.helperInstance();

    return helper && helper.onKeyInput && helper.onKeyInput(getInputInfo(state, event));
  };

  handleInputKeys = (event) => {
    const { onSave, onCancel } = this.props;
    const { value } = event.target;

    let handled = false;

    if (wasEnterPressed(event)) {
      onSave && onSave(value);
      handled = true;
    }

    if (wasEscapePressed(event)) {
      onCancel && onCancel();
      handled = true;
    }

    return handled;
  };

  handlePropertySave = (property, value) => {
    const { value: object, onPropertySave } = this.props;

    onPropertySave && onPropertySave(property, value, object);
  };

  // ----------------------------------
  // Helper Event Handlers
  // ----------------------------------
  handlePropertyChange = (property, newValue, oldValue) => {
    this.setState({ activeValue: newValue });
  };

  handlePropertyCancel = () => {
    this.setState({
      activeProperty: undefined,
      activeValue: undefined
    });
  };

  // ----------------------------------
  // Render Functions
  // ----------------------------------
  renderHelper(property, value) {
    const { helpers } = this.props;
    const Helper = helpers[property] && helpers[property].helper;

    return Helper && <Helper ref={helper => this.helper = helper}
                             property={property}
                             value={value}
                             config={helpers[property]}
                             onPropertyChange={this.handlePropertyChange}
                             onCancel={this.handlePropertyCancel} />;
  }

  render() {
    const { className, textProperty, value, placeholder, autoFocus } = this.props;
    const { activeProperty, activeValue } = this.state;

    return (
      <div className={classNames('InputObject', className)}>
        <input ref={this.textInput}
               className="InputObject_input"
               type="text"
               placeholder={placeholder}
               value={value[textProperty] || ''}
               onKeyDown={this.handleKeyDown}
               onChange={this.handleTextChange}
               autoFocus={autoFocus} />

        {this.renderHelper(activeProperty, activeValue)}
      </div>
    );
  }
}

InputObject.SelectInputHelper = SelectInputHelper;
InputObject.StrengthInputHelper = StrengthInputHelper;

export default InputObject;
