import React from 'react';
import { compose } from 'recompose';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import StatusInputButton from './components/StatusInputButton';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const defaultStatuses = [ 'new', 'done', 'wontdo' ];
const intentTaskStatuses = [ 'new', 'done', 'done-todo', 'wontdo', 'wontdo-todo' ];

const getNotDoneStatus = todo => {
  if (!todo.intents) {
    return 'new';
  } else if (todo.status && todo.status.state === 'doing') {      // optimization
    return 'doing';
  }

  // Search for an intent that indicates this todo is in the `doing` state
  for (const intent of Object.values(todo.intents)) {
    if (['doing', 'done'].includes(intent && intent.status && intent.status.state)) {
      return 'doing';
    }
  }

  return 'intent';
}

const ActionInputPane = ({ todo, intent, onStatusChange, onInputDone, classes, t }) => {
  const onClick = (status, todo) => {
    // To ensure data integrity, these statuses can't be set manually; instead, setting the status
    // to `new` forces a re-calculation of the status. [twl 11.01.20]
    if (['intent', 'doing'].includes(status)) {
      // NOTE: This won't change the status if the todo has an intent that forces the todo to keep
      //       the current status (e.g. `done-todo` or `wontdo-todo`). [twl 25.Mar.20]
      status = 'unknown';
    }

    onStatusChange(status, todo);
    onInputDone();
  };
  const statuses = intent ? (todo.type === 'task' ? intentTaskStatuses : defaultStatuses)
                          : [ getNotDoneStatus(todo), 'done', 'wontdo' ];

  return (
    <div className={classes.root}>
      {statuses.map(status => (
        <StatusInputButton
          key={status}
          status={status}
          todo={todo}
          intent={intent}
          onClick={onClick}
        />
      ))}
    </div>
  );
}

export default compose(
  withStyles(styles),
  withTranslations,
)(ActionInputPane);
