import {
  GoogleAuthProvider,
  getAuth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut,
  updateProfile,
  useDeviceLanguage,
} from 'firebase/auth';
import { IAuthService } from '@lifetools/shared-services';
import { getCurrentUser } from '../auth';

export function initializeAuth(firebaseApp: any, config: any): IAuthService {
  const auth = getAuth(firebaseApp);
  // const AppleAuthProvider = OAuthProvider('apple.com');

  if (config.firebase.authEmulatorUrl) {
    connectAuthEmulator(auth, config.firebase.authEmulatorUrl);
  }


  return {
    api: {
      // @ts-ignore - Firebase v9 incorrectly types these as FirebaseAuth, not Auth [twl 27.May.24]
      createUserWithEmailAndPassword: createUserWithEmailAndPassword.bind(null, auth),
      // @ts-ignore
      fetchSignInMethodsForEmail: fetchSignInMethodsForEmail.bind(null, auth),
      // @ts-ignore
      sendPasswordResetEmail: sendPasswordResetEmail.bind(null, auth),
      // @ts-ignore
      signInWithEmailAndPassword: signInWithEmailAndPassword.bind(null, auth),
      // @ts-ignore
      signInWithRedirect: signInWithRedirect.bind(null, auth),
      // @ts-ignore
      signOut: signOut.bind(null, auth),
      // @ts-ignore
      useDeviceLanguage: useDeviceLanguage.bind(null, auth),
      updateUserProfile: (updates: Parameters<typeof updateProfile>[0]) => {
        if (!auth.currentUser) {
          throw new Error('No user is currently signed in');
        }

        updateProfile(auth.currentUser, updates)
      },
      currentUser: () => auth.currentUser,
    },
    isServiceAccount: () => false,
    isAdmin: () => false,
    userId: () => getCurrentUser()?.uid,
    preferredProviders: ['google.com'],
    providers: {
      // Since this is global branding, it should not be localized
      // 'apple.com': {
      //   label: 'Apple',
      //   icon: 'fab:apple',
      //   factoryClass: auth.OAuthProvider,
      // },
      // 'facebook.com': {
      //   label: 'Facebook',
      //   icon: 'fab:facebook',
      //   providerClass: auth.FacebookAuthProvider,
      // },
      'google.com': {
        label: 'Google',
        icon: 'fab:google',
        providerClass: GoogleAuthProvider,
      },
      // 'twitter.com': {
      //   label: 'Twitter',
      //   icon: 'fab:twitter',
      //   providerClass: auth.TwitterAuthProvider,
      // },
    },
  }
};
