import get from 'lodash/get';
import { IUserSettings } from '@lifetools/shared-app-data';
import { SimpleExpression } from '../../../../types/SimpleExpression';
import { evaluateSimpleExpression } from '../../../../utils/data/evaluateSimpleExpression';
import { resolveVariable } from './resolveVariable';

/**
 * Returns whether the tip specified by `tip` should be shown for the user with `userSettings`.
 *
 * @param tip          - A parsed tip spec
 * @param helpPath     - The path to this tip in `userSettings`
 * @param userSettings - A user settings object for a user
 *
 * @returns `true` if the tip should be shown; `false` otherwise
 */
export function isTipShowing(
  tip: { name: string, when?: SimpleExpression },
  helpPath: string,
  userSettings: IUserSettings,
): boolean {
  const isHidden = get(userSettings, `help.${helpPath}.${tip.name}`, false);

  return isHidden
    ? false
    : tip.when
      ? evaluateSimpleExpression(tip.when, resolveVariable.bind(null, userSettings))
      : true;
}
