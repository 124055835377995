import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import { IUserSettings } from '@lifetools/shared-app-data';
import { decodeDocumentPathElement } from '@lifetools/shared-database';

/**
 * Reads the colors for each calendar in user settings and returns a map mapping unencoded calendar
 * IDs to the colors for those calendars.
 *
 * @param userSettings - The current user settings
 *
 * @returns An object mapping calendar IDs to hex color values
 */
export function getCalendarColors(userSettings: IUserSettings): { [calendarId: string]: string } {
  const infos = get(userSettings, 'calendars.infos');
  const decodedInfos = mapKeys(infos, (value, key) => decodeDocumentPathElement(key));

  return mapValues(decodedInfos, value => get(value, 'settings.color', value.color));
}
