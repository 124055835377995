import { getPaths } from './getPaths';

/**
 * Returns the child paths that exist in the object tree underneath the `path`.
 *
 * @param object - The root of the change object to search
 * @param path   - The parent path
 *
 * @returns An array of the child paths that exist underneath the `path`
 */
export const getChildPaths = (object: object, path: string): string[] => (
  getPaths(object).filter(existingPath => existingPath !== path && existingPath.startsWith(path))
);
