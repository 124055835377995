import get from 'lodash/get';
import { callFunction } from 'services/server/data';
import store from 'services/store';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from 'services/store/settings/definitions';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';

export const syncAccountInfo = makeAsyncActionCreator({
  type: types.SET_USER_SETTINGS,
  when: firstRunOrAfterOldFailure,
  creator: async () => {
    const accountInfo = await callFunction('calendars_syncAccountInfo');

    // Return empty object to set if no account info returned, which happens when the user hasn't
    // yet connected with the integration service
    return !accountInfo ? {} : {
      settings: {
        ...accountInfo
      }
    };
  },
  status: {
    path: collection,
    type: 'read',
    parameters: () => {
      const integration = get(selectUserSettings(store.getState()), 'calendars.config.integration');

      // If the integration changes, we need to re-execute this function
      return `user.integrations.${integration}.accountInfo`;
    },
  }
});

export default syncAccountInfo;
