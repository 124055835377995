/**
 * Parses a display name into a first and last name.
 */
export function parseDisplayName(displayName: string): { firstName?: string, lastName?: string } {
  const names = displayName.split(' ');

  // NOTE: The assumption here is that having two first names separated by a space (e.g.,
  //       "Lisa Marie") is more common than having two last names separated by a space. If there's
  //       only one name, use that as the first name, since that's what we use when sending emails.
  //       The algorithm will always get it wrong sometimes, so it just needs to be "good enough".
  //       [twl 25.Jul.22]
  const lastName = names.length > 1 ? names.pop() : undefined;
  const firstName = names.length > 1 ? names.join(' ') : names?.[0];

  return {
    firstName,
    lastName,
  };
}
