import { LocalTime } from 'js-joda';

/**
 * Rounds the `time` to the next later time that aligns with the multiple of minutes.
 *
 * @param time     - The time to round
 * @param multiple - The number of minutes to round the time to
 *
 * @return The time rounded to the next multiple after the `time`
 */
export function timeCeilMinutes(time: LocalTime, multiple: number = 1): LocalTime {
  const remainder = time.minute() % multiple;

  return time.plusMinutes(remainder > 0 ? multiple - remainder : 0);
}
