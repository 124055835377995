import isPlainObject from 'lodash/isPlainObject';
import { LocalDate as JodaLocalDate, LocalTime as JodaLocalTime } from 'js-joda';
import { services } from '@lifetools/shared-services';
import { LocalTime } from '@lifetools/shared-utils-time';
import { IDocumentData } from '../../documents/types/IDocumentData';

/**
 * Returns whether the value should be converted to a string before being sent to the database.
 *
 * @param value - The value to convert
 *
 * @returns `true` if the value should be converted to a string
 */
function shouldToString(value: any): boolean {
  return value instanceof JodaLocalTime || value instanceof JodaLocalDate ||
         value instanceof LocalTime;
}

/**
 * Converts the data into a format to send to the database.
 *
 * @param data      - The data to convert
 * @param forUpdate - When `true`, prepare the data for an `update` operation; otherwise prepare for
 *                    a  `query` or `create` operation. Defaults to `true`.
 *
 * @returns a deep copy of the data converted into the format required for the database
 */
export function toDatabaseJson(data: IDocumentData, forUpdate: boolean = true): IDocumentData {
  const json: IDocumentData = {};

  for (const key of Object.keys(data)) {
    const value = data[key];
    const newValue = shouldToString(value)
      ? value.toString()
      : (isPlainObject(value) ? toDatabaseJson(value, forUpdate) : value);

    if (typeof newValue !== 'undefined') {
      json[key] = newValue;
    } else if (forUpdate) {
      json[key] = services.database.fieldValues.delete();
    }
  }

  return json;
}
