import upperFirst from 'lodash/upperFirst';
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import ErrorBoundary from 'components/code/ErrorBoundary';
import CssBaseline from 'components/library/CssBaseline';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import IFrame from 'components/library/IFrame';
import Typography from 'components/library/Typography';
import { testProps } from 'utils/test/testProps';

const styles = theme => ({
  root: {
    display: 'flex',
    background: theme.palette.common.white,
    minHeight: '100vh',

    '& > *': {
      padding: theme.spacing.unit * 4,
      flex: '1 1 50%',
    },
  },
  rootDarkForm: {
    [theme.breakpoints.up('sm')]: {
      background: '#333',
    },
  },
  rootWithInfo: {
    [theme.breakpoints.up('md')]: {
      background: theme.palette.common.white,
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: `${-theme.spacing.unit * 3}px ${-theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px ${-theme.spacing.unit * 3}px`,
      paddingBottom: theme.spacing.unit,
      borderBottom: `solid 1px ${theme.palette.grey['200']}`,

      '& > *': {
        flex: '0 1 auto',
      },
    },
  },
  headerDarkForm: {
    [theme.breakpoints.down('xs')]: {
      borderBottom: 'none',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainDarkForm: {
    background: theme.palette.common.white,
    height: 'fit-content',
    maxWidth: 450,
    margin: `${theme.spacing.unit * 2}px auto`,

    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing.unit * 8}px auto`,
      borderRadius: 12,
    }
  },
  mainWithInfo: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 'initial',
      margin: 0,
    },
  },
  info: {
    background: theme.palette.grey['50'],
    display: 'flex',
    borderRight: `solid 1px ${theme.palette.grey['300']}`,
    minHeight: 1000,

    '& > *': {
      flex: '1 1 auto',
    },

    [theme.breakpoints.down('xs')]: {
      borderTop: `solid 1px ${theme.palette.grey['300']}`,
      borderRight: 'none',
      minHeight: 1500,                 // HACK: Good enough to show register content [twl 13.May.20]
    },
  },
  closeButton: {
    position: 'fixed',
    top: theme.spacing.unit * 3,
    right: theme.spacing.unit * 3,
    color: theme.palette.grey['500'],
    fontSize: '1rem',
    padding: theme.spacing.unit,

    '&:hover': {
      color: theme.palette.grey['700'],
    },

    [theme.breakpoints.down('xs')]: {
      position: 'inherit',
      marginLeft: theme.spacing.unit,
    },
  },
  logo: {
    maxWidth: '100%',
    margin: `${theme.spacing.unit * 4}px auto ${theme.spacing.unit * 6}px auto`,

    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing.unit,
      minWidth: 0,
    }
  },
  logoDarkForm: {
    margin: 0,
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing.unit * 4,
    lineHeight: 1.5,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing.unit * 4,
  },
  contentDarkForm: {
    alignItems: 'stretch',
    paddingBottom: 0,
    width: '100%',
  },
});

const themedClass = (classes, style, theme, hasInfoPage) => (
  classnames(
    classes[style],
    classes[`${style}${upperFirst(theme)}`],
    hasInfoPage ? classes[`${style}WithInfo`] : undefined,
  )
);

const AnonymousPage = ({ i18nPath, title, infoUrl, theme, onPageClose, children, classes, t }) => {
  const renderTitle = title || t(i18nPath, 'title', { defaultValue: '' });

  return (
    <div className={themedClass(classes, 'root', theme, infoUrl)} {...testProps('AnonymousPage')}>
      <CssBaseline />

      {infoUrl &&
        <div className={classes.info}>
          <IFrame source={infoUrl} scrolling="no" />
        </div>
      }

      <div className={themedClass(classes, 'main', theme, infoUrl)}>
        <div className={themedClass(classes, 'header', theme, infoUrl)}>
          <img
            className={themedClass(classes, 'logo', theme, infoUrl)}
            src="/images/logo-white-bg.png"
            alt={t('app-title')}
          />

          {onPageClose &&
            <IconButton className={classes.closeButton} onClick={onPageClose}>
              <Icon type="fas:times" />
            </IconButton>
          }
        </div>

        {renderTitle && renderTitle !== '' &&
          <Typography className={classes.title} variant="h1">{renderTitle}</Typography>
        }

        <ErrorBoundary>
          <main className={themedClass(classes, 'content', theme, infoUrl)}>
            {children}
          </main>
        </ErrorBoundary>
      </div>
    </div>
  );
}

AnonymousPage.propTypes = {
  /** The components to render as the content of this page */
  children: PropTypes.node,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /** A function used to provide translations of text. Provided by `withTranslations`. */
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslations,
  withStyles(styles),
)(AnonymousPage);
