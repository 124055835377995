import React, { useState } from 'react';
import classnames from 'classnames';
import { toLocalDate, toNativeDate } from '@lifetools/shared-utils-time';
import Calendar from 'components/library/Calendar';
import withStyles from 'helpers/withStyles';
import DateSelectorCellWrapper from './components/DateSelectorCellWrapper';

const styles = theme => ({
  root: {
    width: 320,
    height: 320,

    '& .rbc-date-cell:not(.rbc-current):hover': {          // hover date
      background: 'inherit',
    },

    '& .rbc-date-cell': {
      display: 'none',
    },
  },
});

const CalendarDateSelector = ({ className, date, onSelect, classes }) => {
  const [monthDate, setMonthDate] = useState(toNativeDate(date));

  return (
    <Calendar
      className={classnames(classes.root, className)}
      components={{
        dateCellWrapper: props => (
          <DateSelectorCellWrapper selectedDate={date} {...props} />
        ),
      }}
      date={monthDate}
      onSelectDate={
        (date, info) => info === 'detail' ? onSelect(toLocalDate(date)) : setMonthDate(date)
      }
    />
  );
}

export default withStyles(styles)(CalendarDateSelector);
