import { services } from '@lifetools/shared-services';
import { subscribe } from '../subscriptions/subscribe';
import { createSubscriptionId } from '../subscriptions/createSubscriptionId';
import { IDocumentData } from './types/IDocumentData';
import { IDocumentOptions } from './types/IDocumentOptions';
import { Id } from './types/Id';
import { applySchema } from './utils/applySchema';

/**
 * Returns the document from the collection with the `id`.
 *
 * @param collection - The name of the collection to retrieve the documents from
 * @param id         - The ID of the document to retrieve
 * @param options    - The options on how to retrieve this document
 *
 * @returns The document data, or `undefined` if no document with the ID could be found
 */
export async function getDocument(
  collection: string,
  id: Id,
  options?: IDocumentOptions,
): Promise<IDocumentData | undefined> {
  const authUserId = services.auth.userId();

  if ((!options || !options.skipAuthenticationCheck) && !authUserId && !services.auth.isAdmin()) {
    // TODO: Think about whether we want to throw an error here. [twl 15.Jun.18]
    services.logger.warn(`Aborting attempt to get a document from '${collection}'. No` +
                         ` authenticated user.`);

    return undefined;
  }

  const docRef = await services.database.collection(collection).doc(id);

  if (options?.noSubscription) {
    const doc = await docRef.get();

    return doc.exists ? { id, ...applySchema(doc.data(), collection) } : undefined;
  } else {
    const subscriptionId = createSubscriptionId(authUserId, collection, { id });

    return await subscribe(collection, docRef, subscriptionId);
  }
}
