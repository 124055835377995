import React from 'react';
import ActionMenuButton from 'components/app/todos/ActionMenuButton';
import ListItem from 'components/library/ListItem';

// TODO: Review whether this is the best place for this. [twl 25.Mar.20]
const buttonWidth = 40;

const TodoItemSecondaryAction = ({ className, value, ...props }) => (
  <ListItem.SecondaryAction className={className}>
    <ActionMenuButton todo={value} {...props} />
  </ListItem.SecondaryAction>
);

// HACK: Material UI currently requires that the component used as the secondary action have the
//       `muiName` of `ListItemSecondaryAction`. For more details, see this issue:
//       https://github.com/mui-org/material-ui/issues/11622
//       [twl 15.Dec.18]
TodoItemSecondaryAction.muiName = "ListItemSecondaryAction";
TodoItemSecondaryAction.width = buttonWidth;

export default TodoItemSecondaryAction;
