import React from 'react';
import Button from 'components/library/ButtonMui';
import withTranslations from 'helpers/withTranslations';

const SubmitButton = ({ i18nPath, i18nKey, className, children, t }) => (
  <Button className={className} type="submit" variant="contained" color="primary">
    {i18nPath || i18nKey ? t(i18nPath, i18nKey) : ''}
    {children}
  </Button>
);

export default withTranslations(SubmitButton);
