/**
 * Rounds the `value` up to the next multiple.
 *
 * @param value    - The value to round
 * @param multiple - The multiple to round to
 *
 * @return The value rounded up to the next multiple
 */
export function ceilMultiple(value: number, multiple: number): number {
  const remainder = value % multiple;

  return value + (remainder > 0 ? multiple - remainder : 0);
}
