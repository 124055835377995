import isPlainObject from 'lodash/isPlainObject';
import { PlainObject } from '../types/PlainObject';
import { mergePathKeys } from './mergePathKeys';

function assertNoUndefinedValues(object: PlainObject) {
  for (const key of Object.keys(object)) {
    const value = object[key];

    if (typeof value === 'undefined') {
      throw new Error(`This object cannot contain undefined values. Key was ${key}`);
    } else if (isPlainObject(value)) {
      assertNoUndefinedValues(value);
    }
  }
}

/**
 * Converts an object with path keys into a full object tree.
 *
 * NOTE: If the object has properties whose values are `undefined`, these will be removed from the
 *       object tree. This behavior may change in the future, so don't rely on this.
 *
 * @param object - The object whose keys should be converted into an object tree
 * @param skipUndefinedCheck - Skips the check for undefined properties in `object`
 *
 * @returns A new object containing the full object tree
 */
export function unflattenPathKeys(object: PlainObject, skipUndefinedCheck?: boolean) {
  if (!skipUndefinedCheck) {
    assertNoUndefinedValues(object);
  }

  return mergePathKeys({}, object);
}
