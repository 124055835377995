import toPath from 'lodash/toPath';

/**
 * Returns the last element of the property path.
 *
 * @param path A property path
 *
 * @returns The last element of the property path or the entire path if there is only one element
 */
export function getLastPathElement(path: string) {
  return toPath(path).pop();
}
