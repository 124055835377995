import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    loadPath: '/locales/{{lng}}/{{ns}}.json',

    lng: "en",

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    // TODO: Enable this occasionally to see console warnings of missing translations [twl 7.Sep.18]
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    backend: {
      // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
      queryStringParams: {
        v: process.env.REACT_APP_VERSION
      },
    },

    react: {
      // the default, but making it explicit since we don't use suspense for anything else right now
      useSuspense: true
    }
  });

export default i18n;
