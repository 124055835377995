import { isArray, isPlainObject, mapValues } from 'lodash';
import { assert } from '../assertions/assert';

/**
 * Returns whether the value is mappable or not. Avoids mapping objects like `Date` and `RegExp`.
 *
 * @param value - The value to test
 *
 * @returns `true` if the value is mappable; `false` otherwise
 */
const isMappable = (value: any): boolean => isArray(value) || isPlainObject(value);

/**
 * Calls `iteratee` on each node in object tree, setting the value of the node to the value returned
 * by the function. If the value returned is an array or object, the value has its values mapped.
 *
 * @param collection - An object or array
 * @param iteratee   - A function to be called on each node with `(value, key, path, collection)`
 * @param parentPath - The parent path to start with; defaults to undefined
 *
 * @returns A new object or array with the values returned by the `iteratee`
 */
export function mapValuesDeep(
  collection: {} | [],
  iteratee: (value: any, keyOrIndex: string | number, path: string, collection: {} | []) => any,
  parentPath: string = '',
): any {
  assert(isMappable(collection), 'The `collection` parameter is not an object or array');

  const mapValue = (value: any, keyOrIndex: string | number) => {
    const path = `${parentPath}${parentPath ? '.' : ''}${keyOrIndex}`;
    const newValue = iteratee(value, keyOrIndex, path, collection);

    return isMappable(newValue) ? mapValuesDeep(newValue, iteratee, path) : newValue;
  };

  return isArray(collection) ? collection.map(mapValue) : mapValues(collection, mapValue);
}
