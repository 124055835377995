import { logout } from 'services/server/auth';
import { types as globalTypes } from 'services/store/actions';
import disableRealtimeData from 'services/store/any/events/disableRealtimeData';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { setSessionValue } from 'utils/browser/setSessionValue';
import { path } from '../definitions';

const logoutUser = makeAsyncActionCreator({
  type: globalTypes.LOGOUT,
  creator: async () => {
    // HACK: This really should be done using a new `beforeAuthenticatedUserUnset` root event, but
    //       adding this event is complicated and I need this fixed tonight. [twl 18.Apr.20]
    await disableRealtimeData();
    await logout();

    setSessionValue('skipSaveFromUrl', 'true');
  },
  status: {
    path,
    type: 'write',
    parameters: () => undefined,
  }
});

export default logoutUser;
