import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/library/Typography';
import isVariant from 'components/utils/isVariant';
import withTranslations from 'helpers/withTranslations';
import TodoItemVariantType from 'types/TodoItemVariant';
import todoText from 'utils/todos/todoText';

const formatsI18nPath = 'data.todos.formats';

const ActivityInfo = ({ value, intent, variant, t }) => {
  const repeatInfo = todoText(value, 'repeatInfo', t);

  return (isVariant(variant, 'compact', 'summary', 'schedule') || intent ? null :
    <Typography variant="caption" color="textSecondary">
      {value.defaultDuration ?
        t(formatsI18nPath, 'repeatInfo-with-duration', {
          duration: todoText(value, 'defaultDuration:expanded', t),
          repeatInfo,
        })
        :
        repeatInfo
      }
    </Typography>
  );
}

ActivityInfo.propTypes = {
  /** The todo object to render */
  value: PropTypes.shape({
    repeat: PropTypes.object,
    defaultDuration: PropTypes.number,
  }),

  /** The variant to use */
  variant: TodoItemVariantType,

  /** A function used to provide translations of text. Provided by `withTranslations`. */
  t: PropTypes.func.isRequired,
};

export default withTranslations(ActivityInfo);
