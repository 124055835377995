import React from 'react';
import TodoItem from 'components/app/todos/TodoItem';
import TodoQuickView from 'components/app/todos/TodoQuickView';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
});

/**
 * Renders a selected todo item in a list, with its associated quick view.
 */
const SelectedTodoItem = ({
  ItemComponent = TodoItem,
  QuickViewComponent = TodoQuickView,
  divider = true,
  id,
  onClick,
  classes,
  ...props
}) => (
  <div id={id} className={divider ? classes.divider : undefined}>
    <ItemComponent {...props} onClick={onClick} selected={true} />
    <QuickViewComponent {...props} noBox />
  </div>
);

export default withStyles(styles)(SelectedTodoItem);
