import { DayOfWeekIndex, ITodoRepeatSchedule } from '@lifetools/shared-app-data';
import { DayOfWeek, LocalDate } from 'js-joda';

const daysOfWeek = [
  DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY, DayOfWeek.THURSDAY, DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY, DayOfWeek.SUNDAY
 ];

/**
 * Returns whether the `date` is on the `schedule`.
 *
 * @param date     - A date
 * @param schedule - A repeating schedule
 *
 * @returns `true` if the date is on the schedule, or if no schedule was provided; false otherwise
 */
export function isScheduled(date: LocalDate, schedule: ITodoRepeatSchedule) {
  if (schedule && schedule.weekly) {
    const dayIndex = daysOfWeek.indexOf(date.dayOfWeek()) + 1;

    return schedule.weekly.indexOf(dayIndex.toString() as DayOfWeekIndex) !== -1;
  }

  return true;
}
