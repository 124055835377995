import React, { Children, useState } from 'react';
import Markdown from 'components/library/Markdown';
import TooltipMui from '@material-ui/core/Tooltip';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';

/*
 * Code for creating arrows with tooltips copied from [Material UI Tooltip
 * examples](https://material-ui.com/components/tooltips/) [twl 27.Sep.19]
 */

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const styles = theme => ({
  default: {
    backgroundColor: theme.palette.grey['100'],
    boxShadow: theme.shadows[1],
  },
  defaultText: {
    color: theme.palette.text.primary,
  },
  defaultPopper: {
    opacity: 1,
  },
  message: {
    position: 'relative',
    backgroundColor: theme.palette.grey['A400'],
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 1.5}px`,
  },
  messageText: {
    color: theme.palette.common.white,
  },
  messagePopper: {
    opacity: 1,
    ...arrowGenerator(theme.palette.grey['A400'])
  },
  markdown: {
    position: 'relative',
    backgroundColor: theme.palette.grey['A400'],
    padding: theme.spacing.unit * 1.5,

    '& p, & span': {
      color: theme.palette.common.white,
      margin: 0,
    },

    '& p:not(:last-child)': {
      marginBottom: theme.spacing.unit * 1.5,
    },

    '& a': {
      color: theme.palette.primary.main,
    },
  },
  markdownText: {
    color: theme.palette.common.white,
  },
  markdownPopper: {
    opacity: 1,
    ...arrowGenerator(theme.palette.grey['A400'])
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
});

/**
 * Renders a tooltip.
 *
 * : variant
 * The style to render for this tooltip: `default` or `message`
 */
const Tooltip = ({ variant = 'default', title, classes, ...props }) => {
  const [ arrowRef, setArrowRef ] = useState(null);
  const isArrowEnabled = ['message', 'markdown'].includes(variant);
  const newProps = { ...props };
  const children = Children.toArray(props.children);

  // NOTE: Tooltips cannot work when the child is a disabled element. To fix this, we need to wrap
  //       the child in a DOM element that can receive hover events. For more info, see:
  //
  //       #8416 Tooltip doesn't work for <IconButton disabled>
  //       https://github.com/mui-org/material-ui/issues/8416
  //
  //       #11601 Allow tooltips to function on disabled elements
  //       https://github.com/mui-org/material-ui/issues/11601
  //
  //       The workaround here is to detect when the disabled state occurs and automatically wrap
  //       the child. [twl 27.Sep.19]
  if (children.length === 1 && children[0].props && children[0].props.disabled) {
    newProps.children = <span>{newProps.children}</span>;
  }

  return (
    <TooltipMui
      classes={{ tooltip: classes[variant], popper: classes[`${variant}Popper`] }}
      PopperProps={!isArrowEnabled ? undefined : {
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      enterDelay={variant === 'default' ? 1000 : 100}
      {...newProps}
      title={
        <Typography
          className={classes[`${variant}Text`]}
          variant="body2"
          component={variant === 'markdown' ? 'div' : 'p'}
        >
          {variant === 'markdown' ? <Markdown value={title} textStyle="body2" /> : title}
          {isArrowEnabled &&
            <span className={classes.arrow} ref={setArrowRef} />
          }
        </Typography>
      }
    />
  );
};

export default withStyles(styles)(Tooltip);
