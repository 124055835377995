import { types as globalTypes } from 'services/store/actions';
import { makeAction } from 'services/store/utils-actions';
import { path } from '../definitions';

// HACK: We need a way to signal that all the authentication event handlers are done. Until the
//       React Suspense is ready, we're doing this by publishing a status update to the `auth`
//       store. This allows a `Loader` to listen to the event and not render the app until all
//       auth has been completed. [twl 13.Dec.18]
const setAuthInitStatus = (state) => makeAction(globalTypes.SET_STATUS, {
  type: 'init',
  path,
  state,
})

export default setAuthInitStatus;
