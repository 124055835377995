import { ITriggerRule, TriggerContext } from '@lifetools/shared-database';
import { services } from '@lifetools/shared-services';

const addCreatedFields: ITriggerRule = {
  stage: 'before',
  operation: 'create',
  func: async ({ changes }: TriggerContext) => {
    if (changes.values) {
      changes.set('createdAt', services.database.fieldValues.serverTimestamp());
      changes.set('createdBy', services.auth.userId());
    }
  },
};

export default addCreatedFields;
