import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import IntentCalendar from 'components/app/intents/IntentCalendar';
import ViewField from 'components/library/ViewField';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { fromNow } from 'utils/time';

const i18nPath = 'data.todos.fields';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 1.5,

    '& > *:not(:first-child)': {
      marginTop: theme.spacing.unit * 2,
    }
  },
  splitRow: {
    display: 'flex',

    '& > *': {
      flex: '1 1 100%',
    },
  },
});

const TodoQuickViewHistory = ({ value, classes, t }) => (
  <div className={classes.root}>
    <div className={classes.splitRow}>
      <ViewField i18nPath={i18nPath} name="createdAt" value={fromNow(value.createdAt)} />
      {value.type !== 'appointment' &&
        <ViewField
          i18nPath={i18nPath}
          name="commitmentCount"
          value={value.intents ? Object.keys(value.intents).length : 0}
        />
      }
    </div>

    {value.type !== 'activity' && value.status.state !== 'new' &&
      <ViewField
        i18nPath={i18nPath}
        name={`${value.status.state}Status`}
        value={value.status.at && fromNow(value.status.at)}
      />
    }

    {value.type !== 'appointment' &&
      <IntentCalendar todo={value} />
    }
  </div>
);

TodoQuickViewHistory.propTypes = {
  /** The intent object to render */
  value: PropTypes.shape({
    type: PropTypes.oneOf([ 'activity', 'appointment', 'task' ]),
    createdAt: PropTypes.object,
    intents: PropTypes.object,
    status: PropTypes.object,
  }),

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /** A function used to provide translations of text. Provided by `withTranslations` */
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withTranslations,
)(TodoQuickViewHistory);
