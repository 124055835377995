import React from 'react';
import { withRouter } from 'react-router';
import StartWorkflowButton from 'components/app/schedules/StartWorkflowButton';
import { buildScene } from 'scenes/utils';
import addSchedule from 'services/store/schedules/actions/addSchedule';
import { routes } from 'routes/routes';
import { resolveRoute } from 'utils/app/routes/resolveRoute';

const nextSteps = {
  commit: 'allocate',
  allocate: 'schedule',
  schedule: 'schedule',
};

async function startWorkflow(
  schedule,
  existingSchedule,
  activePlanType,
  history,
  match,
  handleCreate,
) {
  if (!existingSchedule) {
    await handleCreate(schedule);
  }

  const step = activePlanType ? nextSteps[activePlanType] : 'commit';

  history.push(resolveRoute(routes.workflows[schedule.type], {
    ...match.params,
    planDate: schedule.date,
    step
  }));
}

const StartWorkflowButtonWidget = buildScene(({
  history,
  match,
  existingSchedule,
  activePlanType,
  doAddSchedule,
  ...props
}) => (
  <StartWorkflowButton
    {...props}
    activePlanType={activePlanType}
    existingSchedule={existingSchedule}
    onStart={scheduleInfo =>
      startWorkflow(scheduleInfo, existingSchedule, activePlanType, history, match, doAddSchedule)
    }
  />
), {
  dispatch: {
    doAddSchedule: addSchedule,
  },
});

export default withRouter(StartWorkflowButtonWidget);
