import React from 'react';
import classnames from 'classnames';
import Icon from 'components/library/Icon';
import IFrame from 'components/library/IFrame';
import Link from 'components/library/Link';
import withStyles from 'helpers/withStyles';
import { parseEmbedUri } from 'utils/app/embeds/parseEmbedUri';

const styles = theme => ({
  builtInFrame: {
    border: `solid 1px ${theme.palette.grey[400]}`,
  },
  embedTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.grey[100],
    padding: theme.spacing.unit,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    borderWidth: '1px 1px 0 1px',
    fontWeight: 500,
  },
  embedTitleIcon: {
    color: theme.palette.grey[600],
  },
  aspectRatioContainer: {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  },
  aspectRatioFrame: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
});

const defaultEmbedOptions = {
  type: 'builtin',
  width: '100%',
  height: 300,
  aspectRatio: undefined,                  // use fixed height by default
};

const EmbedLink = ({ className, href, style, children }) => (
  <Link
    className={className}
    style={style}
    variant="body1"
    href={href}
    target="_blank"
    color="textSecondary"
    rel="noopener"
    underline="always"
    onClick={e => e.stopPropagation()}
  >
    {children}
  </Link>
);

const BuiltInEmbed = ({
  uri,
  label,
  width,
  height,
  maxWidth,
  maxHeight,
  aspectWidth,
  aspectHeight,
  classes,
}) => {
  const commonProps = {
    // prevent iframes from grabbing focus; not yet implemented in Chrome
    allow: 'focus-without-user-activation \'none\'',

    // limit what iframes can access
    sandbox: 'allow-scripts allow-forms allow-storage-access-by-user-activation allow-same-origin',

    // not sure if we need this, but the iframe shouldn't participate in the tab sequence right now
    tabIndex: '-1',

    // pass-through CSS props
    ...(maxWidth || maxHeight) && {
      style: {
        maxWidth,
        maxHeight,
      },
    },
  };

  return (
    <>
      {label &&
        <EmbedLink
          className={classes.embedTitle}
          href={uri}
          style={{
            width: width.endsWith('%') ? width : parseInt(width, 10),
          }}
        >
          {label}
          <Icon className={classes.embedTitleIcon} type="fas:external-link-alt" />
        </EmbedLink>
      }

      {!aspectWidth || !aspectHeight
        ? <IFrame
            className={classes.builtInFrame}
            src={uri}
            width={width}
            height={height}
            {...commonProps}
            />
        : <span
            className={classes.aspectRatioContainer}
            style={{ paddingTop: `${aspectHeight / aspectWidth * 100}%` }}
          >
            <IFrame
              className={classnames(classes.builtInFrame, classes.aspectRatioFrame)}
              src={uri}
              {...commonProps}
            />
          </span>
      }
    </>
  );
};

const Embed = ({ variant, source, label, classes }) => {
  const embed = parseEmbedUri(source, defaultEmbedOptions);

  return variant === 'plain'
    ? <EmbedLink href={embed.uri}>{label || embed.uri}</EmbedLink>
    : <BuiltInEmbed {...embed} label={label} classes={classes} />;
};

export default withStyles(styles)(Embed);
