import { DateValue } from '../types/DateValue';
import { ITimestamp } from '../types/ITimestamp';
import { toNativeDate } from './toNativeDate';

/**
 * Converts the `value` to an `ITimestamp` object.
 *
 * @param value - The date value to convert to a `ITimestamp`
 *
 * @returns An `ITimestamp` object, `null` if the value could not be parsed, or undefined if the
 *          `value` was unset
 */
export function toTimestamp(value: DateValue): ITimestamp | undefined | null {
  if (!value) {
    return undefined;
  }

  const { seconds, nanoseconds } = value as any;

  if (seconds != null && nanoseconds != null) {
    return value as ITimestamp;
  }

  const date = toNativeDate(value);

  if (!date) {
    return date;
  }

  const milliseconds = date.valueOf();

  return {
    seconds: Math.floor(milliseconds / 1000),
    nanoseconds: (milliseconds % 1000) * 1000000,
  };
}
