import pick from 'lodash/pick';

/**
 * Returns a new props object containing only those used for testing.
 *
 * @param props - An object representing the props for a component
 *
 * @returns A props object that contains only those props used for testing
 */
export const onlyTestProps = (props: { [name: string]: any }) => pick(props, 'data-test');
