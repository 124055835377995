import { LocalTime } from 'js-joda';
import { TimeValue, toLocalTime } from '@lifetools/shared-utils-time';

/**
 * Converts the `time` to an instance of `LocalTime` or `undefined` if `time` could not be parsed.
 * This method never throws an error, unlike `toLocalTime`. 
 * 
 * @param time - a time value to parse
 * 
 * @returns an instance of `LocalTime` or `undefined` if `time` could not be parsed
 */
export default function toLocalTimeNoThrow(time: TimeValue): LocalTime | undefined {
  try {
    return toLocalTime(time);
  } catch {
    return undefined;
  }
}
