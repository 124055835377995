import React from 'react';
import TodayBase from 'scenes/app/TodayBase';
import AllocationListView from './components/AllocationListView';

const i18nPath = 'scenes.app.AllocationList';

const AllocationList = () => (
  <TodayBase i18nPath={i18nPath} scheduleType="allocate" scheduleView={AllocationListView} />
);

export default AllocationList;
