import { ICollectionQuery } from '../types/ICollectionQuery';
import { ICollectionQueryOptions } from '../types/ICollectionQueryOptions';
import { QueryOperator } from '../types/QueryOperator';
import { addQueryOptions } from './addQueryOptions';
import { hasQueryOperator } from './hasQueryOperator';
import { normalizeForQuery } from './normalizeForQuery';
import { queryOperators } from './queryOperators';

/**
 * Adds the `query` into the `queryRef` and returns a new query reference.
 *
 * @param queryRef - The reference
 * @param query    - The query to add
 * @param options  - The options for how to process the query, e.g. sorting, limits, etc.
 *
 * @returns a new reference with the query added
 *
 * TODO: Type `queryRef` [twl 16.Apr.19]
 */
export function addQuery(
  queryRef: any,
  query: ICollectionQuery,
  options?: ICollectionQueryOptions,
) {
  for (const field of Object.keys(query)) {
    const compareTo = normalizeForQuery(query[field]);

    if (hasQueryOperator(compareTo)) {
      for (const operator of Object.keys(compareTo) as QueryOperator[]) {
        queryRef = queryRef.where(field, queryOperators[operator],
                                  normalizeForQuery(compareTo[operator]));
      }
    } else {
      queryRef = queryRef.where(field, '==', compareTo);
    }
  }

  return options ? addQueryOptions(queryRef, options) : queryRef;
}
