import { services } from '@lifetools/shared-services';
import { IDocument } from '../documents/types/IDocument';
import { IDocumentData } from '../documents/types/IDocumentData';
import { applySchema } from '../documents/utils/applySchema';
import { subscribe } from '../subscriptions/subscribe';
import { createSubscriptionId } from '../subscriptions/createSubscriptionId';
import { addQuery } from './utils/addQuery';
import { parseIntoSubqueries } from './utils/parseIntoSubqueries';
import { ICollectionQuery } from './types/ICollectionQuery';
import { ICollectionQueryOptions } from './types/ICollectionQueryOptions';

/**
 * Returns the documents from the collection that match the query.
 *
 * The `query` supports both exact match or query object which indicate what type of query to
 * perform. The query object should be in the form of `{ [operation]: [value] }` where `operation`
 * is the comparison operation to use and `value` is the value to compare.
 *
 * Support operations include `greaterThan`, `greaterThanOrEqual`, `lessThan`, `lessThanOrEqual`,
 * `before`, `beforeOrOn`, `after`, `afterOrOn` and `not`.
 *
 * @param collection - The name of the collection to retrieve the documents from
 * @param query      - An object mapping fields to the values that must exist on a document for it
 *                     to be returned--this is an AND query of all keys in this object
 *
 * @returns An array of documents with `id` properties that match the `query`
 */
export async function queryDocuments(
  collection: string,
  query: ICollectionQuery = {},
  options?: ICollectionQueryOptions,
): Promise<IDocumentData[]> {
  const authUserId = services.auth.userId();

  if (!authUserId && !services.auth.isAdmin()) {
    // TODO: Think about whether we want to throw an error here. [twl 15.Jun.18]
    services.logger.warn(`Aborting attempt to query documents from '${collection}'. No` +
                         ` authenticated user.`);

    return [];
  }

  const subqueries = parseIntoSubqueries(query);

  let results: IDocumentData[] = [];

  for (const subquery of subqueries) {
    const baseRef = services.database.collection(collection);
    const authRef = authUserId ? baseRef.where('createdBy', '==', authUserId) : baseRef;
    const queryRef = addQuery(authRef, subquery, options);
    const subscriptionId = createSubscriptionId(authUserId, collection, subquery);
    const querySnapshot = await subscribe(collection, queryRef, subscriptionId) as IDocumentData[];

    results = [
      ...results,
      ...querySnapshot,
    ];
  }

  return results;
}
