import { makeSyncActionCreator } from 'services/store/utils-actions';
import { path, types } from '../definitions';

const setAuth = makeSyncActionCreator({
  type: types.SET_AUTH,
  creator: auth => auth,
  status: {
    path,
    type: 'read',
    parameters: () => undefined,
  }
});

export default setAuth;