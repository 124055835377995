import React from 'react';
import classnames from 'classnames';
import Paper from 'components/library/Paper';
import ActionBar from 'components/library/ActionBar';
import { FeatureFlags } from 'contexts/FeatureFlags';
import withDeviceInfo from 'helpers/withDeviceInfo';
import withStyles from 'helpers/withStyles';
import BoxHeader from './components/BoxHeader';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      border: `solid 1px ${theme.palette.grey['300']}`,
      boxShadow: 'none',
    },
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    '-webkit-overflow-scrolling': 'touch',

    [theme.breakpoints.down('sm')]: {
      overscrollBehavior: 'contain',
    },
  },
});

const hasActiveActions = actions => !actions ? false : actions.find(action => action) != null;

const Box = ({ id, className, i18nPath, title, actions, hideHeaderOnMobile, children, classes, isMobileDevice, newMobileUi }) => (
  <Paper id={id} className={classnames(classes.root, className)}>
    {isMobileDevice && (hideHeaderOnMobile || !newMobileUi) ?
      hasActiveActions(actions) && <ActionBar actions={actions} /> :
      <ActionBar actions={actions} component={BoxHeader} componentProps={{ i18nPath, title }} newMobileUi={newMobileUi} />
    }

    <div className={classes.content}>
      {children}
    </div>
  </Paper>
);

/**
 * Temporary wrapper that alters the props used based on a feature flag.
 */
const BoxWrapper = ({ isMobileDevice, ...props }) => (
  <FeatureFlags.Consumer>
    {flags =>
      <Box
        {...props}
        isMobileDevice={isMobileDevice}
        newMobileUi={isMobileDevice && flags.stackedMobileUi}
      />
    }
  </FeatureFlags.Consumer>
);

export default withStyles(styles)(withDeviceInfo(BoxWrapper));
