import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ModalDialog from 'components/library/ModalDialog';
import Form from 'components/library/Form';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleBar: {
    order: 1,
  },
  formError: {
    order: 2,
    background: theme.palette.grey['100'],
    padding: theme.spacing.unit * 2,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
  },
  content: {
    order: 3,

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing.unit * 2,                 // add space between fields
    },
  },
  actions: {
    order: 4,
  },
});

class FormDialog extends Component {
  static propTypes = {
    /** The path from which to load localization strings for this component */
    i18nPath: PropTypes.string,

    /** The title of the dialog. Overrides the title at `${i18nPath}.title` */
    title: PropTypes.string,

    /** The initial values for this form */
    values: PropTypes.object,

    /** The form fields to render for this dialog */
    children: PropTypes.node,

    /** Called with the `values` from this dialog to save them */
    onSave: PropTypes.func,

    /** Called to cancel this dialog */
    onCancel: PropTypes.func,

    /** Called to close this dialog */
    onClose: PropTypes.func,

    /** A function used to provide translations of text & UI elements. Provided by HOC. */
    t: PropTypes.func.isRequired,

    /** An object mapping semantic class names to compiled class names. Provided by HOC. */
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    onClose: () => {},
  }

  doCancel = async () => {
    const { onCancel, onClose } = this.props;

    await onCancel();
    onClose();
  }

  render() {
    const { formId, i18nPath, title, values, schema, children, onSave, onClose } = this.props;
    const { t, classes } = this.props;
    const { form: formClass, formError: formErrorClass, ...dialogClasses } = classes;

    return (
      <ModalDialog
        classes={dialogClasses}
        open={true}
        title={title || t(i18nPath + '.title')}
        container={Form}
        containerProps={{
          id: formId,
          values,
          schema,
          className: formClass,
          classes: {
            error: formErrorClass,
          },
          onSubmit: onSave,
          onSubmitSuccess: onClose,
        }}
        primaryButton={
          <ModalDialog.Button i18nPath={i18nPath} id="save" type="submit" />
        }
        secondaryButton={
          <ModalDialog.Button i18nPath={i18nPath} id="cancel" onClick={this.doCancel} />
        }
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        {children}
      </ModalDialog>
    );
  }
}

export default compose(
  withTranslations,
  withStyles(styles),
)(FormDialog);
