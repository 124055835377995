import React from 'react';
import EditPlanButton from 'components/app/buttons/EditPlanButton';
import PrintButton from 'components/app/buttons/PrintButton';
import ViewLayout from 'components/layouts/ViewLayout';
import withDeviceInfo from 'helpers/withDeviceInfo';
import { buildScene } from 'scenes/utils';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateSettings from 'services/store/settings/actions/updateSettings';
import hasIntent from 'services/store/intents/utils/hasIntent';
import { isDevEnvironment } from 'utils/app/isDevEnvironment';
import { isPlanEditable } from 'utils/app/plans/isPlanEditable';
import DeletePlanButton from 'widgets/buttons/DeletePlanButton';
import HelpTipWidget from 'widgets/common/HelpTipWidget';
import IntentListWidget from 'widgets/intents/IntentListWidget';
import ScheduleNotesWidget from 'widgets/schedules/ScheduleNotesWidget';
import TodoListWidget from 'widgets/todos/TodoList';

const i18nPath = 'scenes.app.CommitmentList.components.CommitmentListView';

async function loadData() {
  this.props.doLoadSettings();
}

const CommitmentListView = buildScene(({
  // view configuration
  planBoxTitle,
  layout = 'split',
  actionMode = 'statusMenu',
  isPlanEditable = true,
  showFutureTasks = true,

  // data
  date,
  schedule,
  intents,

  // context
  isAccountReadOnly,

  // actions
  doUpdateSettings,

  // injections
  isMobileDevice,
  classes,
  t,
}) => (
  <ViewLayout layout={layout}>
    <IntentListWidget
      viewId="remaining"
      viewColumn={1}
      i18nPath={i18nPath + '.components.UndoneIntents'}
      boxTitle={planBoxTitle}
      boxActions={(!isMobileDevice || (isPlanEditable && !isAccountReadOnly)) && [
        !isMobileDevice && <PrintButton />,
        isPlanEditable && !isAccountReadOnly && <EditPlanButton workflow="commit" date={date} />,
        isPlanEditable && !isAccountReadOnly && isDevEnvironment() &&
          <DeletePlanButton schedule={schedule} />,
      ]}
      actionMode={actionMode}
      header={
        <HelpTipWidget
          helpPath="today.commit.plan"
          tips={['intro[newUser]']}
        />
      }
      variant="summary"
      createEnabled={actionMode !== 'statusReadOnly'}
      schedule={schedule}
      intents={intents}
      date={date}
      filter={intent => intent.status.state === 'new'}
      sortBy={{
        property: 'duration',
        direction: 'desc',
        sortNulls: 'bottom',
      }}
      groupBy="todo.tags"
    />

    <IntentListWidget
      viewId="done"
      i18nPath={i18nPath + '.components.DoneIntents'}
      actionMode={actionMode}
      header={
        <HelpTipWidget
          helpPath="today.commit.done"
          tips={['intro[newUser]']}
        />
      }
      variant="schedule:scheduled"
      schedule={schedule}
      intents={intents}
      date={date}
      filter={intent => intent.status.state !== 'new' && intent.status.state !== 'wontdo'}
      groupBy="todo.tags"
    />

    <IntentListWidget
      viewId="skipped"
      i18nPath={i18nPath + '.components.SkippedIntents'}
      actionMode={actionMode}
      header={
        <HelpTipWidget
          helpPath="today.commit.skip"
          tips={['intro[newUser]']}
        />
      }
      variant="schedule:scheduled"
      schedule={schedule}
      intents={intents}
      date={date}
      filter={intent => intent.status.state === 'wontdo'}
      groupBy="todo.tags"
    />

    {showFutureTasks &&
      <TodoListWidget
        viewId="future"
        i18nPath={i18nPath + '.components.FutureTasks'}
        actionMode={actionMode}
        header={
          <HelpTipWidget
            helpPath="today.shared.future"
            tips={['intro[newUser]']}
          />
        }
        date={date}
        query={{
          type: 'task',
          'status.state': 'new',
          createdAt: {
            greaterThan: new Date(date),
          },
        }}
        filter={todo => !hasIntent(todo)}
        groupBy="tags"
        createEnabled
      />
    }

    {(actionMode !== 'statusReadOnly' || schedule.notes) &&
      <ScheduleNotesWidget schedule={schedule} readOnly={actionMode === 'statusReadOnly'} />
    }
  </ViewLayout>
), {
  context: 'isAccountReadOnly',
  styles: theme => ({
    printButton: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
  state: (state, { date }) => ({
    isPlanEditable: isPlanEditable(date, state)
  }),
  dispatch: {
    doLoadSettings: loadSettings,
    doUpdateSettings: updateSettings,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default withDeviceInfo(CommitmentListView);
