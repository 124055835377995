import React from 'react';
import DurationSelector from 'components/library/DurationSelector';
import { testProps } from 'utils/test/testProps';
import FormField from '../FormField';
import MaterialUiField from '../MaterialUiField';

const MuiDurationField = ({ name, value, minimum, maximum, interval, onChange, ...props }) => (
  <FormField
    {...props}
    InputElement={
      <DurationSelector
        name={name}
        value={value}
        minimum={minimum}
        maximum={maximum}
        interval={interval}
        onChange={onChange}
        {...testProps(name)}
      />
    }
  />
);

const DurationField = ({ ...props }) => (
  <MaterialUiField {...props} muiComponent={MuiDurationField} useSetFieldValue useSetFieldTouched />
);

export default DurationField;
