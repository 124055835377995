import { PlainObject } from '@lifetools/shared-utils';

const routeElementRegExp = /^:([-\w]*)(\[([-\w]*)\])?/;

function resolveRouteElement(params: PlainObject, element: string): string {
  if (!element.startsWith(':')) {
    return element;
  }

  const [, param, , defaultValue] = element.match(routeElementRegExp) || [];

  return params[param] || defaultValue;
}

/**
 * Resolves a route that has parameter substitutions using the given `params`.
 *
 * A parameter substitution must be an entire element of a route path and start with a colon (`:`).
 * An optional default value can be defined after the param name using brackets.
 *
 * For example, the following route has two parameters, `date` and `step`, with the value of `date`
 * having a default value of `today` it not otherwise provided: `plan/:date[today]/steps/:step`.
 *
 * @param route - The route to resolve
 * @param params - A map of parameter names to values used to replace parameters in the `route`

 * @returns The route with any parameters substitution for their actual values
 */
export const resolveRoute = (route: string, params: PlainObject): string => (
   route.split('/').map(element => resolveRouteElement(params, element)).join('/')
);
