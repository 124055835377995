import { Children, cloneElement } from 'react';
import { toLocalDate } from '@lifetools/shared-utils-time';

const style = {
  cursor: 'pointer',
};

const DateCellWrapper = ({ value, onSelect, children, ...props }) => {
  const date = toLocalDate(value);

  // HACK: Temporary hack to make sure dates can be selected until the bug in `react-big-calendar`
  //       is fixed. The issue to monitor is
  //       https://github.com/intljusticemission/react-big-calendar/issues/1005
  //       [twl 12.Dec.18]
  const onTouchEnd = event => {
    event.preventDefault();

    onSelect(date, 'detail');
  };

  return Children.map(children, child => cloneElement(child, { style, onTouchEnd }));
}

export default DateCellWrapper;
