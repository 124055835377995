import { services } from '@lifetools/shared-services';
import { IDocumentData } from '../documents/types/IDocumentData';
import { IDocumentReference } from '../documents/types/IDocumentReference';
import { IDocumentSnapshot } from '../documents/types/IDocumentSnapshot';
import { IQuery } from '../queries/types/IQuery';
import { IQuerySnapshot } from '../queries/types/IQuerySnapshot';
import { parseSnapshot } from './utils/parseSnapshot';

/**
 * Returns the data for the `gettable` reference within the `collection`, creating a subscription to
 * the gettable using the `subscriptionId`. Updates to the data after this gettable has executed are
 * sent via the data listener specified by `services.data.listener`.
 *
 * @param collection     - The collection to query against
 * @param gettable       - A document reference or a database query
 * @param subscriptionId - The ID of the subscription to create

 * @returns The documents matching the query, with schemas already applied
 */
export async function subscribe(
  collection: string,
  gettable: IDocumentReference | IQuery,
  subscriptionId: string,
): Promise<IDocumentData | IDocumentData[] | undefined> {
  const listener = services.data.listener;

  if (!subscriptionId) {
    throw new Error(`All subscriptions must have a subscription ID`);
  }

  // TODO: Coallesce queries here based on the subscription ID. Basically, if a subscription is
  //       being added whose subscription ID is a subset of an existing subscription ID, then it
  //       supercedes the old one--thus unsubscribe from the old one and subscribe to this new one.
  //
  //       If, however, this subscription ID contains an existing subscription ID within it, then
  //       it's already covered by the other subscription ID and we should continue execute the
  //       next block that uses `get` to return the data immediately.
  //
  //       As an example, `bPkPwHMZYDONCEWpVMnkr1AkwCH2|todos|type:task,status.state:new` is
  //       superceded by `bPkPwHMZYDONCEWpVMnkr1AkwCH2|todos|type:task`, so if the former is created
  //       first, it should be unsubscribed, so the second one handles the data; otherwise, if the
  //       former is created second, it should be treated as a direct query and not have a
  //       subscription created. [twl 18.Apr.20]

  // If we no listener exists or one already exists, don't create a subscription, just return the
  // results.
  //
  // NOTE: While the caching system of the store should prevent multiple requests of the same
  //       subscription, this is a backstop in case it doesn't. This also ensures all calls to this
  //       function return a data set, even if a subscription wasn't created. [twl 18.Apr.20]
  if (!listener || services.data.subscriptions[subscriptionId]) {
    const snapshot = await gettable.get();

    return parseSnapshot(collection, snapshot);
  }

  return new Promise((resolve, reject) => {
    let firstSnapshot = true;

    // @ts-ignore - TS can't figure out the how to union the two callback signatures
    services.data.subscriptions[subscriptionId] = gettable.onSnapshot(
      (snapshot: IDocumentSnapshot | IQuerySnapshot) => {
        let data = parseSnapshot(collection, snapshot);
        let docSnapshot = snapshot as IDocumentSnapshot;

        // If `data` is undefined, the document was deleted
        if (docSnapshot.id && typeof data === 'undefined') {
          data = { $action: 'delete', id: docSnapshot.id };
        }

        // Once this has been called once, there is no where for the Promise to return data to, so
        // return it via the listener instead
        if (firstSnapshot) {
          resolve(data);
        } else if (listener) {
          listener(data && !Array.isArray(data) ? [ data ] : data, collection, subscriptionId);
        }

        firstSnapshot = false;
      },
      (error: Error) => {
        console.error(`Disconnected subscription ${subscriptionId} after internal error`, error);

        try {
          services.data.subscriptions[subscriptionId]();
        } catch (e) {
          console.error(`Failed to unsubscribe from subscription ${subscriptionId}`, e);
        } finally {
          delete services.data.subscriptions[subscriptionId];
          reject(error);
        }
      }
    );
  });
}
