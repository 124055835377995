import { schemas } from '@lifetools/shared-app-data';
import { ITriggerRule } from '@lifetools/shared-database';
import rootTriggers from '../../store/rootTriggers';

export function initializeData() {
  const data = {
    triggers: [] as ITriggerRule[],
    schemas,
  };

  for (const trigger of rootTriggers) {
    data.triggers.push(trigger);
  }

  return data;
}
