import React from 'react';
import Selector from 'components/library/Selector';
import { testProps } from 'utils/test/testProps';
import FormField from '../FormField';
import MaterialUiField from '../MaterialUiField';

const MuiSelectionField = ({ i18nPath, name, options, value, onChange, ...props }) => (
  <FormField
    {...props}
    InputElement={
      <Selector
        i18nPath={i18nPath}
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        {...testProps(name)}
      />
    }
  />
);

const SelectionField = ({ ...props }) => (
  <MaterialUiField
    {...props}
    muiComponent={MuiSelectionField}
    useSetFieldValue
    useSetFieldTouched
    useI18nPath
  />
);

export default SelectionField;
