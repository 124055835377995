import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Chip from 'components/library/Chip';
import { testProps } from 'utils/test/testProps';

const OptionIndicator = ({ t, i18nProperty, value, selected, show = 'all', }) => {
  const optionPath = i18nProperty ? `indicators.${i18nProperty}.options.${value}` : undefined;

  return (
    <Chip
      color={selected ? 'secondary' : 'default'}
      label={show !== 'iconOnly'
        ? (optionPath ? t(optionPath + '.label', value) : value)
        : undefined
      }
      icon={show !== 'titleOnly' ? t(optionPath + '.icon') : undefined}
      {...testProps('OptionIndicator')}
    />
  );
};

OptionIndicator.propTypes = {
  /**
   * The name of the property used to look up the translation under
   * `indicators.${property}.options.${value`, or `undefined` to not translate the value
   */
  i18nProperty: PropTypes.string,

  /** The value of the property */
  value: PropTypes.any,

  /** Whether this indicator should be displayed as selected */
  selected: PropTypes.bool,

  /** What parts of the indicator to show */
  show: PropTypes.oneOf([ 'all', 'iconOnly', 'titleOnly' ]),
}

export default withTranslation('translations')(OptionIndicator);
