import React from 'react';
import ActionBar from 'components/library/ActionBar';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  viewOptionPane: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
    justifyContent: 'center',

    '& > *:not(:first-child)': {
      marginLeft: theme.spacing.unit,
    },
  },
  selectedButton: {
    background: theme.palette.grey[300],
  },
});

const ViewsPaneButton = ({
  i18nPath = '*',
  expanded,
  projectionsActive,
  showProjections,
  onToggleViewCompact,
  onToggleShowProjections,
  classes,
  ...props
}) => (
  <ActionBar.Button
    i18nPath={i18nPath}
    action="views"
    pane={
      <ActionBar.Pane className={classes.viewOptionPane}>
        <ActionBar.Button
          i18nPath={i18nPath}
          action={expanded ? 'view-expanded' : 'view-compact'}
          classes={{ selected: classes.selectedButton }}
          selected={expanded}
          onClick={onToggleViewCompact}
        />
        {projectionsActive &&
          <ActionBar.Button
            i18nPath={i18nPath}
            action="show-projections"
            classes={{ selected: classes.selectedButton }}
            selected={showProjections}
            onClick={onToggleShowProjections}
          />
        }
      </ActionBar.Pane>
    }
    {...props}
  />
);

export default withStyles(styles)(ViewsPaneButton);
