import React, { useCallback, useState } from 'react';
import Form from 'components/library/Form';
import Link from 'components/library/Link';
import Typography from 'components/library/Typography';
import withPage from 'helpers/withPage';
import { buildScene } from 'scenes/utils';
import { loginWithProvider } from 'services/server/auth';
import loginUser from 'services/store/auth/actions/loginUser';
import setAuthInitStatus from 'services/store/auth/actions/setAuthInitStatus';
import setRedirectResult from 'services/store/auth/actions/setRedirectResult';
import { selectAuthRedirectStatus } from 'services/store/auth/selectors';
import { STATUS_RUNNING } from 'services/store/utils-actions';
import { getActiveProvider } from 'services/server/utils/getActiveProvider';
import { AuthenticationError } from 'types/AuthenticationError';
import compileSchema from 'utils/data/compileSchema';
import { testProps } from 'utils/test/testProps';
import FederatedIdentityAuthButtons from 'widgets/buttons/FederatedIdentityAuthButtons';

const i18nPath = 'scenes.users.Login';
const fieldsI18nPath = i18nPath + '.fields';

const schema = {
  email: {
    type: 'string',
    validation: [
      'trim',                 // Remove spaces before validating--does not modify actual data though
      'required',
      'email',
    ],
  },
};

const schemaWithPassword = {
  email: schema.email,
  password: {
    type: 'string',
    validation: [
      'required',
    ],
  },
};

/**
 * Displays a login form allowing the user to login via an email & password, or via a federated
 * identity provider.
 *
 * Which elements are displayed on this screen depend on the `authProvider` selected:
 *
 * `undefined`
 * : Shows both an email input and buttons for each of the federated identity providers
 *
 * `password`
 * : Shows an email & password login form
 *
 * Other provider
 * : Shows the login for only a specific provider
 *
 * There are also two different places where error messages can be displayed:
 *
 * - At the top of the screen, for errors related to federated identity login
 * - Within the email/password form, for errors related to email & password login
 *
 * Note that, depending on the error, the email/password form may be displayed for federated
 * identity login errors.
 */
const Login = buildScene(({
  allowNewAccount,
  schema,
  schemaWithPassword,
  redirectStatus,
  doLogin,
  doSetAuthInitStatus,
  doSetRedirectResult,
  classes,
  t,
}) => {
  const redirectError = redirectStatus?.error;
  const [authProvider, setAuthProvider] = useState(redirectError?.activeProvider);
  const [email, setEmail] = useState(redirectStatus?.error?.email);
  const formSchema = authProvider === 'password' ? schemaWithPassword : schema;
  const noAccountFound = redirectError?.code === 'auth/account-not-found';
  const showPasswordProvider = !redirectError || authProvider === 'password' ||
    (!redirectError?.activeProvider && !noAccountFound);
  const showFederatedIdProviders = !noAccountFound &&
    redirectError?.activeProvider !== 'password' &&
    (authProvider !== 'password' || redirectError);

  const doViewAll = useCallback(() => {
    setAuthProvider(null);
    doSetRedirectResult(null);
  }, [doSetRedirectResult]);

  const doClearRedirectError = useCallback(() => {
    doSetRedirectResult(null);
  }, [doSetRedirectResult]);

  const doSubmit = useCallback(async formData => {
    // Clear any federated identity error, since the user is now interacting with email/password
    doSetRedirectResult(null);
    setEmail(formData.email);

    if (!authProvider) {
      const provider = await getActiveProvider(formData.email);

      if (!provider) {
        throw new AuthenticationError('auth/user-not-found');
      }

      setAuthProvider(provider);

      if (provider && provider !== 'password') {
        const result = await loginWithProvider('login', provider, formData.email);

        if (result) {
          doSetRedirectResult(result);
        }
      }
    } else if (authProvider === 'password') {
      await doLogin(formData);
      await doSetAuthInitStatus(STATUS_RUNNING);
    }
  }, [authProvider, doSetRedirectResult, doLogin, doSetAuthInitStatus]);

  return (
    <div>
      {redirectError &&
        <div {...testProps('RedirectError', redirectError.code)}>
          <Typography className={classes.redirectError} variant="body1">
            {t([
              redirectError.code === 'auth/account-exists-with-different-credential' &&
                redirectError?.activeProvider === 'password'
                ? `${i18nPath}.errors.auth/account-exists-with-password`
                : `${i18nPath}.errors.${redirectError.code}`,
              `${i18nPath}.errors.auth/unknown`
            ])}
          </Typography>

          {redirectError.code === 'auth/account-not-found' &&
            <Typography className={classes.redirectErrorLink} variant="body1">
              <Link className={classes.registerLink} to="/register" onClick={doClearRedirectError}>
                {t(i18nPath, 'create-account-prompt')}
              </Link>
            </Typography>
          }
        </div>
      }

      {showPasswordProvider &&
        <div>
          <Form.SectionTitle i18nPath={i18nPath} i18nKey="section-email" />

          <Form
            i18nPath={i18nPath}
            className={classes.root}
            schema={formSchema}
            values={{ email }}
            onSubmit={doSubmit}
          >
            <Form.TextField
              className={classes.field}
              i18nPath={fieldsI18nPath}
              name="email"
              autoComplete="username"
              InputLabelProps={{ classes: { root: classes.label } }}
              fullWidth
              autoFocus={!email}
            />

            {authProvider === 'password' &&
              <Form.PasswordField
                className={classes.field}
                i18nPath={fieldsI18nPath}
                name="password"
                autoComplete="current-password"
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth
                autoFocus={!!email}
              />
            }

            <Form.SubmitButton
              className={classes.button}
              i18nPath={i18nPath}
              i18nKey={authProvider === 'password' ? 'login-button' : 'next-button'}
            />
          </Form>

          {authProvider === 'password' &&
            <Typography className={classes.noAccountMessage} variant="body1">
              {t(i18nPath, 'reset-password-prompt')}
              <Link to={{ pathname: '/reset-password', state: { email } }}>
                {t(i18nPath, 'reset-password-link')}
              </Link>
            </Typography>
          }
        </div>
      }

      {showFederatedIdProviders &&
        <div className={classes.sectionOther}>
          {showPasswordProvider &&
            <Form.SectionTitle i18nPath={i18nPath} i18nKey="section-other" />
          }
          <FederatedIdentityAuthButtons
            action="login"
            providers={authProvider ? [ authProvider ] : undefined}
            email={redirectError?.email}
          />
        </div>
      }

      {!authProvider && allowNewAccount && !redirectError &&
        <Typography className={classes.noAccountMessage} variant="body1">
          {t(i18nPath, 'no-account-yet')}
          <Link className={classes.registerLink} to="/register" onClick={doClearRedirectError}>
            {t(i18nPath, 'create-account-prompt')}
          </Link>
        </Typography>
      }

      {(authProvider || noAccountFound) &&
        <Typography className={classes.allOptions} variant="body1">
          <Link useRoutedStyle onClick={doViewAll}>
            &lt; {t(i18nPath, 'all-options-link')}
          </Link>
        </Typography>
      }
    </div>
  );
}, {
  memos: {
    // NOTE: Must be compiled here & not in `Form` since memo only stores last value [twl 7.Dec.18]
    compileSchema: (t, i18nPath) => compileSchema(schema, t, i18nPath),
    compileSchemaWithPassword: (t, i18nPath) => compileSchema(schemaWithPassword, t, i18nPath),
  },
  state: (state, { t, memos }) => ({
    redirectStatus: selectAuthRedirectStatus(state),
    schema: memos.compileSchema(t, i18nPath),
    schemaWithPassword: memos.compileSchemaWithPassword(t, i18nPath),
  }),
  dispatch: {
    doLogin: loginUser,
    doSetAuthInitStatus: setAuthInitStatus,
    doSetRedirectResult: setRedirectResult,
  },
  styles: theme => ({
    root: {
      margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
      textAlign: 'center',

      '& > *:not(:last-child)': {
        marginBottom: theme.spacing.unit * 2,
      },

      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 4}px`,
      borderRadius: theme.spacing.unit,
    },
    label: {
      fontSize: '1.4rem',
    },
    field: {
      paddingTop: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
    },
    sectionOther: {
      marginBottom: theme.spacing.unit * 4,
    },
    registerLink: {
      whiteSpace: 'nowrap',
      marginLeft: '0.5em',
    },
    redirectError: {
      color: theme.palette.error.main,
      textAlign: 'center',
      margin: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,
    },
    redirectErrorLink: {
      textAlign: 'center',
      marginTop: -theme.spacing.unit,
      marginBottom: theme.spacing.unit * 4,
    },
    noAccountMessage: {
      textAlign: 'center',

      '& a': {
        whiteSpace: 'nowrap',
        marginLeft: '0.5em',
      },
    },
    allOptions: {
      textAlign: 'center',
      marginTop: theme.spacing.unit * 4,
    },
  })
});

export default withPage({
  i18nPath,
  theme: 'darkForm',
})(Login);
