import React from 'react';
import classnames from 'classnames';
import { LocalDate } from 'js-joda';
import { toLocalDate } from '@lifetools/shared-utils-time';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    cursor: 'pointer',
    background: theme.palette.common.white,

    '&:hover': {
      background: theme.palette.grey['200'],
    },
  },
  day: {
    float: 'right',
    padding: '2px 4px',
  },
  today: {
    background: theme.palette.grey['200'],
    borderBottom: 'solid 1px #ddd',
    borderLeft: 'solid 1px #ddd',
  },
  selected: {
    background: theme.palette.grey['A100'],
  },
});

const DateSelectorCellWrapper = ({ value, selectedDate, classes }) => {
  const date = toLocalDate(value);
  const selected = selectedDate && toLocalDate(selectedDate).equals(date);
  const isToday =  LocalDate.now().equals(date);

  return (
    <div
      className={classnames('rbc-day-bg', classes.root, selected ? classes.selected : undefined)}
    >
      <span className={classnames(classes.day, isToday ? classes.today : undefined)}>
        {date.dayOfMonth()}
      </span>
    </div>
  );
}

export default withStyles(styles)(DateSelectorCellWrapper);
