import dropRight from 'lodash/dropRight';
import toPath from 'lodash/toPath';

/**
 * Returns the parent of the property path.
 *
 * @param path A property path
 *
 * @returns A normalized property path to the parent of the path, or an empty string if the
 *          parent is the root of the object tree
 */
export function getParentPath(path: string) {
  return dropRight(toPath(path)).join('.');
}
