/**
 * Returns true if the `value` is a special field value to be replaced with the timestamp of the
 * server.
 *
 * @param value - The value to test
 *
 * @returns `true` if the value is a field value representing a server timestamp; `false` otherwise
 */
export const isServerTimestampField = (value: any): boolean => (
  value != null && value.methodName === 'FieldValue.serverTimestamp'
);
