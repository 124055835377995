import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from 'components/library/FormDialog';
import withTranslations from 'helpers/withTranslations';
import compileSchema from 'utils/data/compileSchema';
import ActivityEditForm from './components/ActivityEditForm';
import AppointmentEditForm from './components/AppointmentEditForm';
import TaskEditForm from './components/TaskEditForm';

const forms = {
  activity: ActivityEditForm,
  appointment: AppointmentEditForm,
  task: TaskEditForm,
};

const formSchemas = {};

function getSchema(form, formType, t) {
  if (!form.schema) {
    return undefined;
  }

  if (!formSchemas[formType]) {
    formSchemas[formType] = compileSchema(form.schema, t, form.fieldsI18nPath || form.i18nPath);
  }

  return formSchemas[formType];
}

function doSave(EditForm, onSave, values) {
  const value = EditForm.fromFormValues(values);
  const changes = pick(value, EditForm.editFields);

  onSave(value, changes);
}

const TodoEditDialog = ({ defaultType, value, options, onSave, onCancel, onClose, t }) => {
  const formType = (value && value.type) || defaultType;
  const EditForm = forms[formType];
  const formSchema = getSchema(EditForm, formType, t);

  return (
    <FormDialog
      formId="TodoEditDialog"
      i18nPath={EditForm.i18nPath}
      title={value ? t(EditForm.i18nPath, 'title-edit') : t(EditForm.i18nPath, 'title-create')}
      values={EditForm.toFormValues(value || EditForm.getDefaultValue(options))}
      schema={formSchema}
      onSave={values => doSave(EditForm, onSave, values)}
      onCancel={onCancel}
      onClose={onClose}
    >
      <EditForm todo={value} />
    </FormDialog>
  );
}

TodoEditDialog.propTypes = {
  /**
   * The type of form to display if it cannot be determined by the `value` (e.g. when adding a new
   * value)
   */
  defaultType: PropTypes.oneOf([ 'activity', 'appointment', 'task' ]),

  /** The todo object to render */
  value: PropTypes.object,

  /** Called to save the todo */
  onSave: PropTypes.func,

  /** Called to cancel editing the todo */
  onCancel: PropTypes.func,

  /** Called when the dialog is cancelled, either by saving or cancelling */
  onClose: PropTypes.func,

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

export default withTranslations(TodoEditDialog);
