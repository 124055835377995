import React from 'react';
import { DragOverlay } from '@dnd-kit/core';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[10],
    transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
  },
})

const DragOverlayItem = ({ item, classes, children, ...props }) => (
  <DragOverlay {...props}>
    {item ? <div className={classes.root}>{item}</div> : null}
  </DragOverlay>
);

export default withStyles(styles)(DragOverlayItem);
