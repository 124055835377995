import get from 'lodash/get';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { LocalDate } from 'js-joda';
import getDisplayName from 'helpers/utils/getDisplayName';
import { buildScene } from 'scenes/utils';
import importEvents from 'services/store/integrations/calendars/actions/importEvents';
import loadSettings from 'services/store/settings/actions/loadSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import selectUserSettingsStatus from 'services/store/settings/selectors/selectUserSettingsStatus';
import { clampDate } from 'utils/time/clampDate';

// Cronofy throws errors if we attempt to import events outside this range (relative to today).
// For now imposing the limit on GCalendar too.
//
// TODO: Remove this limit for GCalendar [twl 31.May.22]
const earliestImport = 42;
const latestImport = 201;

function withImportedAppointments(WrappedComponent) {
  async function loadData() {
    // Determine the start date
    const { date = LocalDate.now(), settingsStatus, isAuthorized, doLoadSettings } = this.props;

    if (!settingsStatus || settingsStatus.state !== 'success') {
      doLoadSettings();
    } else if (isAuthorized) {
      const { doImportEvents } = this.props;

      let startDate = clampDate(date.withDayOfMonth(1), earliestImport, latestImport);
      let endDate = clampDate(startDate.plusDays(latestImport), earliestImport, latestImport);

      // This only happens when the user is looking too far into the past or too far into the future
      if (startDate.equals(endDate)) {
        return;
      } else if (startDate.isAfter(endDate)) {
        const earlierDate = endDate;

        endDate = startDate;
        startDate = earlierDate;
      }

      // TODO: Update the `endDate` once we support import statuses that allows us to import only
      //       one month at a time. [twl 14.Oct.18]
      doImportEvents({
        startDate,
        endDate,
      });
    }
  }

  const WithImportedAppointments = buildScene(props => <WrappedComponent {...props} />, {
    state: (state, props) => {
      const userSettings = selectUserSettings(state);
      const integration = get(userSettings, 'calendars.config.integration', 'gcalendar');

      return {
        integration,
        isAuthorized: get(userSettings, `integrations.${integration}.authorized`),
        settingsStatus: selectUserSettingsStatus(state, 'read'),
      };
    },
    dispatch: {
      doImportEvents: importEvents,
      doLoadSettings: loadSettings,
    },
    lifecycle: {
      componentDidMount: loadData,
      componentDidUpdate: loadData,
    }
  });

  hoistNonReactStatics(WithImportedAppointments, WrappedComponent);

  WithImportedAppointments.displayName = `WithImportedAppointments(${getDisplayName(WrappedComponent)})`;

  return WithImportedAppointments;
}

export default withImportedAppointments;
