import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  dragging: {
    background: theme.palette.grey[800],
    outline: 'none',
  },
  draggingInner: {
    opacity: 0,
  }
});

export function SortableItem({ id, classes, children }) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={isDragging ? classes.dragging : undefined}
      style={style}
      {...attributes}
      {...listeners}
    >
      {isDragging
        ? <div className={classes.draggingInner}>{children}</div>
        : children
      }
    </div>
  );
}

export default withStyles(styles)(SortableItem);
