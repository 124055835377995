import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from 'components/library/FormDialog';
import withTranslations from 'helpers/withTranslations';
import NotesEditForm from './components/NotesEditForm';

function doSave(EditForm, onSave, value) {
  const changes = pick(value, EditForm.editFields);

  onSave(value, changes);
}

const NotesEditDialog = ({ value, i18nPath, onSave, onCancel, onClose, t }) => (
  <FormDialog
    formId="NotesEditDialog"
    i18nPath={i18nPath}
    title={t(i18nPath, value.notes ? 'title-edit' : 'title-create')}
    values={value}
    onSave={values => doSave(NotesEditForm, onSave, values)}
    onCancel={onCancel}
    onClose={onClose}
  >
    <NotesEditForm i18nPath={i18nPath} />
  </FormDialog>
);

NotesEditDialog.propTypes = {
  /** The intent object to render */
  value: PropTypes.object,

  /** The path from which to load localization strings for this component */
  i18nPath: PropTypes.string,

  /** Called to save the intent */
  onSave: PropTypes.func.isRequired,

  /** Called to cancel editing the intent */
  onCancel: PropTypes.func,

  /** Called when the dialog is cancelled, either by saving or cancelling */
  onClose: PropTypes.func,

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

export default withTranslations(NotesEditDialog);
