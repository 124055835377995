import React from 'react';
import CalendarBase from 'scenes/base/CalendarBase';
import AppointmentsDetail from './components/AppointmentsDetail';
import AppointmentsMain from './components/AppointmentsMain';

const Appointments = props => (
  <CalendarBase mainPage={AppointmentsMain} detailPage={AppointmentsDetail} />
)

export default Appointments;
