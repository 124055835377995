import React from 'react';
import SubscriptionButton from 'components/app/account/SubscriptionButton';
import Box from 'components/library/Box';
import withTranslations from 'helpers/withTranslations';
import { buildScene } from 'scenes/utils';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { getActivePlanInfo } from 'utils/app/plans/getActivePlanInfo';
import { formatLocalDate } from 'utils/time';
import { formatDaysFromNow } from 'utils/time/formatDaysFromNow';
import OpenBillingPortalButton from './components/OpenBillingPortalButton';
import SettingsField from './components/SettingsField';

const i18nPath = 'scenes.users.Account.components.SubscriptionSettings';

const formatEndsOn = (t, endsOn, days) => (
  (endsOn ? formatLocalDate(endsOn, t(i18nPath, 'date-format')) : '--') +
  ` (${formatDaysFromNow(days, t)})`
);

const endsOnKey = (type, status, toCancel) => {
  switch (type) {
    case 'paid':
      return status === 'canceled'
        ? 'fields.canceled'
        : toCancel
          ? 'fields.ends'
          : ['unpaid', 'ended'].includes(status) ? 'fields.ended' : 'fields.renews';

    case 'free':
      return 'fields.ends';

    default:
      return status === 'active' ? 'fields.ends' : 'fields.ended';
  }
};

const SubscriptionSettings = buildScene(({
  type,
  status,
  title,
  endsOn,
  daysUntilEnd,
  isActive,
  promotion,
  toCancel,
  t,
  classes,
}) => (
  <Box classes={{ content: classes.content }} title={t(i18nPath, 'title')}>
    <div className={classes.planDetails}>
      <SettingsField label={t(i18nPath, 'fields.plan')} value={title} />

      <SettingsField
        label={t(i18nPath, 'fields.status')}
        value={t(i18nPath, `statuses.${toCancel ? 'cancel-future' : status}`)}
      />

      {type !== 'free' &&
        <SettingsField
          label={t(i18nPath, endsOnKey(type, status, toCancel))}
          value={
            type === 'free' ? t(i18nPath, 'ends-never') : formatEndsOn(t, endsOn, daysUntilEnd)
          }
        />
      }

      {promotion && promotion.offer &&
        <SettingsField
          label={t(i18nPath, 'fields.promotion')}
          value={promotion.offer.name || '???'}
        />
      }
    </div>

    {(type !== 'paid' || !['active', 'incomplete', 'past_due'].includes(status)) &&
      <SubscriptionButton
        className={classes.manage}
        color="primary"
        promo={promotion && promotion.offer && promotion.offer.coupon}
      />
    }

    {type === 'paid' &&
      <OpenBillingPortalButton className={classes.manage} color="primary" />
    }
  </Box>
), {
  styles: theme => ({
    content: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
    },
    planDetails: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto',
      rowGap: `${theme.spacing.unit * 2}px`,
    },
    manage: {
      margin: `${theme.spacing.unit * 4}px auto ${theme.spacing.unit * 2}px auto`,
      alignSelf: 'center',
    },
  }),
  memos: {
    activePlanInfo: userSettings => getActivePlanInfo(userSettings),
  },
  state: (state, { memos }) => memos.activePlanInfo(selectUserSettings(state)),
});

export default withTranslations(SubscriptionSettings);
