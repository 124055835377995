import React from 'react';
import TodayBase from 'scenes/app/TodayBase';
import DailyScheduleView from './components/DailyScheduleView';

const i18nPath = 'scenes.app.DailySchedule';

const DailySchedule = () => (
  <TodayBase i18nPath={i18nPath} scheduleType="schedule" scheduleView={DailyScheduleView} />
);

export default DailySchedule;