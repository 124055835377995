import React from 'react';
import classnames from 'classnames';
import Toolbar from 'components/library/Toolbar';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    background: theme.palette.grey[100],
    justifyContent: 'center',

    '& > *:not(:first-child)': {
      marginLeft: theme.spacing.unit,
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',

      '& > *:not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
});

const ActionBarPane = ({ className, classes, children }) => (
  <Toolbar className={classnames(classes.root, className)} variant="dense" disableGutters={true}>
    {children}
  </Toolbar>
);

export default withStyles(styles)(ActionBarPane);
