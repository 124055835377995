import React from 'react';
import { compose } from 'recompose';
import Icon from 'components/library/Icon';
import Link from 'components/library/Link';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'components.app.schedules.Scheduler.components.SchedulerIntentCell.components.ConflictWarning';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    background: theme.palette.grey['100'],
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`,
  },
  warning: {
    display: 'flex',
    marginRight: theme.spacing.unit,
  },
  icon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing.unit,
    marginTop: 4,                        // Fudge to make the icon line up with the text
  },
  message: {
    display: 'inline-block',             // Ensure text wraps in a block next to icon on mobile
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 24,

    '& > a:not(:last-child)::after': {
      display: 'inline-block',           // Removes underline from separator when hovering over link
      content: '"|"',
      margin: `0 ${theme.spacing.unit}px`,
      color: theme.palette.text.secondary,
    },
  },
  action: {
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
  },
});

const ConflictWarning = ({
  intent,
  type,
  duration,
  onUpdate,
  onRemove,
  onScheduleExtend,
  classes,
  t,
}) => (
  <div className={classes.root}>
    <div className={classes.warning}>
      <Icon className={classes.icon} type="fas:exclamation-triangle" />
      <Typography className={classes.message} inline>
        {t(i18nPath, 'message', { duration })}
      </Typography>
    </div>

    <div className={classes.actions}>
      <Link className={classes.action} onClick={() => onRemove(intent)}>
        {t(i18nPath, 'actions.remove')}
      </Link>

      <Link
        className={classes.action}
        onClick={() => onUpdate(intent, { duration: intent.duration - duration })}
      >
        {t(i18nPath, 'actions.reduceDuration')}
      </Link>
      {type === 'scheduleEnd' && onScheduleExtend &&
        <Link className={classes.action} onClick={() => onScheduleExtend(duration)}>
          {t(i18nPath, 'actions.extendSchedule')}
        </Link>
      }
    </div>
  </div>
);

export default compose(
  withStyles(styles),
  withTranslations,
)(ConflictWarning);

