import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from 'services/store/todos/definitions';
import updateRemoteDocument from 'services/store/utils/actions/updateRemoteDocument';

const allocateTodo = makeAsyncActionCreator({
  type: types.ALLOCATE_TODO,
  creator: async (intent, duration) => (
    await updateRemoteDocument(collection, intent.todo.id, {
      [`intents.${intent.scheduleId}.duration`]: duration
    })
  ),
  status: {
    path: collection,
    type: 'write',
    parameters: (intent, _duration) => [ intent.id ],
  }
});

export default allocateTodo;
