import React from 'react';
import { connect } from 'react-redux';
import { TrackedRoute } from '@lifetools/react-router-tracker';
import Redirect from 'components/router/Redirect';
import { hasAuthentication, isAuthenticated } from 'services/store/auth/selectors';
import Splash from 'components/app/navigation/Splash';
import { getSessionValue } from 'utils/browser/getSessionValue';
import { setSessionValue } from 'utils/browser/setSessionValue';

const AnonymousRoute = ({
  component: Component,
  loader = 'splash',
  render,
  redirectTo,
  isAuthenticated,
  hasAuthentication,
  ...props
}) => (
  <TrackedRoute
    {...props}
    render={props => (
      !hasAuthentication
        ? (loader === 'none' ? null : <Splash />)
        : isAuthenticated
          ? (redirectTo ? <Redirect to={redirectTo} /> : null)
          : (render ? render(props) : <Component {...props} />)
    )}
  />
);

const mapStateToProps = (state, { redirectTo }) => {
  const isUserAuthenticated = isAuthenticated(state);
  const redirectFrom = getSessionValue('redirectFrom');

  if (redirectFrom && isUserAuthenticated) {
    setSessionValue('redirectFrom', undefined);
  }

  return ({
    hasAuthentication: hasAuthentication(state),
    isAuthenticated: isUserAuthenticated,
    redirectTo: redirectFrom ?? redirectTo,
  });
}

export default connect(mapStateToProps)(AnonymousRoute);
