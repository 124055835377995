import React from 'react';
import TextField from 'components/library/TextField';
import MaterialUiField from '../MaterialUiField';

const DateField = ({ value, ...props }) => (
  <MaterialUiField
    placeholder="YYYY-MM-DD"
    {...props}
    muiComponent={TextField}
    type="date"
    defaultValue=""
  />
);

export default DateField;
