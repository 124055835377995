import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import Paper from 'components/library/Paper';
import Typography from 'components/library/Typography';
import withContext from 'helpers/withContext';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';

const styles = theme => ({
  root: {
    textAlign: 'left',
    padding: theme.spacing.unit * 3,
  },
  rootFlat: {
    border: `solid 1px ${theme.palette.grey['400']}`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > h3': {
      fontWeight: 500
    }
  },
  button: {
    whiteSpace: 'nowrap',
  },
  message: {
    marginTop: theme.spacing.unit * 2,
  },
});

const DailyPlanInfoListItem = ({
  i18nPath,
  className,
  variant,
  date,
  workflow,
  existingSchedule,
  activePlanType,
  isAccountReadOnly,
  classes,
  t,
}) => (
  <Paper
    className={classnames(classes.root, variant === 'flat' && classes.rootFlat, className)}
    elevation={variant === 'flat' ? 0 : undefined}
  >
    <div className={classes.header}>
      <Typography variant="h3">{t(i18nPath, 'title')}</Typography>

      {!isAccountReadOnly &&
        <StartWorkflowButton
          className={classes.button}
          workflow={workflow}
          existingSchedule={existingSchedule}
          activePlanType={activePlanType}
          date={date}
        />
      }
    </div>

    <Typography variant="body1" className={classes.message}>
      {t(i18nPath, 'benefit')}. {t(i18nPath, 'message')}
    </Typography>
  </Paper>
);

export default compose(
  withContext('isAccountReadOnly'),
  withStyles(styles),
  withTranslations,
)(DailyPlanInfoListItem);
