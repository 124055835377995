import { IIntent, IIntentStatusState, ITodo, ITodoStatusState } from '@lifetools/shared-app-data';
import { IStatusConfig } from '../types/IStatusConfig';
import { getState } from '../utils/getState';

export function nextState(
  config: IStatusConfig<ITodoStatusState> | IStatusConfig<IIntentStatusState>,
  todo: ITodo,
  intent?: IIntent
) {
  const oldState = getState(config, todo, intent);
  // TODO: Figure out why we need to use `as any` here. [twl 13.Mar.19]
  const result = (config.nextState[todo.type] as any)[oldState];

  return result || 'unknown';
}
