import React, { memo, useLayoutEffect, useRef } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Indicators from 'components/app/indicators/Indicators';
import TodoInfo from 'components/app/todos/TodoInfo';
import TodoSummary from 'components/app/todos/TodoSummary';
import Icon from 'components/library/Icon';
import ListItem from 'components/library/ListItem';
import isVariant from 'components/utils/isVariant';
import withDeviceInfo from 'helpers/withDeviceInfo';
import withStyles from 'helpers/withStyles';
import TodoItemVariantType from 'types/TodoItemVariant';
import TodoItemSecondaryAction from './components/TodoItemSecondaryAction';

const styles = theme => {
  const normalActionWidth = TodoItemSecondaryAction.width + theme.spacing.unit * 2;
  const compactActionWidth = TodoItemSecondaryAction.width + theme.spacing.unit;
  const contentPadding = theme.spacing.unit;

  return {
    baseRoot: {
      overflow: 'hidden',
    },
    pending: {
      background: theme.palette.custom.pending.background,
    },
    normalActionRoot: {
      padding: `${contentPadding * 1.5}px ${contentPadding}px ${contentPadding * 1.5}px ${normalActionWidth}px`,
      minHeight: TodoItemSecondaryAction.width + theme.spacing.unit,
    },
    compactActionRoot: {
      padding: `${contentPadding}px ${contentPadding}px ${contentPadding}px ${compactActionWidth}px`,
      minHeight: TodoItemSecondaryAction.width + theme.spacing.unit,
    },
    normalNoActionRoot: {
      padding: `${contentPadding * 1.5}px ${contentPadding}px ${contentPadding * 1.5}px ${contentPadding * 2}px`,
    },
    compactNoActionRoot: {
      padding: `${contentPadding * 1.5}px ${contentPadding}px ${contentPadding * 1.5}px ${contentPadding * 2}px`,
    },
    baseAction: {
      // position the action on the left side. The `ListItemSecondaryAction` must be absolutely
      // positioned because buttons within buttons is not valid HTML. If we're positioning
      // absolutely, we have to use a fixed width to ensure the content is positioned correctly.
      // For more info on absolutely positioned `ListItemSecondaryAction` components, check out:
      // https://github.com/mui-org/material-ui/issues/13495
      // [twl 15.Dec.18]
      left: 0,
      right: 'inherit',
      textAlign: 'center',
    },
    normalAction: {
      width: normalActionWidth,
    },
    compactAction: {
      width: compactActionWidth,
    },
    viewIcon: {
      alignSelf: 'center',
      marginRight: theme.spacing.unit,

      '@media print': {
        display: 'none',
      },
    },
    selected: {
      background: `${theme.palette.action.hover} !important`,

      '&:hover': {
        background: `${theme.palette.action.selected} !important`,
      },
    },
    button: {
      '&:focus': {                     // TODO: Think about focus management for a11y [twl 8.Feb.19]
        background: 'inherit',
      },
    },
    indicators: {
      justifyContent: 'flex-end',
      '&:not(:empty)': {
        marginRight: theme.spacing.unit * 2,
      },
    },
    indicatorsUnderInfo: {
      marginTop: theme.spacing.unit,
      justifyContent: 'flex-start',
    },
  };
};

const TodoItem = ({
  id,
  className,
  value,
  intent,
  variant = 'normal',
  divider = false,
  actionMode,
  date,
  selected,
  component,
  groups,
  onAdd,
  onRemove,
  onStatusChange,
  onSelect,
  onUnselect,
  isMobileDevice,
  isDeviceSizeDown,
  classes,
}) => {
  const ref = useRef();

  useLayoutEffect(() => {
    if (!isMobileDevice && ref.current && value.$pending) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }, [isMobileDevice, value.$pending]);


  // TODO: If we decide to stay with `compact` style for everything, come back and remove the
  //       `normal` style from this component. [20.Dec.18]
  const style = isVariant(variant, 'schedule', 'summary') ? 'compact' : 'compact';
  const onClick = onSelect || onUnselect;
  const showSecondaryAction = actionMode != null;
  const tags = !value.tags || !groups ? value.tags : value.tags.filter(tag => !groups.includes(tag));

  return (
    <ListItem
      id={!showSecondaryAction ? id : undefined}
      className={classnames(classes.baseRoot, value.$pending && classes.pending, className)}
      classes={{
        root: classes[`${style}${actionMode ? 'Action' : 'NoAction'}Root`],
        selected: classes.selected,
        button: classes.button,
      }}
      style={value.color ? { borderLeft: `solid 6px ${value.color}` } : undefined}
      dense
      component={component}
      ContainerComponent={component}
      ContainerProps={showSecondaryAction ? { id, ref } : { ref }}
      button={onSelect != null || onUnselect != null}
      divider={divider}
      selected={selected}
      onClick={onClick && (() => onClick(value, intent))}
    >
      <ListItem.Text>
        <TodoSummary value={value} intent={intent} variant={variant} />
        <TodoInfo value={value} intent={intent} variant={variant} date={date} />
        {isDeviceSizeDown('md') &&
          <Indicators
            className={classnames(classes.indicators, classes.indicatorsUnderInfo)}
            value={value}
            tags={tags}
          />
        }
      </ListItem.Text>

      {!isDeviceSizeDown('md') &&
        <Indicators className={classes.indicators} value={value} tags={tags} />
      }

      {onSelect &&
        <Icon className={classes.viewIcon} type="fas:chevron-down" />
      }

      {onUnselect &&
        <Icon className={classes.viewIcon} type="fas:chevron-up" />
      }

      {/**
        * NOTE: The secondary action *must* be the last child of `ListItem` and *must* have a
        *       `muiName` of `ListItemSecondaryAction`. This is not documented currently, but you can
        *       see it in the code at:
        *       https://github.com/mui-org/material-ui/blob/a207808404a703d1ea2b03ac510343be6404b166/packages/material-ui/src/ListItem/ListItem.js#L99
        *       [twl 15.Dec.18]
        */}
      {showSecondaryAction &&
        <TodoItemSecondaryAction
          className={classnames(classes.baseAction, classes[`${style}Action`])}
          actionMode={actionMode}
          value={value}
          intent={intent}
          onAdd={onAdd}
          onRemove={onRemove}
          onStatusChange={onStatusChange}
        />
      }
    </ListItem>
  );
}

TodoItem.propTypes = {
  /** The custom class to add to this component */
  className: PropTypes.string,

  /** The todo object to render */
  value: PropTypes.shape({
    status: PropTypes.object,
    title: PropTypes.string,
  }),

  /** The active intent for the todo */
  intent: PropTypes.object,

  /** The variant to use. Defaults to `normal` */
  variant: TodoItemVariantType,

  /** Whether a divider should be displayed underneath the item. Defaults to `false` */
  divider: PropTypes.bool,

  /** The type of action to display for this action. Defaults to `undefined` for no action */
  actionMode: PropTypes.oneOf([ 'add', 'remove', 'statusChange', 'statusMenu', 'statusReadOnly' ]),

  /** The date to use as "today" when deciding what to show/hide or for relative date formats */
  date: PropTypes.object,

  /** Whether this item should display as selected. Defaults to `false` */
  selected: PropTypes.bool,

  /** Called to add the todo to a list or entity; when defined, renders the add button */
  onAdd: PropTypes.func,

  /** Called to remove the todo from a list or entity; when defined, renders the remove button */
  onRemove: PropTypes.func,

  /** Called to change the status of the todo; when defined, renders the status button */
  onStatusChange: PropTypes.func,

  /** Called to show the details of the todo */
  onSelect: PropTypes.func,

  /** Called to hide the details of the todo */
  onUnselect: PropTypes.func,

  /** An object mapping semantic class names to compiled class names. Provided by HOC. */
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withDeviceInfo,
  memo,
)(TodoItem);
