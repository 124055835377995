import queryString from 'query-string';

const backShortcuts: { [name: string]: string  } = {
  www: 'https://dayoptimizer.com/',
  home: '/',
}

/**
 * Goes to the previous page in the browser history, unless a `back` parameter exists on the URL,
 * in which case it goes back to the page specified by `back`. The page specified can be a full URL,
 * an absolute or relative path, or a shortcut name that maps to a URL.
 */
export function goBack() {
  const back = queryString.parse(window.location.search || '').back;

  if (typeof back === 'string' && back !== '') {
    window.location.href = (back && backShortcuts[back]) || back;
  } else {
    window.history.back();
  }
}
