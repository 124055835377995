import React from 'react';
import IntentListWidget from 'widgets/intents/IntentListWidget';

const i18nPath = 'widgets.intents.UnallocatedIntentsWidget';

const UnallocatedIntentsWidget = props => (
  <IntentListWidget {...props} i18nPath={i18nPath} filter={intent => !intent.duration} />
);

export default UnallocatedIntentsWidget;
