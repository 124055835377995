import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import StatusSelector from 'components/app/todos/StatusSelector';
import TodoType from 'components/app/todos/TodoType';
import Markdown from 'components/library/Markdown';
import Typography from 'components/library/Typography';
import ViewField from 'components/library/ViewField';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import intentText from 'utils/intents/intentText';

const i18nPath = 'data.intents.fields';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 1.5,

    '& > *:not(:first-child)': {
      marginTop: theme.spacing.unit * 2,
    }
  },
  splitRow: {
    display: 'flex',

    '& > *': {
      flex: '1 1 100%',
    },
  },
  inlineText: {
    display: 'inline-block',
    marginRight: theme.spacing.unit / 2,
  },
});

const getNotes = intent => (
  intent.todo.type === 'activity' && intent.desc ? intent.desc : intent.todo.desc
);

const IntentQuickViewSummary = ({ value, actionMode, onUpdate, onStatusChange, classes, t }) => (
  <div className={classes.root}>
    <div className={classes.splitRow}>
      <ViewField i18nPath={i18nPath} name="status" show="always">
        <StatusSelector
          actionMode={actionMode === 'statusReadOnly' ? actionMode : 'statusMenu'}
          todo={value.todo}
          intent={value}
          onStatusChange={onStatusChange}
        />
      </ViewField>

      <ViewField i18nPath={i18nPath} name="type" show="always">
        <TodoType value={value.todo.type} />
      </ViewField>
    </div>

    <div className={classes.splitRow}>
      <ViewField
        i18nPath={i18nPath}
        name="date"
        value={intentText(value, 'relativeDateTime', t)}
      />

      <ViewField
        i18nPath={i18nPath}
        name="duration"
        show="always"
      >
        <Typography className={classes.inlineText} variant="body2">
          {intentText(value, 'duration', t, '', '', '--')}
        </Typography>

        <Typography className={classes.inlineText} variant="body2">
          {intentText(value, 'durationEndTime', t, ' (', ')', '')}
        </Typography>
      </ViewField>
    </div>

    {value.todo.type === 'appointment' &&
      <ViewField
        i18nPath={i18nPath}
        name="appointmentTime"
        value={intentText(value.todo, 'timeRange')}
      />
    }

    <ViewField i18nPath={i18nPath} object={value.todo} name="location" />

    {getNotes(value) &&
      <ViewField i18nPath={i18nPath} name="desc" show="always">
        <Markdown
          value={getNotes(value)}
          onChange={
            actionMode !== 'statusReadOnly'
              ? desc => onUpdate(value, { todo: { desc } })
              : undefined
          }
        />
      </ViewField>
    }
  </div>
);

IntentQuickViewSummary.propTypes = {
  /** The intent object to render */
  value: PropTypes.shape({
    todo: PropTypes.shape({
      desc: PropTypes.string,
      status: PropTypes.object,
      type: PropTypes.oneOf([ 'activity', 'appointment', 'task' ]),
      start: PropTypes.string,
      end: PropTypes.string,
      location: PropTypes.string,
    }).isRequired,
    start: PropTypes.object,
    duration: PropTypes.number,
  }),

  /** Called to update the intent */
  onUpdate: PropTypes.func,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /** A function used to provide translations of text. Provided by `withTranslations` */
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withTranslations,
)(IntentQuickViewSummary);
