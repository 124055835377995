import securePasswordCheck from 'utils/data/securePasswordCheck';

/**
 * Performs a YUP validation on `value` to determine if the password is secure. If it is, returns
 * true; otherwise adds errors to the YUP error validation for the path to the field being
 * validated.
 *
 * NOTE: This function will not validate whether `value` exists; use a separate required validation
 *       for this. This method returns `true` when `value` is falsey.
 *
 * @param {string} value The password to test to see if it is secure
 *
 * @return {boolean|ValidationError} `true` if the password is secure or a YUP `ValidationError`
 *                                   containing the errors that failed for this check
 */
function validateSecurePassword(value) {
  const result = value && securePasswordCheck(value);

  if (!result || result.length === 0) {
    return true;
  }

  // Create an error so we can override the default message for this field with multiple specific
  // messages
  const error = this.createError({
    path: this.path,
    message: result,
  });

  if (result.length > 1) {
    // Override the "3 errors occurred" message YUP provides when `result` is an array, so we can
    // display individual messages
    error.message = result;
  }

  // TODO: Figure out a way to localize these error messages [twl 9.Dec.18]

  return error;
}

export default validateSecurePassword;
