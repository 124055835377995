interface OperationStatus {
  state: 'running' | 'success' | 'failure';
}

export type AuthorizationState = 'savingOauthCode' | 'savingOauthCodeFailed' |
                                 'loadingAuthorization' | 'loadingAuthorizationFailed' |
                                 'authorized:new' | 'authorized:existing' | 'notAuthorized';

/**
 * Returns the authorization state based on the status of loading the user settings (specifically,
 * the `isAuthorized` setting), the status of saving the OAuth code and the value of `isAuthorized`.
 *
 * States include:
 *
 * loadingAuthorization
 * : During the loading of the `isAuthorized` setting
 *
 * loadingAuthorizationFailed
 * : If the loading of the `isAuthorized` setting has failed
 *
 * authorized:new
 * : If the `isAuthorized` setting was loaded succesfully, its value is `true` and the
 *   authorization occurred in this session (i.e. the OAuth code was saved this session)
 *
 * authorized:existing
 * : If the `isAuthorized` setting was loaded succesfully, its value is `true` and the
 *   authorization occurred in a previous session (i.e. no OAuth code was saved this session)
 *
 * notAuthorized
 * : If the `isAuthorized` setting was loaded succesfully and is `false`
 *
 * savingOauthCode
 * : During the saving of the OAuth code. While the OAuth code is being saved, the authorization
 *   status is unknown.
 *
 * savingOauthCodeFailed
 * : If the saving of the OAuth code failed. If this occurs, the authorization status remains
 *   unknown.
 *
 * @param {object}   oauthCodeStatus The status object for saving the OAuth code
 * @param {object}   settingsStatus  The status object for loading the settings
 * @param {boolean?} isAuthorized    Whether the user has authorized with  Calendar or not
 *
 * @return {string} The state of the component
 */
export function getAuthorizationState(
  oauthCodeStatus: OperationStatus,
  settingsStatus: OperationStatus,
  isAuthorized: boolean
): AuthorizationState {
  switch (oauthCodeStatus.state) {
    case 'running':
      return 'savingOauthCode';

    case 'failure':
      return 'savingOauthCodeFailed';

    default:
      // fall through to settings status
  }

  switch (settingsStatus.state) {
    case 'running':
      return 'loadingAuthorization';

    case 'failure':
      return 'loadingAuthorizationFailed';

    case 'success':
      return isAuthorized ?
              (oauthCodeStatus.state ? 'authorized:new' : 'authorized:existing') :
              'notAuthorized';

    default:
      return 'loadingAuthorization';
  }
}
