import { callFunction } from 'services/server/data';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from 'services/store/settings/definitions';

export const setOauthCode = makeAsyncActionCreator({
  type: types.SET_USER_SETTINGS,
  creator: async (code, state, callbackPath) => ({
    settings: await callFunction(`calendars_setOauthCode`, {
      host: window.location.host,
      callbackPath,
      code,
      state,
    }),
  }),
  status: {
    path: collection,
    type: 'write',
    parameters: (_code, state, callbackPath) => {
      const integration = state && state.split('_')[0];

      return [ 'user', integration, callbackPath ];
    },
  }
});

export default setOauthCode;
