import React, { useEffect, useState } from 'react';
import { Route, useLocation, useParams } from 'react-router-dom';
import routeTracker from '../services/routeTracker';
import { PlainObject } from '../types/PlainObject';

/**
 * Augments the `react-router-dom` `Route` component to track the route information for all routes
 * being currently rendered.
 */
export const TrackedRoute = (props: PlainObject) => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => routeTracker.addRoute(props, params, location), [ location ]);

  return <Route {...props} />;
}
