import React from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { buildScene } from 'scenes/utils';
import setOauthCode from 'services/store/integrations/calendars/actions/setOauthCode';

async function setAuthCode() {
  const { location, history, callbackPath, redirectPath = '/', doSetOauthCode } = this.props;
  const params = queryString.parse(location.search);
  const [ integration ] = (params.state || '').split('_');

  // Always redirect so we don't leave the code on the URL
  history.replace(redirectPath + `?state=${integration}`);

  try {
    await doSetOauthCode(params.code, params.state, callbackPath);
  } catch (e) {
    // TODO: Handle the error condition when code is not valid. [twl 11.Oct.18]
    console.error(e);
  }
}

const SaveOAuthCode = buildScene(() => (
  <div />
), {
  dispatch: {
    doSetOauthCode: setOauthCode,
  },
  lifecycle: {
    componentDidMount: setAuthCode,
  }
});

export default withRouter(SaveOAuthCode);
