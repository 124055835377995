/**
 * Converts a dot-separated version string to an integer.
 *
 * NOTE: This assumes that versions always have the same number of levels. If versions have a
 *       different number of levels, then versions cannot be compared numerically. For instance,
 *       `12.3.4` will be converted to `120304`, but `12.3` will be converted to `1203`.
 *       [twl 7.May.20]
 *
 * @param version - A dot-separated version string

 * @return The version as an integer
 */
export function getVersionAsInteger(version?: string): number | undefined {
  if (!version) {
    return undefined;
  }

  const elements = version.split('.').reverse();

  let number = 0;

  for (let i = 0; i < elements.length; i++) {
    number += Math.pow(100, i) * parseInt(elements[i], 10);
  }

  return number;
}

