import React from 'react';
import classnames from 'classnames';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nPath = '*.time.days-of-week';

const styles = theme => ({
  root: {
    width: '2em',
    height: '2em',
    lineHeight: '2em',
    fontWeight: 500,
    background: theme.palette.grey[100],
    borderRadius: '50%',
    textAlign: 'center',
  },
  checked: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
});

const WeekdaySelectorIcon = ({ dayOfWeek, checked, classes, t }) => (
  <Typography className={classnames(classes.root, checked && classes.checked)} variant="caption">
    {t(i18nPath, dayOfWeek)[0]}
  </Typography>
);

export default withTranslations(withStyles(styles)(WeekdaySelectorIcon));
