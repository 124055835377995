import React from 'react';
import ActionBar from 'components/library/ActionBar';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

const PrintButton = ({ i18nPath = '*', classes }) => (
  <ActionBar.Button
    i18nPath={i18nPath}
    classes={classes}
    action="print"
    onClick={() => window.print()}
  />
);

export default withStyles(styles)(PrintButton);
