import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { useNow } from 'hooks/useNow';
import { formatTime, formatTimeRange } from 'utils/time';
import formatDuration from 'utils/time/formatDuration';
import { minutesLeft } from 'utils/time/minutesLeft';
import { timeUntilDone } from './utils/timeUntilDone';
import { timeCeilMinutes } from './utils/timeCeilMinutes';
import { ceilMultiple } from './utils/ceilMultiple';

const i18nPath = 'components.app.schedules.ScheduleProjections';

const roundMinutesTo = 5;

// TODO: Copied from `Scheduler`. Figure out a good common place for this, or make this translation
//       dependent. [twl 2.Apr.20]
const timeFormat = 'h:mm a';

const styles = theme => ({
  root: {
    background: theme.palette.grey['100'],
    borderBottom: `solid 1px ${theme.palette.grey['400']}`,
    padding: `${theme.spacing.unit * 2}px 0`,
    position: 'sticky',
    top: 0,
    zIndex: 100,

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,

      '& > *:not(:first-child)': {
        justifyContent: 'space-evenly',
      },

      '& > *:last-child': {
        justifyContent: 'flex-end',
      },
    },

    [theme.breakpoints.down('md')]: {
      '& > *:first-child': {
        marginTop: 0,
      },

      '& > *:last-child': {
        marginBottom: 0,
      },
    },

    '@media print': {
      display: 'none',
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: theme.spacing.unit * 2,

    '& > *': {
      whiteSpace: 'nowrap',
    },

    '&:not(:last-child) > *:first-child': {
      marginRight: '0.5em',
    },

    '&:last-child > *:last-child': {
      marginLeft: '0.5em',
    },
  },
  allDone: {
    margin: '0 auto',
  },
});

const ScheduleProjections = ({ className, date, nextStartTime, schedule, intents, classes, t }) => {
  const { now, isNowActive, isBeforeStartTime } = useNow(date, schedule.startTime, nextStartTime);
  const timeRemaining = ceilMultiple(
    intents.reduce((total, intent) => total + timeUntilDone(intent, now), 0),
    roundMinutesTo
  );
  const totalTime = intents.reduce((total, intent) => total + (intent.duration || 0), 0);
  const projectedEnd = timeCeilMinutes(now.plusMinutes(timeRemaining), roundMinutesTo);
  const minutesRemaining = minutesLeft(schedule, date, now, roundMinutesTo)
  const unplannedEffort = minutesRemaining - timeRemaining;
  const showRemaining = isNowActive && !isBeforeStartTime;

  return !isNowActive || totalTime === 0 || timeRemaining > 0
    ? <div className={classnames(classes.root, className)}>
        <div className={classes.row}>
          <Typography variant="subtitle2">{t(i18nPath, 'plan-times')}</Typography>
          <Typography variant="body2">
            <span>
              {formatTimeRange(schedule.startTime, schedule.endTime, null, 'compact') + ' '}
            </span>
            <span>
              ({t(i18nPath, `plan-effort-${showRemaining ? 'remaining' : 'total'}`, {
                effort: minutesRemaining > 0 ? formatDuration(minutesRemaining, 'normal', t) : '0m',
              })})
            </span>
          </Typography>
        </div>

        <div className={classes.row}>
          <Typography variant="subtitle2">
            {t(i18nPath, showRemaining ? 'remaining-effort' : 'committed-effort')}
          </Typography>

          <Typography variant="body2">
            <span>{formatDuration(timeRemaining, 'normal', t) + ' '}</span>
            <span>
              ({t(i18nPath, `remaining-effort-${unplannedEffort > 0 ? 'under' : 'over'}`, {
                effort: formatDuration(Math.abs(unplannedEffort), 'normal', t),
              })})
            </span>
          </Typography>
        </div>

        <div className={classes.row}>
          <Typography variant="subtitle2">
            {t(i18nPath, 'projected-end')}
          </Typography>

          <Typography variant="body2">
            <span>{formatTime(projectedEnd, timeFormat)}</span>
          </Typography>
        </div>
      </div>
    : totalTime > 0
      ? (
        <div className={classnames(classes.root, className)}>
          <Typography className={classes.allDone} variant="body2">
            {t(i18nPath, 'all-done')}
          </Typography>
        </div>
      )
      : null;
}

export default compose(
  withStyles(styles),
  withTranslations,
)(ScheduleProjections);
