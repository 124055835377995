import React from 'react';
import classnames from 'classnames';
import { compose } from 'recompose';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import todoText from 'utils/todos/todoText';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.grey['100'],
    }
  },
  invalid: {
    cursor: 'not-allowed',
    color: theme.palette.grey['400'],
  }
});

const IntentOption = ({ value, onClick, classes, t }) => (
  <Typography
    className={classnames(classes.root, !value.duration && classes.invalid)}
    color="textSecondary"
    onClick={() => value.duration && onClick(value)}
  >
    {todoText(value.todo, 'title', t)}
    {value.todo.type === 'appointment' && todoText(value.todo, 'timeRange', t, ' [', ']')}
    {todoText(value, 'duration', t, ' (', ')', ' (no time estimation--cannot schedule)')}
  </Typography>
);

export default compose(
  withStyles(styles),
  withTranslations,
)(IntentOption);
