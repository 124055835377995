import { LogLevel } from './types/LogLevel';

const levels = [ 'debug', 'info', 'warn', 'error' ];

class Logger {
  /**
   * A short descriptive string for where this logger is used.
   */
  private context?: string;

  /**
   * The logger that created this logger.
   */
  private parent?: Logger;

  /**
   * The minimum level a message needs to be logged by this logger.
   */
  public level: LogLevel = 'info';

  /**
   * Constructs a new logger with the given `context` and `parent` logger.
   *
   * @param context A string describing the context for this logger
   * @param parent  The logger this logger was derived from
   */
  constructor(context?: string, parent?: Logger) {
    this.context = context;
    this.parent = parent;
  }

  /**
   * Sends the message and data to the log with the given level.
   *
   * @param level   The level at which to log the message
   * @param message The message to log
   * @param ...data Any data to log
   */
  protected sendToLog(level: LogLevel, message: any = '', ...data: any[]) {
    console[level](message, ...data);
  }

  /**
   * Logs a message at a specific level, if logging is enabled for that level.
   *
   * @param level   The level at which to log the message
   * @param message The message to log
   * @param ...data Any data to log
   */
  public log(level: LogLevel, message: any = '', ...data: any[]) {
    if (levels.indexOf(this.level) <= levels.indexOf(level)) {
      this.sendToLog(level, message, ...data);
    }
  }

  /**
   * Logs a debug message.
   *
   * @param message The message to log
   * @param ...data Any data to log
   */
  public debug(message?: any, ...data: any[]) {
    this.log('debug', message, ...data);
  }

  /**
   * Logs an informational message.
   *
   * @param message The message to log
   * @param ...data Any data to log
   */
  public info(message?: any, ...data: any[]) {
    this.log('info', message, ...data);
  }

  /**
   * Logs a warning message.
   *
   * @param message The message to log
   * @param ...data Any data to log
   */
  public warn(message?: any, ...data: any[]) {
    this.log('warn', message, ...data);
  }

  /**
   * Logs an error message.
   *
   * @param message The message to log
   * @param ...data Any data to log
   */
  public error(message?: any, ...data: any[]) {
    this.log('error', message, ...data);
  }

  /**
   * Returns whether the `debug` log level is enabled.
   *
   * @returns `true` if the `debug` log level is enabled
   */
  public isDebugEnabled(): boolean {
    return this.isLevelEnabled('debug');
  }

  /**
   * Returns whether the `info` log level is enabled.
   *
   * @returns `true` if the `info` log level is enabled
   */
  public isInfoEnabled(): boolean {
    return this.isLevelEnabled('info');
  }

  /**
   * Returns whether the `warn` log level is enabled.
   *
   * @returns `true` if the `warn` log level is enabled
   */
  public isWarnEnabled(): boolean {
    return this.isLevelEnabled('warn');
  }

  /**
   * Returns whether the `error` log level is enabled.
   *
   * @returns `true` if the `error` log level is enabled
   */
  public isErrorEnabled(): boolean {
    return this.isLevelEnabled('error');
  }

  /**
   * Creates a new child logger.
   *
   * @param context A string describing the context for this logger
   */
  public logger(context: string) {
    return new Logger(context, this);
  }

  /**
   * Returns whether the log `level` is currently enabled, based on the `level` property of this
   * logger.
   */
  private isLevelEnabled(level: LogLevel): boolean {
    return levels.indexOf(this.level) <= levels.indexOf(level);
  }
}

export default Logger;
