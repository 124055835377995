import { types as globalTypes } from 'services/store/actions';
import {
  applyAction,
  createStatusReducer,
  archiveElement,
  setElements,
  updatePayloadProperties,
} from 'services/store/utils-reducers';
import { collection, types } from './definitions';

const initialData = [];

const actionReducers = {
  [globalTypes.LOGOUT]: () => initialData,
  [globalTypes.SET_DATA]: (state, { collection: target, data }) => (
    target !== collection ? state : setElements(state, data)
  ),

  [types.ADD_TODO]: (state, payload) => setElements(state, payload ? [ payload ] : undefined),
  [types.SET_TODO]: (state, payload) => setElements(state, payload ? [ payload ] : undefined),
  [types.SET_TODOS]: (state, payload) => setElements(state, payload.todos),
  [types.UPDATE_TODO]: (state, payload) => updatePayloadProperties(state, payload),
  [types.ARCHIVE_TODO]: (state, payload) => archiveElement(state, payload),
  [types.ALLOCATE_TODO]: (state, payload) => updatePayloadProperties(state, payload),
  [types.SCHEDULE_TODO]: (state, payload) => updatePayloadProperties(state, payload),
  [types.UPDATE_TODO_STATUS]: (state, payload) => updatePayloadProperties(state, payload, 'status'),
}

const dataReducer = (state = initialData, action) => {
  const data = applyAction(actionReducers, state, action);

  // NOTE: Add a special property to the collection array that allows `selectTodos` to detect that
  //       this is the collection data array. This improves memoization when calling selector
  //       functions by passing in `state?.todos?.data` instead of `state`. [twl 11.Oct.22]
  data.$collection = 'todos';

  return data;
};
const statusReducer = createStatusReducer(collection);

const todosReducer = (state = {}, action) => ({
  status: statusReducer(state.status, action),
  data: dataReducer(state.data, action)
});

export default todosReducer;
