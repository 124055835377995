import { IIntent, ISchedule, ScheduleType } from '@lifetools/shared-app-data';

/**
 * Returns the highest level daily plan that can be viewed for the `schedule` with `intents`.
 *
 * @param schedule - A schedule
 * @param intents  - The intents for the schedule
 *
 * @returns the highest level plan that is available for the `schedule`
 */
export function getActivePlanType(
  schedule: ISchedule,
  intents: IIntent[]
): ScheduleType | undefined {
  if (!schedule || !intents || intents.length === 0) {
    return undefined;
  }

  if (schedule.type === 'commit') {
    return 'commit';
  }

  const hasAllocatedIntents = intents.find(intent => intent.duration && intent.duration > 0);

  if (schedule.type === 'allocate') {
    return hasAllocatedIntents  ? 'allocate' : 'commit';
  }

  const hasScheduledIntents = intents.find(intent => intent.start != null);

  return hasScheduledIntents ? 'schedule' : (hasAllocatedIntents ? 'allocate' : 'commit');
}
