/**
 * Returns the topmost window that contains the current window.
 *
 * @returns The Window object for the current tab
 */
export function getRootWindow(): Window {
  let rootWindow: Window = window;

  while (rootWindow.parent !== rootWindow) {
    rootWindow = rootWindow.parent;
  }

  return rootWindow;
}
