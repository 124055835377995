import { toNativeDate } from '@lifetools/shared-utils-time';

const inactiveAppointmentsOnDate = date => [{
  // TODO: Handle appointments that span days [twl 12.Oct.18]
  type: 'appointment',
  'status.state': 'new',
  start: {
    afterOrOn: toNativeDate(date).toISOString(),
    before: toNativeDate(date.plusDays(1)).toISOString(),
  },
}];

export default inactiveAppointmentsOnDate;
