import { IDocumentSchema } from './from-shared-database/IDocumentSchema';
import { DatabaseListener } from './DatabaseListener';

export class DataService {
  /**
   * The callback function called whenever changes to the data are received from a subscription.
   */
  private _listener?: DatabaseListener;

  /**
   * An array of triggers to apply when executing data operations.
   *
   * TODO: Figure out a way to type these without creating a circular reference with
   *       `shared-database`. [twl 12.Apr.19]
   */
  public triggers: any[] = [];

  /**
   * Maps collection names to the schemas used to convert the database data format to the app data
   * format.
   */
  public schemas: { [collection: string]: IDocumentSchema } = {};

  /**
   * Maps the subscription IDs for each active subscription to the unsubcsribe function that
   * unsubscribes from that subscription.
   */
  public subscriptions: { [subscriptionId: string]: () => void } = {};

  /**
   * Returns the callback that is called to receive data updates whenever the data for one of the
   * active subscriptions changes.
   */
  public get listener() {
    return this._listener;
  }

  /**
   * Sets or clears the callback to receive data updates whenever the data for one of the active
   * subscriptions changes.
   */
  public set listener(value: DatabaseListener | undefined) {
    // Unsubscribe from all existing subscriptions whenever listener changes
    for (const subscriptionId of Object.keys(this.subscriptions)) {
      this.subscriptions[subscriptionId]();
      delete this.subscriptions[subscriptionId];
    }

    this._listener = value;
  }
}
