/**
 * Executes an async function with the arguments and returns the results, retrying execution if an
 * error occurs and the `errorHandler` does not throw the error. To handle the error and retry,
 * `errorHandler` is passed the error, the arguments used and the retry count, and should return the
 * new arguments to use for the function for the next retry.
 *
 * TODO: For some reason the TypeScript below isn't triggering code errors properly. If the error
 *       handler returns an object, no error is created, even though the return type is an array.
 *       Figure out why that is and fix it. [twl 21.Sep.20]
 *
 * @param args         - The initial arguments to pass into the function
 * @param func         - The function to call
 * @param errorHandler - A function to call if an error occurs, passing `(error, args, retries)` and
 *                       returning a new set of arguments
 * @param maxRetries   - The maximum number of times to retry `func` (excluding the first call)
 *
 * @returns The result of the `func`
 */
export async function callAsyncWithRetry<A extends any[], R>(
  args: A,
  func: (...args: A) => Promise<R>,
  errorHandler: (error: Error, args: A, retries: number) => A,
  maxRetries: number = 1,
): Promise<R> {
  let retries = 0;

  while (retries < maxRetries + 1) {
    try {
      return await func(...args);
    } catch (e) {
      if (retries >= maxRetries) {
        console.error('Too many retries trying to execute async function. Rethrowing error');

        throw e;
      }

      args = errorHandler(e as Error, args, retries);
    }

    retries++;
  }

  throw new Error('Coding error in asyncWithRetry: reached unreachable code');
}
