import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import withContext from 'helpers/withContext';
import withMemos from 'helpers/withMemos';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

// An pass-through composer that simply returns the component untouched
const SkipEnhancement = component => component;

export function buildScene(component, options) {
  return compose(
    withTranslations,
    options && options.memos ? withMemos(options.memos) : SkipEnhancement,
    options && options.styles ? withStyles(options.styles) : SkipEnhancement,
    options && options.context ? withContext(options.context) : SkipEnhancement,
    connect(
      buildMapStateToProps(options),
      buildMapDispatchToProps(options)
    )
  )(
    addLifecycleMethods(component, options)
  );
}

function addLifecycleMethods(component, options) {
  return !options || !options.lifecycle ? component : lifecycle(options.lifecycle)(component);
}

/**
 * Returns a function that maps the store state to props for a component, equivalent to the
 * `mapStateToProps` function used for Redux.
 *
 * @param {[type]} options [description]
 *
 * @return {(state) => {}} A function that maps the store state to props for a component, or
 *                         undefined if no options were defined.
 */
function buildMapStateToProps(options) {
  if (!options || !options.state || typeof options.state === 'function') {
    return options && options.state ? options.state : undefined;
  }
  else {
    // TODO: Consider making this `paths` and supporting dot syntax for nested properties.
    //       [twl 14.Jun.18]
    const properties = Array.isArray(options.state) ? options.state : [ options.state ];

    return state => {
      const props = {};

      for (const property of Object.keys(state)) {
        if (properties.includes(property)) {
          props[property] = state[property];
        }
      }

      return props;
    };
  }
}

function buildMapDispatchToProps(options) {
  if (!options || !options.dispatch || typeof options.dispatch === 'function') {
    return options && options.dispatch ? options.dispatch : {};
  }
  else {
    return dispatch => {
      const dispatches = {};

      for (const name of Object.keys(options.dispatch)) {
        dispatches[name] = (...args) => {
          const action = options.dispatch[name](...args);

          // All dispatches defined must return an action to dispatch, otherwise they don't belong
          // in the dispatch options
          if (!action) {
            throw new Error(`The action creator '${name}' did not return an action`);
          }

          return dispatch(action);
        };
      }

      return dispatches;
    }
  }
}
