import React from 'react';
import { Trans } from 'react-i18next';

/**
 * Renders text that may be translated. This component renders whichever of the following first
 * returns a non-falsy value:
 *
 * - the `override` prop
 * - the translated text retrieved from the translation key (e.g. `i18nKey`)
 * - the `defaults` prop
 * - the `i18nKey` itself, unless `ignoreMissing` is set, in which case nothing is rendered
 *
 * If no `i18nKey` is provided, one will be constructed by appending the `name` to the `path`. If no
 * `path` or `name` are provided, then the `override` value will be rendered, since there is no way
 * to look up the translation.
 *
 * If `parent` is set to a string or a React Component, this component will be used to wrap the
 * text, with the text being passed as a child to the component. All remaining props are passed
 * into the parent component.
 *
 * If `parent` is not set, then the text will be rendered without a wrapper component.
 *
 * @param {string}           options.i18nKey       The full path to the translation key
 * @param {string}           options.path          The parent path to the translation key
 * @param {string}           options.key           The child path to the translation key
 * @param {string}           options.override      The value to use instead of the translated text
 * @param {string|Component} options.parent        The component used to render the text within
 * @param {string}           options.defaults      The value to use when the translated key could
 *                                                 not be found
 * @param {boolean}          options.ignoreMissing Whether to render the `i18nKey` when it could not
 *                                                 be found
 * @param {...any}           options.otherProps    Other props to pass into the `parent` component
 */
const T = ({ i18nKey, path, name, override, parent, defaults, ignoreMissing, ...otherProps }) => {
  if (!i18nKey) {
    i18nKey = path && name ? `${path}.${name}` : undefined;
  }

  // if (!i18nKey && !override) {
  //   throw new Error(`The 'T' translation component must have an 'override' prop, an 'i18nKey'` +
  //                   ` or the 'path' and 'name' props defined`);
  // }

  return override || !i18nKey ?
    (parent ? React.createElement(parent, otherProps, override) : override) :
    <Trans className={otherProps.className}
           i18nKey={i18nKey}
           parent={parent}
           defaults={defaults ? defaults : (ignoreMissing ? undefined : i18nKey)} />;
};

export default T;
