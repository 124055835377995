import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import withContext from 'helpers/withContext';
import withStyles from 'helpers/withStyles';
import AddActionButton from './components/AddActionButton';
import RemoveActionButton from './components/RemoveActionButton';
import StatusActionButton from './components/StatusActionButton';

const styles = theme => ({
  button: {
    color: theme.palette.primary.main,
    padding: theme.spacing.unit,
  },
  buttonReadOnly: {
    padding: theme.spacing.unit,
  },
});

const ActionButton = ({
  className,
  value,
  intent,
  actionMode,
  onAdd,
  onRemove,
  onStatusChange,
  onStatusMenu,
  isAccountReadOnly,
  classes,
}) => {
  if (isAccountReadOnly && actionMode.indexOf('status') === 0) {
    actionMode = 'statusReadOnly';
  }

  switch (actionMode) {
    case 'add':
      return isAccountReadOnly ? null : (
        <AddActionButton
          className={classnames(classes.button, className)}
          value={value}
          intent={intent}
          onAdd={onAdd}
          onStatusMenu={onStatusMenu}
        />
      );

    case 'remove':
      return isAccountReadOnly ? null : (
        <RemoveActionButton
          className={classnames(classes.button, className)}
          value={value}
          intent={intent}
          onRemove={onRemove}
          onStatusMenu={onStatusMenu}
        />
      );

    case 'statusChange':
      return (
        <StatusActionButton
          className={classnames(classes.button, className)}
          value={value}
          intent={intent}
          onStatusChange={onStatusChange}
          onStatusMenu={onStatusMenu}
        />
      );

    // TODO: Once we move to the status menu being the default when clicking a button, remove this
    //       entirely. [twl 24.Mar.20]
    case 'statusMenu':
      return (
        <StatusActionButton
          className={classnames(classes.button, className)}
          value={value}
          intent={intent}
          onStatusMenu={onStatusMenu}
        />
      );

    case 'statusReadOnly':
      return (
        <StatusActionButton
          className={classnames(classes.buttonReadOnly, className)}
          value={value}
          intent={intent}
        />
      );

    default:
      throw new Error(`Unknown action mode: ${actionMode}`);
  }
}

export default compose(
  withContext('isAccountReadOnly'),
  withStyles(styles)
)(ActionButton);
