import { callFunction } from 'services/server/data';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from 'services/store/settings/definitions';

const revokeProfile = makeAsyncActionCreator({
  type: types.SET_USER_SETTINGS,
  creator: async (profile) => ({
    settings: await callFunction('calendars_revokeProfile', { id: profile.id }),
  }),
  status: {
    path: collection,
    type: 'write',
    parameters: (profileId) => [ profileId ],
  }
});

export default revokeProfile;
