import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputObject from 'components/library/InputObject';
// import Indicators from 'components/app/indicators/Indicators';
// import OptionIndicator from 'components/app/indicators/OptionIndicator';
import { parseTagsInTitle } from 'utils/todos/parseTagsInTitle';
import './TodoEditor.css';

// TODO: Reactivate these after we test more and figure out how to use them [twl 12.Oct.18]
const helpers = {
  // type: {
  //   trigger: '+',
  //   helper: InputObject.SelectInputHelper,
  //   option: OptionIndicator,
  //   options: [ 'appointment', 'activity', 'task' ],   // TODO: Move this to common. [twl 24.Aug.18]
  // },
  // importance: {
  //   trigger: '*',
  //   helper: InputObject.StrengthInputHelper,
  // },
  // urgency: {
  //   trigger: '!',
  //   helper: InputObject.StrengthInputHelper,
  // },
};

class TodoEditor extends Component {
  static propTypes = {
    /** The todo to edit */
    value: PropTypes.object,

    /** The text to display as the placeholder on the text editor */
    placeholder: PropTypes.string,

    /** Whether the editor should grab focus when rendered or not */
    autoFocus: PropTypes.bool,

    /** Called when the user takes an action to commit the current changes to the `value` */
    onSave: PropTypes.func,

    /** Called when the user cancels all changes to the `value` */
    onCancel: PropTypes.func,

    /** Called when the value of a property changes while editing */
    onPropertyChange: PropTypes.func,
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
  };

  state = {
    changes: {}
  };

  editedValue = (prop) => ({ ...prop, ...this.state.changes });

  handleSave = (text) => {
    const changes = parseTagsInTitle(this.state.changes);

    if (changes.title && changes.title !== '') {
      this.props.onSave(this.props.value, changes);
      this.setState({ changes: {} });
    }
  };

  handleCancel = () => {
    this.setState({ changes: {} });
    this.props.onCancel(this.props.value);
  };

  handlePropertySave = (property, value) => {
    const { value: todo, onPropertyChange } = this.props;
    const { changes } = this.state;

    changes[property] = value;

    onPropertyChange && onPropertyChange(property, value, todo);

    this.setState({ changes });
  };

  render() {
    const { className, placeholder, autoFocus } = this.props;
    const value = this.editedValue(this.props.value);

    return (
      <div className={classNames('TodoEditor', className)} data-role="editor">
        <InputObject value={value}
                     textProperty="title"
                     placeholder={placeholder}
                     helpers={helpers}
                     onPropertySave={this.handlePropertySave}
                     onSave={this.handleSave}
                     onCancel={this.handleCancel}
                     autoFocus={autoFocus} />

        {/* <Indicators value={value} /> */}
      </div>
    );
  }
}

export default TodoEditor;
