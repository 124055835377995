import get from 'lodash/get';
import React from 'react';
import { LocalDate } from 'js-joda';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { toLocalDate } from '@lifetools/shared-utils-time';
import getDisplayName from 'helpers/utils/getDisplayName';
import { buildScene } from 'scenes/utils';
import selectIntentsBySchedule from 'services/store/intents/selectors/selectIntentsBySchedule';
import querySchedulesByDate from 'services/store/schedules/actions/querySchedulesByDate';
import selectScheduleByDate from 'services/store/schedules/selectors/selectScheduleByDate';
import selectScheduleStatus from 'services/store/schedules/selectors/selectScheduleStatus';
import queryTodosBySchedule from 'services/store/todos/actions/queryTodosBySchedule';
import selectTodosBySchedule from 'services/store/todos/selectors/selectTodosBySchedule';
import selectTodoStatus from 'services/store/todos/selectors/selectTodoStatus';
import { sortByCreatedAt } from 'utils/sort';

export function withSchedule(WrappedComponent, datePath, includeArchived = false) {
  function loadData() {
    const { date, schedule, doQuerySchedulesByDate, doQueryTodosBySchedule } = this.props;

    doQuerySchedulesByDate(date);

    if (schedule) {
      doQueryTodosBySchedule(schedule);
    }
  };

  const WithSchedule = buildScene(props => (
    <WrappedComponent {...props} />
  ), {
    memos: {
      date: dateString => toLocalDate(dateString),
      todos: (todos, schedule, includeArchived) => (
        selectTodosBySchedule(todos, schedule, includeArchived).sort(sortByCreatedAt.ASC)
      ),
      intents: (todos, schedule, includeArchived) => (
        selectIntentsBySchedule(todos, schedule, includeArchived).sort(sortByCreatedAt.ASC)
      ),
    },
    state: (state, { memos, ...props }) => {
      const dateValue = get(props, datePath);
      const date = dateValue instanceof LocalDate ? dateValue : memos.date(dateValue);
      const schedule = selectScheduleByDate(state, date);

      return {
        date,
        schedule,
        todos: memos.todos(state?.todos?.data, schedule, includeArchived).sort(sortByCreatedAt.ASC),
        intents: memos.intents(state?.todos?.data, schedule, includeArchived),
        scheduleStatus: selectScheduleStatus(state, 'querySchedulesByDate', date),
        todoStatus: selectTodoStatus(state, 'queryTodosBySchedule', schedule?.id),
      };
    },
    dispatch: {
      doQuerySchedulesByDate: querySchedulesByDate,
      doQueryTodosBySchedule: queryTodosBySchedule,
    },
    lifecycle: {
      componentDidMount: loadData,
      componentDidUpdate: loadData,
    }
  });

  hoistNonReactStatics(WithSchedule, WrappedComponent);

  WithSchedule.displayName = `WithSchedule(${getDisplayName(WrappedComponent)})`;

  return WithSchedule;
}
