import get from 'lodash/get';
import React from 'react';
import ViewLayout from 'components/layouts/ViewLayout';
import { buildScene } from 'scenes/utils';
import hasIntent from 'services/store/intents/utils/hasIntent';
import updateSchedule from 'services/store/schedules/actions/updateSchedule';
import loadSettings from 'services/store/settings/actions/loadSettings';
import updateSettings from 'services/store/settings/actions/updateSettings';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { isPlanEditable } from 'utils/app/plans/isPlanEditable';
import { getActivePlanType } from 'utils/schedules/getActivePlanType';
import HelpTipWidget from 'widgets/common/HelpTipWidget';
import IntentListWidget from 'widgets/intents/IntentListWidget';
import UnallocatedIntentsWidget from 'widgets/intents/UnallocatedIntentsWidget';
import ScheduleNotesWidget from 'widgets/schedules/ScheduleNotesWidget';
import TodoListWidget from 'widgets/todos/TodoList';
import AllocationListWidget from './components/AllocationListWidget';

const i18nPath = 'scenes.app.AllocationList.components.AllocationListView';
const viewCompactSettingsPath = 'defaults.today.allocations.compact';
const showProjectionsPath = 'defaults.today.allocations.projections';

async function loadData() {
  this.props.doLoadSettings();
}

const AllocationListView = buildScene(({
  // view configuration
  planBoxTitle,
  layout = 'split',
  actionMode = 'statusMenu',
  isPlanEditable = true,
  showFutureTasks = true,

  // data
  date,
  nextStartTime,
  schedule,
  intents,

  // computed
  viewCompact,
  showProjections,
  isPlanActive,
  activePlanType,

  // context
  isAccountReadOnly,

  // actions
  doUpdateSettings,
  doUpdateSchedule,

  // injections
  classes,
  t,
}) => (
  <ViewLayout layout={layout}>
    <AllocationListWidget
      viewId="allocations"
      viewColumn={1}
      boxTitle={planBoxTitle}
      i18nPath={i18nPath + '.components.AllocatedIntents'}
      actionMode={actionMode}
      header={
        <HelpTipWidget
          helpPath="today.allocate.plan"
          tips={['intro[newUser]','status[newUser]','extend[newUser]']}
        />
      }
      isPlanEditable={isPlanEditable}
      date={date}
      schedule={schedule}
      intents={intents}
      nextStartTime={nextStartTime}
      isPlanActive={isPlanActive}
      activePlanType={activePlanType}
      expanded={!viewCompact}
      showProjections={showProjections}
      isAccountReadOnly={isAccountReadOnly}
      onToggleViewCompact={() => doUpdateSettings({ [viewCompactSettingsPath]: !viewCompact })}
      onToggleShowProjections={() => doUpdateSettings({ [showProjectionsPath]: !showProjections })}
      onUpdateSchedule={doUpdateSchedule}
    />

    {isPlanActive
      ? <UnallocatedIntentsWidget
          viewId="unscheduled"
          viewI18nPath={i18nPath + '.views.UnscheduledIntents'}
          actionMode={actionMode}
          header={
            <HelpTipWidget
              helpPath="today.shared.sideIntents"
              tips={['intro[newUser]']}
            />
          }
          createEnabled={actionMode !== 'statusReadOnly'}
          schedule={schedule}
          intents={intents}
          date={date}
          groupBy="todo.tags"
        />
      : <IntentListWidget
          viewId="commitments"
          i18nPath={i18nPath + '.views.CommitmentList'}
          actionMode={actionMode}
          variant="summary"
          createEnabled={actionMode !== 'statusReadOnly'}
          schedule={schedule}
          intents={intents}
          date={date}
          sortBy={[{ property: 'todo.tags[0]', sortNulls: 'bottom' }, 'todo.title']}
        />
    }

    {showFutureTasks &&
      <TodoListWidget
        viewId="future"
        i18nPath={i18nPath + '.components.FutureTasks'}
        actionMode={actionMode}
        header={
          <HelpTipWidget
            helpPath="today.shared.future"
            tips={['intro[newUser]']}
          />
        }
        date={date}
        query={{
          type: 'task',
          'status.state': 'new',
          createdAt: {
            greaterThan: new Date(date),
          },
        }}
        filter={todo => !hasIntent(todo)}
        groupBy="tags"
        createEnabled={actionMode !== 'statusReadOnly'}
      />
    }

    {(actionMode !== 'statusReadOnly' || schedule.notes) &&
      <ScheduleNotesWidget schedule={schedule} readOnly={actionMode === 'statusReadOnly'} />
    }
  </ViewLayout>
), {
  context: 'isAccountReadOnly',
  state: (state, { actionMode, date, schedule, intents }) => {
    const userSettings = selectUserSettings(state);
    const activePlanType = getActivePlanType(schedule, intents);

    return {
      nextStartTime: get(userSettings, 'defaults.scheduleTimes.startTime', schedule.startTime),
      viewCompact: get(userSettings, viewCompactSettingsPath, false),
      showProjections: get(userSettings, showProjectionsPath, true),
      isPlanEditable: isPlanEditable(date, state),
      isPlanActive: ['allocate', 'schedule'].includes(activePlanType),
      activePlanType,
    };
  },
  dispatch: {
    doLoadSettings: loadSettings,
    doUpdateSettings: updateSettings,
    doUpdateSchedule: updateSchedule,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

export default AllocationListView;
