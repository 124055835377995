import React from 'react';
import MuiIconButton from '@material-ui/core/IconButton';
import Tooltip from 'components/library/Tooltip';

// TODO: Figure out how to merge this with the `Button` class. This is here because we needed to
//       use it for the `Page` component, which is using the Material UI layout. Once we have
//       converted everything over to Material UI, this should probably disappear. [twl 12.Nov.18]

const IconButton = ({ tooltip, tooltipVariant, ...props }) => {
  const button = <MuiIconButton {...props} />;

  return tooltip ? <Tooltip variant={tooltipVariant} title={tooltip}>{button}</Tooltip> : button;
};

export default IconButton;
