import { LocalDate } from 'js-joda';
import { toLocalDate } from '../convert/toLocalDate';
import { DateValue } from '../types/DateValue';

export const isBeforeToday = (date: DateValue) => {
  if (!date) {
    throw new Error('The `date` parameter cannot be null or undefined');
  }

  const localDate = toLocalDate(date);

  if (!localDate) {
    throw new Error('The `date` parameter could not be converted to a local date');
  }

  return localDate.isBefore(LocalDate.now());
}
