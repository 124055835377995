import React, { useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import TodoEditDialog from 'components/app/todos/TodoEditDialog';
import TodoQuickViewHistory from 'components/app/todos/TodoQuickViewHistory';
import ActionBar from 'components/library/ActionBar';
import QuickView from 'components/library/QuickView';
import withContext from 'helpers/withContext';
import withTranslations from 'helpers/withTranslations';
import TodoQuickViewSummary from './components/TodoQuickViewSummary';

const i18nPath = 'data.todos.fields';

const TodoQuickView = ({ value, onUpdate, onDelete, onStatusChange, isAccountReadOnly, t }) => {
  const [ view, setView ] = useState('summary');
  const ViewComponent = view === 'summary' ? TodoQuickViewSummary : TodoQuickViewHistory;
  const actionView = view === 'summary' ? 'history' : 'summary';
  const actions = [];

  if (isAccountReadOnly) {
    onUpdate = undefined;
    onDelete = undefined;
    onStatusChange = undefined;
  }

  if (value.type !== 'appointment') {
    actions.push(
      <ActionBar.Button
        i18nPath={i18nPath}
        action={actionView}
        onClick={() => setView(actionView)}
      />
    );
  }

  if (onUpdate) {
    actions.push(
      <ActionBar.Button
        i18nPath={i18nPath}
        action="edit"
        pane={
          <TodoEditDialog value={value} onSave={onUpdate} />
        }
      />
    );
  }

  if (onDelete) {
    actions.push(
      <ActionBar.Button
        i18nPath={i18nPath}
        action="delete"
        confirmAction
        onClick={() => onDelete(value)}
      />
    );
  }

  return (
    <QuickView actions={actions}>
      <ViewComponent value={value} onUpdate={onUpdate} onStatusChange={onStatusChange} />
    </QuickView>
  );
}

TodoQuickView.propTypes = {
  /** The todo object to render */
  value: PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
    status: PropTypes.object,
    start: PropTypes.string,
    updatedAt: PropTypes.object,                // TODO: Make custom type Timestamp [23.Nov.18]
    createdAt: PropTypes.object.isRequired,     // TODO: Make custom type Timestamp [23.Nov.18]
  }),

  /** Called to update the todo */
  onUpdate: PropTypes.func,

  /** Called to delete the todo */
  onDelete: PropTypes.func,

  /** A function used to provide translations of text. Provided by `withTranslations` */
  t: PropTypes.func.isRequired,
};

export default compose(
  withContext('isAccountReadOnly'),
  withTranslations,
)(TodoQuickView);
