import { ITriggerRule, TriggerContext } from '@lifetools/shared-database';
import { services } from '@lifetools/shared-services';

/**
 * Updates the `at` and `last` history properties of the `status` object for a todo.
 */
const todoStatusHistory: ITriggerRule = {
  stage: 'before',
  operation: 'write',
  collection: 'todos',
  func: async ({ before, changes }: TriggerContext) => {
    if (!changes.values || !changes.has('status.state')) {
      return;
    }

    const oldState = before.get('status.state');

    changes.set('status.at', services.database.fieldValues.serverTimestamp());

    if (oldState && oldState !== changes.get('status.state')) {
      changes.set('status.last', oldState);
    }
  },
};

export default todoStatusHistory;
