import pick from 'lodash/pick';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import Form from 'components/library/Form';
import Link from 'components/library/Link';
import Typography from 'components/library/Typography';
import withPage from 'helpers/withPage';
import setRedirectResult from 'services/store/auth/actions/setRedirectResult';
import { buildScene } from 'scenes/utils';
import { sendPasswordResetEmail } from 'services/server/auth';
import compileSchema from 'utils/data/compileSchema';

const i18nPath = 'scenes.users.ResetPassword';
const fieldsI18nPath = i18nPath + '.fields';

const schema = {
  email: {
    type: 'string',
    validation: [
      'trim',                 // Remove spaces before validating--does not modify actual data though
      'required',
      'email',
    ],
  },
};

const ResetPassword = buildScene(({
  schema,
  location,
  doSetRedirectResult,
  history,
  classes,
  t,
}) => {
  const [sentToEmail, setSentToEmail] = useState(undefined);

  return sentToEmail
    ? (
      <div className={classes.passwordSent}>
        <Typography variant="body1" paragraph>
          {t(i18nPath, 'password-sent', { email: sentToEmail })}
        </Typography>

        <Typography variant="body1" className={classes.login}>
          <Link to="/login">{t(i18nPath, 'login-link')}</Link>
        </Typography>
      </div>
    ) : (
      <div>
        <Form.SectionTitle i18nPath={i18nPath} i18nKey="section-title" />

        <Form
          i18nPath={i18nPath}
          className={classes.root}
          schema={schema}
          values={{
            email: location.state?.email,
          }}
          onSubmit={async ({ email }) => {
            try {
              await sendPasswordResetEmail(email);
              setSentToEmail(email);
            } catch (e) {
              if (e.code === 'auth/account-exists-with-different-credential') {
                await doSetRedirectResult({
                  action: 'login',
                  ...pick(e, 'code', 'message', 'email'),
                  providerId: 'password',
                  activeProvider: e.providerId,
                });

                history.push('/');

                return;
              }

              throw e;
            }
          }}
        >
          <Form.TextField
            className={classes.field}
            i18nPath={fieldsI18nPath}
            name="email"
            autoComplete="username"
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth
            autoFocus
          />

          <Form.SubmitButton className={classes.button} i18nPath={i18nPath} i18nKey="reset-button" />

          <Typography variant="body1" className={classes.login}>
            <Link to="/login">{t(i18nPath, 'login-link')}</Link>
          </Typography>
        </Form>
      </div>
    );
}, {
  memos: {
    // NOTE: Must be compiled here & not in `Form` since memo only stores last value [twl 7.Dec.18]
    compileSchema: (t, i18nPath) => compileSchema(schema, t, i18nPath),
  },
  state: (state, { t, memos }) => ({
    schema: memos.compileSchema(t, i18nPath),
  }),
  dispatch: {
    doSetRedirectResult: setRedirectResult,
  },
  styles: theme => ({
    root: {
      margin: '0 auto',
      textAlign: 'center',

      '& > *:not(:last-child)': {
        marginBottom: theme.spacing.unit * 2,
      },
    },
    button: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 4}px`,
      borderRadius: theme.spacing.unit,
    },
    label: {
      fontSize: '1.4rem',
    },
    field: {
      paddingTop: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
    },
    login: {
      textAlign: 'center',
      marginTop: theme.spacing.unit * 4,
    },
    passwordSent: {
      maxWidth: 450,
      marginTop: theme.spacing.unit * 4,
      textAlign: 'center',
    },
  })
});

export default withPage({
  i18nPath,
  theme: 'darkForm',
})(withRouter(ResetPassword));
