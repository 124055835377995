import get from 'lodash/get';
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import StatusSelector from 'components/app/todos/StatusSelector';
import TodoType from 'components/app/todos/TodoType';
import Markdown from 'components/library/Markdown';
import ViewField from 'components/library/ViewField';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import todoText from 'utils/todos/todoText';

const fieldsI18nPath = 'data.todos.fields';
const formatsI18nPath = 'data.todos.formats';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 1.5,

    '& > *:not(:first-child)': {
      marginTop: theme.spacing.unit * 2,
    }
  },
  splitRow: {
    display: 'flex',

    '& > *': {
      flex: '1 1 100%',
    },
  },
  repeatType: {
    marginTop: 6,      // 4px from `TodoType` top margin + 2px for height difference with `TodoType`
  },
});

const TodoQuickViewSummary = ({ value, onUpdate, onStatusChange, classes, t }) => {
  const repeatInfo = value.type === 'activity' && todoText(value, 'repeatInfo', t);
  const hide = get(value, 'status.hide');
  const doDescChange = value.type !== 'activity' && onUpdate
    ? desc => onUpdate(value, { desc })
    : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.splitRow}>
        {value.type !== 'activity' &&
          <ViewField i18nPath={fieldsI18nPath} name="status" show="always">
            <StatusSelector todo={value} actionMode="statusMenu" onStatusChange={onStatusChange} />
          </ViewField>
        }

        {value.type === 'activity' &&
          <ViewField i18nPath={fieldsI18nPath} name="repeatType" show="always">
            <div className={classes.repeatType}>
              {value.defaultDuration ?
                t(formatsI18nPath, 'repeatInfo-with-duration', {
                  duration: todoText(value, 'defaultDuration:expanded', t),
                  repeatInfo,
                })
                :
                repeatInfo
              }
            </div>
          </ViewField>
        }

        <ViewField i18nPath={fieldsI18nPath} name="type" show="always">
          <TodoType value={value.type} />
        </ViewField>
      </div>

      {value.type === 'task' && hide &&
        <ViewField i18nPath={fieldsI18nPath} name="hide" value={todoText(value, 'hide', t)} />
      }

      {value.type === 'appointment' &&
        <Fragment>
          <ViewField
            i18nPath={fieldsI18nPath}
            name="dateAndTimeRange"
            value={todoText(value, 'startDateAndTimeRange', t)}
          />
          <ViewField i18nPath={fieldsI18nPath} object={value} name="location" />
        </Fragment>
      }

      {value.desc &&
        <ViewField i18nPath={fieldsI18nPath} name="desc" show="always">
          <Markdown value={value.desc} onChange={doDescChange} />
        </ViewField>
      }
    </div>
  );
};

TodoQuickViewSummary.propTypes = {
  /** The todo object to render */
  value: PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
    status: PropTypes.object,
    start: PropTypes.string,
    updatedAt: PropTypes.object,                // TODO: Make custom type Timestamp [23.Nov.18]
    createdAt: PropTypes.object.isRequired,     // TODO: Make custom type Timestamp [23.Nov.18]
  }),

  /** Called to update the notes of the todo */
  onUpdate: PropTypes.func,

  /** Called to update the status of the todo */
  onStatusChange: PropTypes.func,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /** A function used to provide translations of text. Provided by `withTranslations` */
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withTranslations,
)(TodoQuickViewSummary);
