import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { DocumentUpdates, IDocumentData } from '@lifetools/shared-database';

/**
 * Adds a value to the `updates` at the `path`, ensuring that it is added at the appropriate level
 * in the object tree with the appropriate property name.
 *
 * @param updates - A document update whose keys can be paths to keys in an object tree
 * @param path    - The path at which to add a value
 * @param value   - The value to add
 *
 * @returns An new `updates` with the `value` added at `path`
 */
export function addUpdateProperty(
  updates: DocumentUpdates<IDocumentData>,
  path: string,
  value: any,
): DocumentUpdates<IDocumentData> {
  const newUpdates = cloneDeep(updates);
  const parentElements = path.split('.');
  const childElements = [];

  do {
    const parentPath = parentElements.join('.');

    if (newUpdates[parentPath]) {
      if (childElements.length > 0) {
        set(newUpdates[parentPath], childElements.join('.'), value);
      } else {
        newUpdates[parentPath] = value;
      }

      return newUpdates;
    }

    childElements.push(parentElements.pop());
  } while (parentElements.length > 0);

  newUpdates[path] = value;

  return newUpdates;
}
