import React, { Component, Fragment } from 'react';
import { LocalDate } from 'js-joda';
import {
  LocalTime, createMomentDateTime, toLocalDate, toLocalTime, toMomentDate
} from '@lifetools/shared-utils-time';
import Form from 'components/library/Form';
import isValidTimeValidation from 'utils/data/isValidTimeValidation';
import toLocalTimeNoThrow from 'utils/time/toLocalTimeNoThrow';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  scheduleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notice: {
    background: theme.palette.grey['300'],
    textAlign: 'center',
    padding: theme.spacing.unit * 2,
  },
});

class AppointmentEditForm extends Component {
  /**
   * The validation schema for this form.
   */
  static schema = {
    title: {
      type: 'string',
      validation: [
        'required',
      ],
    },
    date: {
      type: 'string',              // From the form's perspective, this is a string
      validation: [
        'required',
      ],
    },
    start: {
      type: 'string',              // From the form's perspective, this is a string
      validation: [
        'required',
        isValidTimeValidation, 
      ],
    },
    end: {
      type: 'string',              // From the form's perspective, this is a string
      validation: [
        isValidTimeValidation, 
        {
          type: 'test',
          name: 'is-after-start-time',
          test: function(value) {
            const startTime = toLocalTimeNoThrow(this.parent.start);
            const endTime = toLocalTimeNoThrow(value);

            return !endTime || !startTime || startTime.isBefore(endTime) ||
                   endTime.toSecondOfDay() === 0;
          }
        }
      ],
    },
  };

  // HACK: Currently there is no way for the form to return only the field that changed. Until we
  //       add this, the `editFields` static prop is used by the `TodoTableEditor` to only save
  //       these fields as the "changes". [twl 13.Oct.18]
  //
  // TODO: Add changes functionality to the Formik form, then remove this hack. [twl 13.Oct.18]
  static editFields = [ 'title', 'start', 'end', 'location', 'desc', 'sync' ];

  /**
   * The path to the translation keys for this form.
   */
  static i18nPath = 'components.app.todos.TodoEditDialog.components.AppointmentEditForm';

  /**
   * The path to the translation keys for the fields on this form.
   */
  static fieldsI18nPath = 'data.todos.fields';

  /**
   * Returns a new data object to use as the default `value` when using this form to add a new item.
   *
   * @returns {object} a new data object
   */
  static getDefaultValue = (options = {}) => {
    const startTime = new LocalTime.now().roundUp(1, 'hours');
    const endTime = startTime.add(1, 'hours');

    let date = options.date || LocalDate.now();

    if (startTime.isMidnight()) {
      date = date.plusDays(1);
    }

    return {
      type: 'appointment',
      date,
      start: createMomentDateTime(date, startTime),
      end: createMomentDateTime(endTime.isMidnight() ? date.plusDays(1) : date, endTime),
    };
  }

  /**
   * Parses the `value` into a flat object containing the values for the form.
   *
   * @param {object} value a data object
   *
   * @returns {object} a form object
   */
  static toFormValues = value => ({
    ...value,
    date: value.start ? toLocalDate(toMomentDate(value.start)).toString() : undefined,
    start: value.start ? toLocalTime(toMomentDate(value.start)).toString() : undefined,
    end: value.end ? toLocalTime(toMomentDate(value.end)).toString() : undefined,
  });

  /**
   * Parses the values returned from the form into a data object.
   *
   * @param {object} value a form object
   *
   * @returns {object} a data object
   */
  static fromFormValues = ({ date, start, end, repeatType, ...values }) => {
    const endTime = end ? new LocalTime(end) : undefined;
    const endDate = endTime && endTime.isMidnight() ? toLocalDate(date).plusDays(1).toString() :
                                                      date;
    return {
      ...values,
      start: createMomentDateTime(date, start,  'YYYY-MM-DD', 'HH:mm').toISOString(),
      end: end ? createMomentDateTime(endDate, end,  'YYYY-MM-DD', 'HH:mm').toISOString()
               : undefined,
      ...values.imported && { sync: false },
    };
  };

  /**
   * Renders the fields for this form. The `value` will be provided by the form this is inserted
   * into.
   */
  render() {
    const { todo, classes } = this.props;
    const fieldsI18nPath = AppointmentEditForm.fieldsI18nPath;

    return (
      <Fragment>
        {todo && todo.imported &&
          <div className={classes.notice}>
            Edits made here will not be synced back to your calendar service. This is a future
            feature.
          </div>
        }

        <Form.TextField i18nPath={fieldsI18nPath} name="title" fullWidth autoFocus />

        <div className={classes.scheduleRow}>
          <Form.DateField i18nPath={fieldsI18nPath} name="date" />
          <Form.TimeField i18nPath={fieldsI18nPath} name="start" />
          <Form.TimeField i18nPath={fieldsI18nPath} name="end" />
        </div>

        <Form.TextField i18nPath={fieldsI18nPath} name="location" fullWidth />
        <Form.TextField
          i18nPath={fieldsI18nPath}
          name="desc"
          multiline
          fullWidth
          rowsMax="10"
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(AppointmentEditForm);
