import values from 'lodash/values';
import { LocalDate } from 'js-joda';
import { IIntentStatusState, ITodo } from '@lifetools/shared-app-types';
import { sort } from '../sort';

/**
 * Returns the number of days where consecutive commmitments had one of the `statuses`, starting
 * from `date` and going back in time.
 *
 * @param todo     - A todo containing intents
 * @param statuses - The statuses to check for
 * @param date     - The date to search backwards from
 *
 * @returns The number of days in the streak where the intents all have one of the `statuses`
 */
function daysInStatusStreak(
  todo: ITodo,
  statuses: IIntentStatusState[],
  date: LocalDate | undefined = LocalDate.now(),
): number {
  const intents = todo.intents
    ? sort(values(todo.intents), 'date desc').filter(intent => !date.isBefore(intent.date))
    : [];

  let count = 0;

  for (const intent of intents) {
    if (!statuses.includes(intent.status?.state)) {
      break;
    }

    count++;
  }

  return count;
}

export default daysInStatusStreak;