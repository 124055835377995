import React from 'react';
import { compose } from 'recompose';
import DailyPlanInfo from 'components/app/schedules/DailyPlanInfo';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'scenes.app.Today.components.NoTodayView';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 4,
  },
  header: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 6}px 0`,
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing.unit * 6}px ${theme.spacing.unit * 4}px`,
    }
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',

    '& > *': {
      flex: `1 1 calc(33% - ${theme.spacing.unit * 4}px)`,
      marginBottom: theme.spacing.unit * 4,
    },

    '& > *:not(:last-child)': {
      marginRight: theme.spacing.unit * 4,
    },

    [theme.breakpoints.down('sm')]: {
      '& > *': {
        flex: `1 1 100%`,
        marginBottom: theme.spacing.unit * 4,
      },

      '& > *:not(:last-child)': {
        marginRight: 0,
      },
    },
  },
});

const NoTodayView = ({ date, schedule, classes, t }) => (
  <div className={classes.root}>
    <Typography className={classes.header} variant="h2">
      {t(i18nPath, 'title')}
    </Typography>

    <div className={classes.options}>
      <DailyPlanInfo date={date} workflow="commit" existingSchedule={schedule} />
      <DailyPlanInfo date={date} workflow="allocate" existingSchedule={schedule} />
      <DailyPlanInfo date={date} workflow="schedule" existingSchedule={schedule} />
    </div>
  </div>
);

export default compose(
  withStyles(styles),
  withTranslations,
)(NoTodayView);
