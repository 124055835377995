import { isDevEnvironment } from '../app/isDevEnvironment';

const authorizedCallbacks = [
  '/appointments/callback',
  '/account/callback',
];

/**
 * Checks to see if the `callbackPath` is a valid callback path. This throws an error during
 * development if this component is used in a route that hasn't been authorized, so that we can go
 * and add the route. Otherwise, no error is presented to the use until they attempt to connect and
 * the return URI is rejected.
 *
 * @param {string} callbackPath The callback path to check
 *
 * @throws if the `callbackPath` has not been authorized & we're running in a dev environment
 */
export function validateCalendarCallbackPath(callbackPath: string) {
  if (isDevEnvironment() && !authorizedCallbacks.includes(callbackPath)) {
    throw new Error(
      `The callback path '${callbackPath}' is not authorized. To use this path, add it to the` +
      ` 'authorizedCallbacks' in 'utils/integrations/validateCalendarCallbackPath', add it to the` +
      ` Authorized Redirect URIs for the 'scheduled-to-dos' OAuth 2.0 Client ID at` +
      ` https://console.developers.google.com/apis/credentials?project=scheduled-to-dos` +
      ` and email Cronofy support to add it as a path for the Cronofy API.`
    );
  }
}
