import { services } from '@lifetools/shared-services';
import { isIntegrationTestEnvironment } from '../../../../../utils/app/isIntegrationTestEnvironment';

export default async function clearFeatureUser() {
  if (isIntegrationTestEnvironment()) {
    return;
  }

  services.features.setAttributes({
    id: null,
    loggedIn: false,
    appVersion: process.env.REACT_APP_VERSION,
  });
}
