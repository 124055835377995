import React from 'react';
import classnames from 'classnames';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import MuiTypography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  header: {
    marginTop: theme.spacing.unit * 2,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  groupWithHeader: {
    marginLeft: theme.spacing.unit * 2,
  },
});

// `options` can be either an object in the form `{ value, label }` or a string. If an array of
// strings, the strings are treated as the values and the translations are looked up from the
// `i18nPath`. If the `label` is missing from an object, the option is looked up from the `i18nPath`
// also.

const RadioSelector = ({
  i18nPath,
  classes,
  className,
  fullWidth,
  name,
  label,
  header,
  options,
  value,
  onChange = () => {},
  t,
}) => (
  <MuiFormControl className={className} component="fieldset" fullWidth={fullWidth}>
    {label &&
      <MuiInputLabel shrink component="legend">{label}</MuiInputLabel>
    }

    {header &&
      <MuiTypography className={classes.header} variant="body2">{header}</MuiTypography>
    }

    <MuiRadioGroup
      aria-label={label}
      name={name}
      className={classnames(classes.group, header && classes.groupWithHeader)}
      value={value || options[0].value}
      onChange={(e) => onChange(e.target.value)}>

      {options.map(option => {
        const value = typeof option === 'object' ? option.value : option;
        const label = typeof option === 'object' && option.label ? option.label :
                                                                   t(i18nPath + '.options', value);

        return (
          <MuiFormControlLabel
            key={value}
            value={value}
            label={label}
            control={<MuiRadio color="primary" />}
          />
        );
      }
      )}

    </MuiRadioGroup>
  </MuiFormControl>
);

export default withStyles(styles)(withTranslations(RadioSelector));

