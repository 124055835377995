import { deleteDocument } from '@lifetools/shared-database';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from '../definitions';

export const deleteSchedule = makeAsyncActionCreator({
  type: types.DELETE_SCHEDULE,
  creator: async schedule => {
    await deleteDocument(collection, schedule.id, { forceDelete: true });

    return schedule.id;
  },
  status: {
    path: collection,
    type: 'write',
  }
});

export default deleteSchedule;
