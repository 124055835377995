const KEY_ENTER = 13;
const KEY_ESC = 27;

export function wasEnterPressed(event) {
  return event.key === 'Enter' || event.keyCode === KEY_ENTER;
}

export function wasEscapePressed(event) {
  return event.key === 'Escape' || event.keyCode === KEY_ESC;
}

export function getKeyChar(event) {
  // TODO: Evaluate the users of this function and ensure they can handle a `charCode` instead of
  //       a `key`. [twl 13.Aug.18]
  return event.key || event.charCode;
}
