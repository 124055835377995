import React, { Fragment } from 'react';
import ItemList from 'components/library/ItemList';
import SelectableItemList from 'components/library/SelectableItemList';
import SelectableSwitch from 'components/library/SelectableSwitch';
import TodoEditor from 'components/app/todos/TodoEditor';
import TodoItem from 'components/app/todos/TodoItem';
import withBox from 'helpers/withBox';
import withTranslations from 'helpers/withTranslations';
import SelectedTodoItem from './components/SelectedTodoItem';
import { testProps } from 'utils/test/testProps';

const i18nPath = 'components.app.todos.TodoList';

/**
 * Returns the value to use for the `id` attribute on the HTML element rendered by the component.
 */
const defaultGetIdAttribute = value => value.templateKey ? `Todo-${value.templateKey}` : undefined;

/**
 * Renders a list of todo items. If `selectMode` is not set to `disabled`, items in the list can be
 * selected, and optionally have a selected component rendered.
 *
 * # Props
 *
 * todos
 * : An array of the todos to render.
 *
 * sortBy
 * : A string in the form `${field} ${directon}` indicating how to sort the items in the list, or a
 *   sort function. The `direction` can be either `asc` or `desc`.
 *
 * selectMode
 * : Whether items in the list should be selectable and how to render them if they are. Options
 *   include `disabled` to turn off selection, `eventOnly` to only fire the `onSelect` event,
 *   `inline` to render the `selectedComponent` instead of the `component` for selected items and
 *   `afterList` to render the selected components twice--once inside the list as normal and again
 *   after the list has rendered (useful mainly for rendering dialogs).
 *
 * createEnabled
 * : Whether to allow the creation of new todos in this list. If set, an editor appears at the top
 *   of the list that the user can use to add new items. When saved, the `onCreate` event is called
 *   to save the value.
 *
 * getIdAttribute
 * : A function which returns the value of the `id` attribute to use on the container component of
 *   each item in the list.
 *
 * selectHandler
 * : The name of the event handler prop used by the `component` to indicate the component has been
 *   selected. Defaults to `onSelect`.
 *
 * unselectHandler
 * : The name of the event handler prop used by the `selectedComponent` to indicate the component
 *   has been unselected. Defaults to `onClose`.
 *
 * header
 * : The component to use to render above the list as a header. If this and `createEnabled` are set,
 *   the header renders above the editor.
 *
 * component
 * : The component to use to render each item in the list. Must take a `value` prop. Defaults to
 *   `TodoItem`.
 *
 * componentProps
 * : An object or a function that takes `item` and returns an object that is passed as additional
 *   props to pass to the component.
 *
 * selectedComponent
 * : The component to use to render each selected item in the list. Must take a `value` prop.
 *   Defaults to `SelectedTodoItem`.
 *
 * selectedProps
 * : An object or a function that takes `item` and returns an object that is passed as additional
 *   props to pass to the selected component.
 *
 * variant
 * : The style variant to use when rendering a todo item. Passed as a prop to the `component` and
 *   `selectedComponent`.
 *
 * actionMode
 * : The type of action to display on the todo item. Passed as a prop to the `component` and
 *   `selectedComponent`.
 *
 * date
 * : The date to use as "today" when showing/hiding items or formatting relative dates.
 *
 * onCreate
 * : A function used to create a new todo.
 *
 * onAdd
 * : A function used to add a todo to a schedule.
 *
 * onRemove
 * : A function used to remove a todo from a schedule.
 *
 * onStatusChange
 * : A function used to change the status of a todo.
 *
 * onSelect
 * : A function used to select a todo.
 *
 * onUpdate
 * : A function used to update the values of a todo after editing.
 *
 * onDelete
 * : A function used to delete a todo.
 */
const TodoList = ({
  // list props
  todos,
  sortBy = 'createdAt desc',
  selectMode = 'inline',
  createEnabled,
  getIdAttribute = defaultGetIdAttribute,
  selectHandler = 'onSelect',
  unselectHandler = 'onUnselect',

  // components used by list
  header,
  component = TodoItem,
  componentProps,
  selectedComponent = SelectedTodoItem,
  selectedComponentProps = { noBox: true },

  // passed into the components
  variant,
  actionMode,
  date,
  expanded,

  // for the list
  onCreate,

  // for components
  onAdd,
  onRemove,
  onStatusChange,
  onUpdate,
  onDelete,

  // for selection
  onSelect,

  // helpers
  t,

  ...props
}) => {
  const editor = (createEnabled &&
    <TodoEditor
      placeholder={t(i18nPath, 'add-placeholder')}
      onSave={(ignore, changes) => onCreate(changes)}
    />
  );

  // TODO: Add support here for the `afterList` version of `selectMode` [twl 18.Dec.18]
  const list = (selectMode === 'inline' ?
    <SelectableItemList
      {...testProps('TodoList')}
      {...props}
      items={todos}
      sortBy={sortBy}
      header={editor}
      component={SelectableSwitch}
      componentProps={{
        selectedComponent,
        selectedProps: {
          variant,
          actionMode,
          date,
          expanded,
          onUpdate,
          onDelete,
          onAdd,
          onRemove,
          onStatusChange,
          ...selectedComponentProps,
        },
        unselectedComponent: component,
        unselectedProps: {
          variant,
          actionMode,
          date,
          expanded,
          divider: true,
          onUpdate,
          onDelete,
          onAdd,
          onRemove,
          onStatusChange,
          ...componentProps,
        },
      }}
      getIdAttribute={getIdAttribute}
      selectHandler={selectHandler}
      unselectHandler={unselectHandler}
    />
    :
    <ItemList
      {...testProps('TodoList')}
      {...props}
      items={todos}
      sortBy={sortBy}
      header={editor}
      component={component}
      componentProps={{
        variant,
        actionMode,
        date,
        expanded,
        divider: true,
        onUpdate,
        onDelete,
        onAdd,
        onRemove,
        onStatusChange,
        onSelect: selectMode === 'eventOnly' ? onSelect : undefined,
        ...componentProps,
      }}
      getIdAttribute={getIdAttribute}
    />
  );

  return (
    <Fragment>
      {header}
      {list}
    </Fragment>
  );
}

export default withBox(withTranslations(TodoList), { i18nPath });
