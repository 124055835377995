import { getStatus } from './utils-selectors';
import { types as globalTypes } from './actions';

export const STATUS_RUNNING = 'running';
export const STATUS_SUCCESS = 'success';
export const STATUS_FAILURE = 'failure';

/**
 * Creates an action from the parameters that follows the
 * [Flux Standard Action](https://github.com/redux-utilities/flux-standard-action) convention.
 *
 * Note that this implementation extends the Flux Standard Action by adding a `status` meta property
 * for tracking the status of async actions. The `error` property is now redundant, but being kept
 * to make it compatible with other libraries that expect Flux Standard Actions.
 *
 * @param {string} type    The action type
 * @param {any}    payload The optional payload for the action
 * @param {string} status  The status of the action: `running`, `success` or `failure`. Defaults to
 *                         `success`.
 *
 * @return {object} An action object following the Flux Standard Action convention
 */
export function makeAction(type, payload, status = STATUS_SUCCESS) {
  return {
    type,
    payload,
    error: status === STATUS_FAILURE,
    meta: {
      status,
    }
  };
}

/**
 * Creates an action that run asynchronously. Asynchronous actions dispatch twice to the store:
 * once when the action is started with a status of `running` and once when the action is complete
 * with a status of `success` or `failure`.
 *
 * TODO: Delete this once everything has been migrated to `makeAsyncActionCreator` [twl 19.Jun.18]
 *
 * @param {string}   type      The action type
 * @param {Function} asyncFunc A function which returns a Promise
 *
 * @return {Promise<void>} A Promise which resolves when the action succeeds or fails
 */
export function makeAsyncAction(type, asyncFunc) {
  return (...args) => {
    return async dispatch => {
      dispatch(makeAction(type, args, STATUS_RUNNING));

      try {
        const payload = await asyncFunc(...args);

        dispatch(makeAction(type, payload, STATUS_SUCCESS));
      }
      catch (e) {
        dispatch(makeAction(type, e, STATUS_FAILURE));

        console.error(e);

        throw e;
      }
    }
  }
}

/**
 * Creates an action creator that creates and dispatches actions synchronously.
 *
 * NOTE: This is just a wrapper around `makeAsyncActionCreator` since a synchronous function can be
 *       called as if it is asynchronous and it will run synchronously. So there's no need to create
 *       another method. If we need to evolve this in the future, we can.
 *
 *       This version skips the `STATUS_RUNNING` dispatch, since no other code can execute between
 *       when the action starts and ends when it's running synchronously. [twl 13.Dec.18]
 */
export function makeSyncActionCreator({ type, when, creator, skip, status }) {
  return makeAsyncActionCreator({ type, when, creator, skip, status }, true);
}

/**
 * Creates an action creator that creates and dispatches actions asynchronously.
 *
 * The required options are `type` and `creator`. This executes the creator asynchronously and then
 * dispatches the return value from the creator as the payload of an action with the `type`.
 *
 * If the `status` option is defined, a global `SET_STATUS` action will be dispatched before and
 * after executing the action creator. Before the action creator is executed, a status action with
 * the state `STATUS_RUNNING` will be dispatched. Afterwards, a status action with the state
 * `STATUS_SUCCESS` or `STATUS_FAILURE` will be dispatched. When dispatching a status action with
 * the state `STATUS_FAILURE`, the `error` will be part of the status payload.
 *
 * The dispatched status will contain the `type` and `path` from the `status` option and a
 * `parameters` property. If the `status` option defines a `parameters` property, this will be
 * called with the arguments of the creator and the return value will be used for the `parameters`;
 * otherwise the arguments of the creator will be used. This allows you to ignore certain parameters
 * when dispatching status actions to share status between multiple action creators (e.g. updating
 * different properties of the same object via different creators).
 *
 * Use status to query and display to the user the current status of the operation.
 *
 * If the `when` option is defined, the function will be called with the current status and the
 * creator parameters `(currentStatus, ...args)`. If the return value is truthy, the action creator
 * will continue as normal, but if the return value is falsey, no dispatches will be performed.
 *
 * Use the `when` to abort the action based on the current status of the store (e.g. the action has
 * already been performed and does not need to be done again).
 *
 * If the `skip` option is defined, the function will be called with the payload from the async
 * creator. If the return value is truthy, the action returned from the creator will be dispatched
 * as normal; otherwise the dispatch will be skipped.
 *
 * Use the `skip` to abort the dispatch based on the results of the creator call. Note that use of
 * the `skip` function does not affect the status. Skipping a dispatch will still result in the
 * status being set to `STATUS_SUCCESS`.
 *
 * If no `skip` function is defined, the action is always dispatched after a successful creator
 * call.
 *
 * @param {string}   option.type    A string representing the type of action to dispatch
 * @param {Function} option.when    A function that returns a boolean indicating whether to execute
 *                                  the creator
 * @param {Function} option.creator An async function that resolves to the action payload
 * @param {Function} option.skip    A function that returns a boolean indicating whether to skip
 *                                  the dispatch of the action or not
 * @param {object}   option.status  The parameters used when sending the `SET_STATUS` dispatches; if
 *                                  not defined, no status dispatches will be sent
 *
 * @param {boolean}  skipRunningStatus Skips dispatching a `STATUS_RUNNING` when `true`
 *
 * @return {Promise<any>} A Promise which resolves to the payload returned by the creator when the
 *                        action succeeds or fails. The return value should only be used by testing
 *                        frameworks.
 */
export function makeAsyncActionCreator({ type, when, creator, skip, status }, skipRunningStatus) {
  // TODO: Convert this into flow or another type check. This is here right now to avoid typos when
  //       referencing the type from a types object. [twl 30.Aug.18]
  if (!type) {
    throw new Error(`The 'type' parameter must be specified when making a creator`);
  }

  return (...args) => {
    return async dispatch => {
      const parameters = status && status.parameters ? status.parameters(...args) : args;

      if (when) {
        const currentStatus = status && getStatus(status.path, status.type, parameters);

        if (!when(currentStatus, ...args)) {
          return;
        }
      }

      if (!skipRunningStatus) {
        maybeDispatchStatusAction(dispatch, status, parameters, STATUS_RUNNING);
      }

      try {
        /**
         * TODO: Rethink how we do async actions. We may need to do this in a thunk instead.
         *       Currently if you edit an entity and press Save, the dialog will close and then
         *       you can see the old value rendered before it updates. This is because there is no
         *       way to await on a dispatch (adding `await` below doesn't help--the issue is that
         *       `return (...args)` cannot be async, and that's what's actually being called by the
         *       UI component). [twl 17.Dec.18]
         */
        const payload = await creator(...args);

        if (!skip || !skip(payload)) {
          dispatch(makeAction(type, payload));
        }

        maybeDispatchStatusAction(dispatch, status, parameters, STATUS_SUCCESS);

        return payload;
      }
      catch (e) {
        maybeDispatchStatusAction(dispatch, status, parameters, STATUS_FAILURE, e);

        console.error(`Failure during ${type} dispatch: ${e.message}`);

        throw e;
      }
    }
  }
}

/**
 * Adds the next `id` to the `object` for the `type`.
 *
 * @param {object} ids    Maps types to the current ID for that type
 * @param {string} type   The type of the object
 * @param {object} object An object whose ID should be added
 */
export function addId(ids, type, object) {
  if (object.id) {
    throw new Error(`The object of type '${type}'' already has the ID ${object.id}`);
  }

  if (!ids[type]) {
    ids[type] = 1;
  }

  object.id = ids[type]++;

  return object;
}

/**
 * Sets the `property` of an immutable element in the immutable `array` with the `id` to the
 * `value`.
 *
 * @param {object[]} array    An array of objects
 * @param {number}   id       The ID of the object whose property should be set
 * @param {string}   property The property to set
 * @param {any}      value    The value to set the property to
 *
 * @returns {Object[]} A new array with the updated element
 */
export function setProperty(array, id, property, value) {
  return array.map(item => item.id !== id ? item : Object.assign({}, item, { [property]: value }));
}

/**
 * Sets the `property` of the immutable element defined by `payload.id` within the immutable `array`
 * to the value defined by the `property` of `payload`.
 *
 * @param {object[]} array    An array of objects
 * @param {object}   payload  A payload containing the `property` property and an `id`
 * @param {string}   property The property to set
 *
 * @returns {Object[]} A new array with the updated element
 */
export function setPayloadProperty(array, payload, property) {
  return setProperty(array, payload.id, property, payload[property]);
}

/**
 * Returns an object that can be used as a enum based on the arguments passed in.
 *
 * TODO: Review [this method](https://medium.com/@amcdnl/enums-in-es7-804a5a01bd70) of making
 *       compile-type safe enums, or use TypeScript enums if we convert to TypeScript.
 *       [twl 15.Jun.18]
 *
 * @param  {...string} args The keys for the enum (the values will be the same as the keys)
 *
 * @return {object} An object containing one key for every argument whose value is the same as the
 *                  key
 */
export function makeEnum(...args) {
  const enumObject = {};

  for (const key of args) {
    enumObject[key] = key;
  }

  return enumObject;
}

// =================================================================================================
// PRIVATE FUNCTIONS
// =================================================================================================
/**
 * Dispatch a status action if the `status` is defined. The status action payload will contain the
 * `type`, `path`, `parameters`, `state` and error` properties, with the `type` and `path` taken
 * from the `status`.
 *
 * @param  {Function} dispatch   The function used to send the dispatch
 * @param  {object}   status     An object defining the `type` and `path` values for the payload
 * @param  {any}      parameters The `parameters` for the payload
 * @param  {string}   state      The `state` for the payload
 * @param  {object}   error      The `error` for the payload
 */
function maybeDispatchStatusAction(dispatch, status, parameters, state, error) {
  if (status) {
    dispatch(makeAction(globalTypes.SET_STATUS, {
      type: status.type,
      path: status.path,
      parameters,
      state,
      error
    }));
  }
}

