import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_DISABLE_SENTRY !== 'true') {
  console.log("Initializing Sentry");

  Sentry.init({
    // NOTE: Hardcoding the DSN because we want to log errors that occur during Firebase
    //       initialization. [twl 15.Jun.20]
    dsn: 'https://201ee6bb3ad948cd93b9eedf0b9bbc8d@o407922.ingest.sentry.io/5277874',
    release: `day-optimizer@${process.env.REACT_APP_VERSION || 'unversioned'}`,
    environment: process.env.REACT_APP_SENTRY_ENV || 'development',
  });
}

export default Sentry;
