import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import Button from 'components/library/ButtonMui';
import Icon from 'components/library/Icon';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    textTransform: 'none',
    justifyContent: 'left',

    '& i': {
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      marginRight: theme.spacing.unit,
    },
  },
  selected: {
    background: theme.palette.grey['100'],
  }
});

const lookupTranslation = (statuses, status, type, property) => {
  // Use `unknown` for both undefined & invalid `status` values; don't convert to parameter default
  const lookup = statuses[status] || statuses['unknown'];

  return lookup[`${property}-${type}`] || lookup[property];
}

const StatusInputButton = ({ status, todo, intent, onClick, classes, t }) => {
  const configKey = intent ? 'intents' : 'todos';
  const statuses = t(`data.${configKey}.fields.status.options`, { returnObjects: true });
  const icon = lookupTranslation(statuses, status, todo.type, 'icon');
  const label = lookupTranslation(statuses, status, todo.type, 'label');
  const state = intent ? intent.status.state : todo.status.state;

  return (
    <Button
      className={classnames(classes.root, state === status && classes.selected)}
      onClick={e => {
        e.stopPropagation();
        onClick && onClick(status, todo);
      }}
    >
      <Icon type={icon} />
      <Typography variant="body2" noWrap>{label}</Typography>
    </Button>
  );
}

export default compose(
  withStyles(styles),
  withTranslations,
)(StatusInputButton);
