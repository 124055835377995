import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import Paper from 'components/library/Paper';
import Typography from 'components/library/Typography';
import withContext from 'helpers/withContext';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';

const styles = theme => ({
  root: {
    border: `solid 1px ${theme.palette.grey['400']}`,
    padding: theme.spacing.unit * 3,
    textAlign: 'center',
  },
  rootFlat: {
    border: `solid 1px ${theme.palette.grey['400']}`,
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
    fontWeight: 500,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    whiteSpace: 'nowrap',
  },
});

const DailyPlanInfoCompactCard = ({
  i18nPath,
  className,
  variant,
  date,
  workflow,
  existingSchedule,
  activePlanType,
  isAccountReadOnly,
  classes,
  t,
}) => (
  <Paper
    className={classnames(classes.root, variant === 'flat' && classes.rootFlat, className)}
    elevation={variant === 'flat' ? 0 : undefined}
  >
    <Typography className={classes.title} variant="h3">{t(i18nPath, 'title')}</Typography>

    <Typography variant="body1">
      {t(i18nPath, 'benefit')}. {t(i18nPath, 'message')}
    </Typography>

    {!isAccountReadOnly &&
      <StartWorkflowButton
        className={classes.button}
        workflow={workflow}
        existingSchedule={existingSchedule}
        activePlanType={activePlanType}
        date={date}
      />
    }
  </Paper>
);

export default compose(
  withContext('isAccountReadOnly'),
  withStyles(styles),
  withTranslations,
)(DailyPlanInfoCompactCard);
