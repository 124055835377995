import { services } from '@lifetools/shared-services';
import sentry from 'services/sentry';

// NOTE: These are designed to mostly align with the `analytics` library.
//       Details at https://getanalytics.io/api/ [twl 3.Jul.20]

const visit = (screenName, params) => {
  try {
    services.analytics.logEvent('screen_view', {
      firebase_screen: screenName,
      ...params
    });
  } catch (e) {
    sentry.captureException(e);
  }
};

const track = (eventName, payload) => {
  try {
    services.analytics.logEvent(eventName, payload);
  }
  catch (e) {
    sentry.captureException(e);
  }
};

const identify = (userId, traits) => {
  try {
    if (typeof userId !== 'undefined') {
      services.analytics.setUserId(userId);
    }

    services.analytics.setUserProperties(traits);
  } catch (e) {
    sentry.captureException(e);
  }
};

export default {
  visit,
  track,
  identify,
}
