import React from 'react';
import Intent from 'components/app/intents/Intent';
import IntentQuickView from 'components/app/intents/IntentQuickView';
import TodoList from 'components/app/todos/TodoList';

const getIdAttribute = value => (
  value.todo && value.todo.templateKey ? `Intent-${value.todo.templateKey}` : undefined
);

/**
 * Wrapper around `TodoList` to support listing intents. Supports all the props that `TodoList`
 * supports except for `todos`. Adds support for the `schedule` and `intents` prop.
 */
const IntentList = ({
  schedule,
  intents,
  onCreate,
  ...props
}) => (
  <TodoList
    {...props}
    todos={intents}
    component={Intent}
    selectedComponentProps={{
      ItemComponent: Intent,
      QuickViewComponent: IntentQuickView,
    }}
    getIdAttribute={getIdAttribute}
    onCreate={todo => onCreate(todo, schedule)}
  />
);

export default IntentList;
