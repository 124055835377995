import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/app/navigation/Page';
import CalendarDetails from 'components/app/views/CalendarDetails';
import { withSchedule } from 'helpers/withSchedule';
import { buildScene } from 'scenes/utils';
import selectSchedulesByQuery from 'services/store/schedules/selectors/selectSchedulesByQuery';
import removeIntent from 'services/store/intents/actions/removeIntent';
import updateIntent from 'services/store/intents/actions/updateIntent';
import querySchedules from 'services/store/schedules/actions/querySchedules';
import { formatLocalDate } from 'utils/time';
import ScheduleView from '../ScheduleView';
import ScheduledDay from './components/ScheduledDay';

const i18nPath = 'scenes.todos.Schedules.components.SchedulesMain';

function loadData() {
  this.props.doQuerySchedules(this.props.query);
}

const SchedulesMain = buildScene(({
  date,
  showDetail,
  onChangeDate,
  schedules,
  doIntentUpdate,
  doIntentDelete,
  schedule,
  scheduleStatus,
  intents,
  t,
}) => (
  <Page title={t(i18nPath, 'title')}>
    <CalendarDetails
      i18nPath={`${i18nPath}.components.CalendarDetails`}
      components={{
        dateCellWrapper: props => (
          <ScheduledDay schedules={schedules} onSelect={onChangeDate} {...props} />
        ),
      }}
      date={date}
      onSelectDate={onChangeDate}
      onNavigateDate={onChangeDate}>

      {showDetail &&
        <ScheduleView
          date={date}
          schedule={schedule}
          scheduleStatus={scheduleStatus}
          intents={intents}
          onIntentUpdate={doIntentUpdate}
          onIntentDelete={doIntentDelete}
        />
      }

    </CalendarDetails>
  </Page>
), {
  state: (state, { date }) => {
    const startDate = date.withDayOfMonth(1);
    const endDate = startDate.plusMonths(1).minusDays(1);

    // HACK: Documents retrieved from Firebase have a schema applied that make the `date` field be
    //       a LocalDate. But when a schedule is created, it uses a text field to store the date as
    //       a format.
    //
    // TODO: Standardize on one approach or the other, and figure out a better way to reconcile
    //       the Firebase schema from the internal schema. [twl 9.Oct.18]
    const query = {
      date: {
        greaterThanOrEqual: formatLocalDate(startDate),
        lessThanOrEqual: formatLocalDate(endDate),
      },
    };
    const selectQuery = {
      date: {
        greaterThanOrEqual: startDate,
        lessThanOrEqual: endDate,
      },
    };

    return {
      query,
      schedules: selectSchedulesByQuery(state, selectQuery),
    };
  },
  dispatch: {
    doQuerySchedules: querySchedules,
    doIntentUpdate: updateIntent,
    doIntentDelete: removeIntent,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

SchedulesMain.propTypes = {
  /** The date of the schedule to load and view */
  date: PropTypes.object.isRequired,

  /** Whether the detail view should be shown or not */
  showDetail: PropTypes.bool,

  /** Called when the user has requested the date on this view change */
  onChangeDate: PropTypes.func,

  /** The schedules to display on the calendar. Provided internally by this component. */
  schedules: PropTypes.arrayOf(PropTypes.object),

  /** The schedule to show in the detail view. Provided by `withSchedule` HOC. */
  schedule: PropTypes.object,

  /** The loading status of the schedule for the detail view. Provided by `withSchedule` HOC. */
  scheduleStatus: PropTypes.object,

  /** The intents for the schedule in the detail view. Provided by `withSchedule` HOC. */
  intents: PropTypes.arrayOf(PropTypes.object),

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

export default withSchedule(SchedulesMain, 'date', true);
