import React from 'react';
import BasicSteps from 'components/app/schedules/BasicSteps';
import Scheduler from 'components/app/schedules/Scheduler';
import ViewLayout from 'components/layouts/ViewLayout';
import ActionBar from 'components/library/ActionBar';
import scheduleIntent from 'services/store/intents/actions/scheduleIntent';
import removeIntent from 'services/store/intents/actions/removeIntent';
import updateIntent from 'services/store/intents/actions/updateIntent';
import updateIntentStatus from 'services/store/intents/actions/updateIntentStatus';
import saveScheduleStep from 'services/store/schedules/actions/saveScheduleStep';
import updateSchedule from 'services/store/schedules/actions/updateSchedule';
import { buildScene } from 'scenes/utils';
import HelpTipWidget from 'widgets/common/HelpTipWidget';
import UnscheduledIntentsWidget from 'widgets/intents/UnscheduledIntentsWidget';
import EditScheduleDialog from 'widgets/schedules/EditScheduleDialog';
import './Schedule.css';

const i18nPath = 'scenes.todos.Schedule';

async function extendSchedule(schedule, duration, doUpdateSchedule) {
  // TODO: Check to ensure the schedule is not extended past 24 hours. This will cause unexpected
  //       behavior. [twl 29.Sep.20]
  await doUpdateSchedule(schedule.id, {
    endTime: schedule.endTime.plusMinutes(duration),
  });
}

export default buildScene(({
  t,
  workflow,
  schedule = {},
  intents,
  date,
  doScheduleIntent,
  doUpdateIntent,
  doUpdateIntentStatus,
  doUpdateSchedule,
  doDeleteIntent,
  doSaveScheduleStep
}) => (
  <div className="Schedule">
    <BasicSteps
      workflow={workflow}
      currentStep="schedule"
      date={date}
      onNext={async () => doSaveScheduleStep(schedule, intents, workflow, 'scheduled')}
    />

    <ViewLayout layout="split" noStickyPadding scrollColumns="*">
      <Scheduler
        boxId="Scheduler"
        viewId="scheduler"
        viewColumn={2}
        viewI18nPath={i18nPath + '.views.Scheduler'}
        i18nPath={i18nPath + '.components.Scheduler'}
        intents={intents}
        actionMode="edit"
        startTime={schedule.startTime}
        endTime={schedule.endTime}
        onIntentSchedule={doScheduleIntent}
        onIntentUpdate={doUpdateIntent}
        onIntentDelete={doDeleteIntent}
        onStatusChange={doUpdateIntentStatus}
        onScheduleExtend={(duration) => extendSchedule(schedule, duration, doUpdateSchedule)}
        boxActions={[
          <ActionBar.Button i18nPath={i18nPath} action="set-times" pane={
            <EditScheduleDialog schedule={schedule} />
          } />,
        ]}
        header={
          <HelpTipWidget
            helpPath="workflow.schedule.scheduler"
            tips={['intro[newUser]']}
          />
        }
      />

      <UnscheduledIntentsWidget
        boxId="UnscheduledIntents"
        viewId="unscheduled"
        viewColumn={1}
        viewI18nPath={i18nPath + '.views.UnscheduledIntents'}
        schedule={schedule}
        intents={intents}
        variant="schedule:unscheduled"
        sortBy={[
          { property: 'duration', direction: 'desc', sortNulls: 'bottom' },
          'todo.title'
        ]}
        groupBy="todo.tags"
        header={
          <HelpTipWidget
            helpPath="workflow.schedule.unscheduled"
            tips={['intro[newUser]']}
          />
        }
      />
    </ViewLayout>
  </div>
), {
  dispatch: {
    doScheduleIntent: scheduleIntent,
    doUpdateIntent: updateIntent,
    doUpdateIntentStatus: updateIntentStatus,
    doUpdateSchedule: updateSchedule,
    doDeleteIntent: removeIntent,
    doSaveScheduleStep: saveScheduleStep,
  },
});
