import { DataOperationType } from '../../operations/types/DataOperationType';
import { isWriteOperation } from '../../operations/utils/isWriteOperation';
import { ITriggerRule } from '../types/ITriggerRule';
import { TriggerStage } from '../types/TriggerStage';

/**
 * Returns true if the `rule` is active for the given `stage`, `operation` and `collection`.
 *
 * @param rule       - A trigger rule
 * @param stage      - The current stage in the operation pipeline
 * @param operation  - The type of operation being performed
 * @param collection - The collection the operation is being performed against
 *
 * @returns `true` if the rule applies; false otherwise
 */
const isTriggered = (
  rule: ITriggerRule,
  stage: TriggerStage,
  operation: DataOperationType,
  collection: string,
): boolean => (
  rule.stage === stage &&
    (rule.collection === collection || typeof rule.collection === 'undefined') &&
    (rule.operation === operation || rule.operation === 'write' && isWriteOperation(operation))
);

export default isTriggered;
