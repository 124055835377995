import { IEntityMap } from '../types/IEntityMap';

/**
 * Converts a map that maps entity IDs to entities into an array of entities with the keys of the
 * map set as the `id` property of each entity.
 *
 * @param entities - An object mapping entity IDs to entities
 *
 * @template T - The type of entity in the entity map
 *
 * @returns An array of entities
 */
export function entityMapToArray<T>(entities: IEntityMap<T>): T[] | undefined {
  // TODO: What happens when an entity is undefined -- for example, when we are deleting an intent.
  //       [twl 16.Apr.19]

  return entities && Object.keys(entities).map(id => ({ id, ...entities[id] }));
}
