import React, { Fragment } from 'react';
import classnames from 'classnames';
import T from 'components/library/T';
import Toolbar from 'components/library/Toolbar';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  header: {
    background: theme.palette.grey['A400'],
    color: theme.palette.common.white,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,

    '@media print': {
      background: theme.palette.grey['50'],
      color: theme.palette.common.black,
      borderBottom: `solid 1px ${theme.palette.grey['300']}`,
    },
  },
  headerMobile: {
    paddingRight: theme.spacing.unit,                          // move icons a bit more to the right
  },
  actions: {
    flex: '1 1 auto',
    textAlign: 'right',
    color: theme.palette.common.white,

    '& > *:not(:first-child)': {
      marginLeft: theme.spacing.unit,
    },

    '@media print': {
      display: 'none',
    },
  },
});

const BoxHeader = ({ i18nPath, title, controls, pane, classes, newMobileUi }) => (
  <Fragment>
    <Toolbar variant="dense" classes={{ root: classnames(classes.header, newMobileUi && classes.headerMobile) }}>
      <Typography variant={newMobileUi ? 'h4' : 'h3'} color="inherit">
        <T path={i18nPath} name="title" override={title} />
      </Typography>

      <div className={classes.actions}>{controls}</div>
    </Toolbar>

    {pane}
  </Fragment>
);

export default withStyles(styles)(BoxHeader);
