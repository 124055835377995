import React from 'react';
import PropTypes from 'prop-types';
import { toNativeDate } from '@lifetools/shared-utils-time';
import { buildScene } from 'scenes/utils';
import { FeatureFlags } from 'contexts/FeatureFlags';
import Page from 'components/app/navigation/Page';
import TodoEditDialog from 'components/app/todos/TodoEditDialog';
import ActionBar from 'components/library/ActionBar';
import withDeviceInfo from 'helpers/withDeviceInfo';
import createTodo from 'services/store/todos/actions/createTodo';
import selectTodos from 'services/store/todos/selectors/selectTodos';
import queryTodos from 'services/store/todos/actions/queryTodos';
import { formatLocalDate } from 'utils/time';
import AppointmentList from '../AppointmentList';

const i18nPath = 'scenes.todos.Appointments.components.AppointmentsDetail';

async function loadData() {
  const { date, doQueryTodos } = this.props;

  // TODO: Need to query on `end` as well. [twl 2.Oct.18]
  doQueryTodos({
// TODO: Uncomment this and then add an index. [twl 27.Nov.18]
//    type: 'appointment',
    start: {
      afterOrOn: toNativeDate(date).toISOString(),
      beforeOrOn: toNativeDate(date.plusDays(1)).toISOString(),
    },
  });
}

const AppointmentsDetail = buildScene(({ date, todos, doCreateTodo, isAccountReadOnly, t, newMobileUi }) => (
  <Page
    title={t(i18nPath, 'title')}
    subtitle={!newMobileUi ? formatLocalDate(date, t(i18nPath, 'date-format')) : undefined}
    backTo={`/appointments/${date}`}>

    <AppointmentList
      boxActions={!isAccountReadOnly && [
        <ActionBar.Button
          i18nPath={i18nPath}
          action="add"
          pane={
            <TodoEditDialog
              defaultType="appointment"
              options={{ date }}
              onSave={doCreateTodo}
            />
          } />,
      ]}
      date={date}
      todos={todos}
      noBoxHeader
    />

  </Page>
), {
  context: 'isAccountReadOnly',
  state: (state) => ({
    todos: selectTodos(state)
  }),
  dispatch: {
    doQueryTodos: queryTodos,
    doCreateTodo: createTodo,
  },
  lifecycle: {
    componentDidMount: loadData,
    componentDidUpdate: loadData,
  },
});

AppointmentsDetail.propTypes = {
  /** The date of the appointments to load and view */
  date: PropTypes.object.isRequired,

  /**
   * An array of all loaded todos.
   */
  todos: PropTypes.arrayOf(PropTypes.object),

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

/**
 * Temporary wrapper that alters the props used based on a feature flag.
 */
const AppointmentsDetailWrapper = withDeviceInfo(({ isMobileDevice, ...props }) => (
  <FeatureFlags.Consumer>
    {flags =>
      <AppointmentsDetail
        {...props}
        newMobileUi={isMobileDevice && flags.stackedMobileUi}
      />
    }
  </FeatureFlags.Consumer>
));

export default AppointmentsDetailWrapper;
