import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'components/library/Markdown';
import withBox from 'helpers/withBox';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const styles = theme => ({
  root: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  missingMessage: {
    textAlign: 'center',
  }
});

const NotesView = ({ i18nPath, value, onChange, classes, t }) => (
  <div className={classes.root}>
    {value
      ? <Markdown value={value} onChange={onChange} />
      : <p className={classes.missingMessage}>
          {t(i18nPath, onChange ? 'missing' : 'missing-readOnly')}
        </p>
    }
  </div>
);

NotesView.propTypes = {
  /** The path from which to load localization strings for this component */
  i18nPath: PropTypes.string,

  /** The notes to render */
  value: PropTypes.string,

  /** Called when the value changes */
  onChange: PropTypes.func,

  /** An object mapping semantic class names to compiled class names. Provided by `withStyles`. */
  classes: PropTypes.object.isRequired,

  /** A function used to provide translations of text. Provided by `withTranslations` */
  t: PropTypes.func.isRequired,
};

export default withTranslations(withBox(withStyles(styles)(NotesView)));
