import React from 'react';
import MuiPaper from '@material-ui/core/Paper';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
});

const Paper = props => <MuiPaper {...props} />;

Paper.muiName = 'Paper';

export default withStyles(styles)(Paper);
