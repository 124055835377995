import { get } from 'lodash';
import { toNativeDate } from '@lifetools/shared-utils-time';
import analytics from 'services/analytics';
import { getTrackingUserId } from '../../../../../utils/app/getTrackingUserId';
import { getVersionAsInteger } from '../../../../../utils/app/getVersionAsInteger';
import { isIntegrationTestEnvironment } from '../../../../../utils/app/isIntegrationTestEnvironment';
import selectUserSettings from '../../../settings/selectors/selectUserSettings';
import store from '../../../store';

export default async function setFeatureUser(authInfo: any) {
  if (isIntegrationTestEnvironment()) {
    return;
  }

  const { user } = authInfo;
  const userSettings = selectUserSettings(store.getState());
  const createdAt = toNativeDate(user.createdAt);

  analytics.identify(getTrackingUserId(user.id), {
    loggedIn: true,
    appVersionNumber: getVersionAsInteger(process.env.REACT_APP_VERSION),
    cohort: get(userSettings, 'cohort', 'unknown'),
    scheduleCount: get(userSettings, 'info.schedules.count', 0),
    ...createdAt && { registeredAt: createdAt.toISOString() },
    // calendarIntegration: get(userSettings, 'calendars.config.integration'),
  });
}
