import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteDocument from 'services/store/utils/actions/updateRemoteDocument';
import { collection, types } from 'services/store/todos/definitions';

const updateIntentStatus = makeAsyncActionCreator({
  type: types.UPDATE_TODO,
  creator: async (intent, state) => (
    await updateRemoteDocument(collection, intent.todo.id, {
      [`intents.${intent.scheduleId}.status.state`]: state
    })
  ),
  status: {
    path: collection,
    type: 'write',
    parameters: (intent, _status) => [ intent.id ],
  }
});

export default updateIntentStatus;
