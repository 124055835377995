import React from 'react';
import Page from 'components/app/navigation/Page';
import configureWrappedComponent from 'helpers/utils/configureWrappedComponent';

/**
 * Enhances a component by wrapping a `Page` component around it.
 */
function withPage(helperProps) {
  return WrappedComponent => (
    configureWrappedComponent(WrappedComponent, 'WithPage',
      ({ pageTitle, pageSubtitle, pageBackTo, noPage, ...props }) => (
        noPage ?
          <WrappedComponent {...props} />
          :
          <Page title={pageTitle} subtitle={pageSubtitle} backTo={pageBackTo} {...helperProps}>
            <WrappedComponent {...props} />
          </Page>
      )
    )
  );
}

export default withPage;
