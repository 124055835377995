import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import unset from 'lodash/unset';
import { PlainObject } from '../types/PlainObject';

/**
 * Merges the object with path keys with another object and returns a new merged object. If the
 * value of a path key in `toMerge` is `undefined`, the corresponding path will be removed from
 * `object`; otherwise the value will overwrite the value at that path underneath `object`.
 *
 * @param object  - The object to merge into
 * @param toMerge - The object containing the values to merge
 * @param prefix  - The path prefix that specifies which sub-tree to merge. If defined, only
 *                  property paths that start with this prefix will be merged; if `undefined`, all
 *                  properties will be merged
 *
 * @returns A new merged object, which is a deep clone of `object` with the values from `toMerge`
 *          applied. If `object` is null or undefined, an empty object is used for the merge.
 */
export function mergePathKeys(
  object?: PlainObject,
  toMerge?: PlainObject,
  prefix?: string,
): Record<string, unknown> {
  const merged = cloneDeep(object) ?? {};

  if (toMerge) {
    for (const path of Object.keys(toMerge)) {
      if (!prefix || path.startsWith(prefix)) {
        if (typeof toMerge[path] !== 'undefined') {
          set(merged, path, cloneDeep(toMerge[path]));
        } else {
          unset(merged, path);
        }
      }
    }
  }

  return merged;
}
