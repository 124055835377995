import get from 'lodash/get';
import { services } from '@lifetools/shared-services';

export function initializeDatabase(firebase: any) {
  const database = firebase.firestore();
  const emulationHost = get(services, 'config.firebase.firestoreEmulationHost');

  if (emulationHost) {
    services.logger.info(`Using locally emulated Firestore from ${emulationHost}`);
    database.settings({
      host: emulationHost,
      ssl: false,
      // HACK: Cypress currently doesn't work with the Firebase emulators unless this option is set.
      //       See https://github.com/cypress-io/cypress/issues/2374#issuecomment-587879412,
      //       https://github.com/cypress-io/cypress/issues/6350 and
      //       https://github.com/firebase/firebase-js-sdk/issues/1674 for more info [twl 25.Oct.20]
      ...window.Cypress && {
        experimentalForceLongPolling: true,
      }
    });
  }

  return database;
}
