import React from 'react';
import range from 'lodash/range';
import { withStyles } from '@material-ui/core/styles';
import Selector from 'components/library/Selector';
import withTranslations from 'helpers/withTranslations';
import formatDuration from 'utils/time/formatDuration';

const itemWidth = 5;
const columnCount = 4;

const styles = theme => ({
  root: {
    minWidth: `${itemWidth}rem`,
    textAlign: 'right',
  },
  select: {
    '&:focus': {
      background: 'inherit',
    },
  },
  menu: {
    '& > *': {
      width: `${itemWidth * columnCount}rem`,
      maxWidth: '100%',                                                 // required for iPhone 5
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
    },
  },
  menuItem: {
    flexBasis: `calc(${itemWidth}rem - ${theme.spacing.unit * 2}px)`,   // px = padding left + right
    justifyContent: 'flex-end',
    paddingLeft: 0,
    paddingRight: theme.spacing.unit * 2,

    // Use a custom breakpoint for when the above basis causes 3 columns instead of 4
    [theme.breakpoints.down(355)]: {
      flexBasis: `calc(90vw / 4 - ${theme.spacing.unit * 2}px)`,
    }
  },
});

function generateOptions(minimum, maximum, interval, t) {
  const defaultOption = {
    label: '--:--',
    value: '',
  };

  const options = range(minimum, maximum + interval, interval).map(value => ({
    value,
    label: formatDuration(value, 'compact', t)
  }));

  return [
    defaultOption,
    ...options,
  ];
}

const DurationSelector = ({
  value,
  minimum = 15,
  maximum = 540,
  interval = 15,
  onChange,
  classes,
  t,
}) => (
  <Selector
    options={generateOptions(minimum, maximum - interval, interval, t)}
    value={value}
    classes={{ root: classes.root, select: classes.select }}
    MenuProps={{
      classes: {
        paper: classes.menu,
      },
    }}
    MenuItemProps={{
      classes: {
        root: classes.menuItem
      }
    }}
    onChange={value => onChange(value === '' ? undefined : value)} />
);

export default withTranslations(withStyles(styles)(DurationSelector));
