import { repeat } from 'lodash';
import DefaultLogger from '../DefaultLogger';
import Logger from '../Logger';
import { LogLevel } from '../types/LogLevel';
import { LogStyle } from '../types/LogStyle';

const rulerChars: any = {
  'h1': '━',
  'h2': '─',
  'h3': '-',
};

function logHorizontalRule(
  style: LogStyle = 'h2',
  width: number = 80,
  level: LogLevel = 'info',
  logger: Logger = DefaultLogger
) {
  if (rulerChars[style]) {
    logger.log(level, repeat(rulerChars[style], width));
  }
}

export default logHorizontalRule;
