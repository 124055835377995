import enableRealtimeData from './any/events/enableRealtimeData';
import setAuthInfo from './auth/events/setAuthInfo';
import setAuthInitSuccess from './auth/events/setAuthInitSuccess';
import clearAnalyticsUser from './integrations/analytics/events/clearAnalyticsUser';
import setAnalyticsUser from './integrations/analytics/events/setAnalyticsUser';
import clearFeatureUser from './integrations/features/events/clearFeatureUser';
import setFeatureUser from './integrations/features/events/setFeatureUser';
import clearSentryUser from './integrations/sentry/events/clearSentryUser';
import setSentryUser from './integrations/sentry/events/setSentryUser';
import setAppSettings from './settings/events/setAppSettings';
import setSubscriptionPlan from './settings/events/setSubscriptionPlan';
import updateLastAuth from './settings/events/updateLastAuth';
import updateTimeZone from './settings/events/updateTimeZone';
import setUserInfo from './user/events/setUserInfo';

// NOTE: Bind all events in `rootEvents` instead of individual store `events.js` files for each
//       module so we can control the order of events across store modules better. [twl 13.Dec.18]
const events = {
  // Called when the authenticated user is set, e.g. via login or first page load
  onAuthenticatedUserSet: [
    setAuthInfo,
    setUserInfo,
    setSentryUser,                        // Set early so user is attached to any errors that follow
    enableRealtimeData,
    setAppSettings,                       // Set after `enableRealtimeData` to update in real-time
    updateTimeZone,
    setSubscriptionPlan,
    updateLastAuth,                       // NOTE: See hack in `registerUser` if this order changes
    setAnalyticsUser,
    setFeatureUser,
    setAuthInitSuccess,                   // must come at end to indicate auth process is done
  ],

  // TODO: Implement this and then remove the hack in `logout` that is calling this event directly.
  //       [twl 18.Apr.20]
  //
  // Called before the authenticated user is unset, e.g. before logout
  // beforeAuthenticatedUserUnset: [
  //   disableRealtimeData,
  // ],

  // Called when the authenticated user is unset, e.g. via logout
  onAuthenticatedUserUnset: [
  ],

  // Called when the anonymous user is set, e.g. via logout or first page load
  onAnonymousUserSet: [
    enableRealtimeData,                   // must come before `setAppSettings` for anonymous users
    setAppSettings,
    setAuthInfo,
    setUserInfo,
    clearSentryUser,
    clearAnalyticsUser,
    clearFeatureUser,
    setAuthInitSuccess,                   // must come at end to indicate auth process is done
  ],

  // Called when the anonymous user is unset, e.g. via login
  onAnonymousUserUnset: [
  ],
};

export default events;
