import { IDocumentData } from './types/IDocumentData';
import { IDocumentSchema } from './types/IDocumentSchema';
import { queryDocuments } from '../queries/queryDocuments';

/**
 * Returns the documents from the collection.
 *
 * @param collection - The name of the collection to retrieve the documents from
 *
 * @returns  An array of documents with `id` properties
 */
export async function getDocuments(collection: string): Promise<IDocumentData[]> {
  return await queryDocuments(collection);
}
