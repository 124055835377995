import { useEffect, useState } from 'react';
import { LocalDate, LocalTime } from 'js-joda';
import { toLocalTime } from '@lifetools/shared-utils-time';

// TODO: Since we're rounding the time up to 5 minutes, we probably don't need to refresh every
//       minute. But it does make the logic easier. [twl 2.Apr.20]
const defaultRefreshRate = 60000;

const determineIfNowIsActive = (date, nextStartTime) => {
  const today = LocalDate.now();

  return (
    today.equals(date) ||
    (today.minusDays(1).equals(date) && LocalTime.now().isBefore(toLocalTime(nextStartTime)))
  );
};

/**
 * Updates return value `now` to indicate the current time of day, updated once a minute by default,
 * or, if `date` is after the next schedule starts, then always returns `startTime`. The
 * `refreshRate` determines how frequently `now` is updated; if set to `0`, it disables updating.
 *
 * @param {LocalDate} date          The date to use now for
 * @param {LocalTime} startTime     The start time of the schedule for `date`
 * @param {LocalTime} nextStartTime The default start time of the next schedule
 * @param {number}    refreshRate   How often to update `now`; defaults to once a minute
 *
 * @return {object} An object with two properties: `now`, a `LocalTime` representing the current
 *                  time, and `isNowActive`, a boolean indicating if `now` is being updated
 */
export function useNow(date, startTime, nextStartTime, refreshRate = defaultRefreshRate) {
  const isNowActive = determineIfNowIsActive(date, nextStartTime) && refreshRate > 0;
  const isBeforeStartTime = LocalDate.now().equals(date) && LocalTime.now().isBefore(startTime);
  const [ now, setNow ] = useState(isNowActive && !isBeforeStartTime ? LocalTime.now() : startTime);
  const [ wasNowActive, setWasNowActive ] = useState(isNowActive);

  useEffect(() => {
    if (!isNowActive) {
      return;
    }

    const updateNow = () => {
      // `isBeforeStartTime` is `false` while `isNowActive` is `true` when viewing yesterday's
      // schedule before today's start time
      if (!isBeforeStartTime || !LocalTime.now().isBefore(startTime)) {
        setNow(LocalTime.now());
      }
    };

    // Update `now` if this is called when `isNowActive` becomes `true`; calling it always causes an
    // infinite loop
    if (isNowActive !== wasNowActive) {
      setWasNowActive(isNowActive);
      updateNow();
    }

    const interval = setInterval(updateNow, refreshRate);

    return () => clearInterval(interval);
    // No need to have `wasNowActive`; it just causes unnecessary renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNowActive, isBeforeStartTime, startTime, refreshRate]);

  return { now, isNowActive, isBeforeStartTime };
}
