import React, { useEffect, useState } from 'react';
import { Location } from 'history';                              // dependency of `react-router-dom`
import { Route as WrappedRoute, useLocation } from 'react-router-dom';
import { OnRouteChangeListener } from '../../types/OnRouteChangeListener';
import { PlainObject } from '../../types/PlainObject';
import { RouteInfo } from '../../types/RouteInfo';

/**
 * Tracks the rendering of routes from React Router.
 */
export class RouteTracker {
  /**
   * The location currently being tracked.
   */
  private location?: Location = undefined;

  /**
   * The routes associated with the current location.
   */
  private routes: RouteInfo[] = [];

  /**
   * The event handler messaged whenever the route changes.
   */
  public onRouteChange?: OnRouteChangeListener = undefined;

  /**
   * Adds a new route to this tracker.
   *
   * @param props    - The props passed to the `Route` component, including its `path`
   * @param params   - Maps the parameter names from the `path` to their current values
   * @param location - The current location
   */
  public addRoute({ path, ...props }: PlainObject, params: PlainObject, location: Location) {
    this.setLocation(location);
    this.routes.push({ path, params, props });
  }

  /**
   * Returns the active route from a list of routes. By default, returns the route with the longest
   * path, under the assumption that routes are nested, so the one with the longest path is the
   * deepest, most relevant route.
   *
   * @param routes - The routes added to this tracker
   *
   * @returns The active route
   */
  public getActiveRoute(routes: RouteInfo[]): RouteInfo | undefined {
    let activeRoute = undefined;

    for (const route of routes) {
      if (!activeRoute || route.path.length >= activeRoute.path.length) {
        activeRoute = route;
      }
    }

    return activeRoute;
  }

  /**
   * Sets the location currently being tracked.
   *
   * @param location - The location being tracked
   */
  private setLocation(location: Location) {
    if (location !== this.location) {
      this.location = location;
      this.routes = [];

      // Wait until the entire DOM has been rendered so we capture all the routes, then fire the
      // `onRouteChange` to indicate the route was updated
      window.requestAnimationFrame(() => {
        const route = this.getActiveRoute(this.routes);

        if (route && this.onRouteChange) {
          this.onRouteChange(route.path, route.params, route.props, this.location);
        }
      });
    }
  }
}
