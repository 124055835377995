import React from 'react';

const SelectableSwitch = ({
  selected,
  selectedComponent: SelectedComponent,
  selectedProps,
  unselectedComponent: UnselectedComponent,
  unselectedProps,
  ...otherProps
}) => (
  selected ?
    <SelectedComponent {...selectedProps} {...otherProps} /> :
    <UnselectedComponent {...unselectedProps} {...otherProps} />
);

export default SelectableSwitch;
