import deepEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import React from 'react';
import memoize from 'memoize-one';
import configureWrappedComponent from 'helpers/utils/configureWrappedComponent';

/**
 * Enhances a component by adding `memos` and `memoSelf` props.
 *
 * The `memos` prop takes each of the functions passed in via `funcs` and memoizes them, thereby
 * allowing their results to be cached.
 *
 * The `memoSelf` is a memoized identity function with a deep equals compare. This can be used to
 * ensure that when a new value is deeply equal to an old value, the old value is passed into a
 * function or into a child component, thus preventing memoization recalculations or component
 * re-rendering.
 *
 * These two can be used in conjunction:
 *
 * ```
 * const MyComponent = ({ query, memos, memoSelf }) => (
 *   <ChildComponent
 *     stableQuery={memoSelf(query)}
 *     onSelect={memos.bindSelect(onSelect, memoSelf(query))}
 * )
 *
 * export default withMemos({
 *   bindSelect: (onSelect, query) => id => onSelect(query, id)
 * })(MyComponent);
 * ```
 *
 * In this example, `stableQuery` is a child prop that will only change when the new `query` prop is
 * not deeply equal to the old query prop. `onSelect` is a callback passed into the child component
 * that will always be the same bound function as long as `onSelect` and `query` remain the same
 * instances. To ensure `query` is the same instance, we use `memoSelf` to again ensure it does not
 * change unless the new `query` is not deeply equal to the old one.
 *
 * NOTE: The current implementation uses `memoize-one`, so the caching is only useful for function
 * parameters which change infrequently.
 *
 * @param {object} funcs An object mapping function names to functions
 *
 * @returns {function} A function that can be used to enhance a React component with the `memos`
 *                     prop
 */
function withMemos(funcs) {
  const memos = funcs && mapValues(funcs, func => memoize(func));
  const memoSelf = memoize(value => value, deepEqual);

  return WrappedComponent => (
    configureWrappedComponent(WrappedComponent, 'WithMemos', props => (
      <WrappedComponent {...props} memos={memos} memoSelf={memoSelf} />
    ))
  );
}

export default withMemos;
