const weeklyActivitiesOnDate = date => [ {
  type: 'activity',
  'repeat.weekly': {
    contains: `${date.dayOfWeek().ordinal() + 1}`      // Monday = '1'
  },
  /*
    TODO: Implement additional repeat options [twl 12.Oct.18]

    // xth weekday of the month (e.g. 2nd Monday)
    type: 'activity',
    'repeat.weekly': {
      contains: `${date.dayOfWeek().ordinal() + 1}-${weekdayNumberOfMonth}`
    },

    // xth of each month
    type: 'activity',
    'repeat.monthly': {
      contains: '${date.dayOfMonth()}'
    },
   */
}];

export default weeklyActivitiesOnDate;
