import { IDocumentReference } from '../types/IDocumentReference';
import { DocumentMetaProperties } from '../../extensions/types/DocumentMetaProperties';

/**
 * Returns whether the document specified by the reference is archvied or not.
 *
 * @param docRef - The document reference for the document
 *
 * @Returns `true` if the document has its archive flag set; `false` otherwise
 */
export async function isDocumentArchived(docRef: IDocumentReference): Promise<boolean> {
  const doc = await docRef.get();

  if (!doc.exists) {
    return false;
  }

  const data = doc.data();

  return data ? data[DocumentMetaProperties.Archived] === true : false;
}
