import { combineReducers } from 'redux';
import auth from './auth/reducers';
import schedules from './schedules/reducers';
import settings from './settings/reducers';
import todos from './todos/reducers';
import user from './user/reducers';

export default combineReducers({
  auth,
  schedules,
  settings,
  todos,
  user,
});
