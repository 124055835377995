import { queryDocuments } from '@lifetools/shared-database';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';
import { collection, types, statusTypes } from '../definitions';

/**
 * TODO: Make this use ADD_SCHEDULES and figure out a way to leverage `querySchedules` above. Maybe
 *       something like:
 *
 *          export const querySchedulesByDate = makeAsyncActionCreator({
 *            ...queryScheduleDefinition,
 *            creator: async date => await queryScheduleDefinition.creator({ date }),
 *            status {
 *              path: collection,
 *              type: statusTypes.querySchedulesByDate,
 *            }
 *          });
 *
 *       [twl 30.Aug.18]
 */
const querySchedulesByDate = makeAsyncActionCreator({
  type: types.SET_SCHEDULES,
  when: firstRunOrAfterOldFailure,
  creator: async date => ({ schedules: await queryDocuments(collection, { date }) }),
  status: {
    path: collection,
    type: statusTypes.querySchedulesByDate,
  }
});

export default querySchedulesByDate;
