import React from 'react';
import withStyles from 'helpers/withStyles';
import IntentCalendarLegendItem from './components/IntentCalendarLegendItem';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',

    '& > *': {
      flex: '1 1 33%',
    },
  },
};

const IntentCalendarLegend = ({ classes }) => (
  <div className={classes.root}>
    <IntentCalendarLegendItem className={classes.legendItem} state="new" />
    <IntentCalendarLegendItem className={classes.legendItem} state="done" />
    <IntentCalendarLegendItem className={classes.legendItem} state="done-todo" />
  </div>
);

export default withStyles(styles)(IntentCalendarLegend);
