import React from 'react';
import { compose } from 'recompose';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import IntentDay from '../../../IntentDay';

const i18nPath = 'components.app.intents.IntentCalendar.components.IntentCalendarLegend.components.IntentCalendarLegendItem';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  intentDay: {
    flex: '0 0 auto',
    padding: 0,
    width: 50,
    height: 50,
  },
  label: {
    marginTop: theme.spacing.unit,
    fontStyle: 'italic',
    textAlign: 'center',
  },
});

const IntentCalendarLegendItem = ({ state, classes, t }) => (
  <div className={classes.root}>
    <IntentDay
      className={classes.intentDay}
      todo={{ type: 'task' }}
      overrideIntent={{ status: { state } }}
    />

    <Typography className={classes.label}>
      {t(i18nPath, `states.${state}`)}
    </Typography>
  </div>
);

export default compose(
  withStyles(styles),
  withTranslations,
)(IntentCalendarLegendItem);
