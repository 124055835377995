import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import getDisplayName from 'helpers/utils/getDisplayName';

const largestMobileBreakpoint = 'sm';

/**
 * Enhances a component by adding a series of props that provide information about the size of the
 * device the component is being rendered on. Current props include:
 *
 * deviceSize
 * : The size of the device, specified as a Material UI breakpoint: `xs`, `sm`, `md`, `lg`, `xl`
 *
 * isMobileDevice
 * : Whether the device is considered a mobile device by this application.
 *
 * isDeviceSizeUp
 * : A function to determine whether the current device size is equal to or greater than the
 *   provided size, e.g., `isDeviceSizeUp('sm')`
 *
 * isDeviceSizeDown
 * : A function to determine whether the current device size is equal to or less than the provided
 *   size, e.g., `isDeviceSizeDown('sm')`
 */
function withDeviceInfo(WrappedComponent) {
  const WithWidth = ({ width, ...props }) => (
    <WrappedComponent
      {...props}
      deviceSize={width}
      isMobileDevice={isWidthDown(largestMobileBreakpoint, width)}
      isDeviceSizeUp={size => isWidthDown(width, size)}
      isDeviceSizeDown={size => isWidthUp(width, size)} />
  );

  const WithDeviceInfo = withWidth()(WithWidth);

  hoistNonReactStatics(WithDeviceInfo, WrappedComponent);

  WithDeviceInfo.displayName = `WithDeviceInfo(${getDisplayName(WrappedComponent)})`;

  return WithDeviceInfo;
}

export default withDeviceInfo;
