import debounce from 'lodash/debounce';
import { useCallback } from 'react';

type GenericFunction<R> = (...args: any[]) => R;

/**
 * Returns a debounced callback function that persists through re-renders.
 *
 * @param func  The function to call
 * @param delay The time to wait before calling the function
 */
export function useDebouncedCallback<F extends GenericFunction<R>, R>(
  func: F,
  delay: number = 250,
): F {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (useCallback(debounce(func, delay), [func, delay]) as unknown) as F;
}
