import { callFunction } from 'services/server/data';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import { collection, types } from 'services/store/settings/definitions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';

const loadAuthorizationUrl = makeAsyncActionCreator({
  type: types.SET_GLOBAL_SETTINGS,
  when: firstRunOrAfterOldFailure,
  creator: async (integration, callbackPath) => ({
    settings: {
      // TODO: Encode any property path special characters in `callbackPath` [twl 5.Jul.19]
      [`integrations.${integration}.${callbackPath}.authorizationUrl`]: await callFunction(
        `calendars_getAuthUrl`, {
          integration,
          host: window.location.host,
          callbackPath,
        }),
    }
  }),
  status: {
    path: collection,
    type: 'read',
    parameters: (integration, callbackPath) => (
      // TODO:  Encode any property path special characters in `callbackPath` [twl 5.Jul.19]
      `global.integrations.${integration}.${callbackPath}.authorizationUrl`
    ),
  }
});

export default loadAuthorizationUrl;
