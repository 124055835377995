import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { TrackedRoute } from '@lifetools/react-router-tracker';
import Redirect from 'components/router/Redirect';
import { FeatureFlags } from 'contexts/FeatureFlags';
import { IsAccountReadOnly } from 'contexts/IsAccountReadOnly';
import withMemos from 'helpers/withMemos';
import { hasAuthentication, isAuthenticated } from 'services/store/auth/selectors';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { getActivePlanInfo } from 'utils/app/plans/getActivePlanInfo';
import { getSessionValue } from 'utils/browser/getSessionValue';
import { setSessionValue } from 'utils/browser/setSessionValue';
import Splash from 'components/app/navigation/Splash';

const AuthenticatedRoute = ({
  component: Component,
  loader = 'splash',
  render,
  redirectTo,
  readOnlyRedirectTo,
  invalidRedirectTo,
  isValid,
  isAuthenticated,
  isDoneRegistration,
  hasAuthentication,
  isAccountReadOnly,
  featureFlags,
  saveFromUrl,
  ...props
}) => (
  <TrackedRoute
    {...props}
    render={props => (
      !hasAuthentication
        ? loader === 'none' ? null : <Splash />
        : !isAuthenticated
          ? (redirectTo ? <Redirect to={redirectTo} saveFromUrl={saveFromUrl} /> : null)
          : isValid && !isValid(props.match)
            ? <Redirect to={invalidRedirectTo} />
            : readOnlyRedirectTo && isAccountReadOnly
              ? <Redirect to={readOnlyRedirectTo} />
              : !isDoneRegistration ? <Splash />
                : <IsAccountReadOnly.Provider value={isAccountReadOnly}>
                    <FeatureFlags.Provider value={featureFlags}>
                      {render ? render(props) : <Component {...props} />}
                    </FeatureFlags.Provider>
                  </IsAccountReadOnly.Provider>
    )}
  />
);

const mapStateToProps = (state, { memos }) => {
  const isUserAuthenticated = isAuthenticated(state);
  const userSettings = selectUserSettings(state);
  const skipSaveFromUrl = getSessionValue('skipSaveFromUrl');

  if (skipSaveFromUrl) {
    setSessionValue('skipSaveFromUrl', undefined);
  }

  return {
    hasAuthentication: hasAuthentication(state),
    isAuthenticated: isUserAuthenticated,
    isDoneRegistration:
      // NOTE: While we could just test for `messages.settings.remind-schedule.enabled`, this seems
      //       more fragile than testing for the `cohort` too. One of these should always be set for
      //       a registered user. While `cohort` should be set for all users, the `deleteSchedule`
      //       integration tests wipes the database and the reset only re-creates
      //       `messages.settings.remind-schedule.enabled`. Fixing this is too complicated right
      //       now. [twl 3.May.22]
      get(userSettings, 'cohort') != null ||
      get(userSettings, 'messages.settings.remind-schedule.enabled') != null,
    isAccountReadOnly: memos.isAccountReadOnly(isUserAuthenticated, userSettings),
    featureFlags: {
      ...get(userSettings, 'flags', {}),
    },
    saveFromUrl: skipSaveFromUrl !== 'true',
  };
};

const memos = {
  isAccountReadOnly: (isAuthenticated, userSettings) => (
    isAuthenticated ? getActivePlanInfo(userSettings).isAccountReadOnly : true
  ),
};

export default compose(
  withRouter,
  withMemos(memos),
  connect(mapStateToProps),
)(AuthenticatedRoute);
