import DefaultLogger from '../DefaultLogger';
import Logger from '../Logger';
import { LogLevel } from '../types/LogLevel';
import { LogStyle } from '../types/LogStyle';
import logHorizontalRule from './logHorizontalRule';

function logHeader(
  title: string,
  style: LogStyle = 'h2',
  level: LogLevel = 'info',
  logger: Logger = DefaultLogger
) {
  switch (style) {
    case 'h1':
    case 'h2':
    case 'h3':
      logger.log(level, '');
      logHorizontalRule(style, undefined, level, logger);
      break;

    case 'h4':
      logger.log(level, '');
      break;
  }

  logger.log(level, title);

  switch (style) {
    case 'h1':
    case 'h2':
    case 'h3':
      logHorizontalRule(style, undefined, level, logger);
      break;
  }
}

export default logHeader;
