import { types as globalTypes } from 'services/store/actions';
import { applyAction, createStatusReducer } from 'services/store/utils-reducers';
import { types } from './definitions';

const initialData = {};

const actionReducers = {
  [globalTypes.LOGIN]: state => state,                  // auth is set via listener after login
  [globalTypes.LOGOUT]: () => initialData,

  [types.REGISTER_USER]: state => state,                // auth is set via listener after register
  [types.SET_AUTH]: (state, payload) => ({              // just copy the payload into the state
    ...payload
  }),
}

const dataReducer = (state = initialData, action) => applyAction(actionReducers, state, action);
const statusReducer = createStatusReducer('auth', { defaultKey: '*' });

const authReducer = (state = {}, action) => ({
  status: statusReducer(state.status, action),
  data: dataReducer(state.data, action)
});

export default authReducer;