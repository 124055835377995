import React from 'react';
import Link from 'components/library/Link';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

const i18nParentPath = 'scenes.app.Today.components.PlanTypeHelp';

const styles = theme => ({
  header: {
    display: 'flex',
    background: '#eee',
    padding: theme.spacing.unit,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,

    '& > h3': {
      fontWeight: 500,
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  body: {
    display: 'flex',
    alignItems: 'start',
    marginLeft: theme.spacing.unit,

    '& li': {
      display: 'list-item',        // override `Typography`, which sets display to `block`
    },

    '& a': {
      textDecoration: 'underline',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'start',
    },
  },
  image: {
    width: 250,
    marginLeft: theme.spacing.unit * 4,
    border: `solid 1px ${theme.palette.grey['400']}`,

    [theme.breakpoints.down('sm')]: {
      margin: `0 auto ${theme.spacing.unit * 2}px auto`,
    },
  },
});

const PlanTypeHelpCard = ({ type, classes, t }) => {
  const i18nPath = `${i18nParentPath}.types.${type}`;

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h3">
          {t(i18nPath, 'title')}
        </Typography>

        <Typography variant="h5">
          {t(i18nPath, 'subtitle')}
        </Typography>
      </div>

      <div className={classes.body}>
        <div>
          <Typography variant="body1" paragraph>
            {t(i18nPath, 'paragraph1')}
          </Typography>

          <Typography variant="body1" paragraph>
            {t(i18nPath, 'paragraph2')}
          </Typography>

          <Typography variant="body1">
            {t(i18nPath, 'videoIntro')}
          </Typography>

          <ul>
            <Typography variant="body1" component="li">
              <Link href={t(i18nPath, 'videoLink1')} target="_blank">
                {t(i18nPath, 'videoTitle1')}
              </Link>
            </Typography>

            <Typography variant="body1" component="li">
              <Link href={t(i18nPath, 'videoLink2')} target="_blank">
                {t(i18nPath, 'videoTitle2')}
              </Link>
            </Typography>
          </ul>
        </div>

        <img
          className={classes.image}
          src={`/images/app/${t(i18nPath, 'image')}`}
          alt={t(i18nPath, 'title')}
        />
      </div>
    </div>
  );
}

export default withTranslations(withStyles(styles)(PlanTypeHelpCard));
