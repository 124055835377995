import { DataOperationType } from '../types/DataOperationType';

/**
 * Returns true if the operation is a write operation.
 *
 * @returns `true` if the operation is a write operation; `false` if it is a read operation
 */
export const isWriteOperation = (operation: DataOperationType): boolean => (
  operation === 'create' || operation === 'update' || operation === 'delete' ||
  operation === 'write'
);
