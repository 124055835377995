import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteDocument from 'services/store/utils/actions/updateRemoteDocument';
import { collection, types } from 'services/store/todos/definitions';

const updateIntent = makeAsyncActionCreator({
  type: types.UPDATE_TODO,
  creator: async (intent, changes) => {
    const { todo, ...other } = changes;
    const updates = { ...todo };

    if (typeof updates.desc !== 'undefined' && intent.todo.type === 'activity') {
      other.desc = updates.desc;
      delete updates.desc;
    }

    for (const key of Object.keys(other)) {
      updates[`intents.${intent.scheduleId}.${key}`] = other[key];
    }

    return await updateRemoteDocument(collection, intent.todo.id, updates);
  },
  status: {
    path: collection,
    type: 'write',
    parameters: (intent, _changes) => [ intent.id ],
  }
});

export default updateIntent;
