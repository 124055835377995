import { replaceAll } from '@lifetools/shared-utils';
import { pathEncodings } from './pathEncodings';

/**
 * Encodes the `element` so it can be used as a single path element within a Firestore document
 * path.
 *
 * @param element - The element to encode
 *
 * @returns The encoded element
 */
export function encodeDocumentPathElement(element?: string | null) {
  if (typeof element === 'undefined') {
    return '#undefined';
  } else if (element === null) {
    return '#null';
  }

  // Encode any % characters, since we'll be it as a special character in our encoding
  let result = replaceAll(element, '%', '%25');

  for (const char in pathEncodings) {
    if (result.indexOf(char) !== -1) {
      result = replaceAll(result, char, pathEncodings[char]);
    }
  }

  return result;
}
