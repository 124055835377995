/**
 * Converts an object map that maps keys to objects into an array of objects, where the key has been
 * added to each object.
 *
 * @param object     - An object map
 * @param idProperty - The name of the property to use for the key in the returned objects
 *
 * @returns An array of the objects in the object map, with the key of each object added
 */
export const objectMapToArray = (
  object: { [id: string]: any } = {},
  idProperty: string = 'id'
): object[] => (
  Object.keys(object).map(key => ({
    [idProperty]: key,
    ...object[key]
  }))
);
