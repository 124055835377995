import { toLocalTime } from '@lifetools/shared-utils-time';
import selectTodosBySchedule from 'services/store/todos/selectors/selectTodosBySchedule';

// TODO: Figure out a way to coerce the type when we load the data, not when it is selected.
//       Currently the schema requires a fixed path to the property to coerce it, so paths with
//       arbitrary keys (in this case, the intent ID), can't be coerced upon load. [twl 7.Sep.18]
function coerceTypes(intent) {
  if (!intent) {
    return intent;
  }

  const newIntent = {};

  if (intent.hasOwnProperty('start')) {
    newIntent.start = intent.start && toLocalTime(intent.start);
  }

  return {
    ...intent,
    ...newIntent,
  };
}

function getIntent(todo, schedule) {
  const scheduleId = schedule && schedule.id;
  const intent = coerceTypes(todo.intents && todo.intents[scheduleId]);

  return {
    id: `${scheduleId}_${todo.id}`,
    ...intent,
    scheduleId,
    schedule,
    todo,
  };
}

const selectIntentsBySchedule = (state, schedule, includeArchived = false) => (
  selectTodosBySchedule(state, schedule, includeArchived).map(todo => getIntent(todo, schedule))
);

export default selectIntentsBySchedule;
