import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Icon from 'components/library/Icon';
import Typography from 'components/library/Typography';

const styles = theme => ({
  root: {
    background: '#333',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing.unit,
  },
  button: {
    color: '#fff',

    '&:disabled': {
      color: '#333',
    }
  },
  label: {
    flex: '1 1 auto',
    textAlign: 'center',
  },
});

const CarouselNavigator = ({ classes, labels = [], index = 0, onChangeIndex }) => (
  <AppBar className={classes.root} position="static" color="default">
    <Button
      data-action="back"
      className={classes.button}
      size="small"
      disabled={index === 0}
      onClick={() => onChangeIndex(index - 1)}>

      <Icon type="fas:chevron-left" />
    </Button>

    <Typography className={classes.label} variant="subtitle1" color="inherit">
      {labels[index]}
    </Typography>

    <Button
      data-action="next"
      className={classes.button}
      size="small"
      disabled={index === labels.length - 1}
      onClick={() => onChangeIndex(index + 1)}>

      <Icon type="fas:chevron-right" />
    </Button>
  </AppBar>
);

export default withStyles(styles)(CarouselNavigator);
