import mapValues from 'lodash/mapValues';
import { boolean, date, number, object, string } from 'yup';
import validateSecurePassword from 'utils/data/validateSecurePassword';

/**
 * Returns a compiled YUP schema that can be used to validate the format and values in an object
 * based on a custom definition format.
 *
 * The definition format is an object that maps property names to property definitions, where each
 * property definition can contain:
 *
 * type
 * : The type of this property: `string`, `number`, `boolean`, `date`
 *
 * validation
 * : An array of validation rules to use to validate this property.
 *
 *
 * ### Validation Rules
 *
 * Valiations rules can be either an object defining a complex rule or a string defining a simple
 * rule.
 *
 * Properties for complex rules include:
 *
 * type
 * : The type of validation rule
 *
 * name
 * : The name of the validation rule. If missing, the `type` will be used.
 *
 * Other properties are passed through as options to the rule itself.
 *
 * Supported rules include:
 *
 * email
 * : Ensures the value matches an e-mail format
 *
 * oneOf
 * : Ensures the value matches one of the values in an array. The `values` option must be passed
 *   containing an array of values to test against.
 *
 * required
 * : Ensures a non-empty value exists
 *
 * securePassword
 * : Ensures the value is a secure password
 *
 * test
 * : Executes a function to determine if the value validates. The `test` option must be passed
 *   containing the function used to test the value, in the form `value => return boolean`.
 *
 * @param {object}   definition A plain object definition of the schema
 * @param {Function} t          The function used to lookup translations
 * @param {string}   i18nPath   The path to the translations for the definition
 *
 * @return {object} A compiled YUP schema
 */
function compileSchema(definition, t, i18nPath) {
  return object().shape(
    mapValues(definition, (propertyDefinition, name) =>
      compilePropertySchema(name, propertyDefinition, t, i18nPath)
    )
  );
}

/**
 * Returns the compiled definition for a schema property.
 *
 * @param {string}   name       The name of the property
 * @param {object}   definition A plain object definition for the property
 * @param {Function} t          The function used to lookup translations
 * @param {string}   i18nPath   The path to the translations for the definition
 *
 * @return {object} A YUP schema object
 */
function compilePropertySchema(name, definition, t, i18nPath) {
  let schema = getPropertyType(definition.type);

  if (definition.validation) {
    schema = definition.validation.reduce((schema, rule) => (
      addValidationRule(schema, rule, getMessage(name, rule, t, i18nPath))
    ), schema);
  }

  return schema;
}

/**
 * Returns the validation message for validation `rule` of the `name` property, using the `t` and
 * `i18nPath` to lookup any translations.
 *
 * @param {string}   name       The name of the property
 * @param {string}   rule       The name of the validation rule
 * @param {Function} t          The function used to lookup translations
 * @param {string}   i18nPath   The path to the translations for the definition
 *
 * @return {string} A translated validation message
 */
function getMessage(name, rule, t, i18nPath) {
  const type = typeof rule === 'string' ? rule : rule.name || rule.type;
  const fieldPath = `${i18nPath}.fields.${name}`;
  const label = t(fieldPath, 'label');

  return t(fieldPath, `validation.${type}`, { label, name });
}

/**
 * Returns the YUP schema object type for the corresponding `type`.
 *
 * @param {string} type The type of the property
 *
 * @return {object} A YUP schema object type
 */
function getPropertyType(type) {
  switch (type) {
    case 'string':
      return string();

    case 'number':
      return number();

    case 'boolean':
      return boolean();

    case 'date':
      return date();

    default:
      throw new Error(`Unknown schema type: ${type}`);
  }
}

/**
 * Appends the named validation rule to the `schema` using the `message` to display when the
 * validation rule fails.
 *
 * @param {opbject} schema  The YUP schema object to add the validation rule to
 * @param {string}  rule    The name of the rule
 * @param {string}  message The validation message
 */
function addValidationRule(schema, rule, message) {
  const type = typeof rule === 'string' ? rule : rule.type;

  switch (type) {
    case 'email':
      return schema.email(message);

    case 'required':
      return schema.required(message);

    case 'oneOf':
      return schema.oneOf(rule.values, message);

    case 'test':
      return schema.test(rule.name, message, rule.test);

    case 'securePassword':
      return schema.test('securePassword', message, validateSecurePassword);

    case 'trim':
      return schema.trim();

    default:
      throw new Error(`Unknown validation rule: ${rule}`);
  }
}

export default compileSchema;
