import { services } from '@lifetools/shared-services';
import { getDocumentReference } from '../documents/getDocumentReference';
import { IDocument } from '../documents/types/IDocument';
import { DocumentPath } from '../documents/types/DocumentPath';
import { addQuery } from '../queries/utils/addQuery';
import { parseIntoSubqueries } from '../queries/utils/parseIntoSubqueries';
import { ICollectionSetRequest } from './types/ICollectionSetRequest';
import { IDocumentSetRequest } from './types/IDocumentSetRequest';
import { IPathSetRequest } from './types/IPathSetRequest';

async function doPathSetRequest(request: IPathSetRequest): Promise<IDocument[]> {
  const docs = [];

  for (const path of request.paths) {
    const docRef = getDocumentReference(path);
    const doc = await docRef.get() as IDocument;          // Currently mimicking the same interface

    if (doc) {
      docs.push(doc);
    }
  }

  return docs;
}

async function doCollectionSetRequest(request: ICollectionSetRequest): Promise<IDocument[]> {
  let results: IDocument[] = [];

  if (request.query) {
    const subqueries = parseIntoSubqueries(request.query);

    for (const subquery of subqueries) {
      const baseRef = services.database.collection(request.collection);
      const queryRef = addQuery(baseRef, subquery);
      const querySnapshot = await queryRef.get();

      results = [
        ...results,
        ...querySnapshot.docs,
      ];
    }
  } else {
    const baseRef = services.database.collection(request.collection);
    const collectionSnapshot = await baseRef.get();

    results = [ ...collectionSnapshot.docs ];
  }

  return results;
}

async function requestDocuments(request: IDocumentSetRequest): Promise<IDocument[]> {
  switch (request.type) {
    case 'pathSet':
      return await doPathSetRequest(request);
      break;

    case 'collectionSet':
      return await doCollectionSetRequest(request);
      break;
  }
}

export default requestDocuments;
