import React from 'react';
import { compose } from 'recompose';
import classnames from 'classnames';
import Paper from 'components/library/Paper';
import Typography from 'components/library/Typography';
import withContext from 'helpers/withContext';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import StartWorkflowButton from 'widgets/schedules/StartWorkflowButton';

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,
    textAlign: 'center',
  },
  rootFlat: {
    border: `solid 1px ${theme.palette.grey['400']}`,
  },
  title: {
    marginBottom: theme.spacing.unit * 4,
  },
  image: {
    maxHeight: 300,
    maxWidth: '100%',
    border: `solid 1px ${theme.palette.grey['400']}`,

    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  button: {
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing.unit * 2,
  },
  message: {
    margin: `${theme.spacing.unit * 4}px 0`,
  },
});

const DailyPlanInfoCard = ({
  i18nPath,
  className,
  variant,
  date,
  workflow,
  existingSchedule,
  activePlanType,
  isAccountReadOnly,
  classes,
  t,
}) => (
  <Paper
    className={classnames(classes.root, variant === 'flat' && classes.rootFlat, className)}
    elevation={variant === 'flat' ? 0 : undefined}
    data-flow={`PlanInfo:${workflow}`}
  >
    <Typography className={classes.title} variant="h2">
      {t(i18nPath, 'title')}
    </Typography>

    <Typography variant="h4" className={classes.message}>
      {t(i18nPath, 'benefit')}
    </Typography>

    <img className={classes.image} src={`/images/app/${t(i18nPath, 'image')}`} alt="" />

    <Typography variant="subtitle1" className={classes.message}>
      {t(i18nPath, 'message')}
    </Typography>

    {!isAccountReadOnly &&
      <StartWorkflowButton
        className={classes.button}
        workflow={workflow}
        existingSchedule={existingSchedule}
        activePlanType={activePlanType}
        date={date}
      />
    }
  </Paper>
);

export default compose(
  withContext('isAccountReadOnly'),
  withStyles(styles),
  withTranslations,
)(DailyPlanInfoCard);
