import { LocalDate, LocalTime } from 'js-joda';
import { ISchedule } from '@lifetools/shared-app-data';
import { toLocalTime } from '@lifetools/shared-utils-time';
import { minutesBetween } from './minutesBetween';

/**
 * Returns the number of minutes remaining on the `schedule`, as calculated on the specific `date`
 * at the specific `time`, rounding the result to `roundMinutesTo`.
 *
 * @param schedule       - The schedule
 * @param date           - The date reference used to calculate the remaining minutes
 * @param time           - The time reference used to calculate the remaining minutes
 * @param roundMinutesTo - The number of minutes to round the time to
 *
 * @returns The number of minutes left until the end of the schedule, or 0 if the schedule has
 *          already ended for the `date`
 */
export const minutesLeft = (
  schedule: ISchedule,
  date: LocalDate,
  time: LocalTime,
  roundMinutesTo: number,
) => {
  const isToday = LocalDate.now().equals(date);
  const endTime = (schedule.endTime && toLocalTime(schedule.endTime)) || time;

  return (
    LocalDate.now().isAfter(date) && time.isAfter(endTime)
      ? 0
      : minutesBetween(time, endTime, isToday && schedule.startDayOffset === 0, roundMinutesTo)
  );
}
