import React from 'react';
import { Field as FormikField } from 'formik';
import withTranslations from 'helpers/withTranslations';
import ErrorMessage from '../ErrorMessage';

const renderValue = ({ name, i18nPath, t }, value, i18nName, defaultValue) => (
  value != null
    ? value
    : t(i18nPath, name + `.${i18nName}`,
        typeof defaultValue !== 'undefined' ? { defaultValue } : undefined)
);

const MaterialUiFieldComponent = ({
  i18nPath,
  field,
  form,
  label,
  header,
  helperText,
  placeholder,
  showIf,
  formatter = (value, values) => value,
  disabled = false,
  useSetFieldValue = false,
  useSetFieldTouched = false,
  useI18nPath = false,
  muiComponent: MuiComponent,
  defaultValue,
  InputLabelProps,
  t,
  tReady: _ignore,                    // Ensure not in ...props to fix warning from `MuiComponent`
  i18n,                               // Ignored -- prevents being output on input
  ...props
}) => {
  const { name, value, onChange, onBlur } = field;
  const { values, touched, errors, submitCount, isSubmitting, setFieldValue, setFieldTouched } = form;
  const context = { name, i18nPath, t };
  const renderPlaceholder = renderValue(context, placeholder, 'placeholder', '');
  const renderLabel = renderValue(context, label, 'label', null);
  const renderHeader = renderValue(context, header, 'header', null);
  const renderHelperText = renderValue(context, helperText, 'hint', '');
  const hide = showIf != null && !showIf(values);

  // NOTE: React will throw a warning about `i18nPath` not being a DOM attribute, even if its value
  //       is `undefined`. Instead, we need to not even include it in the keys unless it's needed.
  //       [twl 3.Dec.18]
  const otherProps = useI18nPath ? { i18nPath: i18nPath + '.' + name } : undefined;


  // HACK: Formik doesn't automatically touch every field if the field is not defined in the
  //       `initialValues`, so we need to look at the submit count to determine whether we should
  //       display the error.  For more info see https://github.com/jaredpalmer/formik/issues/738
  //       [twl 4.Dec.18]
  const isTouched = touched[name] != null || (submitCount > 0 && errors[name] != null);

  return hide ? null : (
    <MuiComponent
      {...props}
      {...field}
      {...otherProps}
      value={formatter(value != null ? value : defaultValue, values)}
      label={renderLabel}
      header={renderHeader}
      placeholder={renderPlaceholder !== '' ? renderPlaceholder : undefined}
      error={isTouched && !!errors[name]}
      helperText={
        isTouched && errors[name] ? <ErrorMessage value={errors[name]} /> : renderHelperText
      }
      FormHelperTextProps={{
        component: 'div'
      }}
      disabled={isSubmitting || disabled}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      onChange={useSetFieldValue ? value => setFieldValue(name, value) : onChange}
      onBlur={useSetFieldTouched ? () => setFieldTouched(name) : onBlur}
    />
  );
};

const MaterialUiField = props => (
  <FormikField {...props} component={withTranslations(MaterialUiFieldComponent)} />
);

export default MaterialUiField;
