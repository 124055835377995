import React, { useState } from 'react';
import { compose } from 'recompose';
import ViewLayout from 'components/layouts/ViewLayout';
import withDeviceInfo from 'helpers/withDeviceInfo';
import withStyles from 'helpers/withStyles';
import { toRouteDate } from 'utils/app/routes/toRouteDate';
import PlanTypeHelp from '../PlanTypeHelp';
import WhichPlanDate from './components/WhichPlanDate';
import WhichPlanType from './components/WhichPlanType';

const styles = theme => ({
  survey: {
    padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,

    '& > *:first-child': {
      marginBottom: theme.spacing.unit * 6,

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing.unit * 2,
      },
    }
  },
  desktopHelp: {
    margin: theme.spacing.unit * 4,
  },
});

// Must be a separate component, otherwise React complains about `viewColumn` being set on a `div`
const DatePlanTypeSurvey = ({ routeDateParam, date, schedule, onShowHelp, classes }) => (
  <div className={classes.survey}>
    <WhichPlanDate planDate={toRouteDate(routeDateParam)} />

    {routeDateParam &&
      <WhichPlanType date={date} onShowHelp={onShowHelp} />
    }
  </div>
);

const NoTodayViewSurveyWithButtons = ({ classes, isMobileDevice, ...props }) => {
  const [showingHelp, setShowingHelp] = useState(false);

  return isMobileDevice && showingHelp
    ? <PlanTypeHelp closeButtons="both" onClose={() => setShowingHelp(false)} />
    : <ViewLayout layout="split">
        <DatePlanTypeSurvey
          {...props}
          classes={classes}
          viewColumn={1}
          onShowHelp={() => setShowingHelp(true)}
        />

        {!showingHelp ? null :
          <PlanTypeHelp className={classes.desktopHelp} onClose={() => setShowingHelp(false)} />
        }
      </ViewLayout>;
}

export default compose(
  withStyles(styles),
  withDeviceInfo,
)(NoTodayViewSurveyWithButtons);
