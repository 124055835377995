import get from 'lodash/get';
import { toNativeDate } from '@lifetools/shared-utils-time';
import { callFunction } from 'services/server/data';
import store from 'services/store';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { collection, types } from 'services/store/todos/definitions';
import firstRunOrAfterOldFailure from 'services/store/utils/actions/firstRunOrAfterOldFailure';

const parseQuery = (query) => ({
  ...query,
  startDate: query.startDate && toNativeDate(query.startDate).toISOString(),
  endDate: query.endDate && toNativeDate(query.endDate).toISOString(),
});

const importEvents = makeAsyncActionCreator({
  type: types.SET_TODOS,
  when: firstRunOrAfterOldFailure,
  creator: async query => ({
    todos: await callFunction('calendars_importEvents', parseQuery(query))
  }),
  status: {
    path: collection,
    type: 'read',
    parameters: query => {
      const integration = get(selectUserSettings(store.getState()), 'calendars.config.integration');

      // If the integration changes, we need to re-execute this function
      return [ integration, query ];
    },
  }
});

export default importEvents;
