import owasp from 'owasp-password-strength-test';

owasp.config({
  allowPassphrases       : true,
  maxLength              : 128,
  minLength              : 8,
  minPhraseLength        : 20,
  minOptionalTestsToPass : 4,
});

function securePasswordCheck(password) {
  const result = owasp.test(password);

  return result.errors.length > 0 ? result.errors : undefined;
}

export default securePasswordCheck;
