import React from 'react';

const SchedulerCell = ({ record, column, rowIndex }) => {
  if (column.name === 'intent' && !record.span) {
    return null;
  }

  const style = column.name === 'intent' ? { gridRowEnd: `span ${record.span}` } : undefined;
  const state = column.state ? column.state(record) : undefined;
  const fill = column.fill ? column.fill(record) : undefined;

  return (
    <div key={`${rowIndex}:${column.name}`} className={column.className} style={style} data-has={state}>
      {fill != null &&
        <div className={column.fillClass} style={{ height: `${fill * 100}%` }} />
      }
      {column.render({ record })}
    </div>
  );
}

export default SchedulerCell;
