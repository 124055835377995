import get from 'lodash/get';
import React from 'react';
import { compose, pure } from 'recompose';
import classnames from 'classnames';
import { isSnoozed, wasSnoozed } from '@lifetools/shared-app-data';
import ListSettingsInfo from 'components/app/common/ListSettingsInfo';
import TodoEditDialog from 'components/app/todos/TodoEditDialog';
import ActionBar from 'components/library/ActionBar';
import Selector from 'components/library/Selector';
import Input from 'components/library/Input';
import parseRepeatType from 'utils/todos/parseRepeatType';
import TodoListWidget from 'widgets/todos/TodoList';
import withContext from 'helpers/withContext';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import { useSavedState } from 'hooks/useSavedState';
import nextScheduledIntent from 'utils/intents/nextScheduledIntent';
import { parseTagsInTitle } from 'utils/todos/parseTagsInTitle';
import { sortHideStatus } from './sortHideStatus';

const saveDelay = 1000;

const styles = theme => ({
  searchPane: {
    // HACK TODO: Figure out why search pane resizes when too many items are filtered and remove
    //            this. [twl 20.Feb.19]
    maxHeight: 48,
  },
  searchInput: {
    margin: theme.spacing.unit * 2,
  },
  optionPane: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
});

const statusSort = {
  property: 'status.state',
  order: [ 'new', 'intent', 'doing', 'defer', 'delegate', 'done', 'wontdo' ],
};

const sortByDefinitions = {
  newestFirst: 'createdAt desc',
  oldestFirst: 'createdAt',
  snoozedUntil: sortHideStatus,
  status: statusSort,
  tagsStatus: statusSort,                            // group defined below via `groupBy`
  title: 'title',
};

const isScheduled = todo =>
  !['done', 'wontdo'].includes(get(todo, 'status.state')) && nextScheduledIntent(todo) != null;

/**
 * TODO: Think about moving these up into a higher level component, since these are specific to the
 *       scenes that are using this component. [twl 20.Feb.19]
 */
const filterDefinitions = {
  // For Tasks
  done: todo => todo.status.state === 'done',
  inProgress: todo => todo.status.state === 'doing',
  notDone: todo => todo.status.state !== 'done',
  notStarted: todo => todo.status.state === 'new' || todo.status.state === 'intent',
  wontDo: todo => todo.status.state === 'wontdo',
  isSnoozed: todo => isSnoozed(todo, undefined),      // Wrap to make sure `date` param uses default
  wasSnoozed,
  neverSnoozed: todo => get(todo, 'status.hide.date') == null,
  isScheduled: todo => isScheduled(todo),
  notScheduled: todo => !isScheduled(todo),

  // For Activities
  daily: todo => parseRepeatType(todo.repeat) === 'daily',
  weekDays: todo => parseRepeatType(todo.repeat) === 'weekDays',
  weekendDays: todo => parseRepeatType(todo.repeat) === 'weekendDays',
  never: todo => parseRepeatType(todo.repeat) === 'never',
  hasDuration: todo => todo.defaultDuration != null,
  noDuration: todo => todo.defaultDuration == null,
};

function searchTodo(todo, query) {
  const searchTitle = query.title && !['', '#'].includes(query.title);
  const searchTags = query.tags.length && todo.tags && todo.tags.length > 0;

  return (searchTitle && todo.title.toLowerCase().indexOf(query.title) !== -1) ||
    (searchTags &&
      todo.tags.find(tag => query.tags.find(queryTag => tag.toLowerCase().startsWith(queryTag))));
}

const TodoMaster = ({
  i18nPath,
  defaultType,
  variant,
  query,
  settings,
  sortOptions,
  filterOptions,
  onCreate,
  onUpdateSettings,
  onResetSettings,
  isAccountReadOnly,
  classes,
  t,
  ...props
}) => {
  const [search, setSearch] = useSavedState(settings.search, onUpdateSettings, 'search', saveDelay);
  const searchQuery = search && parseTagsInTitle({ title: search.toLowerCase() });
  const searchFunc = search && (todo => searchTodo(todo, searchQuery));
  const filterFunc = filterDefinitions[settings.filter];
  const fullFilter = filterFunc && searchFunc ? todo => filterFunc(todo) && searchFunc(todo)
                                              : filterFunc || searchFunc;
  const boxActions = [];

  if (sortOptions) {
    boxActions.push(
      <ActionBar.Button i18nPath={i18nPath} action="sort" pane={
        <ActionBar.Pane className={classes.optionPane}>
          <Selector
            i18nPath={i18nPath + '.sortBy'}
            options={sortOptions}
            value={settings.sort}
            onChange={value => onUpdateSettings('sort', value)}
          />
        </ActionBar.Pane>
      } />
    );
  }

  if (filterOptions) {
    boxActions.push(
      <ActionBar.Button i18nPath={i18nPath} action="filter" pane={
        <ActionBar.Pane className={classes.optionPane}>
          <Selector
            i18nPath={i18nPath + '.filter'}
            options={filterOptions}
            value={settings.filter}
            onChange={value => onUpdateSettings('filter', value)}
          />
        </ActionBar.Pane>
      } />
    );
  }

  boxActions.push(
    <ActionBar.Button i18nPath={i18nPath} action="search" pane={
      <ActionBar.Pane className={classnames(classes.optionPane, classes.searchPane)}>
        <Input
          className={classes.searchInput}
          placeholder={t(i18nPath, 'search.placeholder')}
          value={search}
          onChange={e => setSearch(e.target.value)}
          fullWidth
        />
      </ActionBar.Pane>
    } />
  );

  if (onCreate && !isAccountReadOnly) {
    boxActions.push(
      <ActionBar.Button i18nPath={i18nPath} action="add" pane={
        <TodoEditDialog defaultType={defaultType} onSave={onCreate} />
      } />
    );
  }

  return (
    <TodoListWidget
      {...props}
      i18nPath={i18nPath}
      variant={variant}
      query={query}
      sortBy={sortByDefinitions[settings.sort]}
      groupBy={settings.sort === 'tagsStatus' ? 'tags' : undefined}
      filter={fullFilter}
      boxActions={boxActions}
      header={
        <ListSettingsInfo
          i18nPath={i18nPath}
          settings={{
            ...settings,
            search,
          }}
          onReset={() => onResetSettings()}
        />
      }
    />
  );
};

export default pure(
  compose(
    withTranslations,
    withStyles(styles),
    withContext('isAccountReadOnly'),
  )(TodoMaster)
);
