import React from 'react';
import classnames from 'classnames';
import { toLocalDate } from '@lifetools/shared-utils-time';
import ActionButton from 'components/app/todos/ActionButton';
import withStyles from 'helpers/withStyles';
import { isScheduled } from './isScheduled';

const lightenColor = (theme, color) => theme.palette.augmentColor({ main: color }).light;

const statusStyle = (theme, state) => {
  const hoverColor = theme.palette.custom.statuses[state];
  const defaultColor = lightenColor(theme, lightenColor(theme, hoverColor));

  return {
    background: defaultColor,

    '&:hover': {
      background: hoverColor,         // needs to be explicitly specified to override default hover
    },
  };
}

const styles = theme => ({
  root: {
    height: '60px',
    display: 'flex',

    '& > *': {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  new: statusStyle(theme, 'new'),
  done: statusStyle(theme, 'done'),
  'done-todo': statusStyle(theme, 'done'),
  missed: {
    background: theme.palette.custom.statuses.missed,            // use default hover for this one
  },
  notScheduled: statusStyle(theme, 'notScheduled'),
  statusButton: {
    padding: 0,
  },
});

const calculateBackground = (date, todo, intent) => {
  if (intent && ['new', 'done', 'done-todo'].includes(intent.status.state)) {
    return intent.status.state;
  }

  return isScheduled(date, todo.repeat) ? 'missed' : 'notScheduled';
}

const IntentDay = ({ className, value, todo, intents, overrideIntent, classes }) => {
  const date = toLocalDate(value);
  const intent = overrideIntent || intents.find(intent => date.equals(intent.date));
  const background = calculateBackground(date, todo, intent);

  return (
    <div className={classnames('rbc-day-bg', classes.root, classes[background], className)}>
      {intent &&
        <ActionButton
          className={classes.statusButton}
          actionMode="statusReadOnly"
          value={todo}
          intent={intent}
        />
      }
    </div>
  );
}

export default withStyles(styles)(IntentDay);
