import React from 'react';
import PropTypes from 'prop-types';
import { buildScene } from 'scenes/utils';
import Page from 'components/app/navigation/Page';
import { FeatureFlags } from 'contexts/FeatureFlags';
import withDeviceInfo from 'helpers/withDeviceInfo';
import { withSchedule } from 'helpers/withSchedule';
import { formatLocalDate } from 'utils/time';
import ScheduleView from '../ScheduleView';

const i18nPath = 'scenes.todos.Schedules.components.SchedulesDetail';

const SchedulesDetail = buildScene(({
  date,
  schedule,
  scheduleStatus,
  intents,
  t,
  newMobileUi,
 }) => (
  <Page
    title={t(i18nPath, 'title')}
    subtitle={!newMobileUi ? formatLocalDate(date, t(i18nPath, 'date-format')) : undefined}
    backTo={`/plans/${date}`}>

    <ScheduleView
      date={date}
      schedule={schedule}
      scheduleStatus={scheduleStatus}
      intents={intents} />

  </Page>
));

SchedulesDetail.propTypes = {
  /** The date of the schedule to load and view */
  date: PropTypes.object.isRequired,

  /** The schedule. Provided by `withSchedule` HOC. */
  schedule: PropTypes.object,

  /** The loading status of the schedule. Provided by `withSchedule` HOC. */
  scheduleStatus: PropTypes.object,

  /** The intents for this schedule. Provided by `withSchedule` HOC. */
  intents: PropTypes.arrayOf(PropTypes.object),

  /** A function used to provide translations of text & UI elements. Provided by `buildScene`. */
  t: PropTypes.func,
};

/**
 * Temporary wrapper that alters the props used based on a feature flag.
 */
const SchedulesDetailWrapper = withDeviceInfo(({ isMobileDevice, ...props }) => (
  <FeatureFlags.Consumer>
    {flags =>
      <SchedulesDetail
        {...props}
        newMobileUi={isMobileDevice && flags.stackedMobileUi}
      />
    }
  </FeatureFlags.Consumer>
));

export default withSchedule(SchedulesDetailWrapper, 'date', true);
