import { QueryOperator } from '../types/QueryOperator';

type QueryOperatorLookup = {
  [K in QueryOperator]?: string;
}

export const queryOperators: QueryOperatorLookup = {
  greaterThan: '>',
  greaterThanOrEqual: '>=',
  lessThan: '<',
  lessThanOrEqual: '<=',
  after: '>',
  afterOrOn: '>=',
  before: '<',
  beforeOrOn: '<=',
  contains: 'array-contains',
  //  not: null,                      // no lookup value exists; special processing required
};
