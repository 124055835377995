import { services } from '@lifetools/shared-services';
import { IDocumentData } from '../documents/types/IDocumentData';
import { Id } from '../documents/types/Id';
import { DataOperationType } from '../operations/types/DataOperationType';
import TriggerContext from './TriggerContext';
import { DocumentDataFetcher } from './types/DocumentDataFetcher';
import { TriggerStage } from './types/TriggerStage';
import isTriggered from './utils/isTriggered';

/**
 * Calls all of the registered triggers that apply for the given `stage`, `operation` and
 * `collection`.
 *
 * @param stage      - The current stage of the data pipeline, e.g. `before`, `after`
 * @param operation  - The current operation being performed, e.g. `update`, `delete`
 * @param collection - The collection the operation is being performed against
 * @param id         - The ID of the document, or `undefined` if a new document is being added or
 *                     the operation applies to multiple documents
 * @param changes    - The changes being saved to the document, or `undefined` if this doesn't apply
 * @param before     - The existing stored data or a function used to return the data when passed
 *                     `(collection, id)`
 *
 * @returns The updated changes to apply, or `undefined` if no changes should be applied for this
 *          operation.
 */
export async function callTriggers(
  stage: TriggerStage,
  operation: DataOperationType,
  collection: string,
  id?: Id,
  changes?: IDocumentData,
  before?: IDocumentData | DocumentDataFetcher,
): Promise<IDocumentData | undefined> {
  const rules = services.data.triggers.filter(rule => (
    isTriggered(rule, stage, operation, collection)
  ));

  for (const rule of rules) {
    const context = new TriggerContext(stage, operation, collection, id, changes, before);

    await rule.func(context);

    changes = context.changes.values;
  }

  return changes;
}
