import React from 'react';
import MuiSelect from '@material-ui/core/Select';
import MuiMenuItem from '@material-ui/core/MenuItem';
import withTranslations from 'helpers/withTranslations';

// NOTE: `tReady` is passed by `withTranslations`, but is not used. Including it in the parameter
//       list so it is not included in `props`. [twl 20.Feb.19]
const Selector = ({ i18nPath, options, value, MenuItemProps, onChange, t, tReady, ...props }) => (
  <MuiSelect
    {...props}
    value={value || options[0].value}
    onChange={e => onChange(e.target.value)}>

    {options.map(option => {
      const value = typeof option === 'object' ? option.value : option;
      const label = typeof option === 'object' && option.label ? option.label :
                                                                 t(i18nPath + '.options', value);

      return (
        <MuiMenuItem {...MenuItemProps} key={value} value={value}>{label}</MuiMenuItem>
      );
    })}

  </MuiSelect>
);

export default withTranslations(Selector);
