import React, { Component, Fragment } from 'react';
import { compose } from 'recompose';
import StatusSelector from 'components/app/todos/StatusSelector';
import TodoType from 'components/app/todos/TodoType';
import Form from 'components/library/Form';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';
import intentText from 'utils/intents/intentText';
import { parseTagText } from 'utils/todos/parseTagText';

const fieldsI18nPath = 'data.intents.fields';

const styles = theme => ({
  splitRow: {
    display: 'flex',

    '& > *': {
      display: 'inline-block',
      flex: '1 0 50%',
    },
  },
  readonly: {
    '& > div': {
      color: theme.palette.grey['600'],
    },

    '& > div:hover': {
      border: 'none',
    },
    '& > div::before': {
      border: 'none !important',        // Use important to override complex & changeable selector
    },
    '& > div::after': {
      border: 'none',
    },
  },
});

const IntentFormStatusSelector = ({ value, ...props }) => (
  <StatusSelector todo={value.todo} intent={value} {...props} />
);

class IntentEditForm extends Component {
  // HACK: Currently there is no way for the form to return only the field that changed. Until we
  //       add this, the `editFields` static prop is used by the `TodoTableEditor` to only save
  //       these fields as the "changes". [twl 13.Oct.18]
  //
  // TODO: Add changes functionality to the Formik form, then remove this hack. [twl 13.Oct.18]
  static editFields = [ 'todo.title', 'todo.desc', 'todo.tags', 'duration' ];

  /**
   * The path to the translation keys for this form.
   */
  static i18nPath = 'components.app.intents.IntentEditDialog.components.IntentEditForm';

  /**
   * Parses the `intent` into a flat object containing the values for the form.
   *
   * @param {object} intent a data object
   *
   * @returns {object} a form object
   */
  static toFormValues = intent => {
    const { duration, todo } = intent;
    const { title, type, tags } = todo;

    return {
      intent,
      type,
      duration,
      title,
      desc: todo.type === 'activity' ? (intent.desc || todo.desc) : todo.desc,
      tagText: tags ? tags.join(', ') : '',
    };
  };

  /**
   * Parses the values returned from the form into a data object.
   *
   * @param {object} values a form object
   *
   * @returns {object} a data object
   */
  static fromFormValues = ({ title, desc, duration, intent, tagText }) => ({
    ...intent,
    duration,
    todo: {
      ...intent.todo,
      title,
      desc,
      tags: parseTagText(tagText),
    }
  });

  /**
   * Renders the fields for this form. The `value` will be provided by the form this is inserted
   * into.
   */
  render() {
    const { classes, t } = this.props;

    return (
      <Fragment>
        <Form.TextField i18nPath={fieldsI18nPath} name="title" fullWidth autoFocus />
        <Form.TextField i18nPath={fieldsI18nPath} name="tagText" fullWidth />

        <div className={classes.splitRow}>
          <Form.GenericField
            className={classes.readonly}
            i18nPath={fieldsI18nPath}
            name="status"
            formatter={(_value, values) => values.intent}
            InputComponent={IntentFormStatusSelector}
          />

          <Form.GenericField
            className={classes.readonly}
            i18nPath={fieldsI18nPath}
            name="type"
            InputComponent={TodoType}
          />
        </div>

        <div className={classes.splitRow}>
          <Form.TextField
            className={classes.readonly}
            i18nPath={fieldsI18nPath}
            name="date"
            formatter={(_value, values) => intentText(values.intent, 'relativeDateTime', t)}
            InputProps={{ readOnly: true }}
          />

          <Form.DurationField i18nPath={fieldsI18nPath} name="duration"/>
        </div>

        <Form.TextField i18nPath={fieldsI18nPath} name="desc" multiline fullWidth rowsMax="10" />
      </Fragment>
    );
  }
}

export default compose(
   withTranslations,
   withStyles(styles),
)(IntentEditForm);
