import { LocalDate as JodaLocalDate, LocalTime as JodaLocalTime } from 'js-joda';
import { LocalTime, toLocalDate, toLocalTime } from '@lifetools/shared-utils-time';
import { services } from '@lifetools/shared-services';
import { IDocumentData } from '../types/IDocumentData';
import { IDocumentSchema } from '../types/IDocumentSchema';

/**
 * Coerces the `value` to the `type`.
 *
 * @param value - The value to coerce
 * @param type  - The JavaScript class to coerce the value to
 *
 * @returns The value whose instance is the type `type`
 */
function coerceValue(value: any, type: any): any {
  if (type === JodaLocalTime) {
    return toLocalTime(value);
  } else if (type === JodaLocalDate) {
    return toLocalDate(value);
  } else if (type === LocalTime) {
    return new LocalTime(value);
  } else {
    return new type(value);
  }
}

/**
 * Converts the `object` to a new object whose properties have the types specified in `schema`
 *
 * @param data   - The data to be transformed
 * @param schema - A function or schema that specifies how to map properties to the types those
 *                 properties should be coerced to
 *
 * @returns The transformed data, or the original data if schema is not defined
 */
export function applySchema(
  data: IDocumentData | undefined,
  collection: string,
): IDocumentData | undefined {
  if (!data) {
    return data;
  }

  const schema = services.data.schemas[collection];

  if (!schema) {
    return data;
  } else if (typeof schema === 'function') {
    return schema(data);
  }

  const result: IDocumentData = {};

  for (const key of Object.keys(data)) {
    result[key] = schema[key] ? coerceValue(data[key], schema[key]) : data[key];
  }

  return result;
}
