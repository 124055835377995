import times from 'lodash/times';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Icon from 'components/library/Icon';
import './StrengthIndicator.css';

const StrengthTitle = ({ t, optionPath, value }) => (
  <span className="StrengthIndicator_title">{t(`${optionPath}.titles.${value}`)}</span>
);

function getIconStyle(t, optionPath) {
  const style = {};
  const properties = {
    color: 'color',
    spacing: 'marginRight'
  };

  for (const property in properties) {
    const path = `${optionPath}.icon.${property}`;
    const value = t(path);

    if (value !== path) {
      style[properties[property]] = value;
    }
  }

  return style;
}

const StrengthIcons = ({ t, optionPath, value }) => {
  const type = t(`${optionPath}.icon.type`);
  const style = getIconStyle(t, optionPath);
  const icons = type && times(value, (index) => <Icon key={index} type={type} style={style} />);

  return (
    <span className="StrengthIndicator_icons">{icons}</span>
  );
};

// Show can be 'all', 'iconsOnly' or 'titleOnly'
const StrengthIndicator = ({
  t,
  property,
  value,
  show = 'all',
}) => {
  const optionPath = `indicators.${property}`;

  return (
    <div className="StrengthIndicator">
      {show !== 'titleOnly' && <StrengthIcons t={t} optionPath={optionPath} value={value} />}
      {show !== 'iconsOnly' && <StrengthTitle t={t} optionPath={optionPath} value={value} />}
    </div>
  );
};

export default withTranslation('translations')(StrengthIndicator);
