import isPlainObject from 'lodash/isPlainObject';
import { DocumentMetaProperties } from '@lifetools/shared-database';
import { services } from '@lifetools/shared-services';
import { mergePathKeys } from '@lifetools/shared-utils';
import { types as globalTypes } from 'services/store/actions';

/**
 * Returns the status key for the `parameters`.
 *
 * @param {any[]} parameters An array of parameters
 *
 * @return {string} A string key representing those parameters
 */
export function createStatusKey(parameters) {
  return JSON.stringify(parameters, (key, value) => (
    // NOTE: Don't stringify custom classes. Instead use `toString` to represent their value. This
    //       fixes a circular JSON error caused by the new FieldValue.serverTimestamp including a
    //       circular reference. [twl 24.Jun.20]
    typeof value !== 'object' || Array.isArray(value) || isPlainObject(value) || value == null
      ? value
      : value.toString()
  ));
}

/**
 * Sets the `property` of an immutable element in the immutable `array` with the `id` to the
 * `value`.
 *
 * @param {object[]} array    An array of objects
 * @param {number}   id       The ID of the object whose property should be set
 * @param {string}   property The property to set
 * @param {any}      value    The value to set the property to
 *
 * @return {Object[]} A new array with the updated element
 */
export function setProperty(array, id, property, value) {
  return array.map(item => item.id !== id ? item : Object.assign({}, item, { [property]: value }));
}

/**
 * Sets the `property` of the immutable element defined by `payload.id` within the immutable `array`
 * to the value defined by the `property` of `payload`.
 *
 * @param {object[]} array    An array of objects
 * @param {object}   payload  A payload containing the `property` property and an `id`
 * @param {string}   property The property to set
 *
 * @return {Object[]} A new array with the updated element
 */
export function setPayloadProperty(array, payload, property) {
  return setProperty(array, payload.id, property, payload[property]);
}

/**
 * Updates the properties of the immutable element defined by `payload.id` within the immutable
 * `array` to the values defined by the properties of `payload`.
 *
 * @param {object[]} array    An array of objects
 * @param {object}   payload  A payload containing the `property` property and an `id`
 * @param {string}   path     The path to the properties to be merged, or `undefined` to merge all
 *                            properties
 *
 * @return {Object[]} A new array with the updated element
 */
export function updatePayloadProperties(array, payload, path) {
  const { id, ...changes } = payload;

  return array.map(item => item.id !== id ? item : mergePathKeys(item, changes, path));
}

/**
 * Add the `elements` to the `array`, replacing any existing elements that share the same `id`. Any
 * `elements` that don't match existing items in the array are appended to the end of the array.
 * Any elements that have the special `$action` property set to `delete` are removed from the
 * `array` if they exist.
 *
 * @param {array} array    An array of objects with `id` properties
 * @param {array} elements An array of objects with `id` properties to be merged with `array`
 *
 * @return {array} A new array with all of the elements replacing existing elements or added
 */
export function setElements(array, elements) {
  let changesDetected = false;

  const byId = elements.reduce((lookup, element) => {
    // `remove` means it no longer matches a real-time query, which we can ignore for the stores
    if (element[DocumentMetaProperties.SyncAction] !== 'remove') {
      lookup[element.id] = element;
      changesDetected = true;
    }

    return lookup;
  }, {});

  if (!changesDetected) {
    return array;
  }

  const oldElements = array.map(element => {
    if (byId[element.id]) {
      let replaceElement = byId[element.id];

      delete byId[element.id];

      return replaceElement;
    } else {
      return element;
    }
  }).filter(element => element.$action !== 'delete');

  const newElements = elements.filter(element => byId[element.id] && element.$action !== 'delete');

  return [
    ...oldElements,
    ...newElements,
  ];
}

/**
 * Removes the element with the `id` from the `array`.
 *
 * @param {array}  array   An array of objects
 * @param {string} id      The ID of the element or elements to be removed
 *
 * @return {array} A new array containing all the elements from `array` except the element with `id`
 */
export function removeElementById(array, id) {
  return setElements(array, [{ id: id, $action: 'delete' }]);
}

/**
 * Makes the element with the `id` in the `array` as being archived.
 *
 * @param {array}  array   An array of objects
 * @param {string} id      The ID of the element or elements to be achived
 *
 * @return {Object[]} A new array with the updated element
 */
export function archiveElement(array, id) {
  return updatePayloadProperties(array, {
    id,
    [DocumentMetaProperties.Archived]: true,
    [DocumentMetaProperties.ArchivedAt]: services.database.fieldValues.serverTimestamp(),
  });
}

/**
 * Reduce the `state` by applying the `action` using the `actionReducers`.
 *
 * @param {object} actionReducers Maps action types to functions used to reduce the state taking
 *                                the parameters `(state, payload, error, type)`
 * @param {any}    state          The current state
 * @param {object} action         The action to apply
 *
 * @return {any} The new state
 */
export function applyAction(actionReducers, state, action) {
  // TODO: Implement loading and failure statuses. [twl 15.Jun.18]
  return !actionReducers[action.type] || (action.meta && action.meta.status !== 'success') ? state :
            actionReducers[action.type](state, action.payload, action.error, action.meta,
                                        action.type);
}

/**
 * Creates a status reducer for the data at the `path` within the store.
 *
 * Options include:
 *
 * defaultKey
 * : The key to use if the parameters for a status action are undefined
 *
 * @param {string} path    A path within the store
 * @param {object} options Options that define how the status reducer operates
 *
 * @return {Function} A reducer that reacts to global `SET_STATUS` actions when the `path` matches
 *                    the path of the action.
 */
export function createStatusReducer(path, options = {}) {
  return (state = {}, action) => {
    const status = action.payload;

    if (action.type === globalTypes.SET_STATUS && status.path === path) {
      // NOTE: We can't use lodash `get` to look up failures, since the status key starts with `[`,
      //       which is a special character for lodash paths. [twl 9.Oct.18]
      const key = createStatusKey(status.parameters) || options.defaultKey;

      let failures = (state[status.type] && state[status.type][key] &&
                      state[status.type][key].failures) || 0;

      switch (status.state) {
        case 'failure':
          failures = failures + 1;
          break;

        case 'success':
          failures = 0;
          break;

        default:
          // keep same failure count
          break;
      }

      return {
        ...state,
        [status.type]: {
          ...state[status.type],
          [key]: {
            state: status.state,
            error: status.error,
            failures,
            at: Date.now(),
          }
        }
      };
    } else if (
      action.type === globalTypes.LOGOUT ||
      (action.type === globalTypes.CLEAR_ALL_STATUSES && status.path === path)
    ) {
      return {};                                          // clear the status state
    }

    return state;
  }
}
