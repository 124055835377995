import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/library/Typography';
import withTranslations from 'helpers/withTranslations';
import { testProps } from 'utils/test/testProps';

/**
 * Renders a label and a field value.
 */
const ViewField = ({
  i18nPath,
  className,
  show,
  object,
  name,
  label,
  value,
  defaultValue,
  children,
  t
}) => {
  const renderLabel = label != null ? label : t(i18nPath, name + '.label');
  const renderValue = value != null ? value : (object && object[name] != null ? object[name]
                                                                              : defaultValue);

  return show === 'never' || (show === 'hasValue' && !renderValue) ? null : (
    <div className={className} {...testProps('ViewField', name)}>
      <Typography variant="subtitle2">{renderLabel}</Typography>
      {renderValue &&
        <Typography variant="body2">{renderValue}</Typography>
      }
      {children}
    </div>
  );
}

ViewField.propTypes = {
  /** The path to the translation keys for this component */
  i18nPath: PropTypes.string,

  /** A custom class name for this component */
  className: PropTypes.string,

  /** When to render the field. Defaults to `hasValue` */
  show: PropTypes.oneOf(['always', 'hasValue', 'never']),

  /** The object whose property is being rendered by this field */
  object: PropTypes.object,

  /** The name of the property being rendered */
  name: PropTypes.string,

  /**
   * The label of the field. If not specified, the `${name}.label` key at the `i18nPath` is used
   * to lookup the translation.
   * */
  label: PropTypes.string,

  /** The value of the field to render. If not specified, the value of `object[name]` is used. */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),

  /** The components to render as underneath the value & the label */
  children: PropTypes.node,

   /** A function used to provide translations of text & UI elements. Provided by HOC. */
  t: PropTypes.func.isRequired,
};

ViewField.defaultProps = {
  show: 'hasValue',
};

export default withTranslations(ViewField);
