import React, { useState } from 'react';
import { objectMapToArray } from '@lifetools/shared-utils';
import { toNativeDate } from '@lifetools/shared-utils-time';
import Calendar from 'components/library/Calendar';
import withStyles from 'helpers/withStyles';
import { sort } from 'utils/sort';
import IntentDay from './components/IntentDay';
import IntentCalendarLegend from './components/IntentCalendarLegend';

const styles = theme => ({
  calendar: {
    borderTop: `solid 1px ${theme.palette.grey[100]}`,
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 2,

    '& .rbc-date-cell:not(.rbc-current):hover': {          // hover date
      background: 'inherit',
    },

    '& .rbc-current': {                                    // current date
      background: 'inherit',
      color: 'inherit',
    },

    '& .rbc-month-row': {
      minHeight: 60,
    },
  },
  intentDay: {
    '& button': {
      [theme.breakpoints.down('sm')]: {                    // move below date on mobile
        marginTop: '0.5em',
      },
    },
  },
});

const IntentCalendar = ({ todo, classes }) => {
  const intentList = todo.intents ? sort(objectMapToArray(todo.intents), 'date') : [];
  const initialIntent = intentList.length > 0 ? intentList[intentList.length - 1] : undefined;
  const initialDate = initialIntent ? toNativeDate(initialIntent.date) : new Date();
  const [date, setDate] = useState(initialDate);

  return (
    <div>
      <Calendar
        className={classes.calendar}
        components={{
          dateCellWrapper: props => (
            <IntentDay className={classes.intentDay} todo={todo} intents={intentList} {...props} />
          ),
        }}
        date={date}
        onNavigateDate={setDate}
        onSelectDate={setDate}
      />
      <IntentCalendarLegend />
    </div>
  );
}

export default withStyles(styles)(IntentCalendar);
