import pick from 'lodash/pick';
import moment, { Moment } from 'moment';
import { DateValue } from '../types/DateValue';
import { TimeValue } from '../types/TimeValue';
import { toMomentDate } from './toMomentDate';
import { toMomentTime } from './toMomentTime';

/**
 * Creates a new moment date-time object using separate `date` and `time` values. Optional
 * `dateFormat` and `timeFormat` values can be passed to determine how to parse the values; if not
 * specified, the values are parsed using ISO 8601. See the `toMomentDate` and `toMomentTime`
 * functions for details of the format.
 *
 * @param date       - The date part of the date-time to create
 * @param time       - The time part of the date-time to create
 * @param dateFormat - The format to use for converting the date part
 * @param timeFormat - The format to use for converting the time part
 *
 * @returns A `moment` instance of the date-time
 */
export function createMomentDateTime(
  date: DateValue, time: TimeValue, dateFormat?: string, timeFormat?: string
): Moment {
  const momentDate = toMomentDate(date, dateFormat);
  const momentTime = toMomentTime(time, timeFormat);

  if (!momentDate) {
    throw new Error(`Could not parse the value '${date}' into a valid date`);
  }

  if (!momentTime) {
    throw new Error(`Could not parse the value '${time}' into a valid time`);
  }

  return moment({
    ...momentDate.toObject(),
    ...pick(momentTime.toObject(), 'hours', 'minutes', 'seconds'),
  });
}
