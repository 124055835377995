import cloneDeep from 'lodash/cloneDeep';
import drop from 'lodash/drop';
import getValue from 'lodash/get';
import hasKey from 'lodash/has';
import setValue from 'lodash/set';
import toPath from 'lodash/toPath';
import { getChildPaths, getExistingPathTo } from '@lifetools/shared-utils';
import { Id } from '../documents/types/Id';
import { IDocumentData } from '../documents/types/IDocumentData';

interface ChangeListener {
  (): void;
}

/**
 * The changes being applied to a document.
 */
class DocumentChanges {
  /**
   * The values to save to this document.
   */
  public values?: IDocumentData;

  /**
   * The change listener called whenever the data for this object changes.
   */
  private changeListener?: ChangeListener;

  /**
   * Returns the change listener for this object.
   */
  public get onChange(): ChangeListener | undefined {
    return this.changeListener;
  }

  /**
   * Sets the change listener for this object.
   */
  public set onChange(listener: ChangeListener | undefined) {
    this.changeListener = listener;
  }

  /**
   * Constructs a new set of changes.
   *
   * @param values - The values to save to this document.
   */
  constructor(values?: IDocumentData) {
    this.values = values ? { ...values } :  undefined;
  }

  /**
   * Returns an unflattened version of the values to save to this document.
   */
  public data(): IDocumentData | undefined {
    if (!this.values) {
      return undefined;
    }

    const data = {};

    for (const path of Object.keys(this.values)) {
      setValue(data, path, cloneDeep(this.values[path]));
    }

    return data;
  }

  /**
   * Returns the value at the specified property path.
   *
   * @param path - The path to lookup the value
   *
   * @returns The value at the path for the data of this snapshot
   */
  public get(path: string): any {
    return getValue(this.data(), path);
  }

  /**
   * Sets the value at the specified property path.
   *
   * If an ancestor property has already been set to an object on this changes object, then the
   * value will be merged into the full object changed; otherwise this saves an override value for
   * the specific path only.
   *
   * @param path  - The path to set
   * @param value - The value to set
   *
   * @throws If setting a value at this path would overwrite child paths alreay set
   * @throws If an ancestor property has been set to a non-object value
   */
  public set(path: string, value: any): void {
    if (!this.values) {
      this.values = {};
    }

    if (path.trim() === '') {
      throw new Error('The `path` parameter must have at least one element');
    }

    const childPaths = getChildPaths(this.values, path);

    if (childPaths.length > 0) {
      throw new Error(`The path ${path} would overwrite these existing child paths: ` +
                      childPaths.join(', '));
    }

    const existingPath = getExistingPathTo(this.values, path);
    const subPathElements = drop(toPath(path), toPath(existingPath).length);
    const subPath = subPathElements.join('.');

    if (existingPath === '') {                                    // no object tree along path
      this.values[path] = value;
    } else if (subPath === '') {                                  // full object tree to path
      setValue(this.values, path, value);
    } else {                                                      // parent object tree in path
      let existingParent = getValue(this.values, existingPath);

      // If the existing parent path isn't an object, convert it to an object before we set the
      // subpath
      if (!existingParent || typeof existingParent !== 'object') {
        throw new Error(`The path ${path} would overwrite a non-object value at the existing` +
                        ` path: ${existingPath}`);

        // TODO: If we decide to support overrides of issues like this, this code should make this
        //       work. [twl 20.Apr.19]
        //
        // existingParent = {};
        // setValue(this.values, existingPath, existingParent);
      }

      setValue(existingParent, subPath, value);

      if (this.onChange) {
        this.onChange();
      }
    }
  }

  /**
   * Returns true if a key exists at the `path` in this snapshot.
   *
   * @param path - The path to check
   *
   * @returns `true` if the path exists in this snapshot; `false` otherwise
   */
  public has(path: string): any {
    return hasKey(this.data(), path);
  }
}

export default DocumentChanges;
