import React from 'react';
import PropTypes from 'prop-types';
import FullWidthLayout from 'components/layouts/FullWidthLayout';
import SplitLayout from 'components/layouts/SplitLayout';
import StackedLayout from 'components/layouts/StackedLayout';
import Carousel from 'components/library/Carousel';
import { FeatureFlags } from 'contexts/FeatureFlags';
import withDeviceInfo from 'helpers/withDeviceInfo';
import countNonNullChildren from 'components/utils/countNonNullChildren';

/**
 * Renders a component with props without passing the view props into the component, which can cause
 * warnings to be displayed if the props are passed into a DOM component.
 */
const ViewLayoutElement = ({
  viewI18nPath, viewTitle, viewHelp, viewColumn, viewOrder, component: Component, ...props
}) => (
  <Component {...props} />
);

/**
 * Arranges the children of this component into the specified layout, using a separate mobile layout
 * if viewing the layout on a mobile device.
 */
const ViewLayout = ({ layout, mobileLayout = 'carousel', isMobileDevice, children, ...props }) => {
  switch (isMobileDevice ? (mobileLayout || layout) : layout) {
    case 'split':
      return countNonNullChildren(children) === 1 ?
        <FullWidthLayout>{children}</FullWidthLayout> :
        <SplitLayout {...props}>{children}</SplitLayout>;

    case 'stacked':
      return <StackedLayout {...props} isMobileDevice={isMobileDevice}>{children}</StackedLayout>;

    case 'carousel':
      return <Carousel {...props}>{children}</Carousel>;

    case 'fullWidth':
    default:
      return <FullWidthLayout>{children}</FullWidthLayout>;
  }
}

ViewLayout.propTypes = {
  /** The type of layout to use to layout the children of this component. Defaults to `fullWidth` */
  layout: PropTypes.oneOf([ 'split', 'stacked', 'carousel', 'fullWidth' ]),

  /** The type of layout to use when viewed on a mobile device. Defaults to `carousel` */
  mobileLayout: PropTypes.oneOf([ 'split', 'stacked', 'carousel', 'fullWidth' ]),

  /** The components to render within this layout */
  children: PropTypes.node,

  /** Whether the current device is a mobile device. Provided by HOC. */
  isMobileDevice: PropTypes.bool.isRequired,
};

ViewLayout.Element = ViewLayoutElement;

/**
 * Temporary wrapper for ViewLayout that alters the layout used based on a feature flag.
 */
const ViewLayoutWrapper = props => (
  <FeatureFlags.Consumer>
    {flags =>
      <ViewLayout {...props} mobileLayout={flags.stackedMobileUi ? 'stacked' : 'carousel'} />
    }
  </FeatureFlags.Consumer>
);

export default withDeviceInfo(ViewLayoutWrapper);
