import { mapKeys } from 'lodash';
import { encodeDocumentPathElement } from './encodeDocumentPathElement';

/**
 * Convert each of the keys in `object` into an update path, prefixing each path with `prefix`.
 *
 * @param object - The object whose keys should be converted to update paths
 * @param prefix - The path to prefix to each key
 *
 * @returns An object with all of the keys prefixed with `prefix` and with any special characters
 *          in keys encoded
 */
export const mapKeysToUpdatePaths = (object: object, prefix: string): object => (
  mapKeys(object, (_value, key) => (prefix ? `${prefix}.` : '') + encodeDocumentPathElement(key))
);
