import React from 'react';
import { compose } from 'recompose';
import TextField from 'components/library/TextField';
import Typography from 'components/library/Typography';
import withStyles from 'helpers/withStyles';
import withTranslations from 'helpers/withTranslations';

// TODO: Update this path to match the path to this component if we decide to use this variation.
//       [twl 4.Apr.22]
const i18nPath = 'scenes.app.Today.components.NoTodayViewSurveyWithButtons.components.WhichPlanDate.components.PlanDateButton';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  date: {
    marginLeft: theme.spacing.unit * 4,
  },
});

const PlanDateOption = ({ currentPlanDate, planDate, onChange, classes, t }) => (
  <div className={classes.root}>
    <Typography variant="subtitle1">{t(i18nPath, `button-${planDate}`)}</Typography>

    {planDate === 'future' &&
      <TextField
        className={classes.date}
        type="date"
        value={currentPlanDate || ''}
        onChange={e => onChange && onChange(e.target.value)}
      />
    }
  </div>
);

export default compose(
  withTranslations,
  withStyles(styles),
)(PlanDateOption);
