import analytics from 'services/analytics';
import { getVersionAsInteger } from '../../../../../utils/app/getVersionAsInteger';
import { isIntegrationTestEnvironment } from '../../../../../utils/app/isIntegrationTestEnvironment';

export default async function clearFeatureUser() {
  if (isIntegrationTestEnvironment()) {
    return;
  }

  analytics.identify(null, {
    loggedIn: false,
    appVersionNumber: getVersionAsInteger(process.env.REACT_APP_VERSION),
  });
}
