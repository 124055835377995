import React from 'react';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'components.app.todos.ActionButton.components.AddActionButton';

function handleEvent(event, onAction, onStatusMenu, value, intent) {
  event.stopPropagation();

  if (onStatusMenu && event.altKey) {
    onStatusMenu(event);
  } else {
    onAction(value, intent);
  }
}

const AddActionButton = ({
  className,
  value,
  intent,
  onAdd,
  onStatusMenu,
  t,
}) => {
  if (!onAdd) {
    throw new Error(`'onAdd' is a required prop`);
  }

  return (
    <IconButton
      className={className}
      onClick={e => handleEvent(e, onAdd, onStatusMenu, value, intent)}
      tooltip={t(i18nPath, 'label')}
      data-action="commit"
    >
      <Icon type={t(i18nPath, 'icon')} />
    </IconButton>
  );
}

// TODO: Add propTypes to enforce runtime rules.

export default withTranslations(AddActionButton);
