import React from 'react';
import CheckboxMui from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Checkbox = ({ label, labelClasses, ...props }) => (
  label ?
    <FormControlLabel
      classes={labelClasses}
      control={<CheckboxMui {...props} />}
      label={label}
    />
    :
    <CheckboxMui {...props} />
);

export default Checkbox;
