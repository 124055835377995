import mapKeys from 'lodash/mapKeys';
import { services } from '@lifetools/shared-services';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateRemoteUserDocument from 'services/store/utils/actions/updateRemoteUserDocument';
import { addUpdateProperty } from 'utils/data/addUpdateProperty';
import { updateExistsAtPath } from 'utils/data/updateExistsAtPath';
import { collection, types } from '../definitions';

const updateCalendarSettings = makeAsyncActionCreator({
  type: types.SET_USER_SETTINGS,
  // NOTE: Using the `encodedId` here instead of the `id` because when we load the settings, we're
  //       not decoding the calendar IDs, and there's no need to do a separate decode in the event
  //       handler that calls this just to re-encode there. [twl 27.Jan.21]
  creator: async (encodedId, changes) => {
    if (updateExistsAtPath(changes, 'settings.sync.state')) {
      changes = addUpdateProperty(changes, 'settings.sync.at',
                                  services.database.fieldValues.serverTimestamp());
    }

    return {
      settings: await updateRemoteUserDocument(collection, mapKeys(changes,
        (value, key) => `calendars.infos.${encodedId}.${key}`
      )),
    };
  },
  status: {
    path: collection,
    type: 'write',
    parameters: () => 'user',
  }
});

export default updateCalendarSettings;
