import { ITriggerRule, TriggerContext } from '@lifetools/shared-database';
import { services } from '@lifetools/shared-services';

/**
 * Updates the `at` and `last` history properties of the `status` object for an intent.
 */
const intentStatusHistory: ITriggerRule = {
  stage: 'before',
  operation: 'write',
  collection: 'todos',
  func: async ({ before, changes, after }: TriggerContext) => {
    if (!changes.values || !changes.has('intents')) {
      return;
    }

    const afterIntents = after.get('intents');

    if (afterIntents) {
      for (const id of Object.keys(afterIntents)) {
        const statusPath = `intents.${id}.status`;

        if (changes.has(statusPath)) {
          const oldState = before.get(`${statusPath}.state`);
          const newState = changes.get(`${statusPath}.state`);

          changes.set(`${statusPath}.at`, services.database.fieldValues.serverTimestamp());

          if (oldState && newState && oldState !== newState) {
            changes.set(`${statusPath}.last`, oldState);
          }
        }
      }
    }
  },
};

export default intentStatusHistory;
