import { toLocalTime } from '@lifetools/shared-utils-time';

const createScheduleFromNow = (type, date, startTime, endTime) => ({
  type,
  date,
  startTime: startTime && toLocalTime(startTime),
  endTime: endTime && toLocalTime(endTime),
  defaultStartTime: '9:00',
  defaultEndTime: '17:00',
});

export default createScheduleFromNow;
