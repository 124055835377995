import get from 'lodash/get';
import { IIntent, IIntentStatusState } from '@lifetools/shared-app-types';

/**
 * Maps intent status states to an intent with that status. Includes the special `unknown` status
 * when the status is missing.
 */
interface IIntentStatusStateMap {
  [state: string]: IIntent,
}

/**
 * Returns an object that maps intent status states to the earliest intent with that state from the
 * array of intents.
 *
 * @param intents - An array of intents
 *
 * @returns An object mapping intent status states to the earliest intent with that state; intents
 *          with a missing state are listed under the `unknown` key
 */
function createEarliestIntentStateMap(intents?: IIntent[]): IIntentStatusStateMap {
  const states: IIntentStatusStateMap = {};

  if (intents) {
    for (const intent of intents) {
      const state: (IIntentStatusState | 'unknown') = get(intent, 'status.state', 'unknown');

      if (!states[state] || states[state].date.isAfter(intent.date)) {
        states[state] = intent;
      }
    }
  }

  return states;
}

export default createEarliestIntentStateMap;
