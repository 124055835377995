import { LocalDate as JodaLocalDate, LocalTime as JodaLocalTime } from 'js-joda';
import { LocalTime, toNativeDate } from '@lifetools/shared-utils-time';
import { ICollectionQueryValue } from '../types/ICollectionQueryValue';

/**
 * Normalizes the value so it can be used in a database query.
 *
 * @param value - The original value
 *
 * @returns The value in a form acceptable for a Firebase query.
 */
export function normalizeForQuery(value: ICollectionQueryValue): ICollectionQueryValue {
  return value instanceof JodaLocalDate
    ? toNativeDate(value)
    : ((value instanceof LocalTime || value instanceof JodaLocalTime) ? value.toString() : value);
}
