import { PlainObject } from '@lifetools/shared-utils';

// Characters that are not allowed in Firestore property keys
export const pathEncodings: PlainObject = {
  '*': '%2A',
  '~': '%7E',
  '/': '%2F',
  '[': '%5B',
  ']': '%5D',
  '.': '%2E',
  '#': '%23',              // allowed by Firestore, but use as a special character in our encoding
};
