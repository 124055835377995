import React from 'react';
import { Redirect as RedirectTo } from 'react-router-dom';
import { withRouter } from 'react-router';
import { resolveRoute } from 'utils/app/routes/resolveRoute';
import { setSessionValue } from 'utils/browser/setSessionValue';

/**
 * Redirects to the `to` prop, resolving the value of the prop using the current route parameters
 * first.
 *
 * For instance, if `to` is `/plan/:date/schedule` and the current URL is `/plan/2020-11-24` for the
 * route `/plan/:date`, then the redirected route will be `/plan/2020-11-24/schedule`.
 *
 * @param to          - A string or object specifying where to redirect to
 * @param saveFromUrl - Saves the current location into the `redirectFrom` key in session storage
 * @param ...other    - See `Redirect` in `react-router-dom`
 */
const Redirect = ({ match, to, saveFromUrl, location, ...props }) => {
  if (saveFromUrl) {
    const { pathname, search, hash } = location;

    setSessionValue('redirectFrom', pathname + search + hash);
  }

  return (
    <RedirectTo {...props} to={resolveRoute(to, match.params)} />
  );
}

export default withRouter(Redirect);
