import React from 'react';
import { LocalDate } from 'js-joda';
import Page from 'components/app/navigation/Page';
import Loader from 'components/library/Loader';
import withImportedAppointments from 'helpers/withImportedAppointments';
import { withRoutedDate } from 'helpers/withRoutedDate';
import { withSchedule } from 'helpers/withSchedule';
import withTranslations from 'helpers/withTranslations';
import { getActivePlanType } from 'utils/schedules/getActivePlanType';
import { formatLocalDate } from 'utils/time';
import NoTodayBaseView from './components/NoTodayBaseView';

const BaseContent = ({
  scheduleView: PlanView,
  schedule,
  intents,
  scheduleStatus,
  todoStatus,
  ...props
}) => (
  <Loader status={[scheduleStatus, todoStatus]}>
    {getActivePlanType(schedule, intents)
      ? <PlanView schedule={schedule} intents={intents} {...props} />
      : <NoTodayBaseView schedule={schedule} {...props} />
    }
  </Loader>
);

const BaseContentWithSchedule = withSchedule(withImportedAppointments(BaseContent), 'date');

const TodayBase = ({ i18nPath, t, routeDate = LocalDate.now(), ...props }) => (
  <Page
    title={formatLocalDate(routeDate, t(i18nPath, 'date-format'))}
    docTitle={t(i18nPath, 'doc-title')}
  >
    <BaseContentWithSchedule {...props} i18nPath={i18nPath} date={routeDate} />
  </Page>
);

export default withRoutedDate(withTranslations(TodayBase));
