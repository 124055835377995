import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PrimaryMenu from 'components/app/navigation/PrimaryMenu';
import ErrorBoundary from 'components/code/ErrorBoundary';
import CssBaseline from 'components/library/CssBaseline';
import Drawer from 'components/library/Drawer';
import Icon from 'components/library/Icon';
import IconButton from 'components/library/IconButton';
import PageHeader from 'components/library/PageHeader';
import Toolbar from 'components/library/Toolbar';
import { MaxHeight } from 'contexts/MaxHeight';
import withStyles from 'helpers/withStyles';
import { testProps } from 'utils/test/testProps';
import SubscriptionNotification from 'widgets/account/SubscriptionNotification';

const i18nPath = 'components.app.navigation.Page';

const styles = theme => ({
  root: {
    display: 'flex',
    background: theme.palette.custom.page.background,
    height: '100vh',

    '@media print': {
      background: theme.palette.common.white,
    },
  },
  drawer: {
    width: theme.layout.pageDrawer.width,
    flexShrink: 0,

    '@media print': {
      display: 'none',
    },
  },
  drawerPaper: {
    width: theme.layout.pageDrawer.width,
    background: theme.palette.common.black,
  },
  logo: {
    width: theme.layout.pageDrawerLogo.width,
    display: 'block',
    margin: '0 auto',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: `calc(100% - ${theme.layout.pageDrawer.width}px)`,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'inherit',
    },

    '@media print': {
      display: 'block',
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    '& > *': {
      flex: '1 1 auto',
    },
  },
  toolbarSpacer: theme.mixins.toolbar,
});

class AuthenticatedPage extends Component {
  static propTypes = {
    /** The path from which to load localization strings for this component */
    i18nPath: PropTypes.string,

    /** The title of this page */
    title: PropTypes.string.isRequired,

    /** The subtitle of this page */
    subtitle: PropTypes.string,

    /**
     * The path to navigate to when the Back button is pressed. Setting this will cause the Back
     * button to be displayed as the secondary button.
     */
    backTo: PropTypes.string,

    /** An object mapping semantic class names to compiled class names. Provided by HOC. */
    classes: PropTypes.object.isRequired,

    /** The breakpoint of the current device screen width. Provided by HOC. */
    width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

    /** The router history used to navigate between routes. Provided by HOC. */
    history: PropTypes.object,

    /** The components to render as the content of this page */
    children: PropTypes.node,
  };

  state = {
    open: false,
    flowHeight: undefined,
  };

  doDrawerOpen = () => {
    this.setState({ open: true });
  };

  doDrawerClose = () => {
    this.setState({ open: false });
  };

  doBackTo = () => {
    const { history, backTo } = this.props;

    history.push(backTo);
  };

  render() {
    const { open, flowHeight } = this.state;
    const { t, classes, width } = this.props;
    const { i18nPath: pageI18nPath, title, subtitle, backTo, children } = this.props;
    const isDrawerCloseable = !isWidthUp('md', width);
    const isDrawerOpen = isDrawerCloseable ? open : true;
    const renderTitle = title || t(pageI18nPath, 'title', { defaultValue: '' });
    const renderSubtitle = subtitle || t(pageI18nPath, 'subtitle', { defaultValue: '' });
    const maxHeight = flowHeight ? window.innerHeight - flowHeight : undefined;
    const style = maxHeight ? { height: maxHeight } : undefined;

    return (
      <div className={classes.root} style={style} {...testProps('AuthenticatedPage')}>
        <CssBaseline />

        <Drawer
          data-role="drawer"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          open={isDrawerOpen}
          variant={isDrawerCloseable ? 'temporary' : 'persistent'}
          elevation={0}
          disableBackdropTransition={true}
          disableDiscovery={true}
          onOpen={this.doDrawerOpen}
          onClose={this.doDrawerClose}>

          <Toolbar disableGutters>
            <img className={classes.logo} src="/images/logo.png" alt={t('app.title')} />
          </Toolbar>

          <PrimaryMenu />

        </Drawer>

        <PageHeader
          title={renderTitle !== '' ? renderTitle : undefined}
          subtitle={renderSubtitle !== '' ? renderSubtitle : undefined}
          primaryButton={isDrawerCloseable &&
            <IconButton
              data-action="menu"
              color="inherit"
              aria-label={t(i18nPath + '.drawer-open-button')}
              onClick={this.doDrawerOpen}>

              <Icon type="fas:bars" />

            </IconButton>
          }
          secondaryButton={backTo &&
            <IconButton
              data-action="back"
              color="inherit"
              aria-label={t(i18nPath + '.back-button')}
              onClick={this.doBackTo}>

              <Icon type="fas:chevron-left" />

            </IconButton>
          } />

        <main className={classes.main}>
          <div className={classes.toolbarSpacer} />

          <ErrorBoundary>
            <SubscriptionNotification />

            <div className={classes.content}>
              <MaxHeight.Provider value={maxHeight}>
                {children}
              </MaxHeight.Provider>
            </div>
          </ErrorBoundary>
        </main>
      </div>
    );
  }
}

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  withWidth(),
  withRouter,
)(AuthenticatedPage);
