import React, { Fragment } from 'react';
import classnames from 'classnames';
import Toolbar from 'components/library/Toolbar';
import withStyles from 'helpers/withStyles';

const styles = theme => ({
  root: {
    background: theme.palette.custom.actionBar.background,
    justifyContent: 'center',

    '& > *:not(:first-child)': {
      marginLeft: theme.spacing.unit,
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',

      '& > *:not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
});

const ActionBarComponent = ({ className, controls, pane, classes }) => (
  <Fragment>
    <Toolbar className={classnames(classes.root, className)} variant="dense" disableGutters={true}>
      {controls}
    </Toolbar>

    {pane}
  </Fragment>
);

export default withStyles(styles)(ActionBarComponent);
