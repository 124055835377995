import { get } from 'lodash';
import { UserId } from '@lifetools/shared-services';
import { getPaths } from '@lifetools/shared-utils';
import { ICollectionQuery } from '../queries/types/ICollectionQuery';

const normalize = (value: any) => value instanceof Date ? value.toISOString() : value;

/**
 * Returns the subscription ID for a subscription created for the `authUserId` for the `query` set
 * of documents within the `collection`.
 *
 * @param authUserId - The ID of the current authenticated user
 * @param collection - The collection to query against
 * @param query      - The query specifying the documents to be retrived, or `undefined` to return
 *                     all documents
 *
 * @returns the canonical ID for this subscription
 */
export function createSubscriptionId(
  authUserId: UserId | undefined,
  collection: string,
  query: ICollectionQuery,
): string {
  const queryPaths = query ? getPaths(query) : [];
  const queryEncoded = queryPaths.map(path => `${path}:${normalize(get(query, path))}`).join(',');

  return `${authUserId}|${collection}|${queryEncoded}`;
}
