import { makeEnum } from 'services/store/utils-actions';

export const collection = 'schedules';

export const types = makeEnum(
  'ADD_SCHEDULE',
  'SET_SCHEDULES',
  'UPDATE_SCHEDULE',
  'DELETE_SCHEDULE',
);

export const statusTypes = {
  querySchedules: 'read',
  querySchedulesByDate: 'read',
  saveScheduleStep: 'write',
  updateSchedule: 'write',
};
