import countBy from 'lodash/countBy';
import get from 'lodash/get';
import { ChronoUnit } from 'js-joda';
import { services } from '@lifetools/shared-services';
import { toLocalTime } from '@lifetools/shared-utils-time';
import analytics from 'services/analytics';
import store from 'services/store';
import { makeAsyncActionCreator } from 'services/store/utils-actions';
import updateSchedule from 'services/store/schedules/actions/updateSchedule';
import selectUserSettings from 'services/store/settings/selectors/selectUserSettings';
import { collection, statusTypes, types } from '../definitions';

const statuses = [ 'new', 'committed', 'allocated', 'scheduled' ];
const workflows = [ 'commit', 'allocate', 'schedule' ];
const workflowStepLookup = {
  new: undefined,
  committed: 'commit',
  allocated: 'allocate',
  scheduled: 'schedule',
};

/**
 * Calculates how many intents were configured on the final step of the workflow for a plan with
 * the type `planType`.
 *
 * @param {'commit' | 'allocate' | 'schedule'} planType - the type of plan being created
 * @param {IIntent[]}                          intents  - the intents belonging to the plan
 *
 * @returns a number from 0.0 to 1.0 indicating which percentage of the intents were configured on
 *          the final step of the workflow (e.g. scheduled for schedule workflows, allocated for
 *          allocated workflows)
 */
function calculateCompleteness(planType, intents) {
  const count = planType === 'commit'
    ? intents.length
    : planType === 'allocate'
      ? countBy(intents, intent => intent.duration ? 'allocated' : 'unallocated').allocated
      : countBy(intents, intent => intent.start ? 'scheduled' : 'unscheduled').scheduled;

  return intents && intents.length ? (count || 0) / intents.length : 0;
}

const saveScheduleStep = makeAsyncActionCreator({
  type: types.UPDATE_SCHEDULE,
  creator: async (schedule, intents, workflow, status) => {
    const startTime = toLocalTime(schedule.startTime);
    const endTime = toLocalTime(schedule.endTime);
    const scheduleDuration = startTime && endTime ? startTime.until(endTime, ChronoUnit.MINUTES) : 0;
    const intentsDuration = intents.reduce((total, intent) => total + (intent.duration || 0), 0);
    const fullness = intentsDuration / scheduleDuration;
    const completeness = calculateCompleteness(schedule.type, intents);
    const isFinalStep = workflowStepLookup[status] === schedule.type;
    const updateEdits = isFinalStep && status === schedule.status;
    const updateStatus = statuses.indexOf(status) > statuses.indexOf(schedule.status);
    const updateType = workflows.indexOf(workflow) > workflows.indexOf(schedule.type);
    const updates = {
      'info.intents': {
        count: intents.length,
        duration: intentsDuration,
        fullness,
        completeness,
      },
      ...updateEdits && {
        'info.edits.count':  services.database.fieldValues.increment(1),
        'info.edits.lastAt': services.database.fieldValues.serverTimestamp(),
      },
      ...updateStatus && {
        status,
      },
      ...updateType && {
        type: workflow,
      },
    };

    const saved = await store.dispatch(updateSchedule(schedule.id, updates));
    const userSettings = selectUserSettings(store.getState());
    const analyticsParams = {
      workflow_type: schedule.type,
      workflow_step: workflowStepLookup[status],
      schedule_date: schedule.date,
      schedule_intents: intents.length,
      schedule_duration: intentsDuration,
      schedule_fullness: fullness,
      schedule_completeness: completeness,
      schedule_count: get(userSettings, 'info.schedules.count', 0),
      edit_count: (schedule.info && schedule.info.edits && schedule.info.edits.count) || 0,
    };

    analytics.track(`workflow_plan_step`, analyticsParams);

    if (isFinalStep) {
      analytics.track('workflow_plan_done', analyticsParams);
    }

    return saved;
  },
  status: {
    path: collection,
    type: statusTypes.saveScheduleStep,
    parameters: (id, _changes) => [ id ],
  }
});

export default saveScheduleStep;
