import { IIntent, IIntentStatusState, ITodo, ITodoStatusState } from '@lifetools/shared-app-data';
import { IStatusConfig } from '../types/IStatusConfig';

/**
 * Returns the status state of the `intent`, if defined, otherwise the `todo` based on the `config`.
 * If the state does not exist in the `config.statuses`, then `unknown` is returned as the statw.
 *
 * @param config - The configuration for the todo or intent
 * @param todo   - The todo whose state to use if `intent` is undefined
 * @param intent - The intent whose state to use
 *
 * @return The state, or `unknown` if the state doesn't exist in the config.
 */
export function getState(
  config: IStatusConfig<ITodoStatusState> | IStatusConfig<IIntentStatusState>,
  todo: ITodo,
  intent?: IIntent
): ITodoStatusState | IIntentStatusState | 'unknown'
{
  // TODO: Figure out why I need `any` here. I should be able to do `ITodoStatus | IIntentStatus`,
  //       but when I do that, I get an error with the `status` passed into `indexOf` below.
  //       [twl 13.Mar.19]
  const status: any = intent ? intent.status : todo.status;

  return config.statuses.indexOf(status.state) !== -1 ? status.state : 'unknown';
};
