/**
 * Returns whether the `todo` has an intent. If `schedule` is specified, returns whether `todo` has
 * an intent for the `schedule`; otherwise returns whether `todo` has any intent.
 *
 * @param {object} todo     The todo object to check for intent
 * @param {object} schedule The optional schedule to check intent for
 *
 * @return {boolean} `true` if the `todo` has an intent for the `schedule`, or if `schedule` is
 *                   unset, `true` if the `todo` has any intent; false otherwise
 */
const hasIntent = (todo, schedule) => (
  !!(todo.scheduleIds && todo.scheduleIds.length > 0 &&
      (!schedule ||
       (schedule && schedule.id && todo.scheduleIds.includes(schedule.id))))
);

export default hasIntent;
