import React, { Component } from 'react';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { toNativeDate } from '@lifetools/shared-utils-time';
import CalendarToolbar from './components/CalendarToolbar';
import DateCellWrapper from './components/DateCellWrapper';
import MonthEvent from './components/MonthEvent';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

const localizer = momentLocalizer(moment);

class Calendar extends Component {
  static defaultProps = {
    defaultDate: moment(),
    onSelectDate: () => {},
  };

  selectDate = (date, view) => this.props.onSelectDate(date, view);

  onSelectSlot = event => this.selectDate(event.start, 'detail');
  onDrillDown = date => this.selectDate(date, 'detail');
  onNavigate = date => this.selectDate(date);

  render() {
    const { events, components, onSelectEvent, ...props } = this.props;
    const date = toNativeDate(props.date || props.defaultDate);

    return (
      <BigCalendar {...props}
                   events={events || []}
                   localizer={localizer}
                   selectable
                   date={date}
                   defaultDate={date}
                   onDrillDown={this.onDrillDown}
                   onSelectEvent={onSelectEvent}
                   onSelectSlot={this.onSelectSlot}
                   onNavigate={this.onNavigate}
                   components={{
                     toolbar: CalendarToolbar,
                     month: {
                       event: MonthEvent,
                     },
                     dateCellWrapper: props => (
                       <DateCellWrapper onSelect={this.selectDate} {...props} />
                     ),
                     ...components
                   }} />
    );
  }
}

export default Calendar;
