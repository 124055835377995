import React from 'react';
import { services } from '@lifetools/shared-services';
import Button from 'components/library/ButtonMui';
import withTranslations from 'helpers/withTranslations';

const i18nPath = 'components.app.account.SubscriptionButton';
const subscribeUrl = 'https://dayoptimizer.com/subscribe-now/';

// currentUser
const onUpgrade = promo => window.open(
  `${subscribeUrl}?email=${encodeURIComponent(services.auth.api.currentUser().email)}` +
  (promo ? `&promo=${encodeURIComponent(promo)}` : '')
);

const SubscriptionButton = ({ className, promo, color, t }) => (
  <Button className={className} color={color} variant="contained" onClick={() => onUpgrade(promo)}>
    {t(i18nPath, 'label')}
  </Button>
);

export default withTranslations(SubscriptionButton);
