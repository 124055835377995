import moment, { Moment } from 'moment';
import { LocalTime as JodaLocalTime, DateTimeFormatter, nativeJs } from 'js-joda';
import { LocalTime } from '../LocalTime';
import { TimeValue } from '../types/TimeValue';

const highPrecisionTimeFormat = 'HH:mm:ss.SSS';
const highPrecisionTimeFormatter = DateTimeFormatter.ofPattern(highPrecisionTimeFormat);
const defaultTimeFormatter = DateTimeFormatter.ofPattern('H:mm[:ss[.SSS]]');
const timeFormatters: { [key: string]: DateTimeFormatter } = {};

/**
 * Converts the `value` to a `LocalTime` instance, using the `format` to parse the time. If not
 * specified, the format parses the time as an ISO local time.
 *
 * The `format` is the format used by `LocalTime`. The supported pattern letters are:
 *
 * -----------------------------------------------------------------
 * | Letter | Time Segment               | Type             | e.g. |
 * -----------------------------------------------------------------
 * | H      | hour-of-day (0-23)         | number           | 0    |
 * | h      | clock-hour-of-am-pm (1-12) | number           | 12   |
 * | m      | minute-of-hour             | number           | 30   |
 * | s      | second-of-minute           | number           | 55   |
 * | S      | fraction-of-second         | fraction         | 978  |
 * | a      | am-pm-of-day               | text             | PM   |
 * -----------------------------------------------------------------
 *
 * Other pattern letters may work, but may not be supported in the future.
 *
 * @param value  - The time value to convert
 * @param format - The format to use for the conversion, or `undefined` to use the default format
 *
 * @returns An `LocalTime` instance of the time
 */
export function toLocalTime(value: TimeValue, format?: string): JodaLocalTime | undefined {
  if (!value) {
    return undefined;
  }

  if (value instanceof JodaLocalTime) {
    return value;
  }

  if (format && !timeFormatters[format]) {
    timeFormatters[format] = DateTimeFormatter.ofPattern(format);
  }

  let formatter = (format && timeFormatters[format]) || defaultTimeFormatter;

  // HACK: Check if ISO formatted datetime by looking for ending `Z` [twl 12.Oct.18]
  if (typeof value === 'string' && value.endsWith('Z')) {
    value = moment(value);
  }

  if (value instanceof moment) {
    value = (value as Moment).format(highPrecisionTimeFormat);     // This must be a `moment` format
    formatter = highPrecisionTimeFormatter;
  } else if (value instanceof LocalTime) {
    // HACK: To support the new `LocalTime` class until we can migrate to it. [twl 6.Feb.18]
    value = value.toJodaLocalTime();
  }

  return value instanceof Date
    ? JodaLocalTime.from(nativeJs(value))
    : (value instanceof JodaLocalTime ? value : JodaLocalTime.parse(value.toString(), formatter));
}
