import { escapeRegExp } from './escapeRegExp';

/**
 * Replaces all occurrences of `find` within `str` with `replace`.

 * @param str     - The string to search
 * @param find    - The text to search for
 * @param replace - The value to replace the `find` value with
 *
 * @returns A new string with all instances of `find` in `str` replaces with `replace`
 */
export const replaceAll = (str: string, find: string, replace: string): string => (
  // TODO OPTIMIZATION: Cache the `RegExp` based on `find` [twl 29.Jul.19]
  str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
);
