export interface AspectRatio {
  width: number;
  height: number;
}

/**
 * Parses an aspect ratio in the form `width:height` into a decimal suitable for formatting
 *
 * @param aspectRatio A string representation of an aspect ratio
 *
 * @returns An object containing the width and height of the aspect ratio
 */
export function parseAspectRatio(aspectRatio: string): AspectRatio | undefined {
  if (!aspectRatio || aspectRatio.indexOf(':') === -1) {
    return undefined;
  }

  const [ widthText, heightText ] = aspectRatio.split(':');
  const width = parseFloat(widthText);
  const height = parseFloat(heightText);

  return Number.isFinite(width) && Number.isFinite(height) ? { width, height } : undefined;
}
