import without from 'lodash/without';
import React, { useEffect, useRef } from 'react';
import Form from 'components/library/Form';
import withStyles from 'helpers/withStyles';
import DayOfWeekIcon from './components/DayOfWeekIcon';

// For this control, show Sunday as the first day
const daysOfWeek = [ '7', '1', '2', '3', '4', '5', '6' ];

const styles = theme => ({
  checkbox: {
    padding: theme.spacing.unit * 1.5,

    // These are required to make it fit on a single line on mobile devices with 320px width
    '&:first-child': {
      paddingLeft: 0,
    },

    '&:last-child': {
      paddingRight: 0,
    },
  },
  label: {
    margin: 0,
  },
  hideLabel: {
    display: 'none',
  },
});

const DayOfWeekSelector = ({ name, value, onChange = () => {}, classes, ...props }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    // HACK: If another form element has focus when this element is rendered (e.g., when it is
    //       conditionally displayed after the user selects `specificDays`` for the `repeatType`),
    //       the first checkbox require two clicks to select--one to grab focus and the second to
    //       select. By focusing & blurring the selector, the first checkbox clicked now only
    //       requires a single click to select. This hack works in concert with the `ref` and
    //       `tabIndex` set on the wrapper `div` for this element. [twl 5.Mar.21]
    inputRef.current.focus();
    inputRef.current.blur();
  }, []);

  const handleChange = (dayOfWeek, checked) => {
    onChange((checked ? [ ...value, dayOfWeek ].sort() : without(value, dayOfWeek)) || []);
  };

  return (
    <div ref={inputRef} tabIndex="-1">
      {daysOfWeek.map((dayOfWeek, index) => (
        <Form.CheckboxField
          key={dayOfWeek}
          name={name}
          CheckboxProps={{
            classes: {
              root: classes.checkbox,
            },
            checked: value && value.includes(dayOfWeek),
            icon: <DayOfWeekIcon dayOfWeek={dayOfWeek} />,
            checkedIcon: <DayOfWeekIcon dayOfWeek={dayOfWeek} checked />,
            onChange: e => handleChange(dayOfWeek, e.target.checked),
          }}
          FormControlLabelProps={{
            className: classes.label,
            classes: {
              label: classes.hideLabel,
            },
          }}
        />
      ))}
    </div>
  );
}

export default withStyles(styles)(DayOfWeekSelector);
