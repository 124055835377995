import React from 'react';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

const ListItem = props => <MuiListItem {...props} />;

ListItem.muiName = 'ListItem';

ListItem.SecondaryAction = ListItemSecondaryAction;
ListItem.Text = ListItemText;

export default ListItem;
